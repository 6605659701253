import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import MftIntro from './components/mft-intro';
import MftInfo from './components/mft-info';
import ScrollToTop from './helpers/scroll-to-top';
import {APIHost} from './GlobalVariables';
import axios from 'axios';

class Mft extends Component {
constructor(props) {
	super(props);
	this.state = ({
		mobile: false
	})
	this.UpdateImpressions = this.UpdateImpressions.bind(this);
	this.CheckMobile = this.CheckMobile.bind(this);
}
componentDidMount() {
	window.scroll(0,0);
	window.addEventListener("resize", this.CheckMobile());
	this.UpdateImpressions().catch((error) => {
		console.log("Updating impressions failed");
	});
}

componentWillUnmount() {
    window.removeEventListener("resize", this.CheckMobile)
}

CheckMobile() {
	this.setState({mobile: window.innerWidth <= 760});
}

async UpdateImpressions() {
	const res = await axios.post(APIHost + '/api/updateImpressions', 
		{withCredentials: true, page: 'mft_page'});
	return await res;
}

  render() {

    return (
      <div className="Ect">
		<ScrollToTop>
		<Header />
		<MftIntro history={this.props.history} mobile={this.state.mobile} />
		<MftInfo />
		<Footer />
		</ScrollToTop>
      </div>
    );
  }
}

export default Mft;
