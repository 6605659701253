import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
import {UpdateLastActivity} from '../utilities.js';
			
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter10 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 10,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		answers: [],
		answers_before: [],
		answers_after: [],
		display_video: false,
		loading: true,
		finished: false,
		cc_exercises: false,
		ca_exercises: false,
		viruses_exercises: false,
		stretch_exercises: false,
		display_video_after: false,
		finished_exercises: false,
		finished_core_constitution: false,
		finished_core_attributes: false,
		finished_viruses: false,
		finished_daily: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		cc_language: [],
		ca_language: [],
		virus_language: [],
		viruses: [],
		daily_language: [],
		daily_exercises: false,
		past_exercises: false,
		finished_past: false,
		past_language: [],
		past_life: [],
		chapter_video: "https://player.vimeo.com/video/679873514?h=da4ae3c760",
		questions: []
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.onSubmitCC = this.onSubmitCC.bind(this);
this.SubmitCC = this.SubmitCC.bind(this);
this.SubmitCCCallback = this.SubmitCCCallback.bind(this);
this.onSubmitCA = this.onSubmitCA.bind(this);
this.SubmitCA = this.SubmitCA.bind(this);
this.SubmitCACallback = this.SubmitCACallback.bind(this);
this.onSubmitViruses = this.onSubmitViruses.bind(this);
this.SubmitViruses = this.SubmitViruses.bind(this);
this.SubmitVirusesCallback = this.SubmitVirusesCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.onRemoveEntry = this.onRemoveEntry.bind(this);
this.RemoveEntry = this.RemoveEntry.bind(this);
this.RemoveCCCallback = this.RemoveCCCallback.bind(this);
this.RemoveCACallback = this.RemoveCACallback.bind(this);
this.RemoveVirusCallback = this.RemoveVirusCallback.bind(this);
this.handleMouseUp = this.handleMouseUp.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetDaily =  this.GetDaily.bind(this);
this.SubmitDailyCallback = this.SubmitDailyCallback.bind(this);
this.onSubmitDaily = this.onSubmitDaily.bind(this);
this.SubmitDaily = this.SubmitDaily.bind(this);
this.RemoveDailyLifeCallback = this.RemoveDailyLifeCallback.bind(this);
this.GetPast = this.GetPast.bind(this);
this.onSubmitPast = this.onSubmitPast.bind(this);
this.SubmitPast = this.SubmitPast.bind(this);
this.SubmitPastCallback = this.SubmitPastCallback.bind(this);
this.RemovePastLifeCallback = this.RemovePastLifeCallback.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	UpdateLastActivity();
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, daily_exercises: false, past_exercises: false,
			cc_exercises: false, ca_exercises: false, viruses_exercises: false, display_exercises: false, started: true});
		} else if (stage.data.stage == "core_constitution") {
			this.setState({display_video: false, daily_exercises: false, past_exercises: false,
			cc_exercises: true, ca_exercises: false, viruses_exercises: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "core_attributes")  {
			this.setState({display_video: false, daily_exercises: false, past_exercises: false,
			cc_exercises: false, ca_exercises: true, viruses_exercises: false, display_exercises: true, started: true});		
		} else if (stage.data.stage == "viruses")  {
			this.setState({display_video: false, daily_exercises: false, past_exercises: false,
			cc_exercises: false, ca_exercises: false, viruses_exercises: true, display_exercises: true, started: true});		
		} else if (stage.data.stage == "daily_life") {
			this.setState({display_video: false, daily_exercises: true, past_exercises: false,
			cc_exercises: false, ca_exercises: false, viruses_exercises: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "past_life") {
			this.setState({display_video: false, daily_exercises: false, past_exercises: true,
			cc_exercises: false, ca_exercises: false, viruses_exercises: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "done") {
		this.setState({display_before_questions: false, display_video: false, 
			display_after_questions: false, display_exercises: false, finished: true, started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var temp_cc = [];
		var temp_ca = [];
		var temp_virus = [];
		var temp_daily = [];
		var temp_past = [];
		language.data.language.forEach((element) => {
			if(element.type === "core constitution") {
				temp_cc.push(element);
			} else if (element.type === "core attribute") {
				temp_ca.push(element);
			} else if (element.type === "virus") {
				temp_virus.push(element);
			} else if (element.type === "daily life") {
				temp_daily.push(element);
			} else if (element.type === "past life") {
				temp_past.push(element);
			}
		});
		this.setState({cc_language: temp_cc, ca_language: temp_ca, virus_language: temp_virus, daily_language: temp_daily, past_language: temp_past});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	var daily_promise = this.GetDaily().then((daily) => {
		this.setState({daily_life: daily.data.daily_life});
	}).catch((error) => {
		console.log("GetDaily failed " + error);
		this.setState({server_failure: true});
	});

	var past_promise = this.GetPast().then((past) => {
		this.setState({past_life: past.data.past_life});
	}).catch((error) => {
		console.log("GetPast failed " + error);
		this.setState({server_failure: true});
	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);

	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(viruses_promise);
	all_promises.push(language_promise);
	all_promises.push(past_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var ids = [];
		var original_ids = [];

		this.state.cc_language.forEach((element) => {
			original_ids.push(element.original_id);
		});
		ids = [... new Set(original_ids)];
		if (ids.length === this.state.core_constitution.length) {
			this.setState({finished_core_constitution: true});
		}
		ids = [];
		original_ids = [];
		this.state.ca_language.forEach((element) => {
			original_ids.push(element.original_id);
		});
		ids = [... new Set(original_ids)];
		if (ids.length === this.state.core_attributes.length) {
			this.setState({finished_core_attributes: true});
		}
		ids = [];
		original_ids = [];
		this.state.virus_language.forEach((element) => {
			original_ids.push(element.original_id);
		});
		ids = [... new Set(original_ids)];
		if (ids.length === this.state.viruses.length) {
			this.setState({finished_viruses: true});
		}
		ids = [];
		original_ids = [];
		this.state.daily_language.forEach((element) => {
			original_ids.push(element.original_id);
		});
		ids = [... new Set(original_ids)];
		if (ids.length === this.state.daily_life.length) {
			this.setState({finished_daily: true});
		}
		ids = [];
		original_ids = [];
		this.state.past_language.forEach((element) => {
			original_ids.push(element.original_id);
		});
		ids = [... new Set(original_ids)];
		if (ids.length === this.state.past_life.length) {
			this.setState({finished_past: true});
		}
		if(this.state.chapter_stage != 'exercises') {
			this.setState({finished_exercises: false});
		}
		this.setState({loading: false});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetDaily() {
	const res = await axios.get(APIHost + '/api/dailyLife',
		{params: {withCredentials: true}});
	return await res;
}

async GetPast() {
	const res = await axios.get(APIHost + '/api/pastLife',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({ [event.target.name]: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_current = [];
	var display_cc_language = [];
	var display_ca_language = [];
	var display_virus_language = [];
	var display_viruses = [];
	var display_daily = [];
	var display_daily_language = [];
	var display_past_language = [];
	var display_past = [];
	var display_cc = [];
	var display_ca = [];

	for (var i=0; i<this.state.core_attributes.length; i++) {
		display_ca_language = [];
		for (var j=0; j<this.state.ca_language.length; j++) {
			if (this.state.ca_language[j].original_id == this.state.core_attributes[i].id) {
				display_ca_language.push(<li>{this.state.ca_language[j].entry} <button id={this.state.ca_language[j].id} name="coreAttributes" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
			}
		}
		// just need to duplicate the name below and pass it to the mouse up function
		var temp_id = this.state.core_attributes[i].id;
		display_ca.push(
			<div><div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.core_attributes[i].attribute}</p>
			<h5>Description:</h5>
			<p>
     		{this.state.core_attributes[i].description}
  			</p>
  			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			
  			<div><ul>{display_ca_language}</ul></div>
			</div></div></div><hr /></div>
		);
	}

	for (var i=0; i<this.state.core_constitution.length; i++) {
		display_cc_language = [];
		for (var j=0; j<this.state.cc_language.length; j++) {
			if (this.state.cc_language[j].original_id == this.state.core_constitution[i].id) {
				display_cc_language.push(<li>{this.state.cc_language[j].entry} <button id={this.state.cc_language[j].id} name="coreConstitution" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
			}
		}
		var temp_id = this.state.core_constitution[i].id;
		display_cc.push(
			<div><div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.core_constitution[i].entry}</p>
			<h5>Description:</h5>
			<p>
     		{this.state.core_constitution[i].description}
  			</p>
  			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			
  			<div><ul>{display_cc_language}</ul></div>
			</div></div></div><hr /></div>
		);
	}

	for (var i=0; i<this.state.viruses.length; i++) {
		display_virus_language = [];
		for (var j=0; j<this.state.virus_language.length; j++) {
			if (this.state.virus_language[j].original_id == this.state.viruses[i].id) {
				display_virus_language.push(<li>{this.state.virus_language[j].entry} <button id={this.state.virus_language[j].id} name="viruses" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
			}
		}
		var temp_id = this.state.viruses[i].id;
		display_viruses.push(
			<div><div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.viruses[i].virus}</p>
			<h5>Description:</h5>
			<p>
     		{this.state.viruses[i].description}
  			</p>
  			
			</div>
			<div className='col-md-6'>
			<h5>The Language</h5>
			
  			<div><ul>{display_virus_language}</ul></div>
			</div></div></div><hr /></div>
		);
	}

	for (var i=0; i<this.state.daily_life.length; i++) {
		display_daily_language = [];
		for (var j=0; j<this.state.daily_language.length; j++) {
			if (this.state.daily_language[j].original_id == this.state.daily_life[i].id) {
				display_daily_language.push(<li>{this.state.daily_language[j].entry} <button id={this.state.daily_language[j].id} name="daily" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
			}
		}
		var temp_id = this.state.daily_life[i].id;
		display_daily.push(
			<div><div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.daily_life[i].entry}</p>
			<h5>Description:</h5>
			<p>
     		{this.state.daily_life[i].description}
  			</p>
  			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			
  			<div><ul>{display_daily_language}</ul></div>
			</div></div></div><hr /></div>
		);
	}

	for (var i=0; i<this.state.past_life.length; i++) {
		display_past_language = [];
		for (var j=0; j<this.state.past_language.length; j++) {
			if (this.state.past_language[j].original_id == this.state.past_life[i].id) {
				display_past_language.push(<li>{this.state.past_language[j].entry} <button id={this.state.past_language[j].id} name="daily" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
			}
		}
		var temp_id = this.state.past_life[i].id;
		display_past.push(
			<div><div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.past_life[i].entry}</p>
			<h5>Description:</h5>
			<p>
     		{this.state.past_life[i].description}
  			</p>
  			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			
  			<div><ul>{display_daily_language}</ul></div>
			</div></div></div><hr /></div>
		);
	}

	var his_text = '';
	var his_text2 = '';
	if(history === "history") {
		his_text = "Here is the language of your Mental Core";
	} else {
		his_text = "Congrats! You have finished the chapter!";
		his_text2 = "Review the lists below. If you find any snippets of text that are trivial, you can remove them now. If you are\
		unsure, just leave it. As you progress forward, the most important language you have will be revealed."
	}
		display.push(<div><div className="col-md-12"><h1>{his_text}</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
		<h3>The language of your Core Constitution:</h3>
		<p>{display_cc}</p>
		
		<h3>The language of your Core Attributes:</h3>
		<p>{display_ca}</p>
		<h3>The language of your Viruses:</h3>
		<p>{display_viruses}</p>
		<h3>The language of your Daily Life events:</h3>
		<p>{display_daily}</p>
		<h3>The language of your Past Life events:</h3>
		<p>{display_past}</p>
		</div><h3 className="my-2">Chapter Video:</h3><p><div className="embed-responsive embed-responsive-4by3 service-video">
    <iframe src={this.state.chapter_video} width="320" height="180" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div></p><p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);		
		return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, daily_exercises: false, past_exercises: false,
		cc_exercises: true, ca_exercises: false, viruses_exercises: false, display_exercises: true, started: true});
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: .2,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("core_constitution", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitCCCallback(original_id, id) {
	var temp_cc = this.state.cc_language;
	var ids = [];
	var original_ids = [];
	temp_cc.push({});
	temp_cc[temp_cc.length-1].id = id;
	temp_cc[temp_cc.length-1].entry = this.state[`${original_id}_cc`];
	temp_cc[temp_cc.length-1].original_id = original_id;
	temp_cc[temp_cc.length-1].type = "core constitution";
	console.log("Temp_CC is " + temp_cc[temp_cc.length-1].entry);

	temp_cc.forEach((element) => {
	original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length === this.state.core_constitution.length) {
		this.setState({cc_language: temp_cc, [original_id+"_cc"]: "", finished_core_constitution: true});
	} else {
		this.setState({cc_language: temp_cc, [original_id+"_cc"]: ""});
	}
}

SubmitCC(original_id) {
	axios.post(APIHost + '/api/language', {
			withCredentials: true,
			language: this.state[`${original_id}_cc`],
			type: "core constitution",
			id: original_id
		}).then(res => {
			this.SubmitCCCallback(original_id, res.data.id);
		}).catch(error => {
			console.log("Failed to submit language for core constitution " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitCC(event) {
	event.preventDefault();
	this.SubmitCC(event.target.id);
}

SubmitCACallback(original_id, id) {
	var temp_ca = this.state.ca_language;
	var ids = [];
	var original_ids = [];
	temp_ca.push({});
	temp_ca[temp_ca.length-1].id = id;
	temp_ca[temp_ca.length-1].entry = this.state[`${original_id}_ca`];
	temp_ca[temp_ca.length-1].original_id = original_id;
	temp_ca[temp_ca.length-1].type = "core attribute";

	temp_ca.forEach((element) => {
	original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length === this.state.core_attributes.length) {
		this.setState({ca_language: temp_ca, [original_id+"_ca"]: "", finished_core_attributes: true});
	} else {
		this.setState({ca_language: temp_ca, [original_id+"_ca"]: ""});
	}
}

SubmitCA(original_id) {
	axios.post(APIHost + '/api/language', {
			withCredentials: true,
			language: this.state[`${original_id}_ca`],
			type: "core attribute",
			id: original_id
		}).then(res => {
			this.SubmitCACallback(original_id, res.data.id);
		}).catch(error => {
			console.log("Failed to submit language for core attribute " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitCA(event) {
	event.preventDefault();
	//document.getElementById(event.target.id).disabled = true;
	this.SubmitCA(event.target.id);
}

SubmitVirusesCallback(original_id, id) {
	var temp = this.state.virus_language;
	var ids = [];
	var original_ids = [];
	temp.push({});
	temp[temp.length-1].id = id;
	temp[temp.length-1].entry = this.state[`${original_id}_virus`];
	temp[temp.length-1].original_id = original_id;
	temp[temp.length-1].type = "virus";

	temp.forEach((element) => {
	original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length === this.state.viruses.length) {
		this.setState({virus_language: temp, [original_id+"_virus"]: "", finished_viruses: true});
	} else {
		this.setState({virus_language: temp, [original_id+"_virus"]: ""});
	}
}

SubmitViruses(original_id) {
	axios.post(APIHost + '/api/language', {
			withCredentials: true,
			language: this.state[`${original_id}_virus`],
			type: "virus",
			id: original_id
		}).then(res => {
			this.SubmitVirusesCallback(original_id, res.data.id);
		}).catch(error => {
			console.log("Failed to submit language for virus " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitViruses(event) {
	event.preventDefault();
	this.SubmitViruses(event.target.id);
}

SubmitDailyCallback(original_id, id) {
	var temp = this.state.daily_language;
	var ids = [];
	var original_ids = [];
	temp.push({});
	temp[temp.length-1].id = id;
	temp[temp.length-1].entry = this.state[`${original_id}_daily`];
	temp[temp.length-1].original_id = original_id;
	temp[temp.length-1].type = "daily life";

	temp.forEach((element) => {
	original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length === this.state.daily_life.length) {
		this.setState({daily_language: temp, [original_id+"_daily"]: "", finished_daily: true});
	} else {
		this.setState({daily_language: temp, [original_id+"_daily"]: ""});
	}
}

SubmitDaily(original_id) {
	axios.post(APIHost + '/api/language', {
			withCredentials: true,
			language: this.state[`${original_id}_daily`],
			type: "daily life",
			id: original_id
		}).then(res => {
			this.SubmitDailyCallback(original_id, res.data.id);
		}).catch(error => {
			console.log("Failed to submit language for daily life " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitDaily(event) {
	event.preventDefault();
	this.SubmitDaily(event.target.id);
}

SubmitPastCallback(original_id, id) {
	var temp = this.state.past_language;
	var ids = [];
	var original_ids = [];
	temp.push({});
	temp[temp.length-1].id = id;
	temp[temp.length-1].entry = this.state[`${original_id}_past`];
	temp[temp.length-1].original_id = original_id;
	temp[temp.length-1].type = "past life";

	temp.forEach((element) => {
	original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length === this.state.past_life.length) {
		this.setState({past_language: temp, [original_id+"_past"]: "", finished_past: true});
	} else {
		this.setState({past_language: temp, [original_id+"_past"]: ""});
	}
}

SubmitPast(original_id) {
	axios.post(APIHost + '/api/language', {
			withCredentials: true,
			language: this.state[`${original_id}_past`],
			type: "past life",
			id: original_id
		}).then(res => {
			this.SubmitPastCallback(original_id, res.data.id);
		}).catch(error => {
			console.log("Failed to submit language for past life " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitPast(event) {
	event.preventDefault();
	this.SubmitPast(event.target.id);
}

NextExercisesCallback(stage) {
	window.scroll(0,0);
	if (stage === "core_constitution") {
		this.setState({cc_exercises: false, ca_exercises: true, viruses_exercises: false, daily_exercises: false, past_exercises: false});
	} else if (stage === "core_attributes") {
		this.setState({cc_exercises: false, ca_exercises: false, viruses_exercises: true, daily_exercises: false, past_exercises: false});
	} else if (stage === "viruses") {
		this.setState({cc_exercises: false, ca_exercises: false, viruses_exercises: false, daily_exercises: true, past_exercises: false});
	} else if (stage === "daily_life") {
		this.setState({cc_exercises: false, ca_exercises: false, viruses_exercises: false, daily_exercises: false, past_exercises: true});
	} else {
		this.setState({cc_exercises: false, ca_exercises: false, viruses_exercises: false, display_exercises: false, finished: true});
	}
}

NextExercises(stage) {
	var next_stage = '';
	var percent_complete = null;
	if (stage === "core_constitution") {
		next_stage = "core_attributes";
		percent_complete = .3;
	} else if (stage === "core_attributes") {
		next_stage = "viruses";
		percent_complete = .4;
	} else if (stage === "viruses") {
		next_stage = "daily_life"; 
		percent_complete = .6;
	} else if (stage === "daily_life") {
		percent_complete = .8
		next_stage = "past_life"
	} else if (stage === "past_life") {
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback(stage);
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises(event.target.id);
}

RemoveCCCallback(id) {
	var temp = [];
	var temp_cc = this.state.core_constitution;
	var cnt = 0;
	var ids = [];
	var original_ids = [];
	this.state.cc_language.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			temp[cnt].original_id = element.original_id;
			temp[cnt].type = element.type;
			cnt++;
		}
	});
	temp_cc.forEach((element) => {
		original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length < this.state.core_constitution.length) {
		this.setState({cc_language: temp, finished_core_constitution: false});
	} else {
		this.setState({cc_language: temp});
	}
}

RemoveCACallback(id) {
	var temp = [];
	var cnt = 0;
	var temp_ca = this.state.core_attributes;
	var ids = [];
	var original_ids = [];
	this.state.ca_language.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			temp[cnt].original_id = element.original_id;
			temp[cnt].type = element.type;
			cnt++;
		}
	});
	temp_ca.forEach((element) => {
		original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length < this.state.core_attributes.length) {
		this.setState({ca_language: temp, finished_core_attributes: false});
	} else {
		this.setState({ca_language: temp});
	}
}

RemoveVirusCallback(id) {
	var temp = [];
	var cnt = 0;
	var temp_viruses = this.state.viruses;
	var ids = [];
	var original_ids = [];
	this.state.virus_language.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			temp[cnt].original_id = element.original_id;
			temp[cnt].type = element.type;
			cnt++;
		}
	});
	temp_viruses.forEach((element) => {
		original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length < this.state.viruses.length) {
		this.setState({virus_language: temp, finished_viruses: false});
	} else {
		this.setState({virus_language: temp});
	}
}

RemoveDailyLifeCallback(id) {
	var temp = [];
	var cnt = 0;
	var temp_daily = this.state.daily_life;
	var ids = [];
	var original_ids = [];
	this.state.daily_language.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			temp[cnt].original_id = element.original_id;
			temp[cnt].type = element.type;
			cnt++;
		}
	});
	temp_daily.forEach((element) => {
		original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length < this.state.daily_life.length) {
		this.setState({daily_language: temp, finished_daily: false});
	} else {
		this.setState({daily_language: temp});
	}
}

RemovePastLifeCallback(id) {
	var temp = [];
	var cnt = 0;
	var temp_past = this.state.past_life;
	var ids = [];
	var original_ids = [];
	this.state.past_language.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			temp[cnt].original_id = element.original_id;
			temp[cnt].type = element.type;
			cnt++;
		}
	});
	temp_past.forEach((element) => {
		original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	if (ids.length < this.state.past_life.length) {
		this.setState({past_language: temp, finished_past: false});
	} else {
		this.setState({past_language: temp});
	}
}

RemoveEntry(id, api) {
	axios.delete(APIHost + '/api/language', {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		if (api === "coreConstitution") {
			this.RemoveCCCallback(id);
		} else if (api === "coreAttributes") {
			this.RemoveCACallback(id);
		} else if (api === "viruses") {
			this.RemoveVirusCallback(id);
		} else if (api === "dailyLife") {
			this.RemoveDailyLifeCallback(id);
		} else if (api === "pastLife") {
			this.RemovePastLifeCallback(id);
		}
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveEntry(event) {
	event.preventDefault();
	this.RemoveEntry(event.target.id, event.target.name);
}

handleMouseUp(event) {
	var addition = '';
	if (this.state[`${event.target.name}`]) {
		addition = this.state[`${event.target.name}`] + window.getSelection().toString();
	} else {
		addition = window.getSelection().toString();
	}
  this.setState({[`${event.target.name}`]: addition});
}

DisplayExercises(stage) {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_cc_language = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	if (this.state.cc_exercises) {
		for (var i=0; i<this.state.core_constitution.length; i++) {
			display_cc_language = [];
			for (var j=0; j<this.state.cc_language.length; j++) {
				if (this.state.cc_language[j].original_id == this.state.core_constitution[i].id) {
					display_cc_language.push(<li>{this.state.cc_language[j].entry} <button className="btn btn-primary my-3" id={this.state.cc_language[j].id} name="coreConstitution" onClick={this.onRemoveEntry}>Remove</button></li>);
				}
			}
			// just need to duplicate the name below and pass it to the mouse up function
			var temp_id = this.state.core_constitution[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.core_constitution[i].entry}</p>
			<h5>Description:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.core_constitution[i].description} name={`${this.state.core_constitution[i].id}_cc`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={`${i+1}_cc`} name={`${this.state.core_constitution[i].id}_cc`} value={this.state[`${this.state.core_constitution[i].id}_cc`]} className="form-control"></textarea>
  				</div>
  				<button className="btn btn-primary my-3" onClick={this.onSubmitCC} type="submit" name={`${i+1}_add_cc`} id={this.state.core_constitution[i].id}>Add</button>
  				</form>
  				<div><ul>{display_cc_language}</ul></div>
			</div></div></div>
			);
		}
		display_intro.push(<div><p>What you see below is a list of the sentences extracted from the descriptions you gave. As described in the chapter vide, the idea 
		is to capture the essence of what you provided by capturing concise sentences from the descriptions. You can remove entries that don't add value. You can take parts of the sentences
		from the descriptions by highlighting them or typing them and adding them to the list, or you can leave sentences as they are. There's no right answer. The results of these exercises 
		will be used to empower the rest of your journey so take your time here and enjoy the process of discovering your language! Here's a video walk-through:</p>
		<p> <iframe src="https://player.vimeo.com/video/679872449?h=db038f9a99" width="300" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="core_constitution" className="custom-btn" className="custom-btn" disabled={!this.state.finished_core_constitution}>Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Capture the language of your Core Constitution</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.ca_exercises) {
		for (var i=0; i<this.state.core_attributes.length; i++) {
			display_ca_language = [];
			for (var j=0; j<this.state.ca_language.length; j++) {
				if (this.state.ca_language[j].original_id == this.state.core_attributes[i].id) {
					display_ca_language.push(<li>{this.state.ca_language[j].entry} <button id={this.state.ca_language[j].id} name="coreAttributes" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
				}
			}
			// just need to duplicate the name below and pass it to the mouse up function
			var temp_id = this.state.core_attributes[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.core_attributes[i].attribute}</p>
			<h5>Description:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.core_attributes[i].description} name={`${this.state.core_attributes[i].id}_ca`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={`${i+1}_ca`} name={`${this.state.core_attributes[i].id}_ca`} value={this.state[`${this.state.core_attributes[i].id}_ca`]} className="form-control"></textarea>
  				</div>
  				<button className="btn btn-primary my-3" onClick={this.onSubmitCA} type="submit" name={`${i+1}_add_ca`} id={this.state.core_attributes[i].id}>Add</button>
  				</form>
  				<div><ul>{display_ca_language}</ul></div>
			</div></div></div>
			);
		}
	
		display_intro.push(<div><p>What you see below is a list of the sentences extracted from the descriptions you gave. As described in the chapter vide, the idea 
		is to capture the essence of what you provided by capturing concise sentences from the descriptions. You can remove entries that don't add value. You can take parts of the sentences
		from the descriptions by highlighting them or typing them and adding them to the list, or you can leave sentences as they are. There's no right answer. The results of these exercises 
		will be used to empower the rest of your journey so take your time here and enjoy the process of discovering your language! Here's a video walk-through:</p>
		<p> <iframe src="https://player.vimeo.com/video/679872449?h=db038f9a99" width="300" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="core_attributes" className="custom-btn" disabled={!this.state.finished_core_attributes}>Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Capture the language of your Core Attributes</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.viruses_exercises) {
		for (var i=0; i<this.state.viruses.length; i++) {
			display_virus_language = [];
			for (var j=0; j<this.state.virus_language.length; j++) {
				if (this.state.virus_language[j].original_id == this.state.viruses[i].id) {
					display_virus_language.push(<li>{this.state.virus_language[j].entry} <button id={this.state.virus_language[j].id} name="viruses" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
				}
			}
			var temp_id = this.state.viruses[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.viruses[i].virus}</p>
			<h5>Description:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.viruses[i].description} name={`${this.state.viruses[i].id}_virus`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={`${i+1}_virus`} name={`${this.state.viruses[i].id}_virus`} value={this.state[`${this.state.viruses[i].id}_virus`]} className="form-control"></textarea>
  				</div>
  				<button className="btn btn-primary my-3" onClick={this.onSubmitViruses} name={`${i+1}_add_virus`} type="submit" id={this.state.viruses[i].id}>Add</button>
  				</form>
  				<div><ul>{display_virus_language}</ul></div>
			</div></div></div>
			);
		}
	
		display_intro.push(<div><p>What you see below is a list of the sentences extracted from the descriptions you gave. As described in the chapter vide, the idea 
		is to capture the essence of what you provided by capturing concise sentences from the descriptions. You can remove entries that don't add value. You can take parts of the sentences
		from the descriptions by highlighting them or typing them and adding them to the list, or you can leave sentences as they are. There's no right answer. The results of these exercises 
		will be used to empower the rest of your journey so take your time here and enjoy the process of discovering your language! Here's a video walk-through:</p>
		<p> <iframe src="https://player.vimeo.com/video/679872449?h=db038f9a99" width="300" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="viruses" className="custom-btn" disabled={!this.state.finished_viruses}>Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Capture the language of your Viruses</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.daily_exercises) {
		for (var i=0; i<this.state.daily_life.length; i++) {
			var display_daily_language = [];
			for (var j=0; j<this.state.daily_language.length; j++) {
				if (this.state.daily_language[j].original_id == this.state.daily_life[i].id) {
					display_daily_language.push(<li>{this.state.daily_language[j].entry} <button id={this.state.daily_language[j].id} name="dailyLife" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
				}
			}
			var temp_id = this.state.daily_life[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.daily_life[i].entry}</p>
			<h5>Description:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.daily_life[i].description} name={`${this.state.daily_life[i].id}_daily`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} id={`${i+1}_dl`} rows="1" cols="1" type="text" name={`${this.state.daily_life[i].id}_daily`} value={this.state[`${this.state.daily_life[i].id}_daily`]} className="form-control"></textarea>
  				</div>
  				<button className="btn btn-primary my-3" onClick={this.onSubmitDaily} name={`${i+1}_add_dl`} type="submit" id={this.state.daily_life[i].id}>Add</button>
  				</form>
  				<div><ul>{display_daily_language}</ul></div>
			</div></div></div>
			);

		}
						display_intro.push(<div><p>What you see below is a list of the sentences extracted from the descriptions you gave. As described in the chapter vide, the idea 
		is to capture the essence of what you provided by capturing concise sentences from the descriptions. You can remove entries that don't add value. You can take parts of the sentences
		from the descriptions by highlighting them or typing them and adding them to the list, or you can leave sentences as they are. There's no right answer. The results of these exercises 
		will be used to empower the rest of your journey so take your time here and enjoy the process of discovering your language! Here's a video walk-through:</p>
		<p> <iframe src="https://player.vimeo.com/video/679872449?h=db038f9a99" width="300" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="daily_life" className="custom-btn" disabled={!this.state.finished_daily}>Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
			display.push(<div className="col-md-12"><h1>Capture the language of your Daily Life</h1>{display_button}
			{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.past_exercises) {
		for (var i=0; i<this.state.past_life.length; i++) {
			var display_past_language = [];
			for (var j=0; j<this.state.past_language.length; j++) {
				if (this.state.past_language[j].original_id == this.state.past_life[i].id) {
					display_past_language.push(<li>{this.state.past_language[j].entry} <button id={this.state.past_language[j].id} name="pastLife" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
				}
			}
			var temp_id = this.state.past_life[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.past_life[i].entry}</p>
			<h5>Description:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.past_life[i].description} name={`${this.state.past_life[i].id}_past`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} id={`${i+1}_pl`} rows="1" cols="1" type="text" name={`${this.state.past_life[i].id}_past`} value={this.state[`${this.state.past_life[i].id}_past`]} className="form-control"></textarea>
  				</div>
  				<button className="btn btn-primary my-3" onClick={this.onSubmitPast} name={`${i+1}_add_pl`} type="submit" id={this.state.past_life[i].id}>Add</button>
  				</form>
  				<div><ul>{display_past_language}</ul></div>
			</div></div></div>
			);
		}
	
		display_intro.push(<div><p>What you see below is a list of the sentences extracted from the descriptions you gave. As described in the chapter vide, the idea 
		is to capture the essence of what you provided by capturing concise sentences from the descriptions. You can remove entries that don't add value. You can take parts of the sentences
		from the descriptions by highlighting them or typing them and adding them to the list, or you can leave sentences as they are. There's no right answer. The results of these exercises 
		will be used to empower the rest of your journey so take your time here and enjoy the process of discovering your language! Here's a video walk-through:</p>
		<p> <iframe src="https://player.vimeo.com/video/679872449?h=db038f9a99" width="300" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="past_life" className="custom-btn" disabled={!this.state.finished_past}>Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Capture the language of your Past Life</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	}

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter10">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter10">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter10