import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link } from 'react-router-dom';

class Educator extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="Educator">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>We care about educators!</h1><hr />
            <div className="two-sided-box-in">
    <p>
    Here at My Core Insights, we understand the incredibly stress educators are facing right now. Educators are being asked to do the impossible and make massive adjustments...as if being an educator wasn't already challenging!
    We are here to help! Our passion is to get wellness solutions into the hands of those that need them. These are unprecedented times so unprecedented measures need to be taken.
    We are offering our training for FREE to educators during the pandemic. Self-care is crucial and the job of the educators is incredibly important. We are hoping this empowers each of you
    to not only persevere through these challenges, but thrive!
</p>
<p>
Here's what to do:

<ul>
<li>Head over to the <Link to="/CreateAccount">Create Account </Link>page and create a new Account</li>
<li>You automatically get a free credit for joining, so you can get started right away</li>
<li>Use the <Link to="/Contact">Contact </Link>page to reach out to us and let us know that you are an educator, providing sufficient information to verify that. (We might ask for additional information if needed)</li>
<li>We will add additional credits to your account once verified</li>

</ul>
</p>
    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default Educator;