import React, { Component } from 'react';
import './css/bootstrap.css';
import 'antd/dist/antd.css'
import './css/main.css';
import './css/new.css';
import './css/carousel.css';
import './css/LineIcons.css';
import './css/default.css';
import './css/font-awesome/css/font-awesome.min.css';

import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Mft from './Mft';
import Webinars from './Webinars';
import PayPalProcrastination from './PayPalProcrastination';

import Register from './Register';
import ViewTraining from './ViewTraining';
import ScheduleRetro from './ScheduleRetro';
import ScheduleTraining from './ScheduleTraining';
import GetSessionCredits from './GetSessionCredits';
import About from './About';
import ElliotsCorner from './ElliotsCorner';
import CommunityCorner from './CommunityCorner';
import Contact from './Contact';
import Survey from './Survey';
import Faq from './Faq';
import CreateAccount from './CreateAccount';
import Login from './Login';
import AmbassadorProgram from './AmbassadorProgram';
import AmbassadorCentral from './AmbassadorCentral';
import AmbassadorProgramDetails from './AmbassadorProgramDetails';
import GetCredits from './GetCredits';
import PayPalButton from './PayPalButton';
import SprintHistory from './SprintHistory';
import Chapter1 from './chapters/chapter1';
import Chapter3 from './chapters/chapter3';
import Chapter4 from './chapters/chapter4';
import Chapter2 from './chapters/chapter2';
import Chapter5 from './chapters/chapter5';
import Chapter6 from './chapters/chapter6';   
import History from './History';
import PrivacyPolicy from './PrivacyPolicy';
import Resentment from './articles/Resentment';
import ForgotPassword from './ForgotPassword';
import RetroSpective from './Retrospective';
import TermsOfUse from './TermsOfUse';
import SprintPlanning from './SprintPlanning';
import ManageSprint from './ManageSprint';
import ForBusiness from './ForBusiness';
import CreateSprint from './CreateSprint';
import SetCapacity from './SetCapacity';
import Admin from './Admin';
import PayPalSession from './PayPalSession';
import UnderReact from './articles/UnderReact';
import LittleThingsRight from './articles/LittleThingsRight';
import SixthLoveLanguage from './articles/SixthLoveLanguage';
import Sorry from './articles/Sorry';
import LivingProactively from './articles/LivingProactively';

import FutureSelf from './articles/FutureSelf';
import DirectYourMoment from './articles/DirectYourMoment';
import CloserFurther from './articles/CloserFurther';
import Educators from './Educators';
import Military from './Military';
import PayPalWebinars from './PayPalWebinars';
import Workshops from './Workshops';
import LoadTest from './LoadTest';


import Chapter7 from './chapters/chapter7';
import Chapter8 from './chapters/chapter8';
import Chapter9 from './chapters/chapter9';
import Chapter10 from './chapters/chapter10';
import Chapter11 from './chapters/chapter11';
import Chapter12 from './chapters/chapter12';
import Chapter13 from './chapters/chapter13';
import Chapter14 from './chapters/chapter14';
import Chapter15 from './chapters/chapter15';
import Chapter16 from './chapters/chapter16';
import Chapter17 from './chapters/chapter17';
import Chapter18 from './chapters/chapter18';
import Chapter19 from './chapters/chapter19';
import Chapter20 from './chapters/chapter20';
import Chapter21 from './chapters/chapter21';
import Chapter22 from './chapters/chapter22';
import Chapter23 from './chapters/chapter23';
import Chapter24 from './chapters/chapter24';
import Chapter25 from './chapters/chapter25';
import Chapter26 from './chapters/chapter26';
import Chapter27 from './chapters/chapter27';
import Chapter28 from './chapters/chapter28';
import Chapter29 from './chapters/chapter29';
import Chapter30 from './chapters/chapter30';
import Chapter31 from './chapters/chapter31';
import Chapter32 from './chapters/chapter32';
import Chapter33 from './chapters/chapter33';
import Chapter34 from './chapters/chapter34';
import Chapter35 from './chapters/chapter35';
import Chapter36 from './chapters/chapter36';
import Chapter37 from './chapters/chapter37';
import Chapter38 from './chapters/chapter38';
import Chapter39 from './chapters/chapter39';
import Chapter40 from './chapters/chapter40';
import Chapter41 from './chapters/chapter41';
import Chapter42 from './chapters/chapter42';
import Chapter43 from './chapters/chapter43';
import Chapter44 from './chapters/chapter44';
import Chapter45 from './chapters/chapter45';
import Chapter46 from './chapters/chapter46';
import Chapter47 from './chapters/chapter47';
import Chapter48 from './chapters/chapter48';
import Chapter49 from './chapters/chapter49';
import Chapter50 from './chapters/chapter50';
import Chapter51 from './chapters/chapter51';
import Chapter52 from './chapters/chapter52';
import Chapter53 from './chapters/chapter53';   
import Chapter100 from './chapters/chapter100'; 
import Chapter101 from './chapters/chapter101';
import Chapter102 from './chapters/chapter102'; 
import Chapter103 from './chapters/chapter103';
import Chapter104 from './chapters/chapter104';
import Journal from './Journal';
import Preview from './Preview';
import Home from './Home';
import axios from 'axios';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	//<Route exact path='/preview' render={(props) => <Home {...props} preview={true} />} />
	render() {
		return (
			<div className="App">
				<BrowserRouter>
					<div>
						<Route exact path='/' component={Home} />
						<Route exact path='/Webinars' component={Webinars} />
						<Route exact path='/Workshops' component={Workshops} />
						<Route exact path='/Admin' component={Admin} />
						<Route exact path='/PayPalWebinars' component={PayPalWebinars} />
						<Route exact path='/PayPalProcrastination' component={PayPalProcrastination} />
						<Route exact path='/Register' component={Register} />
						<Route exact path='/PayPalSession' component={PayPalSession} />
						<Route exact path='/UnderReact' component={UnderReact} />
						<Route exact path='/ViewTraining' component={ViewTraining} />
						<Route exact path='/FutureSelf' component={FutureSelf} />
						<Route exact path='/Sorry' component={Sorry} />
						<Route exact path='/Survey' component={Survey} />
						<Route exact path='/CommunityCorner' component={CommunityCorner} />
						<Route exact path='/ScheduleRetro' component={ScheduleRetro} />
						<Route exact path='/ScheduleTraining' component={ScheduleTraining} />
						<Route exact path='/GetSessionCredits' component={GetSessionCredits} />
						<Route exact path='/LivingProactively' component={LivingProactively} />
						<Route exact path='/SixthLoveLanguage' component={SixthLoveLanguage} />
						<Route exact path='/DirectYourMoment' component={DirectYourMoment} />
						<Route exact path='/CloserFurther' component={CloserFurther} />
						<Route exact path='/LittleThingsRight' component={LittleThingsRight} />
						<Route exact path='/Military' component={Military} />
						<Route exact path='/Resentment' component={Resentment} />
						<Route exact path='/History' component={History} />
						<Route exact path='/Educators' component={Educators} />
						<Route exact path='/LoadTest' component={LoadTest} />
						<Route exact path='/ForBusiness' component={ForBusiness} />						
						<Route exact path='/preview' component={Preview} />
						<Route exact path='/PayPalButton' component={PayPalButton} />
						<Route exact path='/MFT' component={Mft} />
						<Route exact path='/About' component={About} />
						<Route exact path='/Contact' component={Contact} />
						<Route exact path='/ElliotsCorner' component={ElliotsCorner} />
						<Route exact path='/Faq' component={Faq} />
						<Route exact path='/CreateAccount/:ID' component={CreateAccount} />
						<Route exact path='/CreateAccount' component={CreateAccount} />
						<Route exact path='/AmbassadorProgram' component={AmbassadorProgram} />
						<Route exact path='/AmbassadorCentral' component={AmbassadorCentral} />
						<Route exact path='/AmbassadorProgramDetails' component={AmbassadorProgramDetails} />
						<Route exact path='/Login' component={Login} />
						<Route exact path='/GetCredits' component={GetCredits} />
						<Route exact path='/ForgotPassword' component={ForgotPassword} />
						<Route exact path='/Home' component={Home} />
						<Route exact path='/PrivacyPolicy' component={PrivacyPolicy} />
						<Route exact path='/TermsOfUse' component={TermsOfUse} />
						<Route exact path='/RetroSpective' component={RetroSpective} />
						<Route exact path='/Journal' component={Journal} />
						<Route exact path='/SprintHistory/:ID' component={SprintHistory} />
						<Route exact path='/SprintPlanning' component={SprintPlanning} />
						<Route exact path='/ManageSprint' component={ManageSprint} />

						<Route exact path='/CreateSprint' component={CreateSprint} />
						<Route exact path='/SetCapacity' component={SetCapacity} />
						<Route path='/Chapter102/:ID' component={Chapter102} />
						<Route path='/Chapter103/:ID' component={Chapter103} />
						<Route path='/Chapter104/:ID' component={Chapter104} />
					   <Route path='/Chapter1/:ID' component={Chapter1} />
						<Route path='/Chapter3/:ID' component={Chapter3} />
						<Route path='/Chapter4/:ID' component={Chapter4} />
						<Route path='/Chapter2/:ID' component={Chapter2} />


						<Route path='/Chapter5/:ID' component={Chapter5} />
						<Route path='/Chapter6/:ID' component={Chapter6} />
						<Route path='/Chapter7/:ID' component={Chapter7} />
						<Route path='/Chapter8/:ID' component={Chapter8} />
						<Route path='/Chapter9/:ID' component={Chapter9} />
						<Route path='/Chapter10/:ID' component={Chapter10} />
						<Route path='/Chapter11/:ID' component={Chapter11} />
						<Route path='/Chapter12/:ID' component={Chapter12} />
						<Route path='/Chapter13/:ID' component={Chapter13} />
						<Route path='/Chapter14/:ID' component={Chapter14} />
						<Route path='/Chapter15/:ID' component={Chapter15} />
						<Route path='/Chapter16/:ID' component={Chapter16} />
						<Route path='/Chapter17/:ID' component={Chapter17} />
						<Route path='/Chapter18/:ID' component={Chapter18} />
						<Route path='/Chapter19/:ID' component={Chapter19} />
						<Route path='/Chapter20/:ID' component={Chapter20} />
						<Route path='/Chapter21/:ID' component={Chapter21} />
						<Route path='/Chapter22/:ID' component={Chapter22} />
						<Route path='/Chapter23/:ID' component={Chapter23} />
						<Route path='/Chapter24/:ID' component={Chapter24} />
						<Route path='/Chapter25/:ID' component={Chapter25} />
						<Route path='/Chapter26/:ID' component={Chapter26} />
						<Route path='/Chapter27/:ID' component={Chapter27} />
						<Route path='/Chapter28/:ID' component={Chapter28} />
						<Route path='/Chapter29/:ID' component={Chapter29} />
						<Route path='/Chapter30/:ID' component={Chapter30} />
						<Route path='/Chapter31/:ID' component={Chapter31} />
						<Route path='/Chapter32/:ID' component={Chapter32} />
						<Route path='/Chapter33/:ID' component={Chapter33} />
						<Route path='/Chapter34/:ID' component={Chapter34} />
						<Route path='/Chapter35/:ID' component={Chapter35} />
						<Route path='/Chapter36/:ID' component={Chapter36} />
						<Route path='/Chapter37/:ID' component={Chapter37} />
						<Route path='/Chapter38/:ID' component={Chapter38} />
						<Route path='/Chapter39/:ID' component={Chapter39} />
						<Route path='/Chapter40/:ID' component={Chapter40} />
						<Route path='/Chapter41/:ID' component={Chapter41} />
						<Route path='/Chapter42/:ID' component={Chapter42} />
						<Route path='/Chapter43/:ID' component={Chapter43} />
						<Route path='/Chapter44/:ID' component={Chapter44} />
						<Route path='/Chapter45/:ID' component={Chapter45} />
						<Route path='/Chapter46/:ID' component={Chapter46} />
						<Route path='/Chapter47/:ID' component={Chapter47} />
						<Route path='/Chapter48/:ID' component={Chapter48} />
						<Route path='/Chapter49/:ID' component={Chapter49} />
						<Route path='/Chapter50/:ID' component={Chapter50} />
						<Route path='/Chapter51/:ID' component={Chapter51} />
						<Route path='/Chapter52/:ID' component={Chapter52} />
						<Route path='/Chapter53/:ID' component={Chapter53} />   
						<Route path='/Chapter100/:ID' component={Chapter100} /> 
						<Route path='/Chapter101/:ID' component={Chapter101} /> 

					</div>
				</BrowserRouter>
			</div>
		);
	}

}

export default App;
