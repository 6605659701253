import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
import {UpdateLastActivity} from '../utilities.js';

const exercise_questions = ["Use the space below to brainstorm on what living an Optimal Live would mean to you. \
							This is just the start of your journey, so don't filter yourself. Just write what comes to mind.\
							Consider areas such as your legacy, the impact you want to have, lifestyle, the state of being you want to be in, \
							how you want to feel each day, and the life you want to live. If this is a struggle, then use simple statements such as: I want to feel joy each day and have freedom.", 
							"When you think of a life of Purposeful Meaning, what derived value do you \
  						want others to have from your life? What impact do you feel you can have if you were to live your Optimal Life? If this isn't clear to you right now, \
  						it will be with time. This is your Journey to take. You will get there!",
							"Use the space below to brainstorm on short and long-term successes you want to get after for yourself. \
							Again, don't filter yourself. Write what comes to mind for this year, the next few years, five years, ten years, and longer if you're able. The 5 Whys can be used here to \
							help identify your successes.", 
							"Re-examine the above answers. Are they aligned? Is what you characterized as your Optimal Life aligned with your idea of Purposeful Meaning and does your definitions of success \
							help you to achieve Purposeful Meaning and your Optimal Life. Write about your findings and thoughts below."];
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter1 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 1,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		answers: [],
		answers_before: [],
		answers_after: [],
		exercise_answers: [],
		display_exercises: false,
		display_after_questions: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		finished_exercises: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
		chapter_stage: null,
		mission: null,
		brand: null,
		chapter_video: "https://player.vimeo.com/video/661242534",
		questions: []
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetQuestions = this.GetQuestions.bind(this);
this.DisplayQuestions = this.DisplayQuestions.bind(this);
this.QuestionState = this.QuestionState.bind(this);
this.onSubmitQuestion = this.onSubmitQuestion.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.SubmitQuestion = this.SubmitQuestion.bind(this);
this.SubmitQuestionCallback = this.SubmitQuestionCallback.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.Exercises = this.Exercises.bind(this);
this.ExercisesCallback = this.ExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.GetQuestionsAfter = this.GetQuestionsAfter.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.ExercisesDone = this.ExercisesDone.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.onSubmitMission = this.onSubmitMission.bind(this);
this.SubmitMission = this.SubmitMission.bind(this);
this.SubmitMissionCallback = this.SubmitMissionCallback.bind(this);
this.onSubmitBrand = this.onSubmitBrand.bind(this);
this.SubmitBrand = this.SubmitBrand.bind(this);
this.SubmitBrandCallback = this.SubmitBrandCallback.bind(this);
this.GetMission = this.GetMission.bind(this);
this.GetBrand = this.GetBrand.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	UpdateLastActivity();
	var all_promises = [];
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, 
			display_after_questions: false, display_exercises: false, started: true});
		} else if (stage.data.stage == "exercises") {
			this.setState({display_video: false, 
			display_after_questions: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "questions_after")  {
			this.setState({display_video: false, 
			display_after_questions: true, display_exercises: false, started: true});		
		} else if (stage.data.stage == "done") {
		this.setState({display_video: false, 
			display_after_questions: false, display_exercises: false, finished: true, started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}
	
	var questions_promise = this.GetQuestions().then( (questions) => {
		this.setState({questions: questions.data.questions});
	}).catch((error) => {
		console.log("GetQuestions failed " + error);
		this.setState({server_failure: true});
	});

	var exercise_answers_promise = this.GetExerciseAnswers().then ((exercise_answers) => {
		this.setState({exercise_answers: exercise_answers.data.exercise_answers})
	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});

	var mission_promise = this.GetMission().then((mission) => {
		if(mission.data.mission != null) {
			this.setState({mission: mission.data.mission, mission_set: true});
		}
	}).catch((error) => {
		console.log("Failed to get Mission");
		this.setState({server_failure: true});
	});

	var brand_promise = this.GetBrand().then((brand) => {
		if(brand.data.brand != null) {
			this.setState({brand: brand.data.brand, brand_set: true});
		}
	}).catch((error) => {
		console.log("Failed to get Brand");
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(chapter_stage_promise);
	all_promises.push(questions_promise);
	all_promises.push(status_promise);
	all_promises.push(exercise_answers_promise);
	all_promises.push(note_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		this.GetQuestionsAfter().then ( (answers) => {
			this.setState({answers_after: answers.data.answers});
		if(this.state.chapter_stage != 'exercises') {
			this.setState({finished_exercises: false});
		}
		var cnt=0;
		this.state.exercise_answers.forEach((element) => {
			this.setState({["exercise" + element.exercise_id + "_done"]: true, [element.exercise_id+"_answer"]: element.text});
			cnt++;
		});
		if (this.state.mission_set) {
			cnt++;
		}
		if (this.state.brand_set) {
			cnt++;
		}
		if (cnt === exercise_questions.length+2 && this.state.chapter_stage === 'exercises') {
			this.setState({finished_exercises: true});
		}

		this.setState({loading: false});
		}).catch((error) => {
			console.log("GetQuestionsAfter failed " + error);
			this.setState({server_failure: true});
		});

	});
}

async GetMission() {
	const res = await axios.get(APIHost + '/api/getMission',
	{params: {withCredentials: true}});
	return await res;
}

async GetBrand() {
	const res = await axios.get(APIHost + '/api/getBrand',
	{params: {withCredentials: true}});
	return await res;
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetQuestionsAfter() {
// used a post because you can't send an object as a get param without a lot of headache
	const res = await axios.post(APIHost + '/api/getAfterAnswers',
	{questions: this.state.questions, withCredentials: true});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetQuestions() {
	const res = await axios.get(APIHost + '/api/getChapterQuestions', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, 
		display_after_questions: false, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter " + error);
		this.setState({start_failure: true});
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

async SubmitQuestionCallback() {
	window.scroll(0,0);
	this.GetQuestionsAfter().then ( (answers) => {
		this.setState({answers_after: answers.data.answers});
		this.setState({display_video: false, display_exercises: false,
	finished: true, display_after_questions: false, answers_done: false})
		});
}

SubmitQuestion() {
	axios.post(APIHost + '/api/answerChapterQuestions', {
		answers: this.state.answers,
		before_after: "after",
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.SubmitQuestionCallback();
	}).catch(error => {
		console.log("SumbitQuestion failed");
		this.setState({question_failure: true});
	});
}

onSubmitQuestion(event) {
	event.preventDefault();
	this.SubmitQuestion();
}

QuestionState(event) {
var temp = this.state.answers;
var already_there = false;
var was_null = false;
var objIndex = -1;
objIndex = temp.findIndex((obj => obj.question == event.target.name));
if (objIndex != -1) {
	already_there = true;
}
if (already_there) {
	objIndex = temp.findIndex((obj => obj.question == event.target.name));
	temp[objIndex].answer = event.target.value;
} else {
	var obj = {question: event.target.name, answer: event.target.value};
	temp.push(obj);
}
if (temp.length == this.state.questions.length) {
	this.setState({answers_done: true});
}
}

onExerciseChange(event) {
	event.persist();
	this.setState({ ["exercise" + event.target.id + "_done"]: false, [event.target.name]: event.target.value, [event.target.id+"_answer"]: event.target.value});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayQuestions() {
	var display = [];
	var questions = [];
	this.state.questions.forEach((element) => {
		questions.push(
		 <div onChange={this.QuestionState} className="checkboxOptions">
		<div className="col-md-6">
		 <p>{element.question}</p>
		 <Popup trigger={<button className="btn btn-primary my-3"> See explanation</button>} position="right center">
    	<div>{element.answer}</div>
  		</Popup><hr/>
		</div>  
    	<div className="floatBlock">			
    	<input type="radio" className="form-control" name={element.id} value="1" />Not confident     												
    	</div>
    	<div className="floatBlock">
    	<input type="radio" className="form-control" name={element.id} value="2" />Confident 
		</div>
		<div className="floatBlock">
		<input type="radio" className="form-control" name={element.id} value="3" />Very confident 
		</div>	
		</div>);
	});
	var temp_dis = [];
	if(this.state.question_failure) {
		temp_dis.push(<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
		If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: {loggedIn: true } }}>here</Link> and let us know.</font></p>
		);
	}
	var display_button = [];
	var display_video = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>             </div></div>); 
	}
	if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	display.push(<div><h1>Let's see how we ended up!</h1>{display_button}{display_video} <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
    	<button onClick={this.onAddNote}>Submit</button> </div>
  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
    	<div><ul>{this.state.display_notes}</ul></div>
  		</Popup></p><hr /><div className="form-group">{questions}</div>

	<button disabled={!this.state.answers_done} onClick={(event) => 
	this.onSubmitQuestion(event)} className="custom-btn">Submit</button>{temp_dis}</div>);

	return display;
}

DisplayFinished(history) {
	var display = [];
	var table = [];
	var answers_before = [];
	var answers_after = [];
	var questions = [];
	var total_before = 0;	
	var more_promises = [];	
	var display_exercises = [];
	var explanations = [];
	var display_actions = [];
	var total_possible = this.state.questions.length*3;
	this.state.questions.forEach( (question) => {
		questions.push(question.question);
		explanations.push(question.answer);
	});
	this.state.answers_after.forEach( (answer) => {
		answers_after.push(answer.answer);
	});

	for (var i = 0; i<this.state.questions.length; i++) {
		table.push(<tr><td>{questions[i]}</td>
		<td>{explanations[i]}</td>
		<td>{answers_after[i]}</td>

		</tr>);
	}
	for (var i = 0; i<exercise_questions.length; i++) {
		display_exercises.push(<p>{i+1}) {exercise_questions[i]}</p>);
		display_exercises.push(<p>{this.state.exercise_answers[i].text}</p>);
	}
	display_actions.push(<div><hr className="new" /><h5>Your personal actions</h5><p>On a regular basis, revisit your Personal Mission Statement and Personal Brand as well as your Optimal Life definitions. Are you aligned towards them? Do they need updating?</p></div>);

	var intro = [];
	if (history === "history") {
		intro.push(<h2>Here is your completed chapter information.</h2>);
	} else {
		intro.push(<h2>Congrats on finishing the chapter!!</h2>);
	}
	display.push(<div className="col-md-12">{intro}
	 <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
	<div><table className="table table-bordered"><thead><tr><th style={{width: '45%'}}>Question</th><th style={{width: '50%'}}>Explanation</th><th style={{width: '5%'}}>Your Confidence (1-3)</th>
  	</tr></thead><tbody>{table}</tbody></table></div>
  	<h4 className="my-2">Exercise Answers:</h4><div className="display-linebreak">{display_exercises}</div>
  	{display_actions}
	<h3 className="my-2">Chapter Video:</h3><p><div className="embed-responsive embed-responsive-4by3 service-video">
    <iframe src={this.state.chapter_video} width="320" height="180" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div></p></div>);		
	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false, 
	display_after_questions: false});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, display_video: false, 
	display_after_questions: true, finished_exercises: false});
}

UpdateStage(stage, callback) {
	var percent_complete = 0;
	if(stage === "exercises") {
		percent_complete = .5;
	} else {
		percent_complete = .75;
	}
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: percent_complete,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("exercises", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
		<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
		</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
         <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
         </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

ExercisesCallback(exercise) {
	var temp_exercises = this.state.exercise_answers;
	var flag = false;
	var cnt = 0;
	temp_exercises.find((ex) => {
		if (ex.id == exercise) {
			temp_exercises[cnt].text = this.state[`${exercise}_answer`];
			flag = true;
		} else {
			cnt++;
		}
	});
	if (!flag) {
	temp_exercises.push({});
	temp_exercises[this.state.exercise_answers.length-1].text = this.state[`${exercise}_answer`];
	temp_exercises[this.state.exercise_answers.length-1].id = exercise;
	}
	this.setState({["exercise" + exercise + "_done"]: true});
	var cnt = 0;
	for(var i = 0; i<exercise_questions.length; i++) {
		if (this.state[`exercise${i+1}_done`]) {
			cnt++;
		}
	}
	if (this.state.mission_set) {
		cnt++;
	}
	if (this.state.brand_set) {
		cnt++;
	}
	if (cnt === exercise_questions.length+2) {
		this.setState({finished_exercises: true});
	}
	this.setState({exercise_answers: temp_exercises});
}

Exercises(id) {
	var answer = {exercise_id: id, answer: this.state[`${id}_answer`]};
	var id = id;
	axios.post(APIHost + '/api/answerExercises', {
		withCredentials: true,
		answer: answer,
		chapter_id: this.state.chapterID
	}).then(res => {
		this.ExercisesCallback(id);
	}).catch(error => {
		console.log("Failed to submit the exercises " + error);
		this.setState({submission_failure: true});
	});
}

onSubmitExercise(event) {
	event.preventDefault();
	this.Exercises(event.target.id);
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitMissionCallback() {
	this.setState({mission_set: true});
	var cnt = 1;
	for(var i = 0; i<exercise_questions.length; i++) {
		if (this.state[`exercise${i+1}_done`]) {
			cnt++;
		}
	}
	if (this.state.brand_set) {
		cnt++;
	}

	if (cnt === exercise_questions.length+2) {
		this.setState({finished_exercises: true});
	}

}

SubmitMission(id) {
	axios.post(APIHost + '/api/addMission', {
		withCredentials: true,
		mission: this.state[`${id}_answer`]
	}).then(res =>  {
		this.SubmitMissionCallback()
	}).catch(error => {
		console.log("Failed to submit mission");
		this.setState({submission_failure: true});
	});
}

onSubmitMission(event) {
	event.preventDefault();
	this.SubmitMission(event.target.id);
}

SubmitBrandCallback() {
	this.setState({brand_set: true});
	var cnt = 1;
	for(var i = 0; i<exercise_questions.length; i++) {
		if (this.state[`exercise${i+1}_done`]) {
			cnt++;
		}
	}
	if (this.state.mission_set) {
		cnt++;
	}
		console.log("cnt is " + cnt);

	if (cnt === exercise_questions.length+2) {
		this.setState({finished_exercises: true});
	}
}

SubmitBrand(id) {
	axios.post(APIHost + '/api/addBrand', {
		withCredentials: true,
		brand: this.state[`${id}_answer`]
	}).then(res =>  {
		this.SubmitBrandCallback();
	}).catch(error => {
		console.log("Failed to submit brand");
		this.setState({submission_failure: true});
	});
}

onSubmitBrand(event) {
	event.preventDefault();
	this.SubmitBrand(event.target.id);
}

DisplayExercises() {
	var display = [];
	var display_questions = [];
	var display_video = [];
	var display_button = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
             <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
             </div></div>); 
	}
	 var display_mission = [];
	var display_brand = [];
	if (this.state.mission_set) {
		display_mission.push(<p>Personal Mission Statement: {this.state.mission}</p>);
	} else {
		display_mission.push(<form>
			<div className="form-group">
 			Personal Mission Statement: <textarea onChange={this.onExerciseChange} id="5" rows="1" cols="1" type="text" name="mission" value={this.state.mission} className="form-control"></textarea>
				</div>
				<button className="btn btn-primary my-3" onClick={this.onSubmitMission} type="submit" id="5">Add</button>
				</form>);
	}
	if (this.state.brand_set) {
		display_brand.push(<p>Personal Brand: {this.state.brand}</p>);
	} else {
		display_brand.push(<form>
			<div className="form-group">
 			Personal Brand: <textarea onChange={this.onExerciseChange} id="6" rows="1" cols="1" type="text" name="brand" value={this.state.brand} className="form-control"></textarea>
				</div>
				<button className="btn btn-primary my-3" onClick={this.onSubmitBrand} type="submit" id="6">Add</button>
				</form>);
	}
	for (var i = 1; i<=exercise_questions.length; i++) {
			display_questions.push(<div><p>{i}) {exercise_questions[i-1]} </p>
			<form>
		  	<div className="form-group">
				<textarea onChange={this.onExerciseChange} value={this.state[`${i}_answer`]} id={i} rows="10" cols="80" type="text" name={[`exercise${i}`]} className="form-control"></textarea>
			</div>
			<button onClick={this.onSubmitExercise} type="submit" id={i} disabled={this.state[`exercise${i}_done`]} className="custom-btn">Submit</button>
			</form></div>);
	}

	display_questions.push(<div><hr className="new" /><p>Start to brainstorm on different ideas for your Personal Mission Statement and Brand based on the 
		results of the previous exercise. For your Mission Statement, you are looking for a sentence or two that captures how you want to show 
		up in your life. Your Brand Statement is a 2-3 word phrase that captures the essence of that Mission Statement. This is again something we will develop throughout 
		the training. This is just a start.</p>{display_mission}{display_brand}</div>);
	display_questions.push(<div><hr className="new" /><h5>Your personal actions</h5><p>On a regular basis, revisit your Personal Mission Statement and Personal Brand as well as your Optimal Life definitions. Are you aligned towards them? Do they need updating?</p></div>);

	if(this.state.display_video_after) {
	display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
	} else {
	display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
	}
	display.push(<div className="col-md-12"><h1>Chapter Exercises</h1>{display_button}
		{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_questions}</div>);
	return display;
}

GoToEnd() {
	this.UpdateStage("questions_after", this.GoToEndCallback);
}

ExercisesDone() {
	var display = [];
	display.push(<button onClick={this.GoToEnd} type="submit" className="custom-btn">Finish</button>
	);
	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else if(this.state.sprintID == "none") {
		return (
		<div className="Chapter1">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter1">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_after_questions && 
						this.DisplayQuestions()}
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished()}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{!this.state.loading && this.state.finished_exercises && this.ExercisesDone()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
			
}
}

export default Chapter1