import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter33 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 33,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		average: 0,
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_exercises: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		victimization: [],
		generalizations: [],
		expectations: [],
		core_constitution: [],
		core_attributes: [],
		decision_latitude: [],
		self_limiting: [],
		judgmental: [],
		aggressive: [],
		regret: [],
		personalization: [],
		future_tailing: [],
		magnification: [],
		all_nothing: [],
		minimizing: [],
		mind_reading: [],
		comparative: [],
		chapter_video: "https://player.vimeo.com/video/449406630",
		language: []
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.onGoToEnd = this.onGoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.Exercises = this.Exercises.bind(this);
this.ExercisesCallback = this.ExercisesCallback.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.onGoToExercises = this.onGoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
this.CoreDisharmony = this.CoreDisharmony.bind(this);
this.onCoreDisharmony = this.onCoreDisharmony.bind(this);
this.CoreDisharmonyCallback = this.CoreDisharmonyCallback.bind(this);
this.GetCoreDisharmonies = this.GetCoreDisharmonies.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage === "video") {
			this.setState({display_video: true, display_exercises: false, started: true});
		} else if (stage.data.stage === "exercises") {
			this.setState({display_video: false, display_exercises: true, started: true});
		} else if (this.state.chapter_stage === "done") {
			this.setState({display_video: false, display_exercises: true, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes})
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {
		this.setState({core_constitution: entries.data.entries})
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	var core_disharmony_promise = this.GetCoreDisharmonies().then((core_disharmonies) => {
		core_disharmonies.data.core_disharmonies.forEach((cd) => {
			if(cd.core_constitution_id != null && cd.stretch_id == null) {
				this.setState({[cd.core_constitution_id+"_cc_core_disharmony"]: true});
			} else if (cd.core_attribute_id != null) {
				this.setState({[cd.core_attribute_id+"_ca_core_disharmony"]: true});
			}
		});
		this.setState({core_disharmonies: core_disharmonies.data.core_disharmonies});
	}).catch((error) => {
		console.log("GetCoreDisharmonies failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(language_promise);
	all_promises.push(core_attributes_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_disharmony_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {

	var cnt = 0;
	//var language_cts = new Array(this.state.language.length);
	var language_cts = [];
	var temp_language = [];
	// Creating a 2-dimensional array. The first entry in the array is the language id. Each entry after that is the Cognitive Trap
	this.GetCognitiveTraps().then((cognitive_traps) => {
		var exercise_answers_promise = this.GetExerciseAnswers().then ((exercise_answers) => {
			this.state.language.forEach((lang) => {
				language_cts.push({});
				language_cts[cnt].language_id = lang.id;
				//temp_language.push(element.id);
				exercise_answers.data.exercise_answers.find((ex) => {
					if(ex.exercise_id == lang.id) {
						this.setState({[lang.id + "_ct"]: true});
					}
				});
			
				var ct_array = [];
				cognitive_traps.data.cognitive_traps.find((element) => {
					
					//language_cts[i] = [];
					if (element.language_id == lang.id) {
						if (element.victimization) {
							ct_array.push("Victimization");
						} 
						if (element.generalizations) {
							ct_array.push("Generalization");
						} 
						if (element.expectations) {
							ct_array.push("Expectations");
						} 
						if (element.decision_latitude) {
							ct_array.push("Decision Latitude");
						} 
						if (element.self_limiting) {
							ct_array.push("Self Limiting");
						} 
						if (element.judgmental) {
							ct_array.push("Judgemental")
						} 
						if (element.aggressive) {
							ct_array.push("Aggressive");
						} 
						if (element.regret) {
							ct_array.push("Regret");
						} 
						if (element.personalization) {
							ct_array.push("Personalization");
						}
						if (element.future_tailing) {
							ct_array.push("Future Tailing");
						} 
						if (element.magnification) {
							ct_array.push("Magnification");
						} 
						if (element.all_nothing) {
							ct_array.push("All or Nothing");
						} 
						if (element.minimizing) {
							ct_array.push("Minimizing");
						} 
						if (element.mind_reading) {
							ct_array.push("Mind Reading");
						} 
						if (element.comparative) {
							ct_array.push("Comparative");
						} 

					}
				});
				language_cts[cnt].cts = ct_array;
					/*language_cts[i] = new Array(ct_array.length);
					language_cts[i] = element.language_id;
					cnt = 0;
					ct_array.forEach((ct) => {

						language_cts[i][cnt] = ct;
						cnt++;
					});*/
					cnt++;
			});

			this.setState({loading: false, exercise_answers: exercise_answers.data.exercise_answers, language_cts: language_cts, cognitive_traps: cognitive_traps.data.cognitive_traps});

		}).catch((error) => {
			console.log("Failed to get cognitive_traps " + error);
			this.setState({server_failure: true});
		});

	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});
			
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreDisharmonies() {
	const res = await axios.get(APIHost + '/api/coreDisharmonies',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetCognitiveTraps() {
	const res = await axios.get(APIHost + '/api/cognitiveTraps', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	this.setState({started: true, display_video: true, display_review: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

ExercisesCallback(exercise) {
	var temp_exercises = this.state.exercise_answers;
	temp_exercises.push({});
	temp_exercises[this.state.exercise_answers.length - 1].text = this.state.current_answer;
	temp_exercises[this.state.exercise_answers.length - 1].exercise_id = exercise;
	this.setState({exercise_answers: temp_exercises});
	this.setState({[exercise + "_ct"]: true});
}

Exercises(id) {
var answer = {exercise_id: id, answer: this.state.current_answer};

axios.post(APIHost + '/api/answerExercises', {
			withCredentials: true,
			answer: answer,
			chapter_id: this.state.chapterID
		}).then(res => {
			this.ExercisesCallback(id);
		}).catch(error => {
			console.log("Failed to submit the exercises");
			this.setState({submission_failure: true});
		});
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false})
}

onSubmitExercise(event) {
	event.preventDefault();
	this.Exercises(event.target.id);
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: .5,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback(true);
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

onExerciseChange(event) {
event.persist();
	this.setState({ [event.target.name]: event.target.value, current_answer: event.target.value});
}

DisplayExercises(history) {
	var display_final = [];
	var display_ct1 = [];
	var display_ct2 = [];
	var display_ct3 = [];
	var display_mc = [];
	var display_current = [];
	var flag = false;
	var display_heading = [];
	var display_cc = [];
	var display_ca = [];
	var ct_string = "";
	var cnt = 1;
	var language_cnt = 0;
	this.state.language_cts.forEach((ct) => {
		this.state.language.find((language) => {
			display_ct3 = [];
			display_ct2 = [];
			if(ct.language_id == language.id) {
				if (ct.cts.length > 0) {
					flag = true;
					ct_string = "";
					for (var i=0; i<ct.cts.length; i++) {
						if (i == 0 && ct.length == 1) {
							ct_string = ct.cts[i];
						} else if (i == ct.cts.length-1) {
							ct_string += ct.cts[i];
						} else {
							ct_string += ct.cts[i] + ", ";
						}
					}
					display_ct3.push(<p>{ct_string}</p>);
					if (language.type == "core constitution") {
						this.state.core_constitution.find((cc) => {
							if (cc.id == language.original_id) {
								display_ct2.push(<div className="display-linebreak"><p><font color="blue">From the Core Constitution entry:</font> {cc.entry}</p>
								<p><font color="blue">The full description is:</font></p>
								<p>{cc.description}</p>
				<button className="btn btn-primary my-3" id={cc.id}
				name="core_constitution"
				 onClick={this.onCoreDisharmony} className="btn btn-primary my-3" disabled={this.state[`${cc.id}_cc_core_disharmony`]}>Core Disharmony</button>
								</div>);
							}
						});
					} else if (language.type == "core attribute") {
						this.state.core_attributes.find((ca) => {
							if (ca.id == language.original_id) {
								display_ct2.push(<div className="display-linebreak"><p><font color="blue">From your Core Attributes:</font> {ca.attribute}</p>
								<p><font color="blue">The full description is:</font></p>
								<p>{ca.description}</p>
<button className="btn btn-primary my-3" id={ca.id}
				name="core_attribute"
				 onClick={this.onCoreDisharmony} className="btn btn-primary my-3" disabled={this.state[`${ca.id}_ca_core_disharmony`]}>Core Disharmony</button>
								</div>);
							}
						});
					}
					if (language.type == "core constitution" || language.type == "core attribute") {
						var fooflag = false;
						var fooex = "";
						if(this.state.exercise_answers.length>0) {
						this.state.exercise_answers.find((ex) => {
								if (ex.exercise_id == language.id) {
									fooflag = true;
									fooex = ex.text;
								}
							});
						}
							
							if (fooflag) {
								display_ct1.push(<div className="display-linebreak">
									<h4>The language entry: {language.entry}</h4>
									<p><font color="blue">Cognitive Trap(s):</font> {display_ct3}</p>
									{display_ct2}
									<p><font color="blue">This was your response:</font> <br />{fooex}</p></div>);
							} else {
								display_ct1.push(<div>
							<h4>The language entry: {language.entry}</h4>
							<p><font color="blue">Cognitive Trap(s):</font> {display_ct3}</p>
							{display_ct2}
							<form>
							<div className="form-group">
	     					<textarea onChange={this.onExerciseChange} rows="10" cols="80" type="text" id={[`exercise${cnt}`]} name={ct[1]} className="form-control"></textarea>
	  						</div>
	  						<button onClick={this.onSubmitExercise} type="submit" name={[`submit${cnt}`]} disabled={this.state[`${language.id}_ct`]} id={language.id} className="custom-btn">Submit</button>
	  						</form></div>);
	  						cnt++;
							}
	  					}
	  				}
  				}
  			});
  			
	});
	if (history && flag) {
		display_final.push(<div><h4>Below are the details of what you accomplished in this chapter</h4>{display_ct1}<p>Head back to   
	your <Link to="/home">Profile</Link></p></div>);
	} else if (history) {
		display_final.push(<div><h4>You didn't have any Cognitive Traps in your Core Language. That's Great!</h4><p>Head back to   
	your <Link to="/home">Profile</Link></p></div>);
	} else if (this.state.finished && flag) {
		display_final.push(<div><h4>Congrats! You finished a very important chapter on your way to Optimal Mental Fitness!</h4><p>Below is a review 
		of what you accomplished. For any entries you didn't provide an answer, the text areas are still available in case you want to provide more insights on the entries.</p><hr className="new" />{display_ct1}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>)
	} else if (this.state.finished) {
		display_final.push(<div><h4>Well - that was an easy chapter for you! You didn't have any Cognitive Traps show up in your Core Language.
		That's Great! We will continue to keep an eye on this as we move forward in the training.</h4><p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);
	} else if (flag) {
	display_final.push(<div><h1>Cognitive Traps in your Core language</h1><p>Below, you will see each of your language entries from your Core Constitution or your Core Attributes 
		that you were able to label with a Cognitive Trap.
		As discussed in the chapter video, these are crucial to get after. The healthy parts of your Mental Core are you at your best.
		If you are expressing yourself while trapped in one of these categories, Core Disharmonies will continue to drive negative
		Response Cycles, preventing you from achieving Optimal Mental Fitness. If you think you have struggled with either the Core Constitution entry or the Core Attribute listed, mark it has a Core Disharmony by clicking the Core Disharmonyy button. This will be used for review later when you start the process of strengthening your Mental Core. Take your time here to read through each one and capture
		how you might have changed your approach expressed in the description shown. Use the text area below each entry to work through this.</p><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><hr />{display_ct1}<hr />
		<p>Click <button className="custom-btn" onClick={this.onGoToEnd} type="submit">Finish</button> when you are ready to complete this chapter.</p></div>);
  	} else {
  		display_final.push(<div><h4>It doesn't appear that you have any Cognitive Traps showing up in your Core Language. That's GREAT!
  		It is definitely something you'll want to continue to keep an eye on. You'll have more opportunities to examine this later in the training</h4></div>);
  	}
	
	return display_final;
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_exercises: true, started: true, finished: true});
}

CoreDisharmonyCallback(id, type) {
	if (type == "core_constitution") {
	this.setState({[id+"_cc_core_disharmony"]: true});
	} else {
	this.setState({[id+"_ca_core_disharmony"]: true});
	}
}

CoreDisharmony(id, type) {
	if (type == "core_constitution") {
		axios.post(APIHost + '/api/coreDisharmonies', {
			withCredentials: true,
			core_constitution_id: id,
			type: type
		}).then(res => {
			this.CoreDisharmonyCallback(id, type);
		}).catch(error => {
			console.log("Failed to submit the exercises");
			this.setState({submission_failure: true});
		});
	} else {
		axios.post(APIHost + '/api/coreDisharmonies', {
			withCredentials: true,
			core_attribute_id: id,
			type: type
		}).then(res => {
			this.CoreDisharmonyCallback(id);
		}).catch(error => {
			console.log("Failed to submit the exercises");
			this.setState({submission_failure: true});
		});
	}
}

onCoreDisharmony(event) {
	event.preventDefault();
	this.CoreDisharmony(event.target.id, event.target.name);
}

GoToEnd(callback) {
	axios.post(APIHost + '/api/closeChapter', {
    	chapter_id: this.state.chapterID,
     	sprint_id: this.state.sprintID,
   		withCredentials: true
    }).then(res => {
    	callback();
    }).catch(error => {
    	console.log("Error closing chapter " + error);
   		this.setState({server_failure: true});
    });
}

onGoToEnd(event) {
	event.preventDefault();
	this.GoToEnd(this.GoToEndCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

onGoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("exercises", this.GoToExercisesCallback);
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.onGoToExercises} className="custom-btn">Go To Review</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter33">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayExercises(true)}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter33">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.display_exercises && 
						this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter33