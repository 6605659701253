import React, { useState, useEffect } from "react";
import Header from './components/header';
import Footer from './components/footer';
import './css/stripe.css';
import axios from 'axios';
import {APIHost} from './GlobalVariables';
import { Link, Redirect } from 'react-router-dom';

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const CreditUpdateFailure = () => (
<h2><font color="red">We apologize. An error occurred while updating the number of credits for you. Support has been automatically contacted and will rectify as soon as possible.<br />
If you have any questions or concerns, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

export default function WebinarCheckout(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [email, setEmail] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [discountCode, setDiscountCode] = useState(null);
  const [paymentID, setPaymentID] = useState(null);
  const [server_failure, setServerFailure] = useState(false);
  const [name, setName] = useState('');
  const [credit_update_failure, setCreditUpdateFailure] = useState(false);
  const [total, setTotal] = useState(parseFloat(props.price));
  const [displayMessage, setDisplayMessage] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
        axios.post(APIHost + '/api/createPaymentIntent', {
          withCredentials: true, amount: parseInt(props.charge)
        }).then((res) => {
            setClientSecret(res.data.clientSecret);
            setPaymentID(res.data.paymentID);
            setTotal(parseFloat(props.price));
        }).catch((error) => {
          console.log("Error is " + error);
          setServerFailure(true);
        });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    var charge = 0;
    if(quantity != 1 || discountCode == "MCI") {
        charge = parseInt(props.charge);
        if(discountCode == "MCI") {
          charge = parseInt(props.charge);
        }
      axios.post(APIHost + '/api/updatePaymentIntent', {
    quantity: quantity, 
    charge: charge,
    id: paymentID,
    withCredentials: true
  }).then( async () => {
    console.log("Successfully update the payment intent");
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name
        }
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      console.log("Quantity for get credits " + props.webinar_id);
        axios.post(APIHost + '/api/purchaseWebinar', {
         webinar_id: props.webinar_id, 
         email: email,
          withCredentials: true
        }).then(() => {
         setError(null);
          setProcessing(false);
          setSucceeded(true);
        }).catch((error) => {
          console.log("Error is " + error);
          setCreditUpdateFailure(true);
        });
    }
  }).catch((error) => {
    console.log("Error is " + error);
    setServerFailure(true);
  });
} else {
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name
        }
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      console.log("webinar_id " + props.webinar_id);
        axios.post(APIHost + '/api/purchaseWebinar', {
         webinar_id: props.webinar_id, 
         email: email,
          withCredentials: true
        }).then(() => {
         setError(null);
          setProcessing(false);
          setSucceeded(true);
        }).catch((error) => {
          console.log("Error is " + error);
          setCreditUpdateFailure(true);
        });
 
    }
  }

  };

const UpdateQuantity = event => {
  setQuantity(event.target.value.replace(/\D/,''));
  var reg_charge = 0;
  var discount_charge = 0;
    reg_charge = parseFloat(props.price);
    discount_charge = parseFloat(props.price);

  if (event.target.value == 0) {
      if (discountCode == "MCI") {
        setTotal(discount_charge);
      } else {
        setTotal(reg_charge);
      }
    } else {
      if(discountCode == "MCI") {
        setTotal(event.target.value*discount_charge);
      } else {
        setTotal(event.target.value*total);
      }
  }
};

const UpdateDiscountCode = event => {
  if (event.target.value == "MCI") {
     setTotal(quantity*parseFloat(props.price));

  }
  setDiscountCode(event.target.value);
};
   /*<p>Have a discount code? Enter it here. <input value={discountCode} onChange={UpdateDiscountCode}/></p>
*/
  return (
          <div className="CheckoutForm">
    <form id="payment-form" onSubmit={handleSubmit}>
    <p>Please make sure the email you provide matches the email you registered with. Please also check your spam folder for the confirmation email.</p>
        <p>Workshop: ${total}</p>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />
      <input
        type="text"
        value={name}
        onChange={(n) => setName(n.target.value)}
        placeholder="Name on card"
      />
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Purchase"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
      {server_failure && <ServerFailure />}
      {credit_update_failure && <CreditUpdateFailure />}
    </form>

      </div>
  );
}
