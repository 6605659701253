import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
import {UpdateLastActivity} from '../utilities.js';
 				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter7 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 7,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		answers: [],
		answers_before: [],
		answers_after: [],
		display_video: false,
		loading: true,
		finished: false,
		cc_exercises: false,
		ca_exercises: false,
		virus_exercises: false,
		stretch_exercises: false,
		display_video_after: false,
		finished_exercises: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		added_cc: false,
		added_ca: false,
		added_virus: false,
		viruses: [],
		questions: [],
		chapter_video: "https://player.vimeo.com/video/447780033"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetStretchAttributes = this.GetStretchAttributes.bind(this);
this.onSubmitCC = this.onSubmitCC.bind(this);
this.SubmitCC = this.SubmitCC.bind(this);
this.SubmitCCCallback = this.SubmitCCCallback.bind(this);
this.onSubmitCA = this.onSubmitCA.bind(this);
this.SubmitCA = this.SubmitCA.bind(this);
this.SubmitCACallback = this.SubmitCACallback.bind(this);
this.onSubmitStretch = this.onSubmitStretch.bind(this);
this.SubmitStretch = this.SubmitStretch.bind(this);
this.SubmitStretchCallback = this.SubmitStretchCallback.bind(this);
this.onRemoveEntry = this.onRemoveEntry.bind(this);
this.RemoveEntry = this.RemoveEntry.bind(this);
this.RemoveCCCallback = this.RemoveCCCallback.bind(this);
this.RemoveCACallback = this.RemoveCACallback.bind(this);
this.RemoveStretchCallback = this.RemoveStretchCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.SubmitVirusCallback = this.SubmitVirusCallback.bind(this);
this.onSubmitVirus = this.onSubmitVirus.bind(this);
this.SubmitVirus = this.SubmitVirus.bind(this);
this.RemoveVirusCallback = this.RemoveVirusCallback.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	UpdateLastActivity();
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (this.state.chapter_stage == "video") {
			this.setState({display_video: true, 
			cc_exercises: false, ca_exercises: false, virus_exercises: false, display_exercises: false, started: true});
		} else if (this.state.chapter_stage == "core_constitution") {
			this.setState({display_video: false, 
			cc_exercises: true, ca_exercises: false, virus_exercises: false, display_exercises: true, started: true});
		} else if (this.state.chapter_stage == "core_attributes")  {
			this.setState({display_video: false, 
			cc_exercises: false, ca_exercises: true, virus_exercises: false, display_exercises: true, started: true});		
		} else if (this.state.chapter_stage == "viruses")  {
			this.setState({display_video: false, 
			cc_exercises: false, ca_exercises: false, virus_exercises: true, display_exercises: true, started: true});		
		} else if (this.state.chapter_stage == "done") {
		this.setState({display_before_questions: false, display_video: false, 
			display_after_questions: false, display_exercises: false, finished: true, display_exercises: false, started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		if(attributes.data.attributes.length > 0) {
			this.setState({added_ca: true});
		}
		this.setState({core_attributes: attributes.data.attributes})
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {
		if (entries.data.entries.length > 0) {
			this.setState({added_cc: true});
		}
		this.setState({core_constitution: entries.data.entries})
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var stretch_promise = this.GetStretchAttributes().then((attributes) => {
		this.setState({stretch_attributes: attributes.data.attributes})
	}).catch((error) => {
		console.log("GetStretchAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		if(viruses.data.viruses.length > 0) {
			this.setState({added_virus: true});
		}
		this.setState({viruses: viruses.data.viruses})
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_attributes_promise);
	all_promises.push(stretch_promise);
	all_promises.push(viruses_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		if(this.state.chapter_stage != 'exercises') {
			this.setState({finished_exercises: false});
		}
		this.setState({loading: false});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetStretchAttributes() {
	const res = await axios.get(APIHost + '/api/stretchAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_before_questions: false, display_video: true, 
		display_after_questions: false, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
event.persist();
	this.setState({ [event.target.name]: event.target.value, current_answer: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_current = [];
	var display_cc = [];
	var display_ca = [];
	var display_viruses = [];
	var display_stretch = [];

	for (var i=0; i<this.state.core_constitution.length; i++) {
		display_current.push(<li>{this.state.core_constitution[i].entry}</li>);
	}
	display_cc.push(<div><ul>{display_current}</ul></div>);
	display_current = [];
	for (var i=0; i<this.state.core_attributes.length; i++) {
		display_current.push(<li>{this.state.core_attributes[i].attribute}</li>);
	}
	display_ca.push(<div><ul>{display_current}</ul></div>);
	display_current = [];
	for (var i=0; i<this.state.viruses.length; i++) {
		display_current.push(<li>{this.state.viruses[i].virus}</li>);
	}
	display_viruses.push(<div><ul>{display_current}</ul></div>);
	display_current = [];
	for (var i=0; i<this.state.stretch_attributes.length; i++) {
		display_current.push(<li>{this.state.stretch_attributes[i].attribute}</li>);
	}	
	var his_text = '';
	if(history === "history") {
		his_text = "Here is how you defined your Mental Core";
	} else {
		his_text = "Congrats! You have finished the chapter!";
	}
	display_stretch.push(<div><ul>{display_current}</ul></div>);
	display.push(<div className="col-md-12"><h1>{his_text}</h1>
	<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
	<div className='container'>
	<div className='row'>  
	<div className='col-md-6'><h3>Your Core Constitution:</h3>
	<p>{display_cc}</p>
	<h3>Your Core Attributes:</h3>
	<p>{display_ca}</p></div>
	<div className='col-md-6'><h3>Your Stretch Attributes:</h3>
	<p>{display_stretch}</p>
	<h3>Your Viruses:</h3>
	<p>{display_viruses}</p>
	</div></div><h3 className="my-2">Chapter Video:</h3><p><div className="embed-responsive embed-responsive-4by3 service-video">
    <iframe src={this.state.chapter_video} width="320" height="180" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div></p></div><p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);		
	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({cc_exercises: true, display_video: false, ca_exercises: false, virus_exercises: false, display_exercises: true});
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: .25,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("core_constitution", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitCCCallback(id) {
	var temp_cc = this.state.core_constitution;
	temp_cc.push({});
	temp_cc[this.state.core_constitution.length-1].entry = this.state.cc;
	temp_cc[this.state.core_constitution.length-1].id = id;
	this.setState({core_constitution: temp_cc, cc: "", added_cc: true});
}

SubmitCC() {
	axios.post(APIHost + '/api/coreConstitution', {
			withCredentials: true,
			entry: this.state.current_answer,
			update: false
		}).then(res => {
			this.SubmitCCCallback(res.data.id);
		}).catch(error => {
			console.log("Failed to submit a core constitution entry");
			this.setState({submission_failure: true});
		});
}

onSubmitCC(event) {
	event.preventDefault();
	this.SubmitCC();
}

SubmitCACallback(id) {
	var temp_ca = this.state.core_attributes;
	temp_ca.push({});
	temp_ca[this.state.core_attributes.length-1].attribute = this.state.ca;
	temp_ca[this.state.core_attributes.length-1].id = id;
	this.setState({core_attributes: temp_ca, ca: "", added_ca: true});
}

SubmitCA() {
	axios.post(APIHost + '/api/coreAttributes', {
			withCredentials: true,
			attribute: this.state.current_answer
		}).then(res => {
			this.SubmitCACallback(res.data.id);
		}).catch(error => {
			console.log("Failed to submit a core attribute");
			this.setState({submission_failure: true});
		});
}

onSubmitCA(event) {
	event.preventDefault();
	this.SubmitCA();
}

SubmitVirusCallback(id) {
	var temp_virus = this.state.viruses;
	temp_virus.push({});
	temp_virus[this.state.viruses.length-1].virus = this.state.virus;
	temp_virus[this.state.viruses.length-1].id = id;
	this.setState({viruses: temp_virus, virus: "", added_virus: true});
}

SubmitVirus() {
	axios.post(APIHost + '/api/viruses', {
			withCredentials: true,
			virus: this.state.current_answer,
			update: false
		}).then(res => {
			this.SubmitVirusCallback(res.data.id);
		}).catch(error => {
			console.log("Failed to submit a virus");
			this.setState({submission_failure: true});
		});
}

onSubmitVirus(event) {
	event.preventDefault();
	this.SubmitVirus();
}

SubmitStretchCallback(id) {
	var temp_stretch = this.state.stretch_attributes;
	temp_stretch.push({});
	temp_stretch[this.state.stretch_attributes.length-1].attribute = this.state.stretch;
	temp_stretch[this.state.stretch_attributes.length-1].id = id;
	this.setState({stretch_attributes: temp_stretch, stretch: ""});
}

SubmitStretch() {
	axios.post(APIHost + '/api/stretchAttributes', {
			withCredentials: true,
			attribute: this.state.current_answer,
			added: false
		}).then(res => {
			this.SubmitStretchCallback(res.data.id);
		}).catch(error => {
			console.log("Failed to submit a stretch attribute");
			this.setState({submission_failure: true});
		});
}

onSubmitStretch(event) {
	event.preventDefault();
	this.SubmitStretch();
}

RemoveCCCallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.core_constitution.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			cnt++;
		}
	});
	if (temp.length == 0) {
		this.setState({added_cc: false});
	}
	this.setState({core_constitution: temp});
}

RemoveCACallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.core_attributes.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].attribute = element.attribute;
			cnt++;
		}
	});
	if(temp.length == 0) {
		this.setState({added_ca: false});
	}
	this.setState({core_attributes: temp});
}

RemoveStretchCallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.stretch_attributes.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].attribute = element.attribute;
			cnt++;
		}
	});
	this.setState({stretch_attributes: temp});
}

RemoveVirusCallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.viruses.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].virus = element.virus;
			cnt++;
		}
	});
	if(temp.length == 0) {
		this.setState({added_virus: false});
	}
	this.setState({viruses: temp});
}

RemoveEntry(id, api) {
	axios.delete(APIHost + '/api/' + api, {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		if (api === "coreConstitution") {
			this.RemoveCCCallback(id);
		} else if (api === "coreAttributes") {
			this.RemoveCACallback(id);
		} else if (api === "stretchAttributes") {
			this.RemoveStretchCallback(id);
		} else {
			this.RemoveVirusCallback(id);
		}
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveEntry(event) {
	event.preventDefault();
	console.log("event.target.id " + event.target.id);
	this.RemoveEntry(event.target.id, event.target.name);
}

NextExercisesCallback(stage) {
	window.scroll(0,0);
	if (stage === "core_constitution") {
		this.setState({ca_exercises: true, cc_exercises: false, virus_exercises: false});
	} else if (stage === "core_attributes") {
		this.setState({ca_exercises: false, cc_exercises: false, virus_exercises: true});
	} else {
		this.setState({ca_exercises: false, cc_exercises: false, virus_exercises: false, display_exercises: false, finished: true});
	}
}

NextExercises(stage) {
	var next_stage = '';
	var percent_complete = null;
	if (stage === "core_constitution") {
		next_stage = "core_attributes";
		percent_complete = .5;
	} else if (stage === "core_attributes") {
		next_stage = "viruses";
		percent_complete = .75
	} else {
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback(stage);
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises(event.target.id);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_heading = [];
// display exercises for core attributes and stretch so they can add and remove from those respective lists
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if(this.state.cc_exercises) {
		display_heading.push(<h1>Defining Your Core Constitution</h1>);
	for (var i=0; i<this.state.core_constitution.length; i++) {
		display_current.push(<li>{this.state.core_constitution[i].entry} <button id={this.state.core_constitution[i].id} className="btn btn-primary my-3" name="coreConstitution" onClick={this.onRemoveEntry}>Remove</button></li>);
	}
	display_mc.push(<div><ul>{display_current}</ul></div>);

	
	display_intro.push(<div><p>A Core Constitution consists of statements of beliefs and values you hold true for yourself 
	and hold others to. Examples are: "I believe people should take responsibility for their actions" and "I value the quality of my 
	marital relationship over the successes I have at work". This is a brainstorming exercise. Try and limit it to 30 minutes. 
	You know you are done when it is taking more than a few minutes to think of the next one. <br /> <br />
	If you are struggling with generating your list, here are some questions that might ignite some ideas:</p>
	<p><ul>
	<li>If you were asked to do a presentation tomorrow on something that excites you or you feel passionate about, what would the topic be?</li>
	<li>If you were asked to write an article for a newspaper or magazine, what would it be about?</li>
	<li>If you were to start a blog, what would the first 3 blog entries be about?</li>
	<li>If you had unlimited money/resources, what would you what to do for the first year, 5 years, 10 years?  What would you want to make sure you accomplished?</li>
	<li>If you were to donate to a charity or start your own, what would that charity be?</li>
	<li>If you could start a business of any kind, what would it be?</li>
	<li>If you could have any job, what would it be?</li>
	<li>If you could sit and have a conversation with any well-known person, who would it be and what would you want to talk about/know?</li>
	<li>What are the most important things you want to accomplish in your life? What motivates that decision?</li>

	</ul>  </p><hr className="new" />
	<div className='container'>
    <div className='row'>  
	<div className='col-md-6'>
	<p>Enter a new Core Constitution entry in the space provided and then hit the "Add" button. Repeat until you are done and then click Finish.</p>
				<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="cc" value={this.state.cc} className="form-control"></textarea>
  				</div>
  				<button className="btn btn-primary my-3" onClick={this.onSubmitCC} type="submit" id="AddCC">Add</button>
  				</form>
  				<br />
  				<p>Click <button disabled={!this.state.added_cc} className="btn btn-primary my-3" onClick={this.onNextExercises} type="submit" id="core_constitution">Finish</button> to move to the next section</p>
  				
  				</div>
  				<div className='col-md-6'><h4>Your Core Constitution</h4> <br />{display_mc}</div></div></div></div>);
	} else if (this.state.ca_exercises) {
		display_heading.push(<h1>Defining Your Core and Stretch Attributes</h1>);
		for (var i=0; i<this.state.core_attributes.length; i++) {
			display_current.push(<li>{this.state.core_attributes[i].attribute} <button className="btn btn-primary my-3" id={this.state.core_attributes[i].id} name="coreAttributes" onClick={this.onRemoveEntry}>Remove</button></li>);
		}
		display_mc.push(<div><ul>{display_current}</ul></div>);
		for (var i=0; i<this.state.stretch_attributes.length; i++) {
			display_current_stretch.push(<li>{this.state.stretch_attributes[i].attribute} <button id={this.state.stretch_attributes[i].id} className="btn btn-primary my-3" name="stretchAttributes" onClick={this.onRemoveEntry}>Remove</button></li>);
		}
		display_stretch.push(<div><ul>{display_current_stretch}</ul></div>);
		display_intro.push(<div><p>List your Core Attributes below. These are the positive attributes you feel you consistently live and 
		express yourself with. For this and the below, they should be in the form of an adjective not a noun, for example, 
		compassionate not compassion. You can include short phrases to express these attributes as well. Statements that start 
		with “I am…”, “I feel I’m good at…”, or “I excel at…” can be used.</p><hr className="new" />
			<div className='container'>
    <div className='row'>  
	<div className='col-md-6'>
	<p>Enter a Core Attribute and then click the Add button. Repeat until you have are finished and then you can move to the Stretch Attributes.</p>
				<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="ca" value={this.state.ca} className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitCA} className="btn btn-primary my-3" type="submit" id="AddCA">Add</button>
  				</form>
  				<br />
  				
  				</div>
  				<div className='col-md-6'><h4>Your Core Attributes</h4> <br />{display_mc}</div></div></div>
  				<hr />
  				<p>List your Stretch attributes. These are attributes that you might express yourself with at times, but it takes 
  				effort to do so. They do not come as naturally to you as your Core Attributes. They are attributes you aspire to 
  				express yourself with more consistently.</p>
  				<div className='container'>
    			<div className='row'>  
				<div className='col-md-6'>
				<p>Add a Stretch Attribute using the space provided and clicking the Add button. Once you are finished, you can move to the next section
				by clicking the Finish button</p>
				<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="stretch" value={this.state.stretch} className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitStretch} className="btn btn-primary my-3" type="submit" id="AddStretch">Add</button>
  				</form>
  				<br />
  				
  				</div>
  				<div className='col-md-6'><h4>Your Stretch Attributes</h4> <br />{display_stretch}</div></div></div>
  				<p>Click <button onClick={this.onNextExercises} className="btn btn-primary my-3" disabled={!this.state.added_ca} type="submit" id="core_attributes">Finish</button> to move to the next section</p>

  				</div>);
	} else if (this.state.virus_exercises) {
		display_heading.push(<h1>Defining Your Viruses</h1>);
		for (var i=0; i<this.state.viruses.length; i++) {
			display_current.push(<li>{this.state.viruses[i].virus} <button className="btn btn-primary my-3" id={this.state.viruses[i].id} name="viruses" onClick={this.onRemoveEntry}>Remove</button></li>);
		}
		display_mc.push(<div><ul>{display_current}</ul></div>);

		
		display_intro.push(<div><p>Here, you will list those parts of your Mental Core that bring you pain, suffering, and negative consequences 
		in your life. These are the Viruses or the infections in your Mental Core. List your Viruses by using phrases such as: I feel…, I fear…, 
		I hate when…, I’m afraid that/to…, I think…, I find myself…, I need…, I want…, and the like. 
		You may also include Viruses in the form of an adjective as you did for the Core Attributes. 
		If you need help in creating the list, consider the following:</p>
		<p><ul>
			<li>What drove you during your last conflicts you had?</li>
			<li>Are there phrases that you can write in the form listed above that might have held you back in your career or current job situation?</li>
			<li>Are there phrases that speak to issues you have faced in your intimate relationships?</li>
			<li>Are there ongoing family conflicts that you feel you have contributed to and if so, how have you or do you contribute?</li>
			<li>Do you have pet-peeves? What are your thoughts, reactions, feelings, etc when they are violated?</li>
			<li>Are there ways you perceive or describe groups of people negatively that have impacted how you interact with people in your life?</li>

		</ul>  </p><hr className="new" />
		<div className='container'>
	    <div className='row'>  
		<div className='col-md-6'>
		<p>Enter a Virus in the space provided and click Add. Once you are finished adding Viruses, click Finish.</p>
					<form>
					<div className="form-group">
	     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="virus" value={this.state.virus} className="form-control"></textarea>
	  				</div>
	  				<button onClick={this.onSubmitVirus} className="btn btn-primary my-3" type="submit" id="AddVirus">Add</button>
	  				</form>
	  				<br />
	  				<p>Click <button onClick={this.onNextExercises} disabled={!this.state.added_virus} className="btn btn-primary my-3" type="submit" id="virus">Finish</button> to move to the next section</p>
	  				
	  				</div>
	  				<div className='col-md-6'><h4>Your Viruses</h4> <br />{display_mc}</div></div></div></div>);
		}
  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}

display.push(<div className="col-md-12">{display_heading}{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter7">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter7">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter7