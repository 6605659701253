import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';

const exercise_questions = ["Find a time you are in an environment where there’s a lot of activity around you, maybe a coffee shop or a mall. \
							Sit or stand and close your eyes. Focus on all the sounds you hear. Try and identify what they all are. Try and divide \
							them into individual sounds as finely as you can. Imagine and appreciate the experiences that might be going on to \
							generate those sounds. Write about your experiences below.", "For the next hour, notice the little things you do and normally take for granted.  If you \
							open a door, take a moment to really feel what the knob feels like in your hand. Feel the weight of the door as you open \
							it. Concentrate on the muscles in your hand and arm. Appreciate all your body is doing to accomplish this task. Feel the \
							pressure in your legs release and engage as you take steps through the doorway. You can pick anything you want. Other \
							examples could be getting something out of the refrigerator or taking a shower. Bring to your awareness all that’s involved \
							in each and every step you have to take in order to do what you otherwise do on autopilot.", "Find a quiet place to sit comfortably. \
							Close your eyes and just breathe normally. See if you can tune into your heart beat and pulse. Can you get a sense of the rhythm of \
							it? It is OK if you can’t. Just work to become aware of it. Now, start to notice the cadence of your breath. Can you count how long \
							the inhale is versus the exhale? Can you find a consistent cadence to it? Now, change your breathing pattern such that your inhale \
							is longer than your exhale. A good example would be 5 second inhale, hold for 2 seconds, 3 second exhale. Try this for 5 cycles. \
							What do you notice about your heart rate? What sensations are you feeling? Now, reverse that, meaning the inhale is 3 seconds, hold \
							for 2, and the exhale is 5. What do you feel now? What happened to your heart rate? What changed about the sensations you are \
								feeling in your body?"];
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter41 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 41,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_exercises: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
		chapter_stage: null,
		exercise_answers: [],
		chapter_video: "https://player.vimeo.com/video/451333493"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.Exercises = this.Exercises.bind(this);
this.ExercisesCallback = this.ExercisesCallback.bind(this);
this.ExercisesDone = this.ExercisesDone.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, display_exercises: false, started: true});
		} else if (stage.data.stage == "exercises") {
			this.setState({display_video: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "done") {
			this.setState({display_video: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var exercise_answers_promise = this.GetExerciseAnswers().then ((exercise_answers) => {
		this.setState({exercise_answers: exercise_answers.data.exercise_answers});
		var cnt=0;
		exercise_answers.data.exercise_answers.forEach((element) => {
			this.setState({["exercise" + element.exercise_id + "_done"]: true, [element.exercise_id+"_answer"]: element.text});
			cnt++;
		});
		if (cnt === exercise_questions.length+1) {
			this.setState({finished_exercises: true});
		}
	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(exercise_answers_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		this.setState({loading: false});
	});
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}
StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter " + error);
		this.setState({start_failure: true});
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
  	event.persist();
	this.setState({ ["exercise" + event.target.id + "_done"]: false, [event.target.name]: event.target.value, [event.target.id+"_answer"]: event.target.value});
}

ExercisesCallback(exercise) {
	var temp_exercises = this.state.exercise_answers;
	temp_exercises.push({});
	/*if(this.state.exercise_answers.length == 0) {
		console.log("Lengh of exercise_answers is 0 " + " and " + exercise + " and " + this.state.current_answer);
		temp_exercises[0].text = this.state.current_answer;
		temp_exercises[0].exercise_id = exercise;
	} else {*/
	
		temp_exercises[this.state.exercise_answers.length - 1].text = this.state[`${exercise}_answer`];
		temp_exercises[this.state.exercise_answers.length - 1].exercise_id = exercise;
	//}
	this.setState({exercise_answers: temp_exercises});
	this.setState({["exercise" + exercise + "_done"]: true});
	var cnt = 0;
	for(var i = 0; i<exercise_questions.length+1; i++) {
		if (this.state[`exercise${i+1}_done`]) {
			cnt++;
		}
	}
	if (cnt === exercise_questions.length+1) {
		this.setState({finished_exercises: true});
	}
}

Exercises(id) {
	var answer = {exercise_id: id, answer: this.state[`${id}_answer`]};
	axios.post(APIHost + '/api/answerExercises', {
		withCredentials: true,
		answer: answer,
		chapter_id: this.state.chapterID
	}).then(res => {
		this.ExercisesCallback(id);
	}).catch(error => {
		console.log("Failed to submit the exercises " + error);
		this.setState({submission_failure: true});
	});
}

onSubmitExercise(event) {
	event.preventDefault();
	this.Exercises(event.target.id);
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_questions = [];
	var display_video = [];
	var display_button = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
             <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
             </div></div>); 
	}
	var flag = false;
	var ex_answer = "";
	var display_response = [];
	this.state.exercise_answers.find((ex) => {
		if (ex.exercise_id == 1) {
			flag = true;
			ex_answer = ex.text;
		}
	});
	if (flag) {
		display_response.push(<p>{ex_answer}</p>);
	} else {
		display_response.push(<form>
		  	<div className="form-group">
				<textarea onChange={this.onExerciseChange} rows="10" cols="80" type="text" name="exercise1" className="form-control"></textarea>
			</div>
			<button onClick={this.onSubmitExercise} type="submit" id="1" disabled={this.state[`exercise1_done`]} className="custom-btn">Submit</button>
			</form>);
	}
	display_questions.push(<div><p>1) It is crucially important to engage with your thought stream in an entirely new way. We have talked about getting to 
	the side of the raging river that is your thought stream. You can’t control your thoughts because you don’t choose your thoughts, but you can get enough separation 
	from your thoughts that you can start making decisions on the direction you take. You can look at your thoughts as input to a decision rather than 
	driving how you respond. RAIN is a useful acronym here. Sit quietly and allow thoughts to come to you and follow these steps:
	<ul><li>R: Recognize that you are having a specific thought without judgement or trying to reason about it</li>
	<li>A: Allow it to be there. Hold it in your mind and simply allow it to exist, again, without judgement</li>
	<li>I: Investigate the thought. WIthout drawing conclusions, simply explore the thought as if someone else presented it to you. Ask yourself questions with a spirit of curiosity and without judgement.</li>
	<li>N: Non-identification. Maintain a distance from the thought. Separate yourself from the thought and allow it to leave your conscious awareness and move to the next one, leaving the previous thought and emotions behind.</li>
	</ul>
	Write about your experience below. What did you notice about the effort you had to put forward to maintain distance from the thought? Did you find yourself struggling to 
	maintain single thoughts? Did you find other thoughts intruded? Remember, this is practice with focus on Progress over Perfection!
	</p>{display_response}</div>);
	for (var i = 1; i<=exercise_questions.length; i++) {
		flag = false;
		var ex_answer = "";
		this.state.exercise_answers.find((ex) => {
			if (ex.exercise_id == i+1) {
				flag = true;
				ex_answer = ex.text;
			}
		});
		display_questions.push(<div><p>{i+1}) {exercise_questions[i-1]} </p><p>{ex_answer}</p></div>);
	}
	if(this.state.display_video_after) {
	display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
	} else {
	display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
	}
	var display_heading = [];
	if (history) {
		display_heading.push(<h1>Below you can review the chapter information</h1>);
	} else {
		display_heading.push(<h1>Congrats on finishing the chapter!</h1>);
	}
	display.push(<div className="col-md-12">{display_heading}{display_button}
		{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><p>As we've discussed, mindfulness is the "How" behind Mental Fitness Training, meaning, it is how you will make your journey from where you 
	  		are to Optimal Mental Fitness. Keep these exercises in mind and continue to practice them as often as you can. These are just the beginning. I would encourage
	  		you to seek additional mindfulness exercises. There will be additional mindfulness help in later themes of the training to look forward to. You Got This!</p><hr className="new" />{display_questions}<hr className="new" />
	  		<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);
	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, display_video: false, finished: true, finished_exercises: false});
}

UpdateStage(stage, callback) {
	var percent_complete = null;
	if (stage === "exercises") {
		percent_complete = .5;
	}
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: percent_complete,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("exercises", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
		<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
		</aside>
	);
	return display;
}

ExercisesDone() {
	var display = [];
	display.push(<button onClick={this.GoToEnd} type="submit" id="2" className="custom-btn">Finish</button>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
         <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
         </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

DisplayExercises() {
	var display = [];
	var display_questions = [];
	var display_video = [];
	var display_button = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
             <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
             </div></div>); 
	}
	var display_response = [];
	var for_id = 1;
		display_response.push(<form>
		  	<div className="form-group">
				<textarea onChange={this.onExerciseChange} id={for_id} value={this.state[`${for_id}_answer`]} rows="10" cols="80" type="text" name="exercise1" className="form-control"></textarea>
			</div>
			<button onClick={this.onSubmitExercise} type="submit" id={for_id} disabled={this.state[`exercise1_done`]} className="custom-btn">Submit</button>
			</form>);
	display_questions.push(<div><p>1) It is crucially important to engage with your thought stream in an entirely new way. We have talked about getting to 
	the side of the raging river that is your thought stream. You can’t control your thoughts because you don’t choose your thoughts, but you can get enough separation 
	from your thoughts that you can start making decisions on the direction you take. You can look at your thoughts as input to a decision rather than 
	driving how you respond. RAIN is a useful acronym here. Sit quietly and allow thoughts to come to you and follow these steps:
	<ul><li>R: Recognize that you are having a specific thought without judgement or trying to reason about it</li>
	<li>A: Allow it to be there. Hold it in your mind and simply allow it to exist, again, without judgement</li>
	<li>I: Investigate the thought. WIthout drawing conclusions, simply explore the thought as if someone else presented it to you. Ask yourself questions with a spirit of curiosity and without judgement.</li>
	<li>N: Non-identification. Maintain a distance from the thought. Separate yourself from the thought and allow it to leave your conscious awareness and move to the next one, leaving the previous thought and emotions behind.</li>
	</ul>
	Write about your experience below. What did you notice about the effort you had to put forward to maintain distance from the thought? Did you find yourself struggling to 
	maintain single thoughts? Did you find other thoughts intruded? Remember, this is practice with focus on Progress over Perfection!
	</p>{display_response}</div>);
	for (var i = 1; i<=exercise_questions.length; i++) {
		display_questions.push(<div><p>{i+1}) {exercise_questions[i-1]} </p>
		<form>
	  	<div className="form-group">
			<textarea onChange={this.onExerciseChange} id={i+1} value={this.state[`${i+1}_answer`]} rows="10" cols="80" type="text" name={[`exercise${i+1}`]} className="form-control"></textarea>
		</div>
		<button onClick={this.onSubmitExercise} type="submit" id={i+1} disabled={this.state[`exercise${i+1}_done`]} className="custom-btn">Submit</button>
		</form></div>);
	}
	if(this.state.display_video_after) {
	display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
	} else {
	display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
	}
	display.push(<div className="col-md-12"><h1>Chapter Exercises</h1>{display_button}
		{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><p>Below are the Mindfulness exercises for this chapter. The focus here is to take the next step in your Cognitive Awareness, focused on your Thought Stream. 
		As you move forward in the training you will start to identify opportunities to intervene in your normal Response Patterns. You've learned that mindfulness is the "How" of
		achieving this. Take your time to document your experiences in the space provided and fully immerse yourself in what you do here.</p><hr className="new" />{display_questions}</div>);
	return display;
}

GoToEnd() {
	this.UpdateStage("done", this.GoToEndCallback);
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else if(this.state.sprintID == "none") {
		return (
		<div className="Chapter41">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished(true)}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter41">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished()}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{!this.state.loading && this.state.finished_exercises && !this.state.finished && this.ExercisesDone()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
			
}
}

export default Chapter41