import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';
import companySuccess from '../images/the-company-success.jpg';
import scale from '../images/at_scale3.png';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.DirectToMFT = this.DirectToMFT.bind(this);
        this.DirectToAccount = this.DirectToAccount.bind(this);
    }

    DirectToMFT(event) {
        event.preventDefault();
        const path = "/mft";
        this.props.history.push({ pathname: path });
    }

    DirectToAccount(event) {
        event.preventDefault();
        const path = "/CreateAccount";
        this.props.history.push({ pathname: path });
    }

    render() {

        return (
            <>
                <section id="testimonial" className="testimonial-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-6">
                                <div className="testimonial-left-content mt-45">
                                    <h6 className="sub-title">Welcome to</h6>
                                    <h4 className="title mb-2">My Core Insights</h4>
                                    <h6>Our Mission: To build a community where mental health and wellness is affordable and accessible to everyone!</h6>
                                    {/* <ul className="testimonial-line">
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul> */}
                                    <p className="text">You deserve to get access to a powerful mental health program regardless of your circumstances. It should work with you and for you. It shouldn't be  unnecessarily hard! You should be able to access it any time and anywhere. Introducing the first self-directed, but guided solution, <Link to={{ pathname: "/mft" }}>
                                        Mental Fitness Training</Link>, delivered to you in a revolutionary framework here at My Core Insights. You will:</p>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Learn to Live your Optimal Life</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Create a strong psychological foundation </h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Define Purposeful Meaning </h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Live life Deliberately and on Your Terms </h6>
                                            </div>
                                            </li>
            
                                    </ul>
                                    {/*  <table className="table my-4">
                                            <tbody>
                                                <tr>
                                                    <th className="px-2"><p className="text">Learn to Live your Optimal Life</p></th>
                                                    <th className="px-2"><p className="text">Define Purposeful Meaning</p></th>
                                                </tr>
                                                <tr>
                                                    <th className="px-2"><p className="text">Attain Fulfilled Success</p></th>
                                                    <th className="px-2"><p className="text">Live life Deliberately and on Your Terms</p></th>
                                                </tr>
                                            </tbody>
                                        </table>  */}
                                    <p>You have one shot at this life. Let's make it an Optimal Life!</p>
                                    <div className="form-input light-rounded-buttons mt-3 text-center">
                                        <button onClick={this.DirectToMFT} className="main-btn light-rounded-two">Learn More</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 py-5">
                                {/* <div className="testimonial-right-content mt-50">
                                        <div className="quota">
                                            <i className="lni lni-quotation"></i>
                                        </div>
                                        <div className="testimonial-content-wrapper testimonial-active">
                                            <div className="single-testimonial">
                                                <div className="testimonial-author d-sm-flex justify-content-between">
                                                    <div className="author-info d-flex align-items-center">
                                                        <iframe title="video" src="https://player.vimeo.com/video/447885591" width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                <div className="author-info d-flex align-items-center justify-content-center pt-5">
                                    <iframe title="video" src="https://player.vimeo.com/video/499735436" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                                </div>
                                <div className="form-input light-rounded-buttons text-center">
                                    <button onClick={this.DirectToAccount} className="main-btn light-rounded-two">Create Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                           <section id="testimonial" className="testimonial-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-6">
                                <div className="testimonial-left-content mt-45">
<div className="text-center"><h4 className="title mb-2">Mental Fitness Training<br />for your business</h4></div>
                    <img src={scale} alt="At Scale" className="img-fluid" />
                    <h5>Imagine addressing the mental wellness challenges in your organization for a fraction of the cost of replacing a single employee! <Link to="/ForBusiness">Learn How</Link></h5>
                                </div>
                            </div>
                            <div className="col-lg-6 py-5">
                    <img src={companySuccess} alt="Company success" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </section>
                <section id="services" className="features-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="single-features mt-40">
                                    <div className="features-content">
                                        <h6 className="para">
                                            "Mental Fitness Training has really helped me to move forward in my life. I have tried a ton of different things to help
                                            myself meet my own expectations I have of my life but nothing seemed to hold for me. The way that it is broken down and easy to make progress
                                            but also really challenges you is amazing!"
                                            </h6>
                                        <p className="text"><br />- Bridget A.<br />Vancouver WA</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="single-features mt-40">
                                    <div className="features-content">
                                        <h6 className="para">
                                            "This is the first time that I feel like I'm having break through after break through. Elliot's approach is amazing and inspirational. You can tell there's a lot
                                            behind what he is teaching you. And it is actually affordable! I can change my life and actually feel good about it because it isn't breaking my bank."
                                            </h6>
                                        <p className="text"><br />- Mark C. <br />Portland OR</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-9">
                                <div className="single-features mt-40">
                                    <div className="features-content">
                                        <h6 className="para">
                                            "I'm really thankful I found My Core Insights and started the training. I have tried literally everything. The way Elliot breaks it all down for you and the way the website works really is
                                            special. It's hard to change big things in your life but this really is making it happen. I'm grateful for this experience."
                                            </h6>
                                        <p className="text"><br />- Alison M.<br />Ontario Canada</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

}

export default Welcome;
