import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { APIHost } from './GlobalVariables';

const ServerFailure = () => (
    <h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const AmbassadorSuccess = () => (
    <h2><font color="blue">Congrats on becoming an Ambassador! So excited for you to be part of this crucial effort! Click here for additional informaion on the program</font></h2>
);

class AmbassadorCentral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referrals: [],
            member_id: this.props.location.state.member_id,
            total_number_objectives: 0,
            loading: false
        }
        this.GetAllReferrals = this.GetAllReferrals.bind(this);
        this.DisplayReferrals = this.DisplayReferrals.bind(this);
        this.GetTotalReferralObjectives = this.GetTotalReferralObjectives.bind(this);
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.setState({ loading: true });
        var all_promises = [];
        var all_referrals_promise = this.GetAllReferrals().then((referrals) => {
            if (referrals.data.referrals.length > 0) {
                this.setState({ referrals: referrals.data.referrals });
            }
            return referrals;
        }).catch((error) => {
            console.log("GetAllReferrals failed " + error);
            this.setState({ server_failure: true });
        });
        var total_promise = this.GetTotalReferralObjectives().then(totals => {
            this.setState({ total_number_objectives: totals.data.total_objectives });

        }).catch((error) => {
            console.log("GetAllReferrals failed " + error);
            this.setState({ server_failure: true });
        });

        all_promises.push(all_referrals_promise);
        all_promises.push(total_promise);

        Promise.all(all_promises).then(() => {
            this.setState({ loading: false });
        }).catch((error) => {
            console.log("all_promises failed " + error);
            this.setState({ server_failure: true });
        });
    }

    async GetTotalReferralObjectives() {
        const res = await axios.get(APIHost + '/api/calculateReferralTotals',
            { params: { withCredentials: true, member_id: this.state.member_id } });
        return await res;
    }

    async GetAllReferrals() {
        const res = await axios.get(APIHost + '/api/getAllReferrals',
            { params: { withCredentials: true, member_id: this.state.member_id } });
        return await res;
    }

    DisplayReferrals() {
        var display = [];
        var display_referrals = [];
        if (this.state.referrals.length > 0) {
            this.state.referrals.forEach((referral) => {
                var last_activity = null;
                if (referral.last_activity) {
                    last_activity = referral.last_activity;
                } else {
                    last_activity = "hasn't logged in yet";
                }
                display_referrals.push(<tr><td>{referral.name}</td><td>{referral.email}</td><td>{last_activity}</td><td>{referral.number_of_objectives}</td></tr>);
            });
            display.push(<div><h4 className="mb-2">Your direct referrals</h4><table className="table table-bordered"><thead><tr><th>Name</th><th>Email</th><th>Last Active</th><th># of Objectives Purchased</th>
            </tr></thead><tbody>{display_referrals}</tbody></table><div className="mt-4">Total number of objectives purchased in your network this month (this includes direct referrals and non-direct referrals): <h6 className="d-inline-block">{this.state.total_number_objectives}</h6>
                </div>
            </div>);
        } else {
            display.push(<h5 className="mt-3 text-danger">You do not have any referrals yet</h5>);
        }
        return display;
    }


    render() {
        return (
            <div className="AmbassadorCentral">
                <Header history={this.props.history} />
                <section className="two-sided-box">

                    <div className="container">
                        <h3>Ambassador Central</h3><hr />
                        <div className="two-sided-box-in py-5">
                            <div className="d-flex justify-content-center px-4">
                                <div style={{ width: '100%' }}>
                                    <div className="mb-3 text-left">
                                        <div>Use your member ID, {this.state.member_id} to refer people to My Core Insights. You can also provide them this link to join:<h6 className="d-inline-block"> https://mycoreinsights.com/CreateAccount/{this.state.member_id}</h6></div></div>
                                    {!this.state.loading && this.DisplayReferrals()}
                                </div>
                            </div></div></div>
                </section>

                <Footer history={this.props.history} />

            </div>
        );


    }
}

export default AmbassadorCentral;