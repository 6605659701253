import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import {APIHost} from './GlobalVariables';
import Header from './components/header';
import Footer from './components/footer';

const Calendly = () => {
  return (
    <div style={{ height: "800px" }}>
      <iframe
        src="https://calendly.com/mycoreinsights/personaltraining"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  );
};

const ServerFailure = () => (
  <h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class ScheduleTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      have_credits: false,
      confirmed: false,
      credits: 0
    }
    this.GetSprint = this.GetSprint.bind(this);
    this.GetCredits = this.GetCredits.bind(this);
    this.DisplayInfo = this.DisplayInfo.bind(this);
    this.ClickedCalendar = this.ClickedCalendar.bind(this);
    this.ClickedCalendarCallback = this.ClickedCalendarCallback.bind(this);
  }

componentDidMount() {
    window.scroll(0, 0);
    this.setState({loading: true});
    var promises = [];

        var credits_promise = this.GetCredits().then(creds => {
          this.setState({ credits: creds.data.credits });
          if (creds.data.credits === 0) {
            this.setState({have_credits: false});
          } else {
            this.setState({have_credits: true});
          }
          return creds;
        }).catch((error) => {
          console.log("GetCredits failed " + error);
          this.setState({ server_failure: true });
        });
    promises.push(credits_promise);
    Promise.all(promises).then(() => {
      this.setState({loading: false});
    }).catch((error) => {
      console.log("Promises failed " + error);
      this.setState({server_failure: true});
    });
}

async GetSprint() {
  const res = await axios.post(APIHost + '/api/getSprint', { withCredentials: true });
  return await res;
}

async GetCredits() {
  const res = await axios.get(APIHost + '/api/getSessionCredits', { withCredentials: true });
  return await res;
}

DisplayInfo() {
  var display = [];
  var display_confirm = [];
  if (this.state.confirmed) {
      display_confirm.push(<h4><font color="red">Your session has been confirmed! So excited to meet with you! Note: only click the Confirm button after scheduling additional sessions</font></h4>);
  } else {
    display_confirm.push(<h4><font color="red">Once you've scheduled your time you must click the confirm button to apply your credit and confirm your appointment otherwise it will be canceled within 24 hours. Only use the button once per scheduled session.</font></h4>);

  }
  if (this.state.have_credits) {
    display.push(<div><p className="text">
                    Use the Calendly application provided here to schedule a 45 minute Personal Training session with Elliot. This is your opportunity to get direct one-on-one help from Elliot
                    on your Journey towards Optimal Mental Fitness. Bring your questions and be ready to be empowered towards your next steps to living your Optimal Life!</p>{display_confirm}
                     <button onClick={this.ClickedCalendar} disabled={this.state.confirmed} type="submit" className="custom-btn">Confirm</button></div>);
  } else {
    display.push(<div><h4>You'll need to purchase Training Session credits before scheduling your Personal Training session. Please click <Link to="/GetSessionCredits">here</Link> to make that purchase.</h4></div>);
  }
  return display;
}

ClickedCalendarCallback() {
  this.setState({confirmed: true});
}

ClickedCalendar(event) {
  event.preventDefault();
    axios.post(APIHost + '/api/confirmSession', {
      withCredentials: true
      }).then( () => {
        console.log("In ClickedCalendar");
        this.ClickedCalendarCallback(true);
      }).catch(error => {
        console.log("ClickedCalendar failed " + error);
        this.setState({server_failure: true});
    }); 
}

  render() {
    if(this.props.location.state == null) {
    return ( <Redirect to='/Home' /> );
  } else if (this.state.loading) {
      return (<div className="ScheduleTraining"><Header /><h3 className="p-3">Loading...</h3></div>);
    } else {
      return (
        <>
        <Header history={this.props.history} />
          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
              {this.state.server_failure && <ServerFailure />}
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">Schedule a Personal Training session with Elliot!</h4>
                    {!this.state.loading && this.DisplayInfo()}
                  </div>
                </div>
                <div className="col-lg-6">
                {!this.state.loading && this.state.have_credits && <Calendly />}
                </div>
              </div>
            </div>
          </section>
          <Footer history={this.props.history} />
        </>
      );
    }
  }
}

export default ScheduleTraining;