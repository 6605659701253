import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter50 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 50,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		finished_exercises: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		viruses: [],
		display_exercises: false,
		chapter_video: "https://player.vimeo.com/video/451336490"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.SubmitExercise = this.SubmitExercise.bind(this);
this.SubmitExerciseCallback = this.SubmitExerciseCallback.bind(this);
this.GetCatalysts = this.GetCatalysts.bind(this);
this.GetSignals = this.GetSignals.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetStretchAttributes = this.GetStretchAttributes.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.GetVirtues = this.GetVirtues.bind(this);
this.GetCoreVirus = this.GetCoreVirus.bind(this);
this.GetAntidotes = this.GetAntidotes.bind(this);
this.onNextVirus = this.onNextVirus.bind(this);
this.NextVirus = this.NextVirus.bind(this);
this.NextVirusCallback = this.NextVirusCallback.bind(this);
this.GoToIntroCallback = this.GoToIntroCallback.bind(this);
this.GoToIntro = this.GoToIntro.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
		chapter_stage_promise = this.GetChapterStage().then ((stage) => {
			this.setState({chapter_stage: stage.data.stage});
			if (this.state.chapter_stage == "video") {
				this.setState({display_video: true, display_exercises: false, started: true});
			} else if (this.state.chapter_stage == "exercises") {
				this.setState({display_video: false, display_exercises: true, intro: false, started: true});	
			} else if (this.state.chapter_stage == "intro") {
				this.setState({display_video: false, display_exercises: true, intro: true, started: true});
			} else if (this.state.chapter_stage == "done") {
				this.setState({display_video: false, display_exercises: false, started: true, finished: true});
			}
		}).catch((error) => {
			console.log("GetChapterStage failed " + error);
			this.setState({server_failure: true});
		});
	}

	var signals_promise = this.GetSignals().then((signals) => {
		this.setState({signals: signals.data.signals});
	}).catch((error) => {
		console.log("GetSignals failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});

	var core_virus_promise = this.GetCoreVirus().then((core_virus) => {
		this.setState({core_virus: core_virus.data.core_virus});
	}).catch((error) => {
		console.log("GetCoreVirus failed " + error);
		this.setState({server_failure: true});

	});

	var antidotes_promise = this.GetAntidotes().then((antidotes) => {
		this.setState({antidotes: antidotes.data.antidotes});
	}).catch((error) => {
		console.log("GetAntidotes failed " + error);
		this.setState({server_failure: true});

	});

	var catalysts_promise = this.GetCatalysts().then((catalysts) => {
		this.setState({catalysts: catalysts.data.catalysts});
	}).catch((error) => {
		console.log("GetCatalysts failed " + error);
		this.setState({server_failure: true});
	});

	var stretch_promise = this.GetStretchAttributes().then((stretch) => {
		this.setState({stretch_attributes: stretch.data.attributes});
	}).catch((error) => {
		console.log("GetStretchAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(language_promise);
	all_promises.push(status_promise);
	all_promises.push(signals_promise);
	all_promises.push(viruses_promise);
	all_promises.push(catalysts_promise);
	all_promises.push(core_attributes_promise);
	all_promises.push(stretch_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_virus_promise);
	all_promises.push(antidotes_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var wisdom_ids = [];
		var courage_ids = [];
		var justice_ids = [];
		var hl_ids = [];
		var transcendence_ids = [];
		var temperance_ids = [];

		this.GetVirtues().then((virtues) => {
			var current_language = [];
			var antidote_answers = [];
			var answer_cnt = 0;
			var cur_cnt = 0;
			for (var i = 0; i< this.state.viruses.length; i++) {
				var flag = false;
				this.state.antidotes.find((antidote) => {
					if (antidote.virus_id == this.state.viruses[i].id && antidote.finished) {
						flag = true;
					}
				});
				if (!flag) {
						this.state.antidotes.find((antidote) => {
							if (antidote.virus_id === this.state.viruses[i].id && antidote.antidote != null) {
								antidote_answers.push({});
								antidote_answers[answer_cnt].exercise = antidote.exercise;
								antidote_answers[answer_cnt].antidote = antidote.antidote;
								answer_cnt++;
								this.setState({["exercise"+antidote.exercise]: true, finished_exercises: true});
							}
						});
					this.state.language.find((lang) => {
						if (lang.original_id == this.state.viruses[i].id && lang.type == "virus") {
							current_language.push({});
							current_language[cur_cnt].id = lang.id;
							current_language[cur_cnt].type = lang.type;
							cur_cnt++;
						}
					});
					this.setState({antidote_answers: antidote_answers, current_virus: this.state.viruses[i].id, current_language: current_language});
					break;
				}
			}
			var wisdom_cnt = 0;
			var justice_cnt = 0;
			var courage_cnt = 0;
			var hl_cnt = 0;
			var transcendence_cnt = 0;
			var temperance_cnt = 0;
			var strongest_virtue = "wisdom";
			var virus_virtues = [];
			var virus_virutes_cnt = 0;
			virtues.data.virtues.forEach((element) => {
				var virus_virtue_flag = false;
				var current_id = null;
				this.state.language.find((lang) => {
					if (lang.id == element.language_id && lang.type == 'virus') {
						virus_virtues.push({});
						this.state.viruses.find((virus) => {
							if ( virus.id == lang.original_id) {
								current_id = virus.id;
								virus_virtue_flag = true;
							}
						});
					}
				});
				if (element.wisdom) {
					if (virus_virtue_flag) {
						virus_virtues[virus_virutes_cnt].virus = current_id
						virus_virtues[virus_virutes_cnt].wisdom = true;
					}
					wisdom_ids.push(element.language_id);
					wisdom_cnt++;
				}
				if (element.justice) {
					if (virus_virtue_flag) {
						virus_virtues[virus_virutes_cnt].virus = current_id
						virus_virtues[virus_virutes_cnt].justice = true;
					}
					justice_ids.push(element.language_id);
					justice_cnt++;
				}
				if (element.courage) {
					if (virus_virtue_flag) {
						virus_virtues[virus_virutes_cnt].virus = current_id
						virus_virtues[virus_virutes_cnt].courage = true;
					}
					courage_ids.push(element.language_id);
					courage_cnt++;
				}
				if (element.humanity_love) {
					if (virus_virtue_flag) {
						virus_virtues[virus_virutes_cnt].virus = current_id
						virus_virtues[virus_virutes_cnt].humanity_love = true;
					}
					hl_ids.push(element.language_id);
					hl_cnt++;
				}
				if (element.transcendence) {
					if (virus_virtue_flag) {
						virus_virtues[virus_virutes_cnt].virus = current_id
						virus_virtues[virus_virutes_cnt].transcendence = true;
					}
					transcendence_ids.push(element.language_id);
					transcendence_cnt++;
				}
				if (element.temperance) {
					if (virus_virtue_flag) {
						virus_virtues[virus_virutes_cnt].virus = current_id
						virus_virtues[virus_virutes_cnt].temperance = true;
					}
					temperance_ids.push(element.language_id);
					temperance_cnt++;
				}
				if (virus_virtue_flag) {
					virus_virutes_cnt++;
				}
			});
			if (justice_cnt>strongest_virtue) {
				strongest_virtue = "justice";
			} 
			if (courage_cnt > strongest_virtue) {
				strongest_virtue = "courage";
			} 
			if (hl_cnt > strongest_virtue) {
				strongest_virtue  = "hl";
			} 
			if (strongest_virtue > transcendence_cnt) {
				strongest_virtue = "transcendence";
			} 
			if (strongest_virtue > temperance_cnt) {
				strongest_virtue = "temperance";
			}

			this.setState({loading: false, virus_virtues: virus_virtues, wisdom_cnt: wisdom_cnt, justice_cnt: justice_cnt, courage_cnt: courage_cnt,
			hl_cnt: hl_cnt, transcendence_cnt: transcendence_cnt, temperance_cnt: temperance_cnt,
			 strongest_virtue: strongest_virtue, virtues: virtues.data.virtues, wisdom: wisdom_ids, courage: courage_ids,
				justice: justice_ids, temperance: temperance_ids, transcendence: transcendence_ids, humanity_love: hl_ids});
		}).catch((error) => {
			console.log("Failed to get virtues " + error);
			this.setState({server_failure: true});
		});	
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetAntidotes() {
	const res = await axios.get(APIHost + '/api/antidotes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCatalysts() {
	const res = await axios.get(APIHost + '/api/catalysts',
		{params: {withCredentials: true}});
	return await res;
}

async GetSignals() {
	const res = await axios.get(APIHost + '/api/signals',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetVirtues() {
	const res = await axios.get(APIHost + '/api/virtues', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreVirus() {
	const res = await axios.get(APIHost + '/api/coreVirusAssociation',
		{params: {withCredentials: true}});
	return await res;
}

async GetStretchAttributes() {
	const res = await axios.get(APIHost + '/api/stretchAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({[event.target.name]: event.target.value});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_current = [];
	var display_cc = [];
	var display_ca = [];
	var display_viruses = [];
	var display_viruses = [];
	var display_video = [];
	var display_button = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if(this.state.display_video_after) {
		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	for (var i=0; i<this.state.viruses.length; i++) {
		var display_antidotes = [];
		this.state.antidotes.find((antidote) => {
			if (this.state.viruses[i].id == antidote.virus_id) {
				display_antidotes.push(<li>{antidote.antidote} </li>);
			}
		});
		
		display_viruses.push(<div><h5>Virus: {this.state.viruses[i].virus}</h5>
		<h5>This Virus's Antidotes</h5>
				<div><ul>{display_antidotes}</ul></div>
				<hr className="new" />
		</div>);
	}
	display.push(<div><h1>Congrats on finishing the chapter</h1><p>Below are the Antidotes you defined for each Virus. Take some time to review
	them here. You will make full use of them in the next chapter in defining your Action Plans.</p>{display_button}{display_video}
	<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
	<hr className="new" />{display_viruses}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>)
	
	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false, intro: false});
}

GoToIntroCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false, intro: true});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, display_video: false, finished: true, loading: false});
}

UpdateStage(stage, callback) {
	var percent_complete = null;
	if (stage == "exercises") {
		percent_complete = .25;
	} else if (stage == "intro") {
		percent_complete = .1;
	}
	axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			percent_complete: percent_complete,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("exercises", this.GoToExercisesCallback);
}

GoToIntro(event) {
	event.preventDefault();
	this.UpdateStage("intro", this.GoToIntroCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-52"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToIntro} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

onSubmitExercise(event) {
	event.preventDefault();
	this.SubmitExercise(event.target.id);
}

SubmitExercise(id) {
	var answer = null;
	var exercise = 0;
	var temp = this.state.antidotes;
	var length = this.state.antidotes.length;
	if (id === "exercise1") {
		answer = this.state[`${this.state.current_virus}_exercise1`];
		exercise = 1;
	} else if (id === "exercise2") {
		answer = this.state[`${this.state.current_virus}_exercise2`];
		exercise = 2;	
	} else if (id === "exercise3") {
		answer = this.state[`${this.state.current_virus}_exercise3`];
		exercise = 3;	
	} else if (id === "exercise4") {
		answer = this.state[`${this.state.current_virus}_exercise4`];
		exercise = 4;	
	}
	temp.push({});
	temp[length].virus_id = this.state.current_virus;
	temp[length].antidote = answer;
	temp[length].exercise = exercise;
	axios.post(APIHost + '/api/antidotes', {
		withCredentials: true,
		antidote: answer,
		virus_id: this.state.current_virus,
		exercise: exercise
	}).then(res => {
		this.SubmitExerciseCallback(id, temp);
	}).catch(error => {
		console.log("Failed to submit an antidote " + error);
		this.setState({submission_failure: true});
	});
}

SubmitExerciseCallback(id, new_antidotes) {
	this.setState({[id]: true, finished_exercises: true, antidotes: new_antidotes});
}

NextVirusCallback() {
	window.scroll(0,0);
	var current_language = [];
	var cur_cnt = 0;
	var finished_flag = false;
	for (var i = 0; i< this.state.viruses.length; i++) {
		var flag = false;
		this.state.antidotes.find((antidote) => {
			if (antidote.virus_id == this.state.viruses[i].id) {
				flag = true;
				finished_flag = true;
			}
		});
		if (!flag) {
				finished_flag = false;
				/*this.state.antidotes.find((antidote) => {
					console.log("check antidotes " + antidote.virus_id + " and " + this.state.viruses[i].id + " and " + antidote.antidote);
					if (antidote.virus_id === this.state.viruses[i].id && antidote.antidote != null) {
						this.setState({["exercise"+antidote.exercise]: true, finished_exercises: true});
					}
				});*/
			this.state.language.find((lang) => {
				if (lang.original_id == this.state.viruses[i].id && lang.type == "virus") {
					current_language.push({});
					current_language[cur_cnt].id = lang.id;
					current_language[cur_cnt].type = lang.type;
				}
			});
			this.setState({finished_exercises: false, antidote_answers: [], current_virus: this.state.viruses[i].id, current_language: current_language,
				exercise1: false, exercise2: false, exercise3: false, exercise4: false,
				[this.state.current_virus+"_exercise1"]: "", [this.state.current_virus+"_exercise2"]: "",
				[this.state.current_virus+"_exercise3"]: "", [this.state.current_virus+"_exercise4"]: ""});
			break;
		}
	}
	if(finished_flag) {
		this.UpdateStage("done", this.GoToEndCallback);
	} else {
		this.setState({loading: false, intro: false, display_exercises: true});
	}
}

NextVirus() {
	if (this.state.intro) {
		this.UpdateStage("exercises", this.NextVirusCallback);
	} else {
		axios.post(APIHost + '/api/antidotes', {
			withCredentials: true,
			virus_id: this.state.current_virus,
			finished: true
		}).then(res => {
			this.NextVirusCallback();
		}).catch(error => {
			console.log("Failed to close out a virus description " + error);
			this.setState({submission_failure: true});
		});
	}
}

onNextVirus(event) {
	event.preventDefault();
	this.setState({loading: true})
	this.NextVirus();
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_viruses = [];
	var display_cc = [];
	var display_ca = [];
	var display_stretch = [];
	var display_questions = [];
	var display_questions_core = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	this.state.core_constitution.forEach((cc) => {
		display_cc.push(<p>Entry: {cc.entry}<Popup trigger={<button className="btn btn-primary my-3"> See description</button>} position="right center">
	    				<div>{cc.description}</div>
	  					</Popup></p>);
	});
	this.state.core_attributes.forEach((ca) => {
		display_ca.push(<p>Attribute: {ca.attribute}<Popup trigger={<button className="btn btn-primary my-3"> See description</button>} position="right center">
	    				<div>{ca.description}</div>
	  					</Popup></p>);
	});
	this.state.stretch_attributes.forEach((stretch) => {
		display_stretch.push(<p>Attribute: {stretch.attribute}<Popup trigger={<button className="btn btn-primary my-3"> See description</button>} position="right center">
	    				<div>{stretch.description}</div>
	  					</Popup></p>);
	});
	this.state.viruses.find((virus) => {
		var display_core_virus = [];
		var display_cc_core = [];
		var display_ca_core = [];
		var display_virus_virtues = [];
		var display_virus_virtues_list = [];
		var has_core = false;
		var virus_virtue_flag = false;
		if (virus.id == this.state.current_virus) {
			var display_catalysts = [];
			var display_signals = [];
			this.state.catalysts.find((c) => {
				if (virus.id == c.virus_id) {
					display_catalysts.push(<li>{c.catalyst}</li>);
				}
			});
			
			this.state.signals.find((s) => {
				if (virus.id == s.virus_id) {
					display_signals.push(<li>{s.signal}</li>);
				}
			});
			this.state.virus_virtues.find((vv) => {
				if (vv.virus == virus.id) {
					console.log("virus_virtue find in exercises " + vv.virtue);
					virus_virtue_flag = true;
					if (vv.wisdom) {
					display_virus_virtues_list.push(<li>Wisdom</li>);
					}
					if (vv.courage) {
					display_virus_virtues_list.push(<li>Courage</li>);
					}
					if(vv.humanity_love) {
					display_virus_virtues_list.push(<li>Humanity and Love</li>);
					}
					if (vv.justice) {
					display_virus_virtues_list.push(<li>Justice</li>);
					}
					if (vv.transcendence) {
					display_virus_virtues_list.push(<li>Transcendence</li>);
					}
					if (vv.temperance) {
					display_virus_virtues_list.push(<li>Temperance</li>);
					}
				}
			});
			// what I need is to have the current_language be an object that has the ID but also the type
			// then I can have an if statement to search either in the core constitution or the attributes object
			this.state.current_language.forEach((cl) => {
				console.log("Current language is " + cl.id);
				this.state.core_virus.find((cv) => {
					if (cl.id == cv.virus_language) {
						has_core = true;
						this.state.language.find((lang) => {
							if (lang.id == cv.core_virus) {
								if (lang.type == "core constitution") {
									this.state.core_constitution.find((cc) => {
										if (cc.id == lang.original_id) {
											display_cc_core.push(<li>{cc.entry}<Popup trigger={<button className="btn btn-primary my-3"> See description</button>} position="right center">
			    							<div>{cc.description}</div>
			  								</Popup></li>)
										}
									});
								} else if (lang.type == "core attribute") {
									this.state.core_attributes.find((ca) => {
										if (ca.id == lang.original_id) {
											display_ca_core.push(<li>{ca.attribute}<Popup trigger={<button className="btn btn-primary my-3"> See description</button>} position="right center">
			    							<div>{ca.description}</div>
			  								</Popup></li>)
										}
									});
								}
							}
						});
					}
				});
			});
			if (has_core) {
				display_core_virus.push(<div><p>Here's a helpful start for getting after this Virus.  You associated these
				helpful parts of your Mental Core with this Virus. Look to these for help in defining your Antidotes</p>
				<ul>{display_cc_core}</ul>
				<ul>{display_ca_core}</ul></div>);
			} else {
				display_core_virus.push(<p>It doesn't look like you associated anything from the healthy parts of your Mental
				Core with this Virus. That's OK! Your Mental Core and your Virtues are still a great resource for getting after
				this Virus and defining your Antidotes.</p>)
			}
			if(virus_virtue_flag) {
				display_virus_virtues.push(<div><p>You associationed language from this Virus to the following Virtues. This is a great starting point 
				for Antidotes!</p><ul>{display_virus_virtues_list}</ul></div>);
			} else {
				display_virus_virtues.push(<p>You didn't associate language from this Virus with any Virtues</p>);
			}
			display_viruses.push(<div><h5>Virus: {virus.virus}</h5>
			<p>Description: {virus.description}</p>
			<div className='container'>
			<div className='row'>  
			<div className='col-md-6'>
			<h5>This Virus's Catalysts</h5>
					<div><ul>{display_catalysts}</ul></div>
			<h5>This Virus's Signals</h5>
					<div><ul>{display_signals}</ul></div>
			<h5>Core Associations</h5>
			{display_core_virus}
			<h5>Virtue Associations</h5>
			{display_virus_virtues}
			</div>

			<div className='col-md-6'>

			<div className='container'>
			<div className='row'>  
			<div className='col-md-6'>
			<h5>Your Core Constitution</h5>
			{display_cc}
			<h5>Your Core Attributes</h5>
			{display_ca}
			</div>
			<div className='col-md-6'>
			<h5>Your Stretch Attributes</h5>
			{display_stretch}
			<h5>Virtues (count is strength of that Virtue for you)</h5>
			<p>Wisdom ({this.state.wisdom_cnt})</p>
			<p>Courage ({this.state.courage_cnt})</p>
			<p>Justice ({this.state.justice_cnt})</p>
			<p>Humanity and Love ({this.state.hl_cnt})</p>
			<p>Transcendence ({this.state.transcendence_cnt})</p>
			<p>Temperance ({this.state.temperance_cnt})</p>
			</div></div></div>
			</div></div></div><hr className="new" /></div>);
			var question1_flag = false;
			var question2_flag = false;
			var question3_flag = false;
			var question4_flag = false;
			this.state.antidote_answers.forEach((answer) => {
				if(answer.exercise == 1) {
					question1_flag = true;
				} else if(answer.exercise == 2) {
					question2_flag = true;
				} else if(answer.exercise == 3) {
					question3_flag = true;
				} else if(answer.exercise == 4) {
					question4_flag = true;
				}
			});
			if (has_core) {
				if (question1_flag) {
					var local_antidote = null;
					this.state.antidote_answers.find((answer) =>  {
						if (answer.exercise == 1) {
							local_antidote = answer.antidote;
						}
					});
					display_questions_core.push(<div><p>Look at the language associated with this Virus and the language you used
					to describe those parts of your Mental Core you associated with this Virus. Can you replace the Virus
					language with the Core language. See if you can re-write the Virus description using this positive language.
					Are there other examples you can think of related to these Mental Core entries that can help you with this Virus?</p>
					<p><b>Your answer:</b> {local_antidote}</p></div>);
				} else {
					display_questions_core.push(<div><p>Look at the language associated with this Virus and the language you used
					to describe those parts of your Mental Core you associated with this Virus. Can you replace the Virus
					language with the Core language. See if you can re-write the Virus description using this positive language.
					Are there other examples you can think of related to these Mental Core entries that can help you with this Virus?</p>
					<form>
					<textarea onChange={this.onExerciseChange} rows="5" cols="40" type="text" value={this.state[`${this.state.current_virus}_exercise1`]} name={`${virus.id}_exercise1`} className="form-control"></textarea>
					</form>
					<button className="custom-btn" onClick={this.onSubmitExercise} type="submit" disabled={this.state.exercise1} id="exercise1">Submit Answer</button></div>);
				}
			}
			display_questions.push(<div>{display_questions_core}</div>);
			if (question2_flag) {
				var local_antidote = null;
				this.state.antidote_answers.find((answer) =>  {
					if (answer.exercise == 2) {
						local_antidote = answer.antidote;
					}
				});
				display_questions.push(<div><p>Look through the rest of your Mental Core entries as well as your Virtues. How can you use the beliefs and values you have
				in your Core Constitution to guide you in dealing with the Catalysts associated with this Virus? If you were to hold true to 
				the Virtues you identified for yourself, how would you deal with these Catalysts?</p>
				<p><b>Your answer:</b> {local_antidote}</p></div>);
			} else {
				display_questions.push(<div><p>Look through the rest of your Mental Core entries as well as your Virtues. How can you use the beliefs and values you have
				in your Core Constitution to guide you in dealing with the Catalysts associated with this Virus? If you were to hold true to 
				the Virtues you identified for yourself, how would you deal with these Catalysts?</p>
				<form>
				<textarea onChange={this.onExerciseChange} rows="5" cols="40" type="text" value={this.state[`${this.state.current_virus}_exericse2`]} name={`${virus.id}_exercise2`} className="form-control"></textarea>
				</form>
				<button className="custom-btn" onClick={this.onSubmitExercise} type="submit" disabled={this.state.exercise2} id="exercise2" >Submit Answer</button></div>);			
			}
			if (question3_flag) {
				var local_antidote = null;
				this.state.antidote_answers.find((answer) =>  {
					if (answer.exercise == 3) {
						local_antidote = answer.antidote;
					}
				});
				display_questions.push(<div><p>Write a critique of the language and reactions you see in the Virus’s passage and the language list associated with the Virus. If you saw someone acting as you describe, 
				what suggestions would you give to that person? How would you suggest they behave differently?</p>
				<p><b>Your answer:</b> {local_antidote}</p></div>);
			} else {
				display_questions.push(<div><p>Write a critique of the language and reactions you see in the Virus’s passage and the language list associated with the Virus. If you saw someone acting as you describe, 
				what suggestions would you give to that person? How would you suggest they behave differently?</p>
				<form>
			<textarea onChange={this.onExerciseChange} rows="5" cols="40" type="text" value={this.state[`${this.state.current_virus}_exercise3`]} name={`${virus.id}_exercise3`} className="form-control"></textarea>
			</form>
			<button className="custom-btn" onClick={this.onSubmitExercise} type="submit" disabled={this.state.exercise3} id="exercise3" >Submit Answer</button></div>);			
			}

			if (question4_flag) {
				var local_antidote = null;
				this.state.antidote_answers.find((answer) =>  {
					if (answer.exercise == 4) {
						local_antidote = answer.antidote;
					}
				});
				display_questions.push(<div><p>If the Virus is of the form “I fear that…”, “I believe that…”, or something similar, ask the question “If that were true, then what?”. 
			This provides a mechanism to challenge the perceived consequences of the Virus. It forces you to examine your assumptions and might disarm you from the fear associated with the virus.  
			For example, take a virus such as “I’m afraid that I’m inadequate at my job”. If you were to ask this question, the answers could be:
			<ul><li>My reviews at work would be poor - they aren’t</li>
			<li>I would not be given the responsibilities I have</li>
			<li>My coworkers wouldn’t seek me out for assistance</li>
			<li>I wouldn’t be able to keep the job as long as I have</li></ul></p>
				<p><b>Your answer:</b> {local_antidote}</p></div>);
			} else {
				display_questions.push(<div><p>If the Virus is of the form “I fear that…”, “I believe that…”, or something similar, ask the question “If that were true, then what?”. 
			This provides a mechanism to challenge the perceived consequences of the Virus. It forces you to examine your assumptions and might disarm you from the fear associated with the virus.  
			For example, take a virus such as “I’m afraid that I’m inadequate at my job”. If you were to ask this question, the answers could be:
			<ul><li>My reviews at work would be poor - they aren’t</li>
			<li>I would not be given the responsibilities I have</li>
			<li>My coworkers wouldn’t seek me out for assistance</li>
			<li>I wouldn’t be able to keep the job as long as I have</li></ul></p>
				<form>
			<textarea onChange={this.onExerciseChange} rows="5" cols="40" type="text" value={this.state[`${this.state.current_virus}_exercise4`]} name={`${virus.id}_exercise4`} className="form-control"></textarea>
			</form>
			<button className="custom-btn" onClick={this.onSubmitExercise} type="submit" disabled={this.state.exercise4} id="exercise4" >Submit Answer</button></div>);			
			}
		}
	});
	if(this.state.intro) {
		display.push(<div><h1>Intro to Building Your Antidotes</h1><p>In this chapter, you will be shown Viruses one at a time and be asked questions
		to build your Antidotes for each one. You will leverage the Cognitive Catalysts you discovered and various Associations that you've made, along with
		the healthy parts of your Mental Core to make that happen. Go ahead and click "Get Started" to start the process.</p>
		<p><button onClick={this.onNextVirus} type="submit" className="custom-btn" id="viruses">Get Started</button></p></div>);
	} else {
		display_intro.push(<div><p>Below, you'll find your Cognitive Catalysts and Signals associated with this
		Virus. You have everything at your disposal to start to define your Antidotes. You'll find your Core Associations from your Language Association System
		below. These are the healthy parts of your Mental Core that you exhibited when describing this Virus. This is a great starting point for your Antidotes. If
		there aren't any of those associations available, no worries! You have all the power of your Mental Core and your Virtues to build from. Answer each of the below questions as 
		thoroughly as possible. These will directly feed your Action Plans you'll build in the next chapter. You Got This!</p>
			<hr /> {display_viruses} {display_questions}
				<p><button onClick={this.onNextVirus} type="submit" className="custom-btn" id="viruses" disabled={!this.state.finished_exercises}>Finish</button></p></div>);
			
		  if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
			} else {
			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
			}
		display.push(<div className="col-md-52"><h1>Building Your Antidotes</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_button}
		{display_video}{display_intro}</div>);
	}

	return display;
}

GoToEnd() {
	this.UpdateStage("done", this.GoToEndCallback);
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter50">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter50">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
					{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter50