import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter45 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 45,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		cc_language: [],
		ca_language: [],
		virus_language: [],
		language: [],
		cognitive_traps: [],
		virus_language_core: [],
		virus_core_part1: false,
		virus_core_part2: false,
		review: false,
		associations: false,
		finished: false,
		display_exercises: false,
		virus_language_core_original: [],
		chapter36_results: [],
		viruses: [],
		chapter_video: "https://player.vimeo.com/video/451334890"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.onGoToEnd = this.onGoToEnd.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.AddAssociations = this.AddAssociations.bind(this);
this.onAddAssociations = this.onAddAssociations.bind(this);
this.AddAssociationsCallback = this.AddAssociationsCallback.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetCoreJumpout = this.GetCoreJumpout.bind(this);
this.GetVirusJumpout = this.GetVirusJumpout.bind(this);
this.LanguageState = this.LanguageState.bind(this);
this.LanguageState2 = this.LanguageState2.bind(this);
this.onVirusCoreAssociations = this.onVirusCoreAssociations.bind(this);
this.VirusCoreAssociations = this.VirusCoreAssociations.bind(this);
this.VirusCoreAssociationsCallback = this.VirusCoreAssociationsCallback.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.Exercises = this.Exercises.bind(this);
this.ExercisesCallback = this.ExercisesCallback.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetChapter36 = this.GetChapter36.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, review: false,
			virus_core_part1: false, virus_core_part2: false, associations: false, display_exercises: false, started: true});
		} else if (stage.data.stage == "associations") {
			this.setState({display_video: false, review: false,
			virus_core_part1: false, virus_core_part2: false, associations: true, display_exercises: true, started: true});
		} else if (stage.data.stage == "virus_core_part1")  {
			this.setState({display_video: false, review: false,
			virus_core_part1: true, virus_core_part2: false, associations: false, display_exercises: true, started: true});			
		} else if (stage.data.stage == "virus_core_part2")  {
			this.setState({display_video: false, review: false,
			virus_core_part1: false, virus_core_part2: true, associations: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "review")  {
			this.setState({display_video: false, review: true,
			virus_core_part1: false, virus_core_part2: false, associations: false, display_exercises: true, started: true});
		} else if (stage.data.stage  == "done") {
			this.setState({display_video: false, review: false,
			virus_core_part1: false, virus_core_part2: false, associations: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_jumpout_promise = this.GetCoreJumpout().then((core_jumpout) => {
		this.setState({core_jumpout: core_jumpout.data.core_language})
	}).catch((error) => {
		console.log("GetCoreJumpout failed " + error);
		this.setState({server_failure: true});
	});

	var virus_jumpout_promise = this.GetVirusJumpout().then((virus_jumpout) => {
		this.setState({virus_jumpout: virus_jumpout.data.virus_language})
	}).catch((error) => {
		console.log("GetVirusJumpout failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});

	var exercise_answers_promise = this.GetExerciseAnswers().then((exercise_answers) => {
		this.setState({exercise_answers: exercise_answers.data.exercise_answers});
		this.state.exercise_answers.forEach((element) => {
			this.setState({[element.exercise_id + "_done"]: true});
		});
	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});
	var language_promise = this.GetLanguage().then((language) => {
		var temp_cc = [];
		var temp_ca = [];
		var temp_virus = [];
		var language_ids = [];
		var virus_core = [];
		var virus_core2 = [];
		var virus_core_associations = [];
		var virus_core_cnt = 0;
		// I'm using 2 different variables because for some reason, when you check the check box,  the LanguageState function
		// by modifying the virus_language_core state variable was also updating the one that was supposed to stay the same
		// No idea why but by using two variables it stopped happening
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
			if (element.virus_core) {
				virus_core.push(element.id);
				virus_core2.push(element.id);
			}
		});
		language.data.language.forEach((element) => {
			if(element.type === "core constitution") {
				if (element.virus_core) {
					virus_core_associations.push({});
					virus_core_associations[virus_core_cnt].core_language_id = element.id;
					virus_core_associations[virus_core_cnt].virus_language_id = element.virus_core;
					virus_core_cnt++;
					this.setState({[element.id+"_disabled"]: true});
				}
				temp_cc.push(element);
			} else if (element.type === "core attribute") {
				if (element.virus_core) {
					virus_core_associations.push({});
					virus_core_associations[virus_core_cnt].core_language_id = element.id;
					virus_core_associations[virus_core_cnt].virus_language_id = element.virus_core;
					virus_core_cnt++;
					this.setState({[element.id+"_disabled"]: true});
				}
				temp_ca.push(element);
			} else if (element.type === "virus") {
				temp_virus.push(element);
			}
		});
		this.setState({virus_language_core: virus_core, virus_language_core_original: virus_core2, language_ids: language_ids, cc_language: temp_cc, ca_language: temp_ca, virus_language: temp_virus, language: language.data.language});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(viruses_promise);
	all_promises.push(core_jumpout_promise);
	all_promises.push(virus_jumpout_promise);
	all_promises.push(exercise_answers_promise);
	all_promises.push(language_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		this.setState({loading: false});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true, added: true}});
	return await res;
}

async GetCoreJumpout() {
	const res = await axios.get(APIHost + '/api/coreLanguageJumpout',
		{params:  {withCredentials: true}});
	return await res;
}

async GetVirusJumpout() {
	const res = await axios.get(APIHost + '/api/virusLanguageJumpout',
		{params:  {withCredentials: true}});
	return await res;
}

async GetChapter36() {
	const res = await axios.get(APIHost + '/api/getChapter36',
		{params:  {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({current_answer: event.target.value, [event.target.name]: event.target.value});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_intro = [];
	var display_mc = [];
	var display_button = [];
	var display_video = [];
	var display_virus_core = [];
	this.state.language.forEach((entry) => {
		var flag = false;
		var answered_flag = false;
		var answer = null;
		var cnt = -1;

		if (entry.virus_core) {
			this.state.exercise_answers.find((ex) => { 
				if (ex.exercise_id == entry.id) {
					answered_flag = true;
					cnt++;
					answer = ex.text;
				}
			});
			display_virus_core.push(<p><font color="blue">Language:</font> {entry.entry}</p>);
			if (entry.type == "core constitution") {
				this.state.core_constitution.find((cc) => {
					if (cc.id == entry.original_id) {
						display_virus_core.push(<p><font color="blue">Original Entry:</font> {cc.entry}</p>);
						display_virus_core.push(<p><font color="blue">Original Description:</font> {cc.description}</p>);
					}
				});
				if (answered_flag) {
					display_virus_core.push(<div><p><font color="blue">Your answer:</font> {answer}</p></div>);
				} else {
					display_virus_core.push(<div><p><font color="blue">You didn't enter a reply to this one.</font></p></div>);
				}
	  			display_virus_core.push(<hr />);
			} else if (entry.type == "core attribute") {
				this.state.core_attributes.find((ca) => {
					if (ca.id == entry.original_id) {
						display_virus_core.push(<p><font color="blue">Original Entry:</font> {ca.attribute}</p>);
						display_virus_core.push(<p><font color="blue">Original Description:</font> {ca.description}</p>);
					}
				});
				this.state.exercise_answers.find((ex) => { 
					if (ex.exercise_id == entry.id) {
						flag = true;
						cnt++;
					}
				});
					if (answered_flag) {
						display_virus_core.push(<div><p><font color="blue">Your answer:</font> {answer}</p></div>);
					} else {
					display_virus_core.push(<div><p><font color="blue">You didn't enter a reply to this one.</font></p></div>);
  					}
  					display_virus_core.push(<hr />);
				}
			}
		});
		display_mc.push(<div>{display_virus_core}</div>);
		display_intro.push(<div><p>Below is all you've learned and achieved in this chapter. Take some time and review it all, and not
		just once, but come back off and on and remind yourself of the journey you took in this chapter through the associations and 
		various discoveries.</p>
			<hr /> {display_mc}
 			</div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
  		if (history == "not history"){
			display.push(<div className="col-md-12"><h1>Congrats! You finished the chapter!</h1>{display_button}
			{display_video}{display_intro}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);
		} else {
			display.push(<div className="col-md-12"><h1>Results of this chapter.</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_button}
				{display_video}{display_intro}<p>Head back to   
	your <Link to="/home">Profile</Link></p></div>);
		}

return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, virus_core_part1: false, virus_core_part2: false, review: false, associations: true, display_video: false});
}

SetChapterStage(callback) {
axios.post(APIHost + '/api/updateStage', {
			stage: "associations",
			withCredentials: true,
			percent_complete: .2,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
event.preventDefault();
this.SetChapterStage(this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="470" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

AddAssociationsCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, virus_core_part1: true, virus_core_part2: false, review: false, associations: false});
}

AddAssociations(associations) {
	var next_stage = "virus_core_part1";
	var percent_complete = .4;
	axios.post(APIHost + '/api/addAssociations', {
		withCredentials: true,
		associations: associations,
		type: "mental_core",
		chapter_id: this.state.chapterID,
  		sprint_id: this.state.sprintID,
  		stage: next_stage,
  		percent_complete: percent_complete
	}).then(() => {
		this.AddAssociationsCallback();
	}).catch((error) => {
		console.log("Failed to submit associations " + error);
		this.setState({server_failure: true});
	});
}

onAddAssociations(event) {
	event.preventDefault();
	var associations = [];
	var cnt = 0;
	var local_core = [];
	var local_virus = this.state.virus_jumpout;
	// this used to be -1 for the virus_cnt and core_cnt assignments which was causing an error
	// when those were empty - I'm not sure why I set it to -1 in the first place
	var virus_cnt = this.state.virus_jumpout.length;
	var local_core = this.state.core_jumpout;
	var core_cnt = this.state.core_jumpout.length;
	this.state.language.forEach((entry) => {
		if(this.state[`${entry.id}_association`] != null) {
			if (entry.type === "virus") {
				local_virus.push({});
				local_virus[virus_cnt].id = entry.id;
				local_virus[virus_cnt].original_id = entry.original_id;
				local_virus[virus_cnt].entry = entry.entry;
				local_virus[virus_cnt].type = entry.type;
				local_virus[virus_cnt].association = this.state[`${entry.id}_association`];
				virus_cnt++;
			} else if (entry.type === "core constitution" || entry.type === "core attribute") {
				local_core.push({});
				local_core[core_cnt].id = entry.id;
				local_core[core_cnt].original_id = entry.original_id;
				local_core[core_cnt].entry = entry.entry;
				local_core[core_cnt].type = entry.type;
				local_core[core_cnt].association = this.state[`${entry.id}_association`];
				core_cnt++;	
			}
			associations.push({});
			associations[cnt].id = entry.id;
			associations[cnt].association = this.state[`${entry.id}_association`];
			cnt++;
		}
	});
	this.setState({virus_jumpout: local_virus, core_jumpout: local_core});
	this.AddAssociations(associations);
}

/*VirusCoreAssociationsCallback(next_stage) {
	window.scroll(0,0);
	if (next_stage == "review") {
		this.setState({display_exercises: true, virus_core_part1: false, virus_core_part2: false, review: true, associations: false});
	} else if (this.state.virus_core_part1) {
		this.setState({display_exercises: true, virus_core_part1: false, virus_core_part2: true, review: false, associations: false});
	} else if (this.state.virus_core_part2) {
		this.setState({display_exercises: true, virus_core_part1: false, virus_core_part2: false, review: true, associations: false});
	} 
}

VirusCoreAssociations(event) {
	var next_stage = '';
	var percent_complete = null;

	if (event.target.id == "go_to_review") {
		next_stage = "review";
		percent_complete = .9;
	} else if (this.state.virus_core_part1) {
		next_stage = "virus_core_part2";
		percent_complete = .8;
	} else {
		next_stage = "review";
		percent_complete = .9;
	}
	axios.post(APIHost + '/api/addVirusCore', {
			withCredentials: true,
			virus_core: this.state.virus_language_core,
			chapter_id: this.state.chapterID,
      		sprint_id: this.state.sprintID,
      		stage: next_stage,
      		percent_complete: percent_complete
		}).then(res => {
			this.VirusCoreAssociationsCallback(next_stage);
		}).catch(error => {
			console.log("Failed calling addVirusCore " + error);
			this.setState({submission_failure: true});
		});
}

onVirusCoreAssociations(event) {
	event.preventDefault();
	this.VirusCoreAssociations(event);
}*/

VirusCoreAssociationsCallback() {
	var virus_core = this.state.virus_language_core_original;
	virus_core.push(this.state.core_association_id);

	this.state.core_jumpout.forEach((core) => {
		this.setState({[core.id+"_checked"]: false});
	});
	this.state.virus_jumpout.forEach((virus) => {
			this.setState({[virus.id+"_checked"]: false});
	});
	this.setState({[this.state.core_association_id+"_disabled"]: true, pick_association_error: false,
virus_language_core_original: virus_core});
}

VirusCoreAssociations() {
	var next_stage = '';
	var percent_complete = null;
	if (this.state.virus_core_part1) {
		next_stage = "virus_core_part2";
		percent_complete = .8;
	} else {
		next_stage = "review";
		percent_complete = .9;
	}
	if (!this.state.virus_association_id || !this.state.core_association_id) {
		this.setState({pick_association_error: true});
	} else {
		axios.post(APIHost + '/api/addVirusCore', {
			withCredentials: true,
			virus_language_association: this.state.virus_association_id,
			core_language_association: this.state.core_association_id
		}).then(res => {
			this.VirusCoreAssociationsCallback();
		}).catch(error => {
			console.log("Failed calling addVirusCore " + error);
			this.setState({submission_failure: true});
		});
	}
}

onVirusCoreAssociations(event) {
	event.preventDefault();
	this.VirusCoreAssociations();
}

ExercisesCallback(exercise) {
	var temp_exercises = this.state.exercise_answers;
	temp_exercises.push({});
	temp_exercises[this.state.exercise_answers.length - 1].text = this.state.current_answer;
	temp_exercises[this.state.exercise_answers.length - 1].exercise_id = exercise;
	this.setState({exercise_answers: temp_exercises});
	this.setState({[exercise + "_done"]: true});
}

Exercises(event) {
var answer = {exercise_id: event.target.id, answer: this.state.current_answer};
var id = event.target.id;
axios.post(APIHost + '/api/answerExercises', {
			withCredentials: true,
			answer: answer,
			chapter_id: this.state.chapterID
		}).then(res => {
			this.ExercisesCallback(id);
		}).catch(error => {
			console.log("Failed to submit the exercises");
			this.setState({submission_failure: true});
		});
}

onSubmitExercise(event) {
	event.preventDefault();
	this.Exercises(event);
}

LanguageState(event) {
	var temp = this.state[`${event.target.name}`];
	var temp2 = [];
	var already_there = false;
	var objIndex = -1;

	objIndex = temp.findIndex((obj => obj == event.target.id));
	if (objIndex != -1) {
		already_there = true;
	}
	if (already_there) {
		for (var i = 0; i<temp.length; i++) {
			if (i != objIndex) {
				temp2.push(temp[i]);
			}
		}
		temp = temp2;
	} else {
		temp.push(event.target.id);
	}
	this.setState({virus_language_core: temp});
}

LanguageState2(event) {
	this.setState({[event.target.id + "_checked"]: !this.state[`${event.target.id}_checked`]});

	if (event.target.name == "core_association") {
		this.state.core_jumpout.forEach((core) => {
			if(core.id != event.target.id) {
				this.setState({[core.id+"_checked"]: false});
			}
		});
		this.setState({core_association_id: event.target.id});
	} else {
		this.state.virus_jumpout.forEach((virus) => {
			if(virus.id != event.target.id) {
				this.setState({[virus.id+"_checked"]: false});
			}
		});
		this.setState({virus_association_id: event.target.id});
	}
}

NextExercisesCallback(next_stage) {
	window.scroll(0,0);
	if (next_stage == "virus_core_part2") {
		this.setState({review: false, virus_core_part1: false, virus_core_part2: true});	
	} else if (next_stage == "review") {
		this.setState({review: true, virus_core_part1: false, virus_core_part2: false});	
	} else if (next_stage == "done") {
		this.setState({review: false, virus_core_part1: false, virus_core_part2: true, finished: true});	
	}
}

NextExercises() {
	var next_stage = '';
	var percent_complete = null;
	if (this.state.virus_core_part1) {
		next_stage = "virus_core_part2";
		percent_complete = .8;
	} else if (this.state.virus_core_part2) { 
		next_stage = "review";
		percent_complete = .9;
	} else if (this.state.review) {
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback(next_stage);
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises();
}

DisplayExercises(stage) {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_cc_language = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="470" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if (this.state.associations) {
		var display_language = [];
		for (var i=0; i<this.state.virus_language.length; i++) {
			display_language.push(<tr><td>{this.state.virus_language[i].entry}</td><td><form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.virus_language[i].id} name={`${this.state.virus_language[i].id}_association`} value={this.state[`${this.state.virus_language[i].id}_association`]} className="form-control"></textarea>
  				</div>
  				</form></td></tr> );
		}
		display_intro.push(<div><p>Below is a list of your language for the new Viruses you've discovered on your Journey in the Training. To the right of each entry is a box for you to put a word that you feel
		encapsulates the language entry. It is whatever word comes to mind when you read that entry. Try not to think too hard or dwell on any one entry. Just take the first 
		word that comes to mind. If you can't think of one, then just skip it. It is better to leave it empty then to try and fit one in. </p>
			<hr /> <table><tr><th>Language Entry</th><th>Word Association</th></tr>{display_language}</table>
 			<p><button onClick={this.onAddAssociations} className="custom-btn" type="submit">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Language Associations</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.virus_core_part1) {
		var display_core = [];
		var display_virus = [];
		this.state.core_jumpout.forEach((core) => {
			if (core.association != null) {
				display_core.push(<p><input onChange={this.LanguageState2} name="core_association" key={`${core.id}_part1`} id={core.id} checked={this.state[`${core.id}_checked`]} type="checkbox" />	{core.association}
								<button onClick={this.onVirusCoreAssociations} className="btn btn-primary my-3" type="submit" disabled={this.state[`${core.id}_disabled`]} id={`${core.id}_submit`}>Add</button></p>);
			}
		});

		this.state.virus_jumpout.forEach((virus) => {
			if (virus.association != null) {
				display_virus.push(<p><input onChange={this.LanguageState2} name="virus_association" key={`${virus.id}_part1`} checked={this.state[`${virus.id}_checked`]} id={virus.id} type="checkbox" />	{virus.association}</p>);
			}
		});

			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Your Core Language Associations</h5>
			{display_core}
			
			</div>
			<div className='col-md-6'>
			<h5>Your Virus Language Associations</h5>
			{display_virus}
			</div></div></div>
			);
		display_intro.push(<div><p>What you see below is your Core Language Associations you built in the previous exercise on the left and your Virus Language Associations
		on the right. Click the check box next to any of the Core Language Associations that seem similar to any of the Virus Language Associations. For example, if you have an 
		Association of "frustrated" on the left and "aggravated" on the right, you could say those are similar. You would click the box next to "frustated" and the box next to "Aggravated" and then click "Add" next to "Frustrated". 
		Once you have found all the associations click Finish to move to the next section. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} className="custom-btn" type="submit" id="core_attributes">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Core Word Associations Versus Your Virus Word Associations</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.virus_core_part2) {
		var display_core = [];
		var display_virus = [];

		this.state.core_jumpout.forEach((core) => {
			var objIndex = -1;
			objIndex = this.state.virus_language_core_original.findIndex((obj => obj == core.id));
			if (objIndex == -1) {
				display_core.push(<div><p><input onChange={this.LanguageState2} name="core_association" key={`${core.id}_part2`} checked={this.state[`${core.id}_checked`]} id={core.id} type="checkbox" />    {core.entry}
									<button onClick={this.onVirusCoreAssociations} className="btn btn-primary my-3" type="submit" disabled={this.state[`${core.id}_disabled`]} id={`${core.id}_submit`}>Add</button></p></div>);

			}
		});

		this.state.virus_jumpout.forEach((virus) => {
				display_virus.push(<p><input onChange={this.LanguageState2} name="virus_association" key={`${virus.id}_part2`} checked={this.state[`${virus.id}_checked`]} id={virus.id} type="checkbox" />	{virus.entry}</p>);
		});

			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Your Core Language</h5>
			{display_core}
			
			</div>
			<div className='col-md-6'>
			<h5>Your Virus Language</h5>
			{display_virus}
			</div></div></div>
			);
		
	
		display_intro.push(<div><p>What you see below is your Core Language on the left and your Virus Language
		on your right. If you were able to check off any of the Core Word Assocations in the last exercise, the language isn't 
		listed below. Only the remaining Core Language is listed. As you did before, click the check boxes next to a Core Language and Virus language entry that seem similar and then click Add next to the Core Language entry.
		For example, if you have an entry that says "I work hard, expect others to" on the left and "I can be overly demanding at times" on the right, you could say those are similar.
		</p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} className="custom-btn" type="submit" id="core_attributes">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Core Language Versus Your Virus Language</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.review) {
		var display_virus_core = [];
		var cnt = -1;
		this.state.language.forEach((entry) => {
			var flag = false;
			var answered_flag = false;
			var answer = null;
			if (entry.virus_core) {
					this.state.exercise_answers.find((ex) => { 
						if (ex.exercise_id == entry.id) {
							answered_flag = true;
							answer = ex.text;
							cnt++;
						}
					});
					display_virus_core.push(<p><font color="blue">Language:</font> {entry.entry}</p>);
					if (entry.type == "core constitution") {
						this.state.core_constitution.find((cc) => {
							if (cc.id == entry.original_id) {
								display_virus_core.push(<p><font color="blue">Original Entry:</font> {cc.entry}</p>);
								display_virus_core.push(<p><font color="blue">Original Description:</font> {cc.description}</p>);
							}
						});

						if (answered_flag) {
							display_virus_core.push(<div><p><font color="blue">Your answer:</font> {answer}</p></div>);
						} else {
						display_virus_core.push(<div><form>
							<div className="form-group">
	     					<textarea onChange={this.onExerciseChange} rows="10" cols="80" type="text" name={`${entry.id}_exercise`} className="form-control"></textarea>
	  						</div>
	  						<button onClick={this.onSubmitExercise} type="submit" id={entry.id} disabled={this.state[`${entry.id}_done`]} className="custom-btn">Submit</button>
	  						</form></div>);
	  					}
	  					display_virus_core.push(<hr />);
					} else if (entry.type == "core attribute") {
						this.state.core_attributes.find((ca) => {
							if (ca.id == entry.original_id) {
								display_virus_core.push(<p><font color="blue">Original Entry:</font> {ca.attribute}</p>);
								display_virus_core.push(<p><font color="blue">Original Description:</font> {ca.description}</p>);
							}
						});
						if (answered_flag) {
							display_virus_core.push(<div><p><font color="blue">Your answer:</font> {answer}</p></div>);
						} else {
						display_virus_core.push(<div><form>
							<div className="form-group">
	     					<textarea onChange={this.onExerciseChange} rows="10" cols="80" type="text" name={`${entry.id}_exercise`} className="form-control"></textarea>
	  						</div>
	  						<button onClick={this.onSubmitExercise} type="submit" id={entry.id} disabled={this.state[`${entry.id}_done`]} className="custom-btn">Submit</button>
	  						</form></div>);
	  					}
	  					display_virus_core.push(<hr />);
					}
			}
		});
		display_mc.push(<div>{display_virus_core}</div>);
		display_intro.push(<div><p>Let's review what's happened so far in this chapter. 
		Below are all the entries from your Core Constitution or your Core Attributes that you were able to relate to
		language in your Viruses that you haven't yet addressed from the previous Associations chapter as well as the new
		ones discovered from your new Viruses in this chapter. This is crucially important to understand. Your Core Constitution is an 
		expression of
		how you want to live your life, your Optimal Life, and your Core Attributes are how you express that ideal. If
		you are expressing yourself when describing the healthy parts of your Mental Core as you do with your Viruses, that
		can indicate Core Disharmonies. Take some time to write about the possible inner tension that exists or any other
		thoughts you have when reading these entries below. Remember, these are just indicators. Just because there was a 
		relationship doesn't mean there's definitely a problem to solve. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onGoToEnd} type="submit" className="custom-btn" id="core_attributes">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Chapter Review</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_button}
{display_video}{display_intro}</div>);
	}

	return display;
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, finished: true});
}

GoToEnd() {
	axios.post(APIHost + '/api/closeChapter', {
    	chapter_id: this.state.chapterID,
     	 sprint_id: this.state.sprintID,
      		withCredentials: true
    	}).then(res => {
    		this.GoToEndCallback();
    	}).catch(error => {
    		console.log("Error closing chapter " + error);
    		this.setState({server_failure: true});
    });
}

onGoToEnd(event) {
	event.preventDefault();
	this.GoToEnd();
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter45">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter45">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter45