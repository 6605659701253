import React, { Component } from 'react';
import '../css/articles.css';
import Header from '../components/header';
import Footer from '../components/footer';

class UnderReact extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="UnderReact">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>Under React!</h1><hr />
            <div className="two-sided-box-in">
    <p>
You sit there, waiting more and more impatiently for your date to arrive. She is known to be late at times, but this is getting ridiculous. Finally, in she walks, looking as frantic as you would expect. You don't want to give her a chance to explain. The chance to explain came and went 20 minutes ago and after repeated phone call attempts and ignored texts. You stand red faced and let her have it!  With your voice raised you tell her how inconsiderate she is and how there can't possibly be an explanation that would make this OK. Her body tenses even more than it was. With tears flowing, she matches your intensity with an almost inaudible tone. She explains that she was rear ended. In the confused aftermath of the accident, she dropped and broke her phone. She had no way of reaching him and was frightened and upset about the entire situation. Realizing his error but desperate to save himself from fully submitting to his mistake, he reacts with an attitude of "how was I supposed to know". Overcome with his callous attitude, the argument moves from the situation at hand to his overall callousness throughout their entire relationship and his attention turns to her lack of accountability throughout their relationship. Things continue to spiral out of control until she storms off ensuring him that it is over between them. 
    </p>
    <p>
We have all reacted too strongly to situations, regretting the intensity once we have more information. Reactions are like first impressions. Once one is made and it is not what you want, it is much more difficult to change the course of the interaction than if it was done more appropriately initially. We must under react to situations and only escalate our reactions when warranted or necessary. Whatever your reaction is, the other person will likely meet or exceed your intensity. This will easily lead to a completely unnecessary escalation as it did with our ill-fated couple above. We hate to admit we are wrong in our reactions which makes things all the worse. Once we have reacted poorly, we defend ourselves, escalating things further with the frustrated counterpart.
    </p>
    <p>
How do we do better here? First thing to do is to realize people are doing the best they can and to assume the best of intentions from others. We move amazingly fast to the conclusion that whomever we are interacting with is a terrible and malicious person out to get us. We imagine all the terrible reasons the person has for doing whatever they are doing instead of making assumptions that there might be valid reasons that we should try and understand or that we will discover at a later time. This is a strikingly strange conclusion based on the fact that many of these interactions are with our friends, family, and girlfriends and boyfriends. If they are truly the type of person we so quickly conclude they are, then why have we carried on relationships with them for the time we have? Have they not earned the benefit of more positive assumptions? If you can't imagine the positive, then imagine nothing at all. Abstain from assumptions and certainly conclusions until you have sufficient information to form them.

    </p>
    <p>
    Second is to focus on the "first impression" of these situations. Maintaining a calm demeanor, soft speech, and a mild manor will ensure you under react to what could eventually warrant a stronger reaction once you have sufficient information to escalate. This has a disarming effect ensuring the person openly communicates the truth of the situation. If you open fire, the defensive wall goes up, and they will surely return fire. Maintain a fair, firm, and friendly approach to encourage continued communication. The more you do this, the more true and clear information you will get from the other person and the better chance you have for understanding the situation. If they choose to escalate they behavior, you can choose to maintain your composure. This again encourages communication and may still allow you to deescalate the situation and get to a better understanding.
    </p>
    <p>
Lastly, think long term and remove yourself from the intensity of the moment. The memory of an event is always much less intense than the moment of the event. We rarely remember the substance of many seemingly important moments in our lives, but we are left with an overall impression of those moments. It is similar to our recollection of dreams. Knowing this, focus on the impression you want the other person to have in the future. Imagine looking back on the events transpiring and imagine all you have is the emotions left behind. What do you want to carry forward and what do you want the other person to carry forward? These are cumulative. Each interaction and resultant impression build and result in either a healthy loving relationship or an unhealthy one full of regrets and resentment. How you react and deal with these moments dictate this. Give them and yourself the gift of underreacting. 
    </p>
    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default UnderReact;