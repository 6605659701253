import React, { Component } from 'react';
import { Card, Timeline } from 'antd'

const CoreCode = () => (
    <div className="single-block pt-4">
        <h3>Mental Fitness Training</h3>
        <p>
            Success might look different for different people, but we all want to have an Optimal Life.
            A life full of peace, joy, happiness, and contentment. We seem to keep bumping up against
            something that’s stopping us from becoming exactly as we want to be. Mental Fitness Training is
            the unlock that empowers you to Define, Discover, Restructure, and Strengthen  the essence of who you are, your Mental Core. This enables you to live the life of your choosing and on your terms. You learn to train your
            subconscious to serve you rather than you serving it and start to respond to life on your terms.
            Mental Fitness Training is a disruptive change to the mental wellness industry, putting the power in your hands
            to live your Optimal Life.
                                </p>

    </div>
);
const ExercisesCode = () => (
    <div className="single-block">
        <h3>Optimizing how you get the help you deserve</h3>
        <p>
            Making the necessary changes in your life can feel overwhelming at times. Even knowing how to start The
            Journey towards the life you want can seem impossibly unclear. That is why how you approach a problem is
            as important as what you do to solve the problem. The revolution that the technology industry went through
            in recent years has demonstrated that. They realized that attacking HUGE problems all at the same time wasted
            time and money and made things unnecessarily difficult. Breaking the big things into small things and putting
            those small things into shorter-term, achievable goals that built towards the larger goals made a lot more sense.
            That approach has been adapted here at My Core Insights to deliver Mental Fitness Training in an equally
            revolutionary way. You can finally achieve your long term success in a much more manageable and achievable way.
                                </p>
    </div>
);

const ArticlesCode = () => (
    <div className="single-block">
        <h3>Delivering the complex, simply</h3>
        <p>
            Mental Fitness Training is based on the groundbreaking therapy, Cognitive Response Therapy (CRT), developed by the founder of My Core Insights, Elliot Barnett.
            CRT is powered by several scientific theories identified and demonstrated through Elliot's research. Decades of research in
            neuroscience has revealed astounding results related to the complexity of the human mind. Although much is left
            to be discovered and fully demonstrated, we now understand a great deal about the human subconscious and the question
            of human consciousness. With the understanding that so much of what it is to be human is done below our conscious
            awareness and operates at a subconscious level, we can stop trying to make changes in our lives that go against the
            fundamental nature of how our body and mind work. Mental Fitness Training has taken the complexity out of those discoveries
            and has provided the means for anyone that is willing to make changes, to actually make those changes last and achieve Optimal Mental Fitness.
                                </p>
    </div>
);

const TrainingCode = () => (
    <div className="single-block">
        <h3>Sharing and learning towards your Optimal Life</h3>
        <p>
            True and lasting success is never achieved alone. It is through community that a greater success can be attained. At My Core
            Insights, you are able to learn from others, contribute to others, and celebrate your successes with Mental Fitness Training.
            You can take your journey privately, or share and collaborate with others. The power is back in your hands to direct your own mental health and wellness journey.
            
                                </p>
    </div>
);



class MftInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            core: true,
            exercises: false,
            articles: false,
            training: false,
        };
        this.toggleCore = this.toggleCore.bind(this);
        this.toggleExercises = this.toggleExercises.bind(this);
        this.toggleArticles = this.toggleArticles.bind(this);
        this.toggleTraining = this.toggleTraining.bind(this);
    }

    toggleCore() {
        const core = this.state.core;
        if (!core) {
            this.setState({
                core: true,
                exercises: false,
                articles: false,
                training: false,
            });
        }
    }

    toggleExercises() {
        const exercises = this.state.exercises;
        if (!exercises) {
            this.setState({
                core: false,
                exercises: true,
                articles: false,
                training: false,
            });
        }
    }

    toggleArticles() {
        const articles = this.state.articles;
        if (!articles) {
            this.setState({
                core: false,
                exercises: false,
                articles: true,
                training: false,
            });
        }
    }

    toggleTraining() {
        const training = this.state.training;
        if (!training) {
            this.setState({
                core: false,
                exercises: false,
                articles: false,
                training: true,
            });
        }

    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Card style={{ width: '100%' }}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4 py-3">
                                            <Timeline className="mt-4">
                                                <Timeline.Item> <button className="btn btn-primary" onClick={this.toggleCore}>The Training</button></Timeline.Item>
                                                <Timeline.Item> <button className="btn btn-primary" onClick={this.toggleExercises}>The Framework</button></Timeline.Item>
                                                <Timeline.Item><button className="btn btn-primary" onClick={this.toggleArticles}>The Science</button></Timeline.Item>
                                                <Timeline.Item><button className="btn btn-primary" onClick={this.toggleTraining}>The Community</button></Timeline.Item>
                                            </Timeline>

                                        </div>
                                        <div className="col-md-8">
                                            {this.state.core && <CoreCode />}
                                            {this.state.exercises && <ExercisesCode />}
                                            {this.state.articles && <ArticlesCode />}
                                            {this.state.training && <TrainingCode />}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                  </>
        );

    }
}

export default MftInfo;