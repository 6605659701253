import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import Popup from "reactjs-popup";
import { APIHost } from './GlobalVariables';
import { Button } from 'antd';

class Journal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      entries: [],
      entry: ""
    }
    this.JournalCallback = this.JournalCallback.bind(this);
    this.GetJournal = this.GetJournal.bind(this);
    this.UpdateJournal = this.UpdateJournal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.DisplayEntries = this.DisplayEntries.bind(this);

  }
  componentDidMount() {
    window.scroll(0, 0);
    this.setState({ loading: true });
    this.GetJournal().then((entries) => {
      this.setState({ loading: false, entries: entries.data.entries });
    }).catch((error) => {
      console.log("failed to get the entries " + error);
    });
  }

  async GetJournal() {
    const res = await axios.get(APIHost + '/api/getJournalEntries', { withCredentials: true });
    return await res;
  }

  JournalCallback(status) {
    if (status) {
      this.props.history.push("/Home");
    } else {
      console.log("couldn't update the journal");
    }
  }

  UpdateJournal(JournalCallback) {
    axios.post(APIHost + '/api/addJournalEntry', {
      entry: this.state.entry,
      withCredentials: true
    }).then(res => {
      JournalCallback(true);
    }).catch(error => {
      console.log("Error submitting journal " + error);
    });
  }

  DisplayEntries() {
    var display = [];
    var display_entry = [];
    this.state.entries.forEach((entry) => {
      var d = new Date(entry.date);
      //var date = "-" + (entry.date.getMonth()+1) + "-" + entry.date.getFullYear()
      var date = d.getMonth();
      /* display_entry.push(<Popup trigger={<button className="simple_button"> {entry.date}</button>}>
       <p>{entry.entry.split("\n").map((i,key) => {
               return <p>{i}</p>;
           })}</p>
     </Popup>);*/
      display_entry.push(<Popup trigger={<Button type="dashed">{entry.date}</Button>}>
        <div className="display-linebreak"><p>{entry.entry}</p></div></Popup>);
    });
    display.push(<div>{display_entry}</div>);
    return display;
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
    this.UpdateJournal(this.JournalCallback);
  }

  render() {
    return (<div className="Journal">
      <Header history={this.props.history} loggedIn={true} />
      <section className="two-sided-box">
        <div className="container pb-5">
          <div className="bg-white">

            <div className="row">
              <div className="col-md-4">
                <h4 className="mb-3">Previous journal entries</h4>
                {this.DisplayEntries()}
              </div>
              <div className="col-md-8">
                <h4 className="mb-3">What's on your mind?</h4>
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <textarea rows="10" cols="60" type="text" className="form-control" id="entry" name="entry"
                      onChange={this.onChange}></textarea>
                  </div>
                  <div className="form-input light-rounded-buttons mt-25">
                    <button type="submit" className="main-btn light-rounded-two">Submit</button>
                  </div>
                </form>
              </div>
            </div></div></div></section>

      <Footer history={this.props.history} loggedIn={true} />
    </div>
    );
  }
}



export default Journal