import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/My-Core-Insights-Transparent.png';
import '../css/header.css';
import {APIHost} from '../GlobalVariables';
axios.defaults.withCredentials = true;

const ServerFailure = () => (
	<p><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogged: this.props.loggedIn,
			loading: true,
			display_items: false,
			server_failure: false
		}
		this.onClick = this.onClick.bind(this);
		this.logoutCallBack = this.logoutCallBack.bind(this);
		this.Logout = this.Logout.bind(this);
		this.GetLogin = this.GetLogin.bind(this);
		this.DisplayLogin = this.DisplayLogin.bind(this);
		this.DisplayHeader = this.DisplayHeader.bind(this);
		this.ToggleItems = this.ToggleItems.bind(this);

	}

	logoutCallBack(result) {
		if (result) {
			this.props.history.push({ pathname: '/Home', state: { loggedIn: false } });
		} else {
			this.setState({ server_failure: true });
			this.props.history.push("/Home");
		}
	}

	Logout(logoutCallBack) {
		axios.post(APIHost + '/api/logout', {}, {
			withCredentials: true
		}).then(res => {
			this.setState({ isLogged: false, loading: false });
			logoutCallBack(true);
		}).catch(error => {
			logoutCallBack(false);
		});
	}

	onClick(event) {
		event.preventDefault();
		this.setState({ loading: true });
		this.Logout(this.logoutCallBack);
	}

	componentDidMount() {
		this.GetLogin().then(res => {
			if (res) {
				this.setState({ isLogged: true, loading: false });
			} else {
				this.setState({ isLogged: false, loading: false });
			}
		}).catch(error => {
			this.setState({ isLogged: false, loading: false });
		});
	}

	async GetLogin() {
		const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
		return await res.data.loggedIn;
	}

	// Not using this any longer - moved this to be included in the top header section
	DisplayLogin() {
		var display = [];
		if (this.state.loading || this.state.isLogged || this.props.loggedIn) {
			return null;
		} else {
			display.push(
				<nav className="navbar navbar-expand-lg heading_nav">
					<div className="container">
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
							<span className="navbar-toggler-icon"></span>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<Link to={{ pathname: "/Login" }} className="nav-link">Login</Link>
								</li>
								<li className="nav-item">
									<Link to={{ pathname: "/CreateAccount" }} className="nav-link">Create Account</Link>
								</li>
							</ul>

						</div>
					</div>
				</nav>);
		}
		return display;
	}

	ToggleItems(event) {
		event.preventDefault();
		this.setState({display_items: !this.state.display_items});
	}

	DisplayHeader() {
		var display = [];
		if (!this.state.isLogged && !this.props.loggedIn) {
			if (this.state.display_items) {
			display.push(
				<>
					<button className="navbar-toggler" type="button" onClick={this.ToggleItems}>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Home" }}>Home</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/mft" }}>Mental Fitness Training</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/ForBusiness" }}>For Business</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/About" }}>About</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/faq" }}>Help</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/contact" }}>Contact</Link>
							</li>
							<li className="nav-item">
								<Link to={{ pathname: "/Login" }} className="nav-link">Login</Link>
							</li>
							<li className="nav-item">
								<Link to={{ pathname: "/CreateAccount" }} className="nav-link">Create Account</Link>
							</li>
						</ul>
					</div>
				</>
			);
			} else {
			display.push(
				<>
					<button className="navbar-toggler" type="button" onClick={this.ToggleItems}>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/Home" }}>Home</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/mft" }}>Mental Fitness Training</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/ForBusiness" }}>For Business</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/About" }}>About</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/faq" }}>Help</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={{ pathname: "/contact" }}>Contact</Link>
							</li>
							<li className="nav-item">
								<Link to={{ pathname: "/Login" }} className="nav-link">Login</Link>
							</li>
							<li className="nav-item">
								<Link to={{ pathname: "/CreateAccount" }} className="nav-link">Create Account</Link>
							</li>
						</ul>
					</div>
				</>
			);
		}
		} else {
			if (this.state.display_items) {
			display.push(
				<>
				<button className="navbar-toggler" type="button" onClick={this.ToggleItems}>
				<span className="navbar-toggler-icon"></span>
				<span className="navbar-toggler-icon"></span>
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className="navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/" }}>Profile</Link>
						</li>

						<li className="nav-item">
							<Link onClick={this.onClick} className="nav-link" to={{ pathname: "/" }}>Logout</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/AmbassadorProgram" }}>Ambassador Program</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: '/CommunityCorner', state: { loggedIn: true } }}>Community Corner</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/faq" }}>Help</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/contact" }}>Contact</Link>
						</li>
					</ul>
					{this.state.server_failure && <ServerFailure />}
				</div>
				</>
				);
			} else {
			display.push(
				<>
				<button className="navbar-toggler" type="button" onClick={this.ToggleItems}>
				<span className="navbar-toggler-icon"></span>
				<span className="navbar-toggler-icon"></span>
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/" }}>Profile</Link>
						</li>

						<li className="nav-item">
							<Link onClick={this.onClick} className="nav-link" to={{ pathname: "/" }}>Logout</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/AmbassadorProgram" }}>Ambassador Program</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: '/CommunityCorner', state: { loggedIn: true } }}>Community Corner</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/faq" }}>Help</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to={{ pathname: "/contact" }}>Contact</Link>
						</li>
					</ul>
					{this.state.server_failure && <ServerFailure />}
				</div>
				</>
				);
			}
		}
		return display;
	}

	render() {
		return (
			<div className="Header">
				<nav className="navbar navbar-expand-lg heading_nav">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img src={logo} alt="My Core Insights" className="img-fluid" width="120" height="120" />
						</a>
						{!this.state.loading && this.DisplayHeader()}
					</div>
				</nav>
			</div>
		);
	}
}

export default Header;