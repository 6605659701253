import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter31 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 32,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_exercises: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		victimization: [],
		generalizations: [],
		expectations: [],
		decision_latitude: [],
		self_limiting: [],
		judgmental: [],
		aggressive: [],
		regret: [],
		personalization: [],
		future_tailing: [],
		magnification: [],
		all_nothing: [],
		minimizing: [],
		mind_reading: [],
		comparative: [],
		chapter_video: "https://player.vimeo.com/video/447962164",
		language: []
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onGoToReview = this.onGoToReview.bind(this);
this.GoToReviewCallback = this.GoToReviewCallback.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayReview = this.DisplayReview.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.CloseChapter = this.CloseChapter.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
		chapter_stage_promise = this.GetChapterStage().then ((stage) => {
			this.setState({chapter_stage: stage.data.stage});
			if (stage.data.stage === "video") {
				this.setState({display_video: true, display_exercises: false, started: true});
			} else if (stage.data.stage === "review") {
				this.setState({display_video: false, display_review: true, started: true});
			} else if (stage.data.stage === "done") {
				this.setState({display_video: false, display_review: true, started: true, finished: true});
			}
		}).catch((error) => {
			console.log("GetChapterStage failed " + error);
			this.setState({server_failure: true});
		});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);

	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(language_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var language_ids_gen = [];
		var language_ids_exp = [];
		var language_ids_dl = [];
		var language_ids_sl = [];
		var language_ids_j = [];
		var language_ids_a = [];
		var language_ids_r = [];
		var language_ids_p = [];
		var language_ids_ft = [];
		var language_ids_mag = [];
		var language_ids_an = [];
		var language_ids_min = [];
		var language_ids_mr = [];
		var language_ids_c = [];
		var language_ids_v = [];
		this.GetCognitiveTraps().then((cognitive_traps) => {
			cognitive_traps.data.cognitive_traps.forEach((element) => {
				if (element.victimization) {
					language_ids_v.push(element.language_id);
				} 
				if (element.generalizations) {
					language_ids_gen.push(element.language_id);
				} 
				if (element.expectations) {
					language_ids_exp.push(element.language_id);
				} 
				if (element.decision_latitude) {
					language_ids_dl.push(element.language_id);
				} 
				if (element.self_limiting) {
					language_ids_sl.push(element.language_id);
				} 
				if (element.judgmental) {
					language_ids_j.push(element.language_id);
				} 
				if (element.aggressive) {
					language_ids_a.push(element.language_id);
				} 
				if (element.regret) {
					language_ids_r.push(element.language_id);
				} 
				if (element.personalization) {
					language_ids_p.push(element.language_id);
				}
				if (element.future_tailing) {
					language_ids_ft.push(element.language_id);
				} 
				if (element.magnification) {
					language_ids_mag.push(element.language_id);
				} 
				if (element.all_nothing) {
					language_ids_an.push(element.language_id);
				} 
				if (element.minimizing) {
					language_ids_min.push(element.language_id);
				} 
				if (element.mind_reading) {
					language_ids_mr.push(element.language_id);
				} 
				if (element.comparative) {
					language_ids_c.push(element.language_id);
				} 
			});
			this.setState({loading: false, cognitive_traps: cognitive_traps.data.cognitive_traps, victimization: language_ids_v,
				generalizations: language_ids_gen, expectations: language_ids_exp, decision_latitude: language_ids_dl, self_limiting: language_ids_sl,
				judgmental: language_ids_j, aggressive: language_ids_a, regret: language_ids_r, personalization: language_ids_p, future_tailing: language_ids_ft,
				magnification: language_ids_mag,  all_nothing: language_ids_an, minimizing: language_ids_min, mind_reading: language_ids_mr, 
				comparative: language_ids_c});
		}).catch((error) => {
			console.log("Failed to get cognitive_traps " + error);
			this.setState({server_failure: true});
		});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetCognitiveTraps() {
	const res = await axios.get(APIHost + '/api/cognitiveTraps', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	this.setState({started: true, display_video: true, display_review: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_review: true, started: true, finished: true});
}

GoToEnd() {
	this.CloseChapter();
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: .5,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

CloseChapter() {
	axios.post(APIHost + '/api/closeChapter', {
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		this.GoToEndCallback();
	}).catch(error => {
		console.log("failed to close the chapter " + error);
		this.setState({submission_failure: true});
	});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayReview(history) {
	var display_final = [];
	var display_button = [];
	var display_header = [];
	var display_v1 = [];
	var display_v2 = [];
	var display_gen1 = [];
	var display_gen2 = [];
	var display_exp1 = [];
	var display_exp2 = [];
	var display_dl1 = [];
	var display_dl2 = [];
	var display_sl1 = [];
	var display_sl2 = [];
	var display_a1 = [];
	var display_a2 =  [];
	var display_j1 = [];
	var display_j2 = [];
	var display_r1 = [];
	var display_r2 = [];
	var display_p1 = [];
	var display_p2 = [];
	var display_ft1 = [];
	var display_ft2 = [];
	var display_mag1 = [];
	var display_mag2 = [];
	var display_an1 = [];
	var display_an2 = [];
	var display_min1 = [];
	var display_min2 = [];
	var display_mr1 = [];
	var display_mr2 = [];
	var display_c1 = [];
	var display_c2 = [];

	if (!history && this.state.finished) {
		display_button.push(<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p>)
	} else if (!history) {
		display_button.push(<button onClick={this.GoToEnd} type="submit" id="2" className="custom-btn">Finish</button>);
	}
	var display_heading = [];
	if (this.state.victimization.length == 0) {
		display_v2.push(<div><h4>You didn't mark anything as Victimization. That's great! It is still something to keep an eye out for in your daily life</h4></div>);
	} else {
		for (var i=0; i<this.state.victimization.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.victimization[i]) {
					display_v2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_v1.push(
			<div><ul>{display_v2}</ul></div>
		);
	} 
	if (this.state.generalizations.length == 0) {
		display_gen1.push(<div><p>You didn't mark anything as Generalizations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.generalizations.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.generalizations[i]) {
					display_gen2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_gen1.push(
			<div><ul>{display_gen2}</ul></div>
		);
	} 
	if (this.state.expectations.length == 0) {
		display_exp1.push(<div><p>You didn't mark anything as Expectations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.expectations.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.expectations[i]) {
					display_exp2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_exp1.push(
			<div><ul>{display_exp2}</ul></div>
		);
	} 
	if (this.state.decision_latitude.length == 0) {
		display_dl1.push(<div><p>You didn't mark anything as Expectations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.decision_latitude.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.decision_latitude[i]) {
					display_dl2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_dl1.push(
			<div><ul>{display_dl2}</ul></div>
		);
	} 

	if (this.state.self_limiting.length == 0) {
		display_sl1.push(<div><p>You didn't mark anything as Expectations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.self_limiting.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.self_limiting[i]) {
					display_sl2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_sl1.push(
			<div><ul>{display_sl2}</ul></div>
		);
	} 

	if (this.state.aggressive.length == 0) {
		display_a1.push(<div><p>You didn't mark anything as Expectations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.aggressive.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.aggressive[i]) {
					display_a2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_a1.push(
			<div><ul>{display_a2}</ul></div>
		);
	} 
	if (this.state.judgmental.length == 0) {
		display_j1.push(<div><p>You didn't mark anything as Judgemental. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.judgmental.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.judgmental[i]) {
					display_j2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_j1.push(
			<div><ul>{display_j2}</ul></div>
		);
	} 
	if (this.state.regret.length == 0) {
		display_r1.push(<div><p>You didn't mark anything as Regret. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.regret.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.regret[i]) {
					display_r2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_r1.push(
			<div><ul>{display_r2}</ul></div>
		);
	} 
	if (this.state.personalization.length == 0) {
		display_p1.push(<div><p>You didn't mark anything as Personalization. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.personalization.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.personalization[i]) {
					display_p2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_p1.push(
			<div><ul>{display_p2}</ul></div>
		);
	} 
	if (this.state.future_tailing.length == 0) {
		display_ft1.push(<div><p>You didn't mark anything as Future Tailing. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.future_tailing.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.future_tailing[i]) {
					display_ft2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_ft1.push(
			<div><ul>{display_ft2}</ul></div>
		);
	} 
	if (this.state.magnification.length == 0) {
		display_mag1.push(<div><p>You didn't mark anything as Magnification. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.magnification.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.magnification[i]) {
					display_mag2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_mag1.push(
			<div><ul>{display_mag2}</ul></div>
		);
	} 
	if (this.state.all_nothing.length == 0) {
		display_an1.push(<div><p>You didn't mark anything as All or Nothing. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.all_nothing.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.all_nothing[i]) {
					display_an2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_an1.push(
			<div><ul>{display_an2}</ul></div>
		);
	} 
	if (this.state.minimizing.length == 0) {
		display_min1.push(<div><p>You didn't mark anything as Minimizing. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.minimizing.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.minimizing[i]) {
					display_min2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_min1.push(
			<div><ul>{display_min2}</ul></div>
		);
	} 
	if (this.state.mind_reading.length == 0) {
		display_mr1.push(<div><p>You didn't mark anything as All or Nothing. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.mind_reading.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.mind_reading[i]) {
					display_mr2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_mr1.push(
			<div><ul>{display_mr2}</ul></div>
		);
	} 
	if (this.state.comparative.length == 0) {
		display_c1.push(<div><p>You didn't mark anything as Comparative. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.comparative.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.comparative[i]) {
					display_c2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_c1.push(
			<div><ul>{display_c2}</ul></div>
		);
	} 
	if (history || !this.state.finished) {
		display_header.push(<h1>Here are all the various Cognitive Traps you identified.</h1>);
	} else {
		display_header.push(<h1>Congrats! You finished your review of your Cognitive Traps!</h1>);
	}

		display_final.push(<div className="col-md-12">{display_header} <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
		<div className='container'>
    	<div className='row'>  
		<div className='col-md-6'>
		<h3>Victimization:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447923098" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup><p>{display_v1}</p>
		<h3>Generalizations:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447900486" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup><p>{display_gen1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Expectations:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447905847" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_exp1}</p>
		<h3>Decision Latitude:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447910719" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_dl1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Self-limiting:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/451590470" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_sl1}</p>
		<h3>Aggressive:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447914851" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_a1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Judgemental:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447918744" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_j1}</p>
		<h3>Regret:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447927520" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_r1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Personalization:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447933112" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_p1}</p>
		<h3>Future Tailing:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447941869" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_ft1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Magnification:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447950227" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_mag1}</p>
		<h3>All or Nothing:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447947103" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_an1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Minimizing:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447953071" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_min1}</p>
		<h3>Mind Reading:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447955833" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_mr1}</p>
		</div>
		<div className='col-md-6'>
		<h3>Comparative:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447958874" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_c1}</p>
		</div>
		</div></div>{display_button}
		</div>);	
	return display_final;
}

GoToReviewCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_review: true, started: true});
}

onGoToReview(event) {
	event.preventDefault();
	this.UpdateStage("review", this.GoToReviewCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"> <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.onGoToReview} className="custom-btn">Go To Review</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}


render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter31">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayReview(true)}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter31">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.display_review && 
						this.DisplayReview()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter31