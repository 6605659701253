import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { APIHost } from './GlobalVariables';
import referrals1 from './images/50_referrals.jpeg';
import referrals2 from './images/2_levels.jpeg';
import referrals3 from './images/3_levels.jpeg';

const ServerFailure = () => (
    <h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const AmbassadorSuccess = () => (
    <h2><font color="blue">Congrats on becoming an Ambassador! So excited for you to be part of this crucial effort! Click here for additional informaion on the program</font></h2>
);

class AmbassadorProgramDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            terms_of_use: true,
            ambassador_success: false,
            ambassador_status: false,
            not_logged_in: false,
            loading: false
        }
        this.TermsOfUse = this.TermsOfUse.bind(this);
        this.MakeAmbassador = this.MakeAmbassador.bind(this);
        this.MakeAmbassadorCallback = this.MakeAmbassadorCallback.bind(this);
        this.GetAmbassadorInfo = this.GetAmbassadorInfo.bind(this);
        this.DisplayAmbassador = this.DisplayAmbassador.bind(this);
        this.CheckLogin = this.CheckLogin.bind(this);
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.setState({ loading: true });
        this.CheckLogin().then((result) => {
            if (result.data.loggedIn) {
                this.GetAmbassadorInfo().then((ambassador) => {
                    this.setState({ ambassador_status: ambassador.data.ambassador_status, loading: false });
                }).catch((error) => {
                    console.log("GetAmbassadorInfo failed " + error);
                    this.setState({ server_failure: true });
                });
            } else {
                this.setState({ not_logged_in: true, loading: false });
            }
        }).catch((error) => {
            console.log("CheckLogin failed " + error);
            this.setState({ server_failure: true });
        });
    }

    async GetAmbassadorInfo() {
        const res = await axios.get(APIHost + '/api/checkAmbassador',
            { params: { withCredentials: true } });
        return await res;
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    MakeAmbassadorCallback() {
        this.setState({ ambassador_success: true, terms_of_use: true });
    }

    MakeAmbassador(event) {
        axios.post(APIHost + '/api/makeAmbassador', {
            withCredentials: true
        }).then(res => {
            this.MakeAmbassadorCallback();
        }).catch(error => {
            console.log("MakeAmbassador failed " + error);
            this.setState({ server_failure: true });
        });
    }

    TermsOfUse(event) {
        this.setState({ terms_of_use: !this.state.terms_of_use });
    }

    DisplayAmbassador() {
        var display = [];
        var other_display = [];
        if (this.state.ambassador_status) {
            display.push(<p>Thanks for being part of the My Core Insights Ambassador Program! Head over to the <Link to="/AmbassadorProgramDetails">Details</Link> page for additional information.</p>);
        } else {
            if (this.state.not_logged_in) {
                other_display.push(<p>Head back to the <Link to="/CreateAccount">Create Account</Link> page and check the "I would like to become an Ambassador..."
                check box, when creating your account, to upgrade to an Ambassador account</p>);
            } else {
                other_display.push(<div><p className="pull-right"><input onChange={this.TermsOfUse} type="checkbox" /> Please acknowledge you have
                                    read the My Core Insights <Link to="/TermsOfUse">Terms and Conditions of Use</Link> and <Link to="/PrivacyPolicy">Privacy Policy</Link>, specifially the area entitled "Ambassador Program".
                                    By clicking Create Account, you agree and are bound by these terms.
                                  <button type="submit" onClick={this.MakeAmbassador} disabled={this.state.terms_of_use} className="custom-btn">Become an Ambassador</button></p>
                    {this.state.server_failure && <ServerFailure />}
                    {this.state.ambassador_success && <AmbassadorSuccess />}
                </div>);
            }
            display.push(<div> <p>
                Are you passionate about mental health awareness? Have you wanted to make a major impact in people's lives? Could you use an additional source of income with tremendous growth opportunities?
                Our passion here at My Core Insights is to give the power back to each person to direct their own mental health destiny. We achieve this by providing
                the very first self-directed but guided psychotherapy solution that is fully accessible and affordable to everyone. We believe that everyone has a right to be empowered towards living an Optimal Life.
    Mental Fitness Training is how we are making that happen, all delivered in the powerful framework at My Core Insights. </p>
                <p>
                    The My Core Insights Ambassador Program makes it possible for you to join in our revolutionary effort by helping to get the word out. You will have decades of research and developmeent behind you
    so you can confidently refer people to a solution that works.<b> Finally, you have some place to direct
                    those in need to something that will truly make a difference in their life. In doing so, you will share in our success!
                    Being part of the Ambassador Program means you get to share in the revenue generated by those you refer. The profit sharing model that we are offering is extremely generous,
    with the possibility of sharing from 40% up to 50% of the revenue! This includes the potential for leadership bonuses as your reach grows. </b>
    The details for the compensation program can be found here.
</p>
                <h4> Together we can create the very first social platform where people can feel safe to take their journey towards Optimal Mental Fitness and their Optimal Life! Imagine the possiblities! Imagine
empowering people through your mentorship and the revolutionary approach here at My Core Insights!</h4>
                {other_display}
            </div>);

        }
        return display;
    }

    render() {
        return (
            <div className="AmbassadorProgramDetails">
                <Header history={this.props.history} />


                <section className="two-sided-box">

                    <div className="container">
                        <h3>My Core Insights - the Ambassador Program Details!</h3><hr />
                        <div className="two-sided-box-in">
                            <p>We here at My Core Insights are excited that you are interested in joining the family as a My Core Insights Ambassador. We are ushering in the Mental Health and Wellness Revolution and
                            can't wait for you to join in that movement. We are here to make a positively disruptive difference in people's life and you can do the same. In doing so, you will share not only in the success
        of that impact, but in our financial success. </p>
                            <p>What we are offering is an affiliate marketing partnership. The concept is quite simple but extremely powerful. Users of My Core Insights purchase credits that can be used
                            to access the Objectives of Mental Fitness Training. Each Objective is $34.99. For each person you directly refer to My Core Insights, you will receive
                            40% of the revenue generated by that user month after month, in perpetuity! In addition to the 40% for direct referrals, you have the potential of receiving additional percentages through leadership bonuses.
        Those are based on the total number of Objectives purchased from everyone within your network. These percentages range from an additional 3% up to 10% of additional income!</p>
                            <p>Below are various scenarios that will help make this clear. The typical user will purchase 2 Objectives per month or spend $69.98 per month. That's just an average...could be
        more or less depending on the person.</p>
                            <div className="d-flex justify-content-center">
                                <div>
                                <img src={referrals1} alt="My Core Insights" className="img-fluid" width="800" height="400" />
                                <hr />
                                <img src={referrals2} alt="My Core Insights" className="img-fluid" width="800" height="400" />

                                <hr />
                                <img src={referrals3} alt="My Core Insights" className="img-fluid" width="800" height="400" />
                                </div>
                            </div>



                        </div></div>
                </section>

                <Footer history={this.props.history} />

            </div>
        );


    }
}

export default AmbassadorProgramDetails;