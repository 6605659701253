import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import happiness from '../images/happiness.jpg';
import adventure from '../images/adventure.jpg';
import idea from '../images/idea.jpg';
import graffiti from '../images/graffiti.jpg';


class MyCarousel extends Component {
  constructor(props) {
  super(props);
}

  render() {
  var settings = {
  	showThumbs: false,
  	autoPlay: true,
  	infiniteLoop: true
  	};
    return (
      <div className="MyCarousel">
      
                  <Carousel {...settings}>

  	<div>
 <img src={adventure} alt="adventure" width="1100" height="500" />
      <div className="legend" width="100" height="100">
           
           <h1>Mental Fitness Training</h1>
           <h4>Achieving Optimal Mental Fitness</h4>
           <p>
               Begin your journey towards your Optimal Life...
           </p>
           <Link to={{ pathname: "/mft"}} className="custom-btn" role="button">Read More</Link>
		</div>
    </div>
    <div>
    	    	<img src={happiness} alt="happiness" width="1100" height="500" />
    	<div className="legend">

    		<h1>Latest Article</h1>
           <h4>Blissfully Deceived</h4>
           <p>
               We have an amazing propensity to live with delusions. We deceive ourselves and allow
               ourselves to be deceived in order to avoid pain...
           </p>
           <Link to={{ pathname: "/articles"}} className="custom-btn" role="button">Read More</Link>
           </div>
    </div>
    <div>
    	<img src={idea} alt="idea" width="1100" height="500" />
    	<div className="legend">
    	<h4>A key unlock to help you in your life</h4>
<iframe src="https://player.vimeo.com/video/353664033" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>  		</div>
  	</div>

</Carousel>


      </div>
      
    );
    }
}

export default MyCarousel;