import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Header from './components/header';
import Footer from './components/footer';
import axios from 'axios';
import { APIHost } from './GlobalVariables';
import { Link, Redirect } from 'react-router-dom';

const CLIENT = {
  sandbox:
    "your_sandbox_key",
  production:
    "your_production_key"
};

const CLIENT_ID = "AXfaduH2bsRGm_-iOWDRUnvsNu8JY5FrfwhoHr4C624JGchOI6wshR5b-o0cRlSfkqHIZHnXaMY2FhiT"
//process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

const CreditUpdateFailure = () => (
  <h2><font color="red">We apologize. An error occurred while updating the number of credits for you. Support has been automatically contacted and will rectify as soon as possible.<br />
If you have any questions or concerns, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const ServerFailure = () => (
  <h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

let PayPalProcrastination = null;
class PaypalProcrastination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      quantity: 1,
      update_credit_failure: false,
      total: 19.99,
      discountCode: "",
      loading: false,
      account_type: '',
      server_failure: false,
      paid: false
    };

    this.UpdateQuantity = this.UpdateQuantity.bind(this);
    this.UpdateDiscountCode = this.UpdateDiscountCode.bind(this);
    this.onChange = this.onChange.bind(this);

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    this.setState({loading: true});
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    this.setState({total: 19.99});
    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalProcrastination = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalProcrastination = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  createOrder = (data, actions) => {
    var reg_charge = 0;
    var discount_charge = 0;
    reg_charge = 19.99;
    discount_charge = 19.99;
    if (this.state.discountCode == "MCISESSION") {
      var amount = this.state.quantity * discount_charge;
    } else {
      var amount = this.state.quantity * reg_charge;
    }
    return actions.order.create({
      purchase_units: [
        {
          description: +"Objective",
          amount: {
            currency_code: "USD",
            value: amount,
          },
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      console.log("Payment Approved: ", paymentData);
      axios.post(APIHost + '/api/purchaseWebinar', {
        webinar_id: 2,
        email: this.state.email,
        withCredentials: true
      }).then(() => {
        this.setState({ showButtons: false, paid: true });
      }).catch((error) => {
        console.log("Error is " + error);
        this.setState({ update_credit_failure: true });
      });

    });
  };

  UpdateQuantity(event) {
    this.setState({ quantity: event.target.value.replace(/\D/, '') });

      var reg_charge = 0;
  var discount_charge = 0;
    reg_charge = 19.99;
    discount_charge = 19.99;
  if (event.target.value == 0) {
      if (this.state.discountCode == "MCISESSION") {
        this.setState({total: discount_charge});
      } else {
        this.setState({total: reg_charge})
      }
    } else {
      if(this.state.discountCode == "MCI") {
        this.setState({total: event.target.value*discount_charge});
      } else {
        this.setState({total: event.target.value*this.state.total});
      }
  }
  };

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  UpdateDiscountCode(event) {
        var reg_charge = 0;
    var discount_charge = 0;
    reg_charge = 19.99;
    discount_charge = 19.99;
      if (event.target.value == "MCISESSION") {
        this.setState({total: this.state.quantity * discount_charge});
      } else {
        this.setState({ total: this.state.quantity * reg_charge });
      }
    this.setState({discountCode: event.target.value});
  }

  render() {
    const { showButtons, loading, paid } = this.state;
    if (this.state.loading) {
      return (<div className="Home"><Header /><h1>Loading...</h1></div>);
    } else {
      return (
        <div className="main">
          <Header history={this.props.history} />
          <section className="two-sided-box">
            <div className="container">
              <div className="row justify-content-center">

                <div className="col-md-6">
                  <div className="paypal-box">
                    <h5 className="mb-3">PayPal requires additional information not necessary for credit purchase like your shipping address, phone number, etc. This is a requirement from PayPal. None
                        of the information is used or stored by My Core Insights.</h5>
                                            <div className="form-input mt-25">
                      <p>Please provide the email you used to register with</p>
                      <div className="input-items default">
                        <input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
                        <i className="lni lni-envelope"></i>
                      </div>
                    </div>
                    <p>Webinar: {this.state.total}</p>
                    {showButtons && (
                      <div className="mt-3">
                        <PayPalProcrastination
                          createOrder={(data, actions) => this.createOrder(data, actions)}
                          onApprove={(data, actions) => this.onApprove(data, actions)}
                        />
                      </div>
                    )}

                    {paid && (
                      <div className="main">
                        <h2>
                          You successfully purchased the webinar/workshop. You will receive access details soon!
            </h2>
                      </div>
                    )}
                    {this.state.update_credit_failure && <CreditUpdateFailure />}
                  </div>
                </div>
              </div>
            </div>

          </section>
          <Footer />

        </div>

      );
    }
  }
}


export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`)(PaypalProcrastination);
