import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter36 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 36,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		language: [],
		past_language: [],
		review: false,
		associations: false,
		finished: false,
		display_exercises: false,
		viruses: [],
		stretch_attributes: [],
		jumpout_past: [],
		jumpout_daily: [],
		stretch_associations: [],
		virus_associations: [],
		ca_associations: [],
		updated: false,
		cc_associations: [],
		chapter_video: "https://player.vimeo.com/video/451332132",
		adding_new: false
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.LanguageState = this.LanguageState.bind(this);
this.GetDaily =  this.GetDaily.bind(this);
this.TypeChange = this.TypeChange.bind(this);
this.AddAssociation = this.AddAssociation.bind(this);
this.onAddAssociation = this.onAddAssociation.bind(this);
this.AddAssociationCallback = this.AddAssociationCallback.bind(this);
this.GetPast = this.GetPast.bind(this);
this.GetStretchAttributes = this.GetStretchAttributes.bind(this);
this.GetJumpoutDaily = this.GetJumpoutDaily.bind(this);
this.GetJumpoutPast = this.GetJumpoutPast.bind(this);
this.ExercisesCallback = this.ExercisesCallback.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.Exercises = this.Exercises.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.onMakeCore = this.onMakeCore.bind(this);
this.MakeCore = this.MakeCore.bind(this);
this.MakeCoreCallback = this.MakeCoreCallback.bind(this);
this.onCoreDisharmony = this.onCoreDisharmony.bind(this);
this.CoreDisharmony = this.CoreDisharmony.bind(this);
this.CoreDisharmonyCallback = this.CoreDisharmonyCallback.bind(this);
this.GetCoreDisharmonies = this.GetCoreDisharmonies.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, review: false, 
				past_associations: false, daily_associations: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "daily_associations") {
			this.setState({display_video: false, review: false, 
				past_associations: false, daily_associations: true, display_exercises: true, started: true});
		} else if (stage.data.stage == "past_associations")  {
			this.setState({display_video: false, review: false, 
				past_associations: true, daily_associations: false, display_exercises: true, started: true});			
		} else if (stage.data.stage == "review")  {
			this.setState({display_video: false, review: true, 
				past_associations: false, daily_associations: false, display_exercises: true, started: true});
		} else if (stage.data.stage  == "done") {
			this.setState({display_video: false, review: false, 
				past_associations: false, daily_associations: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var temp_daily = [];
		var temp_past = [];
		language.data.language.forEach((element) => {
			if (element.type === "daily life") {
				temp_daily.push(element);
			} else if (element.type === "past life") {
				temp_past.push(element);
			} 
		});
		this.setState({daily_language: temp_daily, language: language.data.language, past_language: temp_past});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	var daily_promise = this.GetDaily().then((daily) => {
		this.setState({daily_life: daily.data.daily_life});
	}).catch((error) => {
		console.log("GetDaily failed " + error);
		this.setState({server_failure: true});
	});

	var stretch_promise = this.GetStretchAttributes().then((stretch) => {
		this.setState({stretch_attributes: stretch.data.attributes});
	}).catch((error) => {
		console.log("GetStretchAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var past_promise = this.GetPast().then((past) => {
		this.setState({past_life: past.data.past_life});
	}).catch((error) => {
		console.log("GetPast failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var exercise_answers_promise = this.GetExerciseAnswers().then ((exercise_answers) => {
		exercise_answers.data.exercise_answers.forEach((ea) => {
			this.setState({[ea.exercise_id+"_done"]: true});
		});
		this.setState({exercise_answers: exercise_answers.data.exercise_answers});
	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var jumpout_daily_promise = this.GetJumpoutDaily().then((jump_daily) => {
		this.setState({jumpout_daily: jump_daily.data.jumpout_language});
	}).catch((error) => {
		console.log("GetJumpoutDaily failed  " + error);
		this.setState({server_failure: true});
	});

	var jumpout_past_promise = this.GetJumpoutPast().then((jump_past) => {
		this.setState({jumpout_past: jump_past.data.jumpout_language});
	}).catch((error) => {
		console.log("GetJumpoutPast failed  " + error);
		this.setState({server_failure: true});
	});

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	var core_disharmony_promise = this.GetCoreDisharmonies().then((core_disharmonies) => {
		this.setState({core_disharmonies: core_disharmonies.data.core_disharmonies});
	}).catch((error) => {
		console.log("GetCoreDisharmonies failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(core_disharmony_promise);
	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(viruses_promise);
	all_promises.push(language_promise);
	all_promises.push(daily_promise);
	all_promises.push(past_promise);
	all_promises.push(stretch_promise);
	all_promises.push(jumpout_past_promise);
	all_promises.push(jumpout_daily_promise);
	all_promises.push(exercise_answers_promise);
	Promise.all(all_promises).then( () => {
		var temp_daily = [];
		var temp_past = [];
		var temp_stretch = [];
		var temp_virus = [];
		var temp_ca = [];
		var temp_cc = [];
		var virus_cnt = 0;
		var stretch_cnt = 0;
		var ca_cnt = 0;
		var cc_cnt = 0;
		var test_virus = [];
		this.state.jumpout_daily.forEach((jd) => {
			if (jd.association_type === "virus") {
				test_virus.push({});
				this.state.viruses.find((virus) => {
					if (virus.id == jd.association_id) {
						test_virus[virus_cnt].virus = virus.virus;
						test_virus[virus_cnt].added_virus = virus.added;
					}
				});
				this.state.daily_life.find((dl) => {
					if (dl.id == jd.original_id) {
						test_virus[virus_cnt].daily_entry = dl.entry;
						test_virus[virus_cnt].daily_description = dl.description;
					}
				});
				test_virus[virus_cnt].daily_id = jd.id;
				test_virus[virus_cnt].language_entry = jd.entry;
				virus_cnt++;
			}
		});
		this.state.jumpout_past.forEach((jp) => {
			if(jp.association_type === "virus") {
				test_virus.push({});
				this.state.viruses.find((virus) => {
					if (virus.id == jp.association_id) {
						test_virus[virus_cnt].virus = virus.virus;
						test_virus[virus_cnt].added_virus = virus.added;
					}
				});
				this.state.past_life.find((dl) => {
					if (dl.id == jp.original_id) {
						test_virus[virus_cnt].daily_entry = dl.entry;
						test_virus[virus_cnt].daily_description = dl.description;
					}
				});
				test_virus[virus_cnt].daily_id = jp.id;
				test_virus[virus_cnt].language_entry = jp.entry;
				virus_cnt++;
			}
		});
				this.setState({virus_associations: test_virus});

		virus_cnt = 0;
		this.state.jumpout_daily.forEach((jd) => {
			var flag = false;
			if(jd.association_type === "stretch attribute") {
				this.state.stretch_attributes.find((stretch) => {
					if (stretch.id === jd.association_id) {
						this.state.core_disharmonies.find((cd) => {
							if (cd.stretch_id == stretch.id && (cd.daily_life_id || cd.past_life_id)) {
								this.setState({[stretch.id+"_make_core"]: true, [stretch.id+"_core_disharmony"]: true});
							}
						});
						temp_stretch.push({});
						flag = true;
						temp_stretch[stretch_cnt].stretch_attribute = stretch.attribute;
						temp_stretch[stretch_cnt].stretch_id = stretch.id;
					}
				});
				if (flag) {
					this.state.daily_life.find((dl) => {
						if (dl.id == jd.original_id) {
							temp_stretch[stretch_cnt].daily_entry = dl.entry;
							temp_stretch[stretch_cnt].daily_description = dl.description;
							temp_stretch[stretch_cnt].daily_past_id = dl.id;
							temp_stretch[stretch_cnt].daily_past_type = "daily";
						}
					});
					temp_stretch[stretch_cnt].language_entry = jd.entry;
					stretch_cnt++;
				}
			} else if(jd.association_type === "core constitution") {
				temp_cc.push({});
				this.state.core_constitution.find((cc) => {
					if (cc.id === jd.association_id) {
						temp_cc[cc_cnt].cc = cc.entry;
					}
				});
				this.state.daily_life.find((dl) => {
					if (dl.id == jd.original_id) {
						temp_cc[cc_cnt].daily_entry = dl.entry;
						temp_cc[cc_cnt].daily_description = dl.description;
					}
				});
				temp_cc[cc_cnt].language_entry = jd.entry;
				cc_cnt++;
			} else if(jd.association_type === "core attribute") {
				temp_ca.push({});
				this.state.core_attributes.find((ca) => {
					if (ca.id == jd.association_id) {
						temp_ca[ca_cnt].ca = ca.attribute;
					}
				});
				this.state.daily_life.find((dl) => {
					if (dl.id == jd.original_id) {
						temp_ca[ca_cnt].daily_entry = dl.entry;
						temp_ca[ca_cnt].daily_description = dl.description;
					}
				});
				temp_ca[ca_cnt].language_entry = jd.entry;
				ca_cnt++;
			}
		});
		this.state.jumpout_past.forEach((jp) => {
			var flag = false;
		if(jp.association_type === "stretch attribute") {
				this.state.stretch_attributes.find((stretch) => {
					if (stretch.id == jp.association_id) {
						this.state.core_disharmonies.find((cd) => {
							if (cd.stretch_id == stretch.id) {
								this.setState({[stretch.id+"_make_core"]: true, [stretch.id+"_core_disharmony"]: true});
							}
						});
						temp_stretch.push({});
						flag = true;
						temp_stretch[stretch_cnt].stretch_attribute = stretch.attribute;
						temp_stretch[stretch_cnt].stretch_id = stretch.id;
					}
				});
				if  (flag) {
					this.state.past_life.find((dl) => {
						if (dl.id == jp.original_id) {
							temp_stretch[stretch_cnt].daily_entry = dl.entry;
							temp_stretch[stretch_cnt].daily_description = dl.description;
							temp_stretch[stretch_cnt].daily_past_id = dl.id;
							temp_stretch[stretch_cnt].daily_past_type = "past";
						}
					});
					temp_stretch[stretch_cnt].language_entry = jp.entry;
					stretch_cnt++;
				}
			} else if(jp.association_type === "core constitution") {
				temp_cc.push({});
				this.state.core_constitution.find((cc) => {
					if (cc.id == jp.association_id) {
						temp_cc[cc_cnt].cc = cc.entry;
					}
				});
				this.state.past_life.find((dl) => {
					if (dl.id == jp.original_id) {
						temp_cc[cc_cnt].daily_entry = dl.entry;
						temp_cc[cc_cnt].daily_description = dl.description;
					}
				});
				temp_cc[cc_cnt].language_entry = jp.entry;
				cc_cnt++;
			} else if(jp.association_type === "core attribute") {
				temp_ca.push({});
				this.state.core_attributes.find((ca) => {
					if (ca.id == jp.association_id) {
						temp_ca[ca_cnt].ca = ca.attribute;
					}
				});
				this.state.past_life.find((dl) => {
					if (dl.id == jp.original_id) {
						temp_ca[ca_cnt].daily_entry = dl.entry;
						temp_ca[ca_cnt].daily_description = dl.description;
					}
				});
				temp_ca[ca_cnt].language_entry = jp.entry;
				ca_cnt++;
			}
		});


		this.setState({cc_associations: temp_cc, 
			ca_associations: temp_ca, stretch_associations: temp_stretch});


		this.state.language.forEach((element) => {
			if (element.association_id) {
				this.setState({[element.id+"_answer"]: true});
			}
			if (element.association_type == "core constitution") {
				this.state.core_constitution.find((cc) => {
					if (cc.id == element.association_id) {
						this.setState({[element.id+"_answer_value"]: cc.entry});
					}
				});
			} else if (element.association_type  == "core attribute") {
				this.state.core_attributes.find((ca) => {
					if (ca.id == element.association_id) {
						this.setState({[element.id+"_answer_value"]: ca.attribute});
					}
				});	
			} else  if (element.association_type == "virus") {
				this.state.viruses.find((virus) => {
					if (virus.id == element.association_id) {
						this.setState({[element.id+"_answer_value"]: virus.virus});
					}
				});						
			} else if (element.association_type == "stretch attribute") {
				this.state.stretch_attributes.find((stretch) => {
					if (stretch.id == element.association_id) {
						this.setState({[element.id+"_answer_value"]: stretch.attribute});
					}
				});	
			}
			if (element.type === "daily life") {
				//this.setState({[element.id + "_checked"]: false});
				temp_daily.push(element);
			} else if (element.type === "past life") {
				temp_past.push(element);
			} 
		});
		this.setState({loading: false});

	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetJumpoutPast() {
	const res = await axios.get(APIHost +  '/api/jumpOutLanguage', 
		{params: {withCredentials: true, type: 'past life'}});
	return await res;
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetJumpoutDaily() {
	const res = await axios.get(APIHost +  '/api/jumpOutLanguage', 
		{params: {withCredentials: true, type: 'daily life'}});
	return await res;
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetStretchAttributes() {
	const res = await axios.get(APIHost + '/api/stretchAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreDisharmonies() {
	const res = await axios.get(APIHost + '/api/coreDisharmonies',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetDaily() {
	const res = await axios.get(APIHost + '/api/dailyLife',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetPast() {
	const res = await axios.get(APIHost + '/api/pastLife',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({[event.target.id+"_answer_value"]: event.target.value, adding_new: true, [this.state.current_daily_past+"_disabled"]: false});
}

DisplayFinished(history) {
	var display_stretch = [];
	var display_virus = [];
	var display_cc = [];
	var display_ca = [];
	var display_stretch_exercises = [];
	var display_virus_exercises = [];
	var display_mc_exercises = [];
	var display_mc = [];
	var display_video = [];
	var display = [];
	var display_intro = [];
	var display_button = [];
	for(var i = 0; i<this.state.stretch_associations.length; i++) {
		display_stretch.push(<div><p><font color="blue">Stretch Attribute:</font> {this.state.stretch_associations[i].stretch_attribute}</p>
		<p><font color="blue">From Daily entry:</font> {this.state.stretch_associations[i].daily_entry}</p>
		<p><font color="blue">Description:</font> {this.state.stretch_associations[i].daily_description}</p></div>);
	}

	for(var i = 0; i<this.state.virus_associations.length; i++) {
						var ex_text = null;
				this.state.exercise_answers.find((ea) => {
					if (ea.exercise_id == this.state.virus_associations[i].daily_id) {
						ex_text = ea.text;
					}
				});
				if (i == this.state.virus_associations.length-1) {
					display_virus.push(<div><p><font color="blue">Virus:</font> {this.state.virus_associations[i].virus}</p>
					<p><font color="blue">From Daily entry:</font> {this.state.virus_associations[i].daily_entry}</p>
					<p><font color="blue">Description:</font> {this.state.virus_associations[i].daily_description}</p>
					<p><font color="blue">Your Answer:</font> {ex_text}</p><hr className="new" /></div>);
				} else {
					display_virus.push(<div><p><font color="blue">Virus:</font> {this.state.virus_associations[i].virus}</p>
					<p><font color="blue">From Daily entry:</font> {this.state.virus_associations[i].daily_entry}</p>
					<p><font color="blue">Description:</font> {this.state.virus_associations[i].daily_description}</p>
					<p><font color="blue">Your Answer:</font> {ex_text}</p><hr /></div>);
				}
	}

	for(var i = 0; i<this.state.ca_associations.length; i++) {
		display_ca.push(<div><p><font color="blue">Core Attribute:</font> {this.state.ca_associations[i].ca}</p>
		<p><font color="blue">From Daily entry:</font> {this.state.ca_associations[i].daily_entry}</p>
		<p><font color="blue">Description:</font> {this.state.ca_associations[i].daily_description}</p><hr /></div>);
	}

	for(var i = 0; i<this.state.cc_associations.length; i++) {
		display_ca.push(<div><p><font color="blue">Core Constitution:</font> {this.state.cc_associations[i].cc}</p>
		<p><font color="blue">From Daily entry:</font> {this.state.cc_associations[i].daily_entry}</p>
		<p><font color="blue">Description:</font> {this.state.cc_associations[i].daily_description}</p><hr /></div>);
	}

	display_stretch_exercises.push(<div><p>Below are the times you associated a Stretch Attribute with a Daily or Past Life 
	event. If you moved a Stretch Attribute to be a Core Attribute, it is no  longer on this list.</p></div>);

	if (this.state.stretch_associations.length > 0) {
		display_mc.push(<div>{display_stretch_exercises}{display_stretch}<hr className="new" /></div>);
	}

	display_virus_exercises.push(<div><p>Below, you will find those times you associated an existing Virus with a Daily or Past language entry or you added a new Virus to associate
	with that entry. Included is the information you provided in the review section.</p></div>);

	if (this.state.virus_associations.length > 0) {
		display_mc.push(<div>{display_virus_exercises}{display_virus}</div>);
	}

	display_mc_exercises.push(<div><p>Below, you will find where you were able to associate a healthy part of your Mental Core to a Daily or Past language entry. Take time to review
	and celebrate these moments. This is you expressing your Authentic Self. Take another look at what Core Attributes or what from your Core Constitution 
	was expressed. These might indicate the strongest parts of your Mental Core or indicate what you find the easiest to express.</p></div>);

	if(this.state.cc_associations.length > 0 || this.state.ca_associations.length > 0) {
		display_mc.push(<div>{display_mc_exercises}{display_ca}{display_cc}</div>);
	}
	display_intro.push(<div><p>Here's another chance to review this crucial information.</p>
		<hr className="new" /> {display_mc}</div>);
	if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
	} else {
		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
	}
	display.push(<div className="col-md-12"><h1>You finished the chapter!</h1>{display_button}
	{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}	<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);

	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, review: false, daily_associations: true, past_associations: false, display_video: false});
}

SetChapterStage(callback) {
axios.post(APIHost + '/api/updateStage', {
			stage: "daily_associations",
			withCredentials: true,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID, 
			percent_complete: .25,
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
	event.preventDefault();
	this.SetChapterStage(this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

NextExercisesCallback() {
	window.scroll(0,0);
	this.state.core_constitution.forEach((element) => {
		if (this.state[`${element.id}_checked_cc`]) {
			this.setState({[element.id + "_checked_cc"]: false});
		}
	});
	this.state.core_attributes.forEach((element) => {
		if(this.state[element.id + "_checked_ca"]) {
			this.setState({[element.id + "_checked_ca"]: false});
		}
	});
	this.state.viruses.forEach((element) => {
		if(this.state[element.id + "_checked_virus"]) {
				this.setState({[element.id + "_checked_virus"]: false});
		}
	});
	this.state.stretch_attributes.forEach((element) => {
		if(this.state[element.id + "_checked_stretch"]) {
				this.setState({[element.id + "_checked_stretch"]: false});
			}

	});
	if (this.state.daily_associations) {
		this.setState({display_exercises: true, review: false, daily_associations: false, past_associations: true});
	} else if (this.state.past_associations) {
		this.setState({display_exercises: true, review: true, daily_associations: false, past_associations: false});
	} else if (this.state.review) {
		this.setState({display_exercises: false, review: false, daily_associations: false, past_associations: false, finished: true});
	} 
}

NextExercises(associations) {
	var next_stage = '';
	var percent_complete = null;
	if (this.state.daily_associations) {
		next_stage = "past_associations";
		percent_complete = .5;
	} else if (this.state.past_associations) {
		next_stage = "review";
		percent_complete = .75;
	} else if (this.state.review) { 
		next_stage = "done";
	}
	// no idea why but transitioning from the daily to the past section - the values  in the text box
	// were remaining - doing the below solved it.
	this.state.daily_language.forEach((element) => {
		this.setState({[element.id+"_answer_value"]: ""});
	});
	this.state.past_language.forEach((element) => {
		this.setState({[element.id+"_answer_value"]: ""});
	});
	axios.post(APIHost + '/api/updateStage', {
		stage: next_stage,
		withCredentials: true,
		percent_complete: percent_complete,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
    	this.NextExercisesCallback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

AddAssociationCallback(mc, new_entry, new_id) {
	var display = [];
	display.push(<p><font color="blue">Update sent - make another selection to update again.</font></p>);
	var temp_virus = this.state.virus_associations;
	var temp_cc = this.state.cc_associations;
	var temp_stretch = this.state.stretch_associations;
	var temp_ca = this.state.ca_associations;
	var state_viruses = this.state.viruses;
	var state_cc = this.state.core_constitution;
	var state_ca = this.state.core_attributes;
	var state_stretch = this.state.stretch_attributes;
	var original_id = null;
	this.state.language.find((lang) => {
		if (lang.id == this.state.current_daily_past) {
			original_id = lang.original_id;
		}
	});
	if (mc == "virus") {
		temp_virus.push({});

		if(this.state.adding_new) {
			state_viruses.push({});
			state_viruses[this.state.viruses.length-1].virus = new_entry;
			state_viruses[this.state.viruses.length-1].id = new_id;
			temp_virus[this.state.virus_associations.length-1].virus = new_entry;
			temp_virus[this.state.virus_associations.length-1].added_virus = true;
		} else {
			this.state.viruses.find((virus) => {
				if (virus.id == this.state.current_virus) {
					temp_virus[this.state.virus_associations.length-1].virus = virus.virus;
					temp_virus[this.state.virus_associations.length-1].added_virus = virus.added;
				}
			});
		}
		if (this.state.daily_associations) {
			this.state.daily_life.find((dl) => {
				if (dl.id == original_id) {
					temp_virus[this.state.virus_associations.length-1].daily_entry = dl.entry;
					temp_virus[this.state.virus_associations.length-1].daily_description = dl.description;
				}
			});
			
		} else if (this.state.past_associations) {
			this.state.past_life.find((dl) => {
				if (dl.id == original_id) {
					temp_virus[this.state.virus_associations.length-1].daily_entry = dl.entry;
					temp_virus[this.state.virus_associations.length-1].daily_description = dl.description;
				}
			});
		}
		temp_virus[this.state.virus_associations.length-1].daily_id = this.state.current_daily_past;
			this.state.language.find((lang) => {
				if (lang.id == this.state.current_daily_past) {
					temp_virus[this.state.virus_associations.length-1].language_entry = lang.entry;
				}
			});
	} else if (mc == "core constitution") {
		temp_cc.push({});
		if (this.state.adding_new) {
			temp_cc[this.state.cc_associations.length-1].cc = new_entry;
		} else {
			this.state.core_constitution.find((cc) => {
				if (cc.id == this.state.current_core_constitution) {
					temp_cc[this.state.cc_associations.length-1].cc = cc.entry;
				}
			});
		}
		if (this.state.daily_associations) {
			this.state.daily_life.find((dl) => {
				if (dl.id == original_id) {
					temp_cc[this.state.cc_associations.length-1].daily_entry = dl.entry;
					temp_cc[this.state.cc_associations.length-1].daily_description = dl.description;
				}
			});
		} else if (this.state.past_associations) {
			this.state.past_life.find((dl) => {
				if (dl.id == original_id) {
					temp_cc[this.state.cc_associations.length-1].daily_entry = dl.entry;
					temp_cc[this.state.cc_associations.length-1].daily_description = dl.description;
				}
			});			
		}
		this.state.language.find((lang) => {
			if (lang.id == this.state.current_daily_past)
			temp_cc[this.state.cc_associations.length-1].language_entry = lang.entry;
		});
	} else if (mc == "stretch attribute") {
		temp_stretch.push({});
		if (this.state.adding_new) {
			temp_stretch[this.state.stretch_associations.length-1].stretch_attribute = new_entry;
			temp_stretch[this.state.stretch_associations.length-1].stretch_id = new_id;
		} else {
			this.state.stretch_attributes.find((stretch) => {
				if (stretch.id == this.state.current_stretch) {
					temp_stretch[this.state.stretch_associations.length-1].stretch_attribute = stretch.attribute;
					temp_stretch[this.state.stretch_associations.length-1].stretch_id = stretch.id;
				}
			});
		}
		if (this.state.daily_associations) {
			this.state.daily_life.find((dl) => {
				if (dl.id == original_id) {
					temp_stretch[this.state.stretch_associations.length-1].daily_entry = dl.entry;
					temp_stretch[this.state.stretch_associations.length-1].daily_description = dl.description;
					temp_stretch[this.state.stretch_associations.length-1].daily_past_type = "daily"
				}
			});
		} else if (this.state.past_associations) {
			this.state.past_life.find((dl) => {
				if (dl.id == original_id) {
					temp_stretch[this.state.stretch_associations.length-1].daily_entry = dl.entry;
					temp_stretch[this.state.stretch_associations.length-1].daily_description = dl.description;
					temp_stretch[this.state.stretch_associations.length-1].daily_past_type = "past"
				}
			});			
		}
		this.state.language.find((lang) => {
			if (lang.id == this.state.current_daily_past) {
				temp_stretch[this.state.stretch_associations.length-1].language_entry = lang.entry;
			}
		});
	} else if (mc == "core attribute") {
		temp_ca.push({});
		if (this.state.adding_new) {
			temp_ca[this.state.ca_associations.length-1].ca = new_entry;
		} else {
			this.state.core_attributes.find((ca) => {
				if (ca.id == this.state.current_core_attribute) {
					temp_ca[this.state.ca_associations.length-1].ca = ca.entry;
				}
			});
		}
		if (this.state.daily_associations) {
			this.state.daily_life.find((dl) => {
				if (dl.id == original_id) {
					temp_ca[this.state.ca_associations.length-1].daily_entry = dl.entry;
					temp_ca[this.state.ca_associations.length-1].daily_description = dl.description;
				}
			});
		} else if (this.state.past_associations) {
			this.state.past_life.find((dl) => {
				if (dl.id == original_id) {
					temp_ca[this.state.ca_associations.length-1].daily_entry = dl.entry;
					temp_ca[this.state.ca_associations.length-1].daily_description = dl.description;
				}
			});			
		}
		this.state.language.find((lang) => {
			if (lang.id == this.state.current_daily_past)
			temp_ca[this.state.ca_associations.length-1].language_entry = lang.entry;
		});
	} 
	this.setState({cc_associations: temp_cc, virus_associations: temp_virus, stretch_associations: temp_stretch,
		ca_associations: temp_ca, viruses: state_viruses});
	this.setState({[this.state.current_daily_past+"_updated"]: true, [this.state.current_daily_past+"_disabled"]: true, [this.state.current_daily_past+"_display_updated"]: display});
	if (this.state[`${this.state.current_daily_past}_cc`]) {
		this.setState({[this.state.current_daily_past+"_answer"]: this.state.current_core_constitution})
	} else if (this.state[`${this.state.current_daily_past}_ca`]) {
		this.setState({[this.state.current_daily_past+"_answer"]: this.state.current_core_attribute});
	} else if (this.state[`${this.state.current_daily_past}_virus`]) {
		this.setState({[this.state.current_daily_past+"_answer"]: this.state.current_virus});
	} else if (this.state[`${this.state.current_daily_past}_stretch`]) {
		this.setState({[this.state.current_daily_past+"_answer"]: this.state.current_stretch});
	}
}

AddAssociation(id) {
	var association = {};
	var flag = false;
	var mc = "";
	if (this.state[`${this.state.current_daily_past}_cc`]) {
		mc = "core constitution";
		association = {association: this.state.current_core_constitution, association_id: this.state.current_daily_past, association_type: "core constitution"}
	} else if (this.state[`${this.state.current_daily_past}_ca`]) {
		mc = "core attribute";
		if (this.state.adding_new) {
			association = {association: this.state.current_core_attribute, association_id: this.state.current_daily_past, association_type: "stretch attribute"}
		} else {
			association = {association: this.state.current_core_attribute, association_id: this.state.current_daily_past, association_type: "core attribute"}			
		}
	} else if (this.state[`${this.state.current_daily_past}_virus`]) {
		mc = "virus";
		association = {association: this.state.current_virus, association_id: this.state.current_daily_past, association_type: "virus"}
	} else if (this.state[`${this.state.current_daily_past}_stretch`]) {
		mc = "stretch attribute";
		association = {association: this.state.current_stretch, association_id: this.state.current_daily_past, association_type: "stretch attribute"}
	}
	if (this.state.adding_new) {
		//if (this.state[`${this.state.current_daily_past}_cc`]) {
	}
	if (this.state.adding_new) {
		flag=true;
	}
	axios.post(APIHost + '/api/addAssociations', {
		withCredentials: true,
		associations: association,
		type: "daily_past", 
		adding_new: flag,
		new_entry: this.state[`${id}_answer_value`],
		new_entry_type: mc
	}).then((res) => {
		this.AddAssociationCallback(mc, this.state[`${id}_answer_value`], res.data.returned_id);
	}).catch((error) =>  {
		console.log("AddAssociation failed " + error);
		this.setState({server_failure: true});
	});
}

onAddAssociation(event) {
	event.preventDefault();
	this.AddAssociation(event.target.id);
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises();
}

ExercisesCallback(exercise) {
	var temp_exercises = this.state.exercise_answers;
	temp_exercises.push({});
	temp_exercises[this.state.exercise_answers.length - 1].text = this.state[`${exercise}_answer_value`];
	temp_exercises[this.state.exercise_answers.length - 1].exercise_id = exercise;
	this.setState({exercise_answers: temp_exercises});
	this.setState({[exercise+"_done"]: true});
}

Exercises(id) {
	var answer = {exercise_id: id, answer: this.state[`${id}_answer_value`]};
	axios.post(APIHost + '/api/answerExercises', {
			withCredentials: true,
			answer: answer,
			chapter_id: this.state.chapterID
		}).then(res => {
			this.ExercisesCallback(id);
		}).catch(error => {
			console.log("Failed to submit the exercises");
			this.setState({submission_failure: true});
		});
}

onSubmitExercise(event) {
	event.preventDefault();
	this.Exercises(event.target.id);
}

LanguageState(event) {
		//this.setState({[id + "_disabled"]: true});
		//this.setState({disabled_39: true});
		this.setState({[this.state.current_daily_past+"_disabled"]: false});
	if (this.state.daily_associations) {
		this.state.daily_language.find((dl) => {
			if(dl.id != event.target.id) {
				this.setState({[dl.id+"_cc"]: false, [dl.id+"_ca"]: false, [dl.id+"_virus"]: false,
					[dl.id+"_answer_value"]: ""});
			}
		});
	} else if (this.state.past_associations) {
		this.state.past_language.find((pl) => {
			if(pl.id != event.target.id) {
				this.setState({[pl.id+"_cc"]: false, [pl.id+"_ca"]: false, [pl.id+"_virus"]: false,
					[pl.id+"_answer_value"]: ""});
			}
		});
	}

	if (event.target.name == "daily_language" || event.target.name == "past_language") {
		this.state.core_constitution.forEach((cc) => {
			this.setState({[cc.id+"_checked_cc"]: false});
		});
		this.state.core_attributes.forEach((ca) => {
			this.setState({[ca.id+"_checked_ca"]: false});
		});
		this.state.viruses.forEach((virus) => {
			this.setState({[virus.id+"_checked_virus"]: false});
		});
		this.state.stretch_attributes.forEach((stretch) => {
			this.setState({[stretch.id+"_checked_stretch"]: false});
		});
	}

	if (event.target.name == "daily_language") {
	this.setState({[event.target.id + "_checked"]: !this.state[`${event.target.id}_checked`], 
	current_daily_past: event.target.id});
	this.state.daily_language.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked`]) {
				this.setState({[element.id + "_checked"]: false});
			}
		}
	});
	} else if (event.target.name == "past_language") {
		this.setState({[event.target.id + "_checked"]: !this.state[`${event.target.id}_checked`], 
		current_daily_past: event.target.id});
		this.state.past_language.forEach((element) => {
			if (element.id != event.target.id) {
				if (this.state[`${element.id}_checked`]) {
					this.setState({[element.id + "_checked"]: false});
				}
			}
		});
	} else if (event.target.name == "core_constitution") {
		this.setState({[this.state.current_daily_past+"_answer_value"]: event.target.value, [this.state.current_daily_past+"_cc"]: true,
			[this.state.current_daily_past+"_ca"]: false, [this.state.current_daily_past+"_stretch"]: false, [this.state.current_daily_past+"_virus"]: false, [event.target.id + "_checked_cc"]: !this.state[event.target.id + "_checked_cc"],
		 current_core_constitution: event.target.id, adding_new: false});
		this.state.core_constitution.forEach((element) => {
			if (element.id != event.target.id) {
				if (this.state[`${element.id}_checked_cc`]) {
					this.setState({[element.id + "_checked_cc"]: false});
					//this.setState({[element.id + "_checked_cc"]: !this.state[`${element.id}_checked_cc`]});

				}
			}
		});
		this.state.core_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_ca"]) {
				this.setState({[element.id + "_checked_ca"]: false});
			}
		});
		this.state.viruses.forEach((element) => {
			if(this.state[element.id + "_checked_virus"]) {
					this.setState({[element.id + "_checked_virus"]: false});
			}
		});
		this.state.stretch_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_stretch"]) {
					this.setState({[element.id + "_checked_stretch"]: false});
				}

		});
	} else if ( event.target.name == "core_attributes") {
		this.setState({[this.state.current_daily_past+"_answer_value"]: event.target.value, [this.state.current_daily_past+"_ca"]: true,
			[this.state.current_daily_past+"_cc"]: false, [event.target.id + "_checked_ca"]: !this.state[event.target.id + "_checked_ca"],
		[this.state.current_daily_past+"_virus"]: false, [this.state.current_daily_past+"_stretch"]: false, current_core_attribute: event.target.id, adding_new: false});
		this.state.core_attributes.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked_ca`]) {
					this.setState({[element.id + "_checked_ca"]: false});
				}
			}
		});
		this.state.viruses.forEach((element) => {
			if(this.state[element.id + "_checked_virus"]) {
					this.setState({[element.id + "_checked_virus"]: false});
			}
		});
		this.state.core_constitution.forEach((element) => {
			if(this.state[element.id + "_checked_cc"]) {
					this.setState({[element.id + "_checked_cc"]: false});
				}

		});
		this.state.stretch_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_stretch"]) {
					this.setState({[element.id + "_checked_stretch"]: false});
				}

		});
	} else if (event.target.name == "viruses") {
		this.setState({[this.state.current_daily_past+"_answer_value"]: event.target.value, [this.state.current_daily_past+"_virus"]: true,
		[this.state.current_daily_past+"_ca"]: false, [event.target.id + "_checked_virus"]: !this.state[event.target.id + "_checked_virus"],
		 [this.state.current_daily_past+"_cc"]: false, [this.state.current_daily_past+"_stretch"]: false, current_virus: event.target.id, adding_new: false});
		this.state.viruses.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked_virus`]) {
					this.setState({[element.id + "_checked_virus"]: false});
				}
			}
		});
		this.state.core_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_ca"]) {
					this.setState({[element.id + "_checked_ca"]: false});
			}
		});
		this.state.core_constitution.forEach((element) => {
			if(this.state[element.id + "_checked_cc"]) {
					this.setState({[element.id + "_checked_cc"]: false});
				}

		});
		this.state.stretch_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_stretch"]) {
					this.setState({[element.id + "_checked_stretch"]: false});
				}

		});
	} else if (event.target.name == "stretch_attributes") {
		this.setState({[this.state.current_daily_past+"_answer_value"]: event.target.value, [this.state.current_daily_past+"_stretch"]: true,
		[this.state.current_daily_past+"_ca"]: false, [event.target.id + "_checked_stretch"]: !this.state[event.target.id + "_checked_stretch"],
		 [this.state.current_daily_past+"_cc"]: false, [this.state.current_daily_past+"_virus"]: false, current_stretch: event.target.id, adding_new: false});
		this.state.stretch_attributes.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked_stretch`]) {
					this.setState({[element.id + "_checked_stretch"]: false});
				}
			}
		});
		this.state.core_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_ca"]) {
					this.setState({[element.id + "_checked_ca"]: false});
			}
		});
		this.state.core_constitution.forEach((element) => {
			if(this.state[element.id + "_checked_cc"]) {
					this.setState({[element.id + "_checked_cc"]: false});
				}

		});
		this.state.viruses.forEach((element) => {
			if(this.state[element.id + "_checked_virus"]) {
					this.setState({[element.id + "_checked_virus"]: false});
			}
		});
	}
}

TypeChange(event) {
	if (event.target.name == "core_constitution") {
		this.setState({[this.state.current_daily_past+"_cc"]: true});
		if(this.state[`${this.state.current_daily_past}_virus`]) {
			this.setState({[this.state.current_daily_past+"_virus"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_ca`]) {
			this.setState({[this.state.current_daily_past+"_ca"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_stretch`]) {
			this.setState({[this.state.current_daily_past+"_stretch"]: false});
		}
		//this.setState({[this.state.current_daily_past+"_virus"]: !this.state[`${this.state.current_daily_past}_virus`],
		//[this.state.current_daily_past+"_ca"]: !this.state[`${this.state.current_daily_past}_ca`], [this.state.current_daily_past+"_cc"]: !this.state[`${this.state.current_daily_past}_cc`]});
	} else if ( event.target.name == "viruses") {
		this.setState({[this.state.current_daily_past+"_virus"]: true});
		if(this.state[`${this.state.current_daily_past}_cc`]) {
			this.setState({[this.state.current_daily_past+"_cc"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_ca`]) {
			this.setState({[this.state.current_daily_past+"_ca"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_stretch`]) {
			this.setState({[this.state.current_daily_past+"_stretch"]: false});
		}
	} else if ( event.target.name == "core_attributes") {
		this.setState({[this.state.current_daily_past+"_ca"]: true});
		if(this.state[`${this.state.current_daily_past}_cc`]) {
			this.setState({[this.state.current_daily_past+"_cc"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_virus`]) {
			this.setState({[this.state.current_daily_past+"_virus"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_stretch`]) {
			this.setState({[this.state.current_daily_past+"_stretch"]: false});
		}
	} else if ( event.target.name == "stretch_attributes") {
		this.setState({[this.state.current_daily_past+"_stretch"]: true});
		if(this.state[`${this.state.current_daily_past}_cc`]) {
			this.setState({[this.state.current_daily_past+"_cc"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_virus`]) {
			this.setState({[this.state.current_daily_past+"_virus"]: false});
		}
		if(this.state[`${this.state.current_daily_past}_cc`]) {
			this.setState({[this.state.current_daily_past+"_cc"]: false});
		}
	}
}

MakeCoreCallback(stretch_id) {
	this.setState({[stretch_id+"_make_core"]: true, [stretch_id+"_core_disharmony"]: true});
}

MakeCore(stretch_id, stretch_attribute) {
	axios.post(APIHost + '/api/makeCore', {
		withCredentials: true,
		id: stretch_id,
		stretch_attribute: stretch_attribute
	}).then(res => {
		this.MakeCoreCallback(stretch_id);
	}).catch(error => {
		console.log("Failed to submit the exercises");
		this.setState({submission_failure: true});
	});
}

onMakeCore(event) {
	event.preventDefault();
	this.MakeCore(event.target.id, event.target.name);
}

CoreDisharmonyCallback(stretch_id) {
	this.setState({[stretch_id+"_make_core"]: true, [stretch_id+"_core_disharmony"]: true});
}

CoreDisharmony(stretch_id, daily_past_id, type) {
	axios.post(APIHost + '/api/coreDisharmonies', {
		withCredentials: true,
		stretch_id: stretch_id,
		daily_past_id: daily_past_id,
		type: type
	}).then(res => {
		this.CoreDisharmonyCallback(stretch_id);
	}).catch(error => {
		console.log("Failed to submit the exercises");
		this.setState({submission_failure: true});
	});
}

onCoreDisharmony(event) {
	event.preventDefault();
	this.CoreDisharmony(event.target.id, event.target.name, event.target.title);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_cc = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	
	if (this.state.daily_associations) {
		var display_language = [];
		for (var i=0; i<this.state.daily_language.length; i++) {
			if(this.state[`${this.state.daily_language[i].id}_answer`]) {
			display_language.push(<div><p>Daily Language Entry: <input onChange={this.LanguageState} name="daily_language" key={`${this.state.daily_language[i].id}_daily`} checked={this.state[`${this.state.daily_language[i].id}_checked`]} id={this.state.daily_language[i].id} type="checkbox" /> {this.state.daily_language[i].entry}</p><p><form>
				<p>Enter new Virus, Core Constitution entry, or Core Attribute, or pick from your list on the Right</p><div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.daily_language[i].id} name="daily_life" value={this.state[`${this.state.daily_language[i].id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<input onChange={this.TypeChange} name="core_constitution" key={`${this.state.daily_language[i].id}_cc`} checked={this.state[`${this.state.daily_language[i].id}_cc`]} id={this.state.daily_language[i].id} type="checkbox" />Core Constitution
  				<input onChange={this.TypeChange} name="core_attributes" key={`${this.state.daily_language[i].id}_ca`} checked={this.state[`${this.state.daily_language[i].id}_ca`]} id={this.state.daily_language[i].id} type="checkbox" />Core Attribute
  				<input onChange={this.TypeChange} name="viruses" key={`${this.state.daily_language[i].id}_virus`} checked={this.state[`${this.state.daily_language[i].id}_virus`]} id={this.state.daily_language[i].id} type="checkbox" />Virus
       			<input onChange={this.TypeChange} name="stretch_attributes" key={`${this.state.daily_language[i].id}_stretch`} checked={this.state[`${this.state.daily_language[i].id}_stretch`]} id={this.state.daily_language[i].id} type="checkbox" />Stretch Attribute								
  				<button type="submit" id={this.state.daily_language[i].id} name="daily_life" className="btn btn-primary my-3" disabled={this.state[`${this.state.daily_language[i].id}_disabled`]} onClick={this.onAddAssociation}>Update</button>{this.state[`${this.state.daily_language[i].id}_display_updated`]}<hr className="new" />
  				</p></div> );
			} else {
			display_language.push(<div><p>Daily Language Entry: <input onChange={this.LanguageState} name="daily_language" key={`${this.state.daily_language[i].id}_daily`} checked={this.state[`${this.state.daily_language[i].id}_checked`]} id={this.state.daily_language[i].id} type="checkbox" /> {this.state.daily_language[i].entry}</p><p><form>
				<p>Enter new Virus, Core Constitution entry, or Core Attribute, or pick from your list on the Right</p><div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.daily_language[i].id} name="daily_life" value={this.state[`${this.state.daily_language[i].id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<input onChange={this.TypeChange} name="core_constitution" key={`${this.state.daily_language[i].id}_cc`} checked={this.state[`${this.state.daily_language[i].id}_cc`]} id={this.state.daily_language[i].id} type="checkbox" />Core Constitution
  				<input onChange={this.TypeChange} name="core_attributes" key={`${this.state.daily_language[i].id}_ca`} checked={this.state[`${this.state.daily_language[i].id}_ca`]} id={this.state.daily_language[i].id} type="checkbox" />Core Attribute
  				<input onChange={this.TypeChange} name="viruses" key={`${this.state.daily_language[i].id}_virus`} checked={this.state[`${this.state.daily_language[i].id}_virus`]} id={this.state.daily_language[i].id} type="checkbox" />Virus
     			<input onChange={this.TypeChange} name="stretch_attributes" key={`${this.state.daily_language[i].id}_stretch`} checked={this.state[`${this.state.daily_language[i].id}_stretch`]} id={this.state.daily_language[i].id} type="checkbox" />Stretch Attribute				
  				<button type="submit" id={this.state.daily_language[i].id} name="daily_life" disabled={this.state[`${this.state.daily_language[i].id}_disabled`]} onClick={this.onAddAssociation} className="btn btn-primary my-3">Submit</button>{this.state[`${this.state.daily_language[i].id}_display_updated`]}<hr className="new" />
  				</p></div> );
  			}
		}

		for (var i=0; i<this.state.core_constitution.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="core_constitution" value={this.state.core_constitution[i].entry} key={`${this.state.core_constitution[i].id}_cc`} checked={this.state[`${this.state.core_constitution[i].id}_checked_cc`]} id={this.state.core_constitution[i].id} type="checkbox" /> {this.state.core_constitution[i].entry}</p>);
		}

		for (var i=0; i<this.state.core_attributes.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="core_attributes" value={this.state.core_attributes[i].attribute} key={`${this.state.core_attributes[i].id}_ca`} checked={this.state[`${this.state.core_attributes[i].id}_checked_ca`]} id={this.state.core_attributes[i].id} type="checkbox" /> {this.state.core_attributes[i].attribute}</p>);
		}

		for (var i=0; i<this.state.viruses.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="viruses" value={this.state.viruses[i].virus} key={`${this.state.viruses[i].id}_viruses`} checked={this.state[`${this.state.viruses[i].id}_checked_virus`]} id={this.state.viruses[i].id} type="checkbox" /> {this.state.viruses[i].virus}</p>);
		}

		for (var i=0; i<this.state.stretch_attributes.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="stretch_attributes" value={this.state.stretch_attributes[i].attribute} key={`${this.state.stretch_attributes[i].id}_stretch`} checked={this.state[`${this.state.stretch_attributes[i].id}_checked_stretch`]} id={this.state.stretch_attributes[i].id} type="checkbox" /> {this.state.stretch_attributes[i].attribute}</p>);
		}
	
		display_intro.push(<div><p>Below is a list of the language you extracted from your Daily Life descriptions along with all the entries from your Mental Core including your Core Constitution, Core Attributes, and Viruses.
		The idea below is to associate one of the entries in your Mental Core with the language of your Daily Life. Here's what to do:
		<ul><li>Click one of the boxes next to one of the entries for the language of your Daily Life</li>
		<li>Find an entry in your Mental Core that you feel matches well to that entry. It will automatically show-up in the text area and the correct type will be checked.</li>
		<li>If you can't find an entry from your Mental Core, see if you can identify a new entry for your Core Constitution, a new Core Attribute, or new Virus. Enter that in the
		text area and select which type you are entering</li>
		<li>Click the add button once you have the entry you want</li>
		<li>If either an existing entry from your Mental Core or a new entry can't be identified, then leave it blank</li></ul>
		Here's a demonstration of what to do:</p>
		<p><iframe src="https://player.vimeo.com/video/452705816" width="340" height="264" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr className="new" /> 		
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Daily Life Language:</h5> {display_language}
			</div>
			<div className='col-md-6'>
			
			<h5>Your Mental Core:</h5> {display_cc}</div>
			
			</div></div>
 			<p><button className="custom-btn" onClick={this.onNextExercises} type="submit">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Daily Language Associations</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.past_associations) {
		var display_past_language = [];
		for (var i=0; i<this.state.past_language.length; i++) {
			if(this.state[`${this.state.past_language[i].id}_answer`]) {
			display_past_language.push(<div><p>Past Language Entry: <input onChange={this.LanguageState} name="past_language" key={`${this.state.past_language[i].id}_past`} checked={this.state[`${this.state.past_language[i].id}_checked`]} id={this.state.past_language[i].id} type="checkbox" /> {this.state.past_language[i].entry}</p><p><form>
				<p>Enter new Virus, Core Constitution entry, or Core Attribute, or pick from your list on the Right</p><div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.past_language[i].id} name="past_life" value={this.state[`${this.state.past_language[i].id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<input onChange={this.TypeChange} name="core_constitution" key={`${this.state.past_language[i].id}_cc`} checked={this.state[`${this.state.past_language[i].id}_cc`]} id={this.state.past_language[i].id} type="checkbox" />Core Constitution
  				<input onChange={this.TypeChange} name="core_attributes" key={`${this.state.past_language[i].id}_ca`} checked={this.state[`${this.state.past_language[i].id}_ca`]} id={this.state.past_language[i].id} type="checkbox" />Core Attribute
  				<input onChange={this.TypeChange} name="viruses" key={`${this.state.past_language[i].id}_virus`} checked={this.state[`${this.state.past_language[i].id}_virus`]} id={this.state.past_language[i].id} type="checkbox" />Virus
   				<input onChange={this.TypeChange} name="stretch_attributes" key={`${this.state.past_language[i].id}_stretch`} checked={this.state[`${this.state.past_language[i].id}_stretch`]} id={this.state.past_language[i].id} type="checkbox" />Stretch Attribute

  				<button type="submit" id={this.state.past_language[i].id} name="past_life" disabled={this.state[`${this.state.past_language[i].id}_disabled`]} className="btn btn-primary my-3" onClick={this.onAddAssociation}>Update</button>{this.state[`${this.state.past_language[i].id}_display_updated`]}<hr className="new" />
  				</p></div> );
			} else {
			display_past_language.push(<div><p>Past Language Entry: <input onChange={this.LanguageState} name="past_language" key={`${this.state.past_language[i].id}_past`} checked={this.state[`${this.state.past_language[i].id}_checked`]} id={this.state.past_language[i].id} type="checkbox" /> {this.state.past_language[i].entry}</p><p><form>
				<p>Enter new Virus, Core Constitution entry, or Core Attribute, or pick from your list on the Right</p><div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.past_language[i].id} name="past_life" value={this.state[`${this.state.past_language[i].id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<input onChange={this.TypeChange} name="core_constitution" key={`${this.state.past_language[i].id}_cc`} checked={this.state[`${this.state.past_language[i].id}_cc`]} id={this.state.past_language[i].id} type="checkbox" />Core Constitution
  				<input onChange={this.TypeChange} name="core_attributes" key={`${this.state.past_language[i].id}_ca`} checked={this.state[`${this.state.past_language[i].id}_ca`]} id={this.state.past_language[i].id} type="checkbox" />Core Attribute
  				<input onChange={this.TypeChange} name="viruses" key={`${this.state.past_language[i].id}_virus`} checked={this.state[`${this.state.past_language[i].id}_virus`]} id={this.state.past_language[i].id} type="checkbox" />Virus
  	   				<input onChange={this.TypeChange} name="stretch_attributes" key={`${this.state.past_language[i].id}_stretch`} checked={this.state[`${this.state.past_language[i].id}_stretch`]} id={this.state.past_language[i].id} type="checkbox" />Stretch Attribute

  				<button type="submit" id={this.state.past_language[i].id} name="past_life" className="btn btn-primary my-3" disabled={this.state[`${this.state.past_language[i].id}_disabled`]} onClick={this.onAddAssociation}>Submit</button>{this.state[`${this.state.past_language[i].id}_display_updated`]}<hr className="new" />
  				</p></div> );
  			}
		}

		for (var i=0; i<this.state.core_constitution.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="core_constitution" value={this.state.core_constitution[i].entry} key={`${this.state.core_constitution[i].id}_cc`} checked={this.state[`${this.state.core_constitution[i].id}_checked_cc`]} id={this.state.core_constitution[i].id} type="checkbox" /> {this.state.core_constitution[i].entry}</p>);
		}

		for (var i=0; i<this.state.core_attributes.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="core_attributes" value={this.state.core_attributes[i].attribute} key={`${this.state.core_attributes[i].id}_ca`} checked={this.state[`${this.state.core_attributes[i].id}_checked_ca`]} id={this.state.core_attributes[i].id} type="checkbox" /> {this.state.core_attributes[i].attribute}</p>);
		}

		for (var i=0; i<this.state.viruses.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="viruses" value={this.state.viruses[i].virus} key={`${this.state.viruses[i].id}_viruses`} checked={this.state[`${this.state.viruses[i].id}_checked_virus`]} id={this.state.viruses[i].id} type="checkbox" /> {this.state.viruses[i].virus}</p>);
		}

		for (var i=0; i<this.state.stretch_attributes.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="stretch_attributes" value={this.state.stretch_attributes[i].attribute} key={`${this.state.stretch_attributes[i].id}_stretch`} checked={this.state[`${this.state.stretch_attributes[i].id}_checked_stretch`]} id={this.state.stretch_attributes[i].id} type="checkbox" /> {this.state.stretch_attributes[i].attribute}</p>);
		}
	
		display_intro.push(<div><p>Below is a list of the language you extracted from your Past Life descriptions along with all the entries from your Mental Core, your Core Constitution, Core Attributes, and Viruses.
		The idea below is to associate one of the entries in your Mental Core with the language of your Past Life. Here's what to do:
		<ul><li>Click one of the boxes next to one of the entries for the language of your Past Life</li>
		<li>Find and entry in your Mental Core that you feel matches well to that entry. It will automatically show-up in the text area and the correct type will be checked.</li>
		<li>If you can't find an entry from your Mental Core, see if you can identify a new entry for your Core Constitution, a new Core Attribute (it will actually be added as a Stretch Attribute for now), or new Virus. Enter that in the
		text area and select which type you are entering</li>
		<li>If either an existing entry from your Mental Core or a new entry can't be identified, then leave it blank</li></ul>
		Here's a demonstration of what to do:</p>
		<p><iframe src="https://player.vimeo.com/video/452706150" width="340" height="264" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr className="new" /> 		
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-8'>
			<h5>Past Life Language:</h5> {display_past_language}
			</div>
			<div className='col-md-4'>
			
			<h5>Your Mental Core:</h5> {display_cc}</div>
			
			</div></div>
 			<p><button onClick={this.onNextExercises} className="custom-btn" type="submit">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Past Life Language Associations</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.review) {
		var display_stretch = [];
		var display_virus = [];
		var display_cc = [];
		var display_ca = [];
		var display_stretch_exercises = [];
		var display_virus_exercises = [];
		var display_mc_exercises = [];
		for(var i = 0; i<this.state.stretch_associations.length; i++) {
			display_stretch.push(<div><p><font color="blue">Stretch Attribute:</font> {this.state.stretch_associations[i].stretch_attribute}</p>
			<p><font color="blue">From Daily entry:</font> {this.state.stretch_associations[i].daily_entry}</p>
			<p><font color="blue">Description:</font> {this.state.stretch_associations[i].daily_description}</p>
			<button className="btn btn-primary my-3" onClick={this.onMakeCore} id={this.state.stretch_associations[i].stretch_id}
				name={this.state.stretch_associations[i].stretch_attribute} className="btn btn-primary my-3" disabled={this.state[`${this.state.stretch_associations[i].stretch_id}_make_core`]}>Make Core</button>
			<button className="btn btn-primary my-3" id={this.state.stretch_associations[i].stretch_id}
				name={this.state.stretch_associations[i].daily_past_id} title={this.state.stretch_associations[i].daily_past_type}
				 onClick={this.onCoreDisharmony} className="btn btn-primary my-3" disabled={this.state[`${this.state.stretch_associations[i].stretch_id}_core_disharmony`]}>Core Disharmony</button>
			<hr /></div>);
		}
		for(var i = 0; i<this.state.virus_associations.length; i++) {
			if(this.state[`${this.state.virus_associations[i].daily_id}_done`]) {
				var ex_text = null;
				this.state.exercise_answers.find((ea) => {
					if (ea.exercise_id == this.state.virus_associations[i].daily_id) {
						ex_text = ea.text;
					}
				});
				display_virus.push(<div><p><font color="blue">Virus:</font> {this.state.virus_associations[i].virus}</p>
				<p><font color="blue">From Daily entry:</font> {this.state.virus_associations[i].daily_entry}</p>
				<p><font color="blue">Description:</font> {this.state.virus_associations[i].daily_description}</p>
				<p><font color="blue">Your Answer:</font> {ex_text}</p><hr /></div>);
			} else {
				display_virus.push(<div><p><font color="blue">Virus:</font> {this.state.virus_associations[i].virus}</p>
				<p><font color="blue">From Daily entry:</font> {this.state.virus_associations[i].daily_entry}</p>
				<p><font color="blue">Description:</font> {this.state.virus_associations[i].daily_description}</p>
				<form>
				<div className="form-group">
					<textarea onChange={this.onExerciseChange} rows="10" cols="80" id={this.state.virus_associations[i].daily_id} type="text" name="virus" className="form-control"></textarea>
					</div>
					<button onClick={this.onSubmitExercise} type="submit" id={this.state.virus_associations[i].daily_id} disabled={this.state[`${this.state.virus_associations[i].daily_id}_done`]} className="custom-btn">Submit</button>
					</form><hr /></div>);
  				}
		}

		for(var i = 0; i<this.state.ca_associations.length; i++) {
			display_ca.push(<div><p><font color="blue">Core Attribute:</font> {this.state.ca_associations[i].ca}</p>
			<p><font color="blue">From Daily entry:</font> {this.state.ca_associations[i].daily_entry}</p>
			<p><font color="blue">Description:</font> {this.state.ca_associations[i].daily_description}</p><hr /></div>);
		}

		for(var i = 0; i<this.state.cc_associations.length; i++) {
			display_ca.push(<div><p><font color="blue">Core Constitution:</font> {this.state.cc_associations[i].cc}</p>
			<p><font color="blue">From Daily entry:</font> {this.state.cc_associations[i].daily_entry}</p>
			<p><font color="blue">Description:</font> {this.state.cc_associations[i].daily_description}</p><hr /></div>);
		}

		display_stretch_exercises.push(<div><h3>Stretch Attributes Discussion</h3><p>Below, you will find those times that you assocatiated a Stretch Attribute to
		your Daily or Past Life events. This is an opportunity to recognize a few possibilities that can help you on your journey.
		If Stretch Attributes are showing up in these entries, it could mean one of 3 things. One possibility is that what you
		think is a Stretch Attribute is actually a Core Attribute. If you examine the below and feel that what you called a Stretch Attribute
		might actually be an Attribute you express more regularly, making it a Core Attribute, hit the "Make Core" button. Another possibility is
		that you are representing yourself as something you aren't or not being genuine in that moment, which can indicate a Core Disharmony. Examine the situation and if you find
		that there's tension expressed, meaning you are finding that you represented yourself as something you aren't, click the "Core Disharmony" button. 
		An example of this would be if you have a Stretch Attribute of Empathetic and the language entry indicated you were being empathetic in that situation, but when you 
		read the full description, you realize you were actually faking empathy towards the person to gain confidence or achieve some goal you had, that
		would indicate disingenuous behaviour and possibly a Core Disharmony at work. A third possiblity is that you were simply expressing that
		Stretch Attribute appropriately. After all, Stretch Attributes are attributes you do possess, just not as strongly as Core Attributes. If that's the case, 
		simply re-read the associated entry and celebrate the success you had in practicing your Stretch Attributes. </p><hr className="new" /></div>);

		if (this.state.stretch_associations.length > 0) {
			display_mc.push(<div>{display_stretch_exercises}{display_stretch}</div>);
		}

		display_virus_exercises.push(<div><h3>Virus Associations</h3><p>Below, you will find those times you associated an existing Virus with a Daily or Past language entry or you added a new Virus to associate
		with that entry. Take time here to journal in the text area provided on the impact expressing yourself with that Virus might have had. What alternatives were there? 
		If you were to express yourself with healthy parts of your Mental Core, what Core Constitution entry or Core Attribute might you look to? What do you think
		the outcome might have been if you expressed yourself using those parts of your Mental Core?</p><hr className="new" /></div>);

		if (this.state.virus_associations.length > 0) {
			display_mc.push(<div>{display_virus_exercises}{display_virus}</div>);
		}

		display_mc_exercises.push(<div><h3>Healthy Associations</h3><p>Below, you will find where you were able to associate a healthy part of your Mental Core to a Daily or Past language entry. Take time to review
		and celebrate these moments. This is you expressing your Authentic Self. Take another look at what Core Attributes or what from your Core Constitution 
		was expressed. These might indicate the strongest parts of your Mental Core or indicate what you find the easiest to express.</p><hr className="new" /></div>);

		if(this.state.cc_associations.length > 0 || this.state.ca_associations.length > 0) {
			display_mc.push(<div>{display_mc_exercises}{display_ca}{display_cc}</div>);
		}
		display_intro.push(<div><p>Let's review what's happened so far in this chapter. 
		Below are the associations you provided in the previous sections with various explanations and actions. Please take your
		time here to understand the implications of what we've discovered. These associations are part of your Language Association System
		and will continue to be used in future 
		chapters of the training to continue your Journey to Optimal Mental Fitness!</p>
			<hr className="new" /> {display_mc}
 			<p><button onClick={this.onNextExercises} className="custom-btn" type="submit" id="core_attributes">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Chapter Review</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	}

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter36">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter36">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}

						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter36