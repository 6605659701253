import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter37 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 37,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		core_attributes: [],
		core_constitution: [],
		language: [],
		past_language: [],
		review: false,
		associations: false,
		finished: false,
		display_exercises: false,
		stretch_attributes: [],
		stretch_associations: [],
		virus_associations: [],
		ca_associations: [],
		cc_associations: [],
		missing_attributes_entries: [],
		stretch_reliance: false,
		high_stretch: false,
		chapter_video: "https://player.vimeo.com/video/451332795"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.LanguageState = this.LanguageState.bind(this);
this.AddAssociation = this.AddAssociation.bind(this);
this.onAddAssociation = this.onAddAssociation.bind(this);
this.AddAssociationCallback = this.AddAssociationCallback.bind(this);
this.GetStretchAttributes = this.GetStretchAttributes.bind(this);
this.ExercisesCallback = this.ExercisesCallback.bind(this);
this.onSubmitExercise = this.onSubmitExercise.bind(this);
this.Exercises = this.Exercises.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.onCoreDisharmony = this.onCoreDisharmony.bind(this);
this.CoreDisharmony = this.CoreDisharmony.bind(this);
this.CoreDisharmonyCallback = this.CoreDisharmonyCallback.bind(this);
this.GetCoreDisharmonies = this.GetCoreDisharmonies.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, attribute_associations: false, 
				stretch_reliance: false, missing_attributes: false, display_exercises: false, started: true});
		} else if (stage.data.stage == "attribute_associations") {
			this.setState({display_video: false, attribute_associations: true, 
				stretch_reliance: false, missing_attributes: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "stretch_reliance")  {
			this.setState({display_video: false, attribute_associations: false, 
				stretch_reliance: true, missing_attributes: false, display_exercises: true, started: true});			
		} else if (stage.data.stage == "missing_attributes")  {
			this.setState({display_video: false, attribute_associations: false, 
				stretch_reliance: false, missing_attributes: true, display_exercises: true, started: true});
		} else if (stage.data.stage  == "done") {
			this.setState({display_video: false, attribute_associations: false, 
				stretch_reliance: false, missing_attributes: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var stretch_promise = this.GetStretchAttributes().then((stretch) => {
		this.setState({stretch_attributes: stretch.data.attributes});
	}).catch((error) => {
		console.log("GetStretchAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var exercise_answers_promise = this.GetExerciseAnswers().then ((exercise_answers) => {
		this.setState({exercise_answers: exercise_answers.data.exercise_answers});
		this.state.exercise_answers.forEach((element) => {
			console.log("Exercise id " + element.text + " and " + element.exercise_id);
			this.setState({["exercise" + element.exercise_id + "_done"]: true});
		});
	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});

	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	var core_disharmony_promise = this.GetCoreDisharmonies().then((core_disharmonies) => {
		this.setState({core_disharmonies: core_disharmonies.data.core_disharmonies});
	}).catch((error) => {
		console.log("GetCoreDisharmonies failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(core_disharmony_promise);
	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(stretch_promise);
	all_promises.push(exercise_answers_promise);
	Promise.all(all_promises).then( () => {
		var ids = [];
		var original_ids = [];
		var temp_daily = [];
		var temp_past = [];
		var temp_stretch = [];
		var temp_virus = [];
		var temp_ca = [];
		var temp_cc = [];
		var virus_cnt = 0;
		var stretch_cnt = 0;
		var ca_cnt = 0;
		var cc_cnt = 0;
		var temp_missing = [];
		var temp_added_stretch = [];
		var added_stretch_cnt = 0;
		var missing_cnt = 0;
		this.state.core_constitution.forEach((cc) => {
			var flag=false;
			if(cc.association_type === "stretch attribute") {
				temp_stretch.push({});
				this.setState({[cc.id+"_answer"]: true});
				this.state.stretch_attributes.find((stretch) => {
					if (stretch.id === cc.association_id) {

						if(stretch.added) {
							temp_added_stretch.push({});
							temp_added_stretch[added_stretch_cnt].stretch_attribute = stretch.attribute;
							flag = true;
						}
						this.setState({[cc.id+"_answer_value"]: stretch.attribute})
						temp_stretch[stretch_cnt].stretch_attribute = stretch.attribute;
						temp_stretch[stretch_cnt].stretch_id = stretch.id;
					}
				});
				if (flag) {
					temp_added_stretch[added_stretch_cnt].cc_entry = cc.entry;
					temp_added_stretch[added_stretch_cnt].cc_description = cc.description;
					temp_added_stretch[added_stretch_cnt].cc_id = cc.id;	
					added_stretch_cnt++;
				}
				this.state.core_disharmonies.find((cd) => {
					if (cd.core_constitution_id == cc.id) {
						this.setState({[cc.id+"_core_disharmony"]: true});
					}
				});
				temp_stretch[stretch_cnt].cc_entry = cc.entry;
				temp_stretch[stretch_cnt].cc_description = cc.description;
				temp_stretch[stretch_cnt].cc_id = cc.id;
				stretch_cnt++;
			} else if(cc.association_type === "core attribute") {
				temp_ca.push({});
				this.setState({[cc.id+"_answer"]: true});
				this.state.core_attributes.find((ca) => {
					if (ca.id === cc.association_id) {
						this.setState({[cc.id+"_answer_value"]: ca.attribute})
						temp_ca[ca_cnt].ca = ca.attribute;
					}
				});
				temp_ca[ca_cnt].cc_entry = cc.entry;
				temp_ca[ca_cnt].cc_description = cc.description;
				temp_ca[ca_cnt].cc_id = cc.id;
				ca_cnt++;
			} else if (cc.association_id == null) {
				temp_missing.push({});
				temp_missing[missing_cnt].cc_entry = cc.entry;
				if (cc.description == null) {
					temp_missing[missing_cnt].cc_description = "You haven't provided a description for this entry yet";
				} else {
					temp_missing[missing_cnt].cc_description = cc.description;
				}
				temp_missing[missing_cnt].cc_id = cc.id;
				this.setState({[temp_missing[missing_cnt].cc_id+"_done"]: true});
				missing_cnt++;
			}
		});
		var stretch_percent = stretch_cnt/(stretch_cnt+ca_cnt);
		if (stretch_percent > .3) {
			this.setState({high_stretch: true});
		}
		this.setState({added_stretch: temp_added_stretch, stretch_associations: temp_stretch, ca_associations: temp_ca, missing_attributes_entries: temp_missing, loading: false});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetJumpoutPast() {
	const res = await axios.get(APIHost +  '/api/jumpOutLanguage', 
		{params: {withCredentials: true, type: 'past life'}});
	return await res;
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetJumpoutDaily() {
	const res = await axios.get(APIHost +  '/api/jumpOutLanguage', 
		{params: {withCredentials: true, type: 'daily life'}});
	return await res;
}

async GetCoreDisharmonies() {
	const res = await axios.get(APIHost + '/api/coreDisharmonies',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetStretchAttributes() {
	const res = await axios.get(APIHost + '/api/stretchAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetDaily() {
	const res = await axios.get(APIHost + '/api/dailyLife',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetPast() {
	const res = await axios.get(APIHost + '/api/pastLife',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	if(event.target.name == "answer_stretch") {
		this.setState({[event.target.id+"_answer_stretch"]: event.target.value});
	} else if (event.target.name == "new_stretch") {
		this.setState({[event.target.id+"_new_stretch"]: event.target.value,
		[event.target.id+"_done"]: false});
	} else {
		this.setState({[event.target.id+"_answer_value"]: ""});
	}
}

DisplayFinished(history) {
	var display_stretch = [];
	var display_stretch_exercises = [];
	var display_video = [];
	var display = [];
	var display_intro = [];
	var display_mc = [];
	var display_button = [];
	var display_missing_exercises = [];
		var display_stretch_entries = [];
		this.state.stretch_associations.forEach((sa) => {
			if (this.state[`exercise${sa.stretch_id}_done`]) {
				var ex_text = null;
				this.state.exercise_answers.find((ea) => {
					if (ea.exercise_id == sa.stretch_id) {
						ex_text = ea.text;
					}
				});
				display_stretch_entries.push(<div><h5>Stretch Attribute: {sa.stretch_attribute}</h5>
				<p><font color="blue">Core Constitution entry:</font> {sa.cc_entry}</p>
				<p><font color="blue">Description:</font> {sa.cc_description}</p>
				<p><font color="blue">Your Response:</font> {ex_text}</p></div>
				);
			} else {
				display_stretch_entries.push(<div><h5>Stretch Attribute: {sa.stretch_attribute}</h5>
				<p><font color="blue">Core Constitution entry:</font> {sa.cc_entry}</p>
				<p><font color="blue">Description:</font> {sa.cc_description}</p>
				</div>);
			}
		});
		var display_missing = [];
		this.state.added_stretch.forEach((as) => {
			display_missing.push(<div><h5>Added Stretch Attribute: {as.stretch_attribute}</h5>
			<p><font color="blue">Core Constitution entry:</font> {as.cc_entry}</p>
			<p><font color="blue">Description:</font> {as.cc_description}</p>
			</div>);
		});

	if (this.state.stretch_associations.length > 0) {
		display_stretch_exercises.push(<div><h3>Reliance on Stretch Attributes</h3><p>Below are the results of the section where we discussed reliance on Stretch Attributes. 
		Continue to keep this in mind in your daily life. Being your authentic self means that you are expressing yourself through the 
		healthy parts of your Mental Core. Your Stretch Attributes aren't quite fully part of your Mental Core yet. Looking to them for
		practice to integrate them into your Mental Core is a wonderful way to strengthen your Mental Core, but do so with genuine intent
		ensuring you aren't representing yourself as something you're not. Core Disharmonies can strengthen the Viruses and go against
		the progress you're making here.</p>{display_stretch_entries}</div>);
	} else {
		display_stretch_exercises.push(<div><h3>Reliance on Stretch Attributes for your Core Constitution wasn't a prevelant issue for you. That's Great!
		You will continue to practice your Core Constitution in the coming chapters</h3></div>);
	}

	display_mc.push(<div>{display_stretch_exercises}</div>);

	if (this.state.added_stretch.length > 0) {
		display_missing_exercises.push(<div><h3>Missing Attribute Associations</h3><p>Below you will find where you added a new Stretch Attribute
		to a Core Constitution entry where you were unable to associate a Core or Stretch Attribute during the exercises. As mentioned previously, 
		your Attributes give you the ability to express what is in your Core Constitution. Identifying gaps in those crucial associations is a huge step 
		forward in your Journey. You will work on incorporating these new Stretch Attributes to your Mental Core in later chapters. </p>{display_missing}</div>);
	} else {
		display_missing_exercises.push(<div><h3>It doesn't look like you had to add any new Stretch Attributes to enable you to express your Core Constitution. That's Great!
		You will continue to learn to use your existing Attributes more effectively to express your Authentic Self in the coming chapters</h3></div>);
	}
	display_mc.push(<div>{display_missing_exercises}</div>);

	display_intro.push(<div><p>Let's review what's happened in this chapter. 
	Below are the associations you provided in the previous sections with various explanations and actions. Please take your
	time here to understand the implications of what we've discovered. These associations will continue to be used in future 
	chapters of the training to continue your Journey to Optimal Mental Fitness!</p>
		<hr className="new" /> {display_mc}</div>);
	if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
	} else {
		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
	}
	display.push(<div className="col-md-12"><h1>You finished the chapter!</h1>{display_button}
	{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}	<hr className="new" /><p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);

	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, attribute_associations: true, 
		stretch_reliance: false, missing_attributes: false, display_exercises: true});
}

SetChapterStage(callback) {
	axios.post(APIHost + '/api/updateStage', {
			stage: "attribute_associations",
			percent_complete: .25,
			withCredentials: true,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
	event.preventDefault();
	this.SetChapterStage(this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

NextExercisesCallback() {
	window.scroll(0,0);
			var ids = [];
		var original_ids = [];
		var temp_daily = [];
		var temp_past = [];
		var temp_stretch = [];
		var temp_virus = [];
		var temp_ca = [];
		var temp_cc = [];
		var virus_cnt = 0;
		var stretch_cnt = 0;
		var ca_cnt = 0;
		var cc_cnt = 0;
		var temp_missing = [];
		var temp_added_stretch = [];
		var added_stretch_cnt = 0;
		var missing_cnt = 0;
		this.state.core_constitution.forEach((cc) => {
			var flag=false;
			if(cc.association_type === "stretch attribute") {
				temp_stretch.push({});
				this.state.stretch_attributes.find((stretch) => {
					if (stretch.id == cc.association_id) {
						this.state.core_disharmonies.find((cd) => {
							if (cd.stretch_id == stretch.id && cd.core_constitution_id) {
								this.setState({[stretch.id+"_core_disharmony"]: true});
							}
						});
						if(stretch.added) {
							temp_added_stretch.push({});
							temp_added_stretch[added_stretch_cnt].stretch_attribute = stretch.attribute;
							flag = true;
						}
						this.setState({[cc.id+"_answer_value"]: stretch.attribute});
						temp_stretch[stretch_cnt].stretch_attribute = stretch.attribute;
						temp_stretch[stretch_cnt].stretch_id = stretch.id;
					}
				});
				if (flag) {
					temp_added_stretch[added_stretch_cnt].cc_entry = cc.entry;
					temp_added_stretch[added_stretch_cnt].cc_description = cc.description;
					temp_added_stretch[added_stretch_cnt].cc_id = cc.id;	
					added_stretch_cnt++;
				}
				temp_stretch[stretch_cnt].cc_entry = cc.entry;
				temp_stretch[stretch_cnt].cc_description = cc.description;
				temp_stretch[stretch_cnt].cc_id = cc.id;
				this.setState({[cc.id+"_done"]: false});
				stretch_cnt++;
			} else if(cc.association_type === "core attribute") {
				temp_ca.push({});
				this.state.core_attributes.find((ca) => {
					if (ca.id == cc.association_id) {
						this.setState({[cc.id+"_answer_value"]: ca.attribute})
						temp_ca[ca_cnt].ca = ca.attribute;
					}
				});
				temp_ca[ca_cnt].cc_entry = cc.entry;
				temp_ca[ca_cnt].cc_description = cc.description;
				temp_ca[ca_cnt].cc_id = cc.id;
				ca_cnt++;
			} else if (cc.association_id == null) {
				temp_missing.push({});
				temp_missing[missing_cnt].cc_entry = cc.entry;
				if (cc.description == null) {
					temp_missing[missing_cnt].cc_description = "You haven't provided a description for this entry yet";
				} else {
					temp_missing[missing_cnt].cc_description = cc.description;
				}
				temp_missing[missing_cnt].cc_id = cc.id;
				missing_cnt++;
			}
		});
		var stretch_percent = stretch_cnt/(stretch_cnt+ca_cnt);
		if (stretch_percent > .3) {
			this.setState({high_stretch: true});
		}
		this.setState({added_stretch: temp_added_stretch, stretch_associations: temp_stretch, ca_associations: temp_ca, missing_attributes_entries: temp_missing});

	if (this.state.attribute_associations) {
			this.setState({display_video: false, attribute_associations: false, 
				stretch_reliance: true, missing_attributes: false, display_exercises: true, started: true});
	} else if (this.state.stretch_reliance) {
			this.setState({display_video: false, attribute_associations: false, 
				stretch_reliance: false, missing_attributes: true, display_exercises: true, started: true});
	} else if (this.state.missing_attributes) {
			this.setState({display_video: false, attribute_associations: false, 
				stretch_reliance: false, missing_attributes: false, display_exercises: false, started: true, finished: true});
	} 
}

NextExercises() {
	var next_stage = '';
	var percent_complete = null;
	if (this.state.attribute_associations) {
		next_stage = "stretch_reliance";
		percent_complete = .5;
	} else if (this.state.stretch_reliance) {
		next_stage = "missing_attributes";
		percent_complete = .75;
	} else if (this.state.missing_attributes) { 
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
		stage: next_stage,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID,
		percent_complete: percent_complete
	}).then(res => {
    	this.NextExercisesCallback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

/*AddAssociationCallback(id, association_id, type, new_stretch, stretch_id) {
	var temp_stretch_associations = this.state.stretch_associations;
	if (new_stretch) {
		var temp_stretch = this.state.stretch_attributes;
		temp_stretch_associations.push({});
		temp_stretch_associations[this.state.stretch_associations.length-1].stretch_attribute = new_stretch;
		temp_stretch_associations[this.state.stretch_associations.length-1].stretch_id = stretch_id;
		temp_stretch.push({});
		temp_stretch[this.state.stretch_attributes.length-1].id = stretch_id;
		temp_stretch[this.state.stretch_attributes.length-1].attribute = this.state[`${id}_new_stretch`];
		temp_stretch[this.state.stretch_attributes.length-1].added = true;
		this.setState({stretch_attributes: temp_stretch, stretch_associations: temp_stretch_associations});
			this.state.core_constitution.find((cc) => {
				if (cc.id == id) {
					cc.association_id = stretch_id;
					cc.association_type = "stretch attribute";
				}
			});
			this.setState({[id+"_done"]: true});
	} else {
		this.state.stretch_attributes.find((stretch) => {
			console.log("Looking at stretch " + stretch.id + " and " + association_id);
			if (stretch.id == association_id) {
				temp_stretch_associations.push({});
				console.log("Adding the following stretch stuff " + stretch.attribute + " and " + stretch.id);
				temp_stretch_associations[this.state.stretch_associations.length-1].stretch_attribute = stretch.attribute;
				temp_stretch_associations[this.state.stretch_associations.length-1].stretch_id = stretch.id;
			}
		});
		this.state.core_constitution.find((cc) => {
			if (cc.id == id) {
				cc.association_id = association_id;
				cc.association_type = type;
			}
		});

		this.setState({stretch_associations: temp_stretch_associations});
				this.state.stretch_associations.forEach((association) => {
			console.log("Looping through temp_stretch_associations " + association.stretch_attribute);
		});
	}
	this.setState({[id+"_disabled"]: true});
	if (this.state[`${this.state.current_cc}_ca`]) {
		this.setState({[id+"_answer"]: this.state.current_core_attribute})
	} else if (this.state[`${this.state.current_cc}_sa`]) {
		this.setState({[id+"_answer"]: this.state.current_stretch_attribute});
	} else {
		this.setState({[id + "_added"]: true});
	}
}*/

AddAssociationCallback(id, association_id, type, new_stretch, stretch_id) {
	if (new_stretch) {
		var temp_stretch = this.state.stretch_attributes;
		temp_stretch.push({});
		temp_stretch[this.state.stretch_attributes.length-1].id = stretch_id;
		temp_stretch[this.state.stretch_attributes.length-1].attribute = this.state[`${id}_new_stretch`];
		temp_stretch[this.state.stretch_attributes.length-1].added = true;
		this.setState({stretch_attributes: temp_stretch});
			this.state.core_constitution.find((cc) => {
				if (cc.id == id) {
					cc.association_id = stretch_id;
					cc.association_type = "stretch attribute";
				}
			});
			this.setState({[id+"_done"]: true});
	} else {
		this.state.core_constitution.find((cc) => {
			if (cc.id == id) {
				cc.association_id = association_id;
				cc.association_type = type;
			}
		});
	}
	this.setState({[id+"_disabled"]: true});
	if (this.state[`${this.state.current_cc}_ca`]) {
		this.setState({[id+"_answer"]: this.state.current_core_attribute})
	} else if (this.state[`${this.state.current_cc}_sa`]) {
		this.setState({[id+"_answer"]: this.state.current_stretch_attribute});
	} else {
		this.setState({[id + "_added"]: true});
	}
}

AddAssociation(id, name) {
	var type = "";
	var association_id = 0;
	if (this.state[`${this.state.current_cc}_ca`]) {
		type = "core attribute";
		association_id = this.state.current_core_attribute;
	} else if (this.state[`${this.state.current_cc}_sa`]) {
		type = "stretch attribute";
		association_id = this.state.current_stretch_attribute;
	} 
	if(name === "new_stretch"){
		axios.post(APIHost + '/api/coreConstitution', {
				withCredentials: true,
				type: "association", 
				association_type: "stretch attribute",
				association_id: id,
				stretch_attribute: this.state[`${id}_new_stretch`],
				stretch_description: this.state[`${id}_answer_stretch`],
				add_stretch: true,
				id: id
			}).then((res) => {
				this.AddAssociationCallback(id, association_id, type, true, res.data.stretch_id);
			}).catch((error) =>  {
				console.log("AddAssociation failed " + error);
				this.setState({server_failure: true});
			});
	} else {
		axios.post(APIHost + '/api/coreConstitution', {
			withCredentials: true,
			type: "association", 
			association_type: type,
			association_id: association_id,
			id: id
		}).then(() => {
			this.AddAssociationCallback(id, association_id, type, false);
		}).catch((error) =>  {
			console.log("AddAssociation failed " + error);
			this.setState({server_failure: true});
		});
	}
}

onAddAssociation(event) {
	event.preventDefault();
	this.AddAssociation(event.target.id, event.target.name);
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises();
}

ExercisesCallback(exercise) {
	this.setState({[exercise+"_done"]: true});
}

Exercises(id) {
	var answer = {};
	if (this.state[`${id}_answer_stretch`]) {
		answer = {exercise_id: id, answer: this.state[`${id}_answer_stretch`]};
	} else {
		answer = {exercise_id: id, answer: this.state[`${id}_answer_value`]};
	}
	
	axios.post(APIHost + '/api/answerExercises', {
			withCredentials: true,
			answer: answer,
			chapter_id: this.state.chapterID
		}).then(res => {
			this.ExercisesCallback(id);
		}).catch(error => {
			console.log("Failed to submit the exercises");
			this.setState({submission_failure: true});
		});
}

onSubmitExercise(event) {
	event.preventDefault();
	this.Exercises(event.target.id);
}

LanguageState(event) {
	this.setState({[this.state.current_cc+"_disabled"]: false});
	if (event.target.name == "core_constitution") {
		this.state.core_attributes.forEach((ca) => {
			this.setState({[ca.id+"_checked_ca"]: false});
		});
		this.state.stretch_attributes.forEach((stretch) => {
			this.setState({[stretch.id+"_checked_sa"]: false});
		});
	this.setState({[event.target.id + "_checked"]: !this.state[`${event.target.id}_checked`], 
	current_cc: event.target.id});
	this.state.core_constitution.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked`]) {
				this.setState({[element.id + "_checked"]: false});
			}
		}
	});
	} else if ( event.target.name == "core_attributes") {
		this.setState({[this.state.current_cc+"_answer_value"]: event.target.value, [this.state.current_cc+"_ca"]: true,
			[this.state.current_cc+"_stretch"]: false, [event.target.id + "_checked_ca"]: !this.state[event.target.id + "_checked_ca"],
		[this.state.current_association+"_sa"]: false, current_core_attribute: event.target.id});
		this.state.core_attributes.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked_ca`]) {
					this.setState({[element.id + "_checked_ca"]: false});
				}
			}
		});
		this.state.stretch_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_sa"]) {
					this.setState({[element.id + "_checked_sa"]: false});
				}

		});
	} else if (event.target.name == "stretch_attributes") {
		this.setState({[this.state.current_cc+"_answer_value"]: event.target.value, [this.state.current_cc+"_sa"]: true,
			[this.state.current_cc+"_ca"]: false, [event.target.id + "_checked_sa"]: !this.state[event.target.id + "_checked_sa"],
		[this.state.current_association+"_ca"]: false, current_stretch_attribute: event.target.id});
		this.state.stretch_attributes.forEach((element) => {
		if (element.id != event.target.id) {
			if (this.state[`${element.id}_checked_sa`]) {
					this.setState({[element.id + "_checked_sa"]: false});
				}
			}
		});
		this.state.core_attributes.forEach((element) => {
			if(this.state[element.id + "_checked_ca"]) {
					this.setState({[element.id + "_checked_ca"]: false});
			}
		});
	}
}

CoreDisharmonyCallback(cc_id) {
	this.setState({[cc_id+"_core_disharmony"]: true});
}

CoreDisharmony(cc_id, stretch_id) {
	axios.post(APIHost + '/api/coreDisharmonies', {
		withCredentials: true,
		stretch_id: stretch_id,
		core_constitution_id: cc_id,
		type: "core_constitution"
	}).then(res => {
		this.CoreDisharmonyCallback(cc_id);
	}).catch(error => {
		console.log("Failed to submit the exercises");
		this.setState({submission_failure: true});
	});
}

onCoreDisharmony(event) {
	event.preventDefault();
	this.CoreDisharmony(event.target.id, event.target.name);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_cc = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if (this.state.attribute_associations) {
		var display_language = [];
		for (var i=0; i<this.state.core_constitution.length; i++) {
			if(this.state[`${this.state.core_constitution[i].id}_answer`]) {
			display_language.push(<div><p>Core Constitution Entry: <input onChange={this.LanguageState} name="core_constitution" key={`${this.state.core_constitution[i].id}_cc`} checked={this.state[`${this.state.core_constitution[i].id}_checked`]} id={this.state.core_constitution[i].id} type="checkbox" /> {this.state.core_constitution[i].entry}</p><p><form>
				<p>Pick an Attribute from the right.</p><div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.core_constitution[i].id} name="core_constitution" value={this.state[`${this.state.core_constitution[i].id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<button type="submit" id={this.state.core_constitution[i].id} name="core_constitution" disabled={this.state[`${this.state.core_constitution[i].id}_disabled`]} onClick={this.onAddAssociation}>Update</button><hr className="new" />
  				</p></div> );
			} else {
			display_language.push(<div><p>Core Constitution Entry: <input onChange={this.LanguageState} name="core_constitution" key={`${this.state.core_constitution[i].id}_cc`} checked={this.state[`${this.state.core_constitution[i].id}_checked`]} id={this.state.core_constitution[i].id} type="checkbox" /> {this.state.core_constitution[i].entry}</p><p><form>
				<p>Pick an Attribute from the right.</p><div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={this.state.core_constitution[i].id} name="core_constitution" value={this.state[`${this.state.core_constitution[i].id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<button type="submit" id={this.state.core_constitution[i].id} name="core_constitution" disabled={this.state[`${this.state.core_constitution[i].id}_disabled`]} className="btn btn-primary my-3" onClick={this.onAddAssociation}>Submit</button><hr className="new" />
  				</p></div> );
  			}
		}

		for (var i=0; i<this.state.core_attributes.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="core_attributes" value={this.state.core_attributes[i].attribute} key={`${this.state.core_attributes[i].id}_ca`} checked={this.state[`${this.state.core_attributes[i].id}_checked_ca`]} id={this.state.core_attributes[i].id} type="checkbox" /> {this.state.core_attributes[i].attribute}</p>);
		}

		for (var i=0; i<this.state.stretch_attributes.length; i++) {
			display_cc.push(<p><input onChange={this.LanguageState} name="stretch_attributes" value={this.state.stretch_attributes[i].attribute} key={`${this.state.stretch_attributes[i].id}_sa`} checked={this.state[`${this.state.stretch_attributes[i].id}_checked_sa`]} id={this.state.stretch_attributes[i].id} type="checkbox" /> {this.state.stretch_attributes[i].attribute}</p>);
		}
	
		display_intro.push(<div><p>Below are the entries you provided for your Core Constitution on the left and your Core and Stretch Attributes on the right.
		Here's what to do:
		<ul><li>Click one of the boxes next to one of the entries of your Core Constitution</li>
		<li>Find a Core or Stretch Attribute on the right that you feel you would use when living that entry. It will automatically show-up in the text area.</li>
		<li>Click the Submit button once you have the entry you want</li>
		<li>If you can't find a good match, then leave it blank</li></ul>
		Here's a demonstration of what to do:</p>
		<p><iframe src="https://player.vimeo.com/video/452711073" width="340" height="264" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>
			<hr className="new" /> 		
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Core Constitution:</h5> {display_language}
			</div>
			<div className='col-md-6'>
			<h5>Your Mental Core:</h5> {display_cc}</div>
			</div></div>
 			<p><button onClick={this.onNextExercises} className="custom-btn" type="submit">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Core Constitution Associations</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_button}
{display_video}{display_intro}</div>);
	} else if (this.state.stretch_reliance) {
		var display_stretch_entries = [];
		this.state.stretch_associations.forEach((sa) => {
			if (this.state[`exercise${sa.cc_id}_done`]) {
				var ex_text = null;
				this.state.exercise_answers.find((ea) => {
					if (ea.exercise_id == sa.cc_id) {
						ex_text = ea.text;
					}
				});
				display_stretch_entries.push(<div><p>Stretch Attribute: {sa.stretch_attribute}</p>
				<button className="btn btn-primary my-3" id={sa.cc_id} name={sa.stretch_id}
				 onClick={this.onCoreDisharmony} disabled={this.state[`${sa.cc_id}_core_disharmony`]}>Core Disharmony</button>
				<p><font color="blue">Core Constitution entry:</font> {sa.cc_entry}</p>
				<p><font color="blue">Description:</font> {sa.cc_description}</p>
				<p><font color="blue">Your Response:</font> {ex_text}</p><hr /></div>
				);
			} else {
				display_stretch_entries.push(<div><p>Stretch Attribute: {sa.stretch_attribute}</p>
				<button className="btn btn-primary my-3" id={sa.cc_id} name={sa.stretch_id}
				 onClick={this.onCoreDisharmony} disabled={this.state[`${sa.cc_id}_core_disharmony`]}>Core Disharmony</button>
				<p><font color="blue">Core Constitution entry:</font> {sa.cc_entry}</p>
				<p><font color="blue">Description:</font> {sa.cc_description}</p>
				<form>
				<div className="form-group">
					<textarea onChange={this.onExerciseChange} rows="10" cols="80" id={sa.cc_id} type="text" name="answer_stretch" className="form-control"></textarea>
					</div>
					<button onClick={this.onSubmitExercise} type="submit" className="btn btn-primary my-3" id={sa.cc_id} disabled={this.state[`${sa.cc_id}_done`]} className="custom-btn">Submit</button>
					</form><hr /></div>);
			}
		});
		if (this.state.stretch_associations.length === 0) {
			display_intro.push(<div><p>It doesn't look like you assigned any Stretch Attributes to your Core Constitution entries. Let's consider this for a moment.
			This might be a good thing. The results of the next section will help you understand the implications to this. In that section, we take a look at any Core Constitution
			entries that you weren't able to assign either a Core or a Stretch Attribute to. But, if you are able to assign mostly Core Attributes to your Core Constitution
			that's definitely a good thing. That means you are able to reliably express what you truly stand for you in life. If numerous Stretch Attributes were appearing, that can 
			indicate a strain in expressing what you hold yourself and others to. You will have an opportunity to keep track of this as time goes on and you potentially add Amendments to 
			your Core Constitution</p></div>);
		} else if (this.state.stretch_associations.length > 0 && !this.state.high_stretch) {
			display_intro.push(<div><p>It looks like you assigned Core Attributes and not Stretch Attributes to your Core Constitution most of the time. That's great! An Attribute is your 
			means of expressing the beliefs and values in your Core Constitution. A Core Attribute is something you feel you can express consistently. Looking to those
			when wanting to express who you are means you are more easily expressing your authentic self. If you are looking to your Stretch Attributes too much, that can mean you are struggling
			to express parts of your Core Constitution. This can potentially lead to Core Disharmonies and negative Response Cycles. Even though you assigned mostly Core Attributes, 
			this is still an opportunity to take a look at those times you assigned a Stretch Attribute to an entry. Take a look at those cases below. Is the belief or value you have there 
			something you feel you can express easily? Have you felt strain when trying to express that belief or value? Take some time to write about this in the space provided.
			If you feel there's a potential for a Core Disharmony in the situations you were expressing your Core Constitution entry with a Stretch Attribute, go ahead and click 
			the Core Disharmony button to mark that entry. This will be used in later chapters as we continue to work towards resolving your Core Disharmonies.</p><hr className="new" />{display_stretch_entries}</div>
			)
		} else if (this.state.high_stretch) {
			display_intro.push(<div><p>It looks like you assigned a relatively high number of Stretch Attributes to your entries of your Core Constitution. Let's take a look at this
			together. The challenge here is that the entries in your Core Constitution are what you hold yourself and others to. The Attributes in your Mental Core are your ability 
			to express what you have in your Core Constitution. If you are looking to Stretch Attributes more than you are Core Attributes, this can indicate a strain in 
			expressing your authentic self and can lead to Core Disharmonies. Take some time here to read through the Core Constitution entries and descriptions. 
			Do you find it difficult to truly live that belief or value? Do you feel tension when looking to the Stretch Attribute to express that belief or value?
						If you feel there's a potential for a Core Disharmony in the situations you were expressing your Core Constitution entry with a Stretch Attribute, go ahead and click 
			the Core Disharmony button to mark that entry. This will be used in later chapters as we continue to work towards resolving your Core Disharmonies.
			We will work on this further in future chapters when you work to integrate Stretch Attributes fully into your Mental Core and work to practice expressing
			your Core Constitution consistently.</p><hr className="new" />{display_stretch_entries}</div>);
		}
		var display_next = [];
		display_next.push(<div><p><button onClick={this.onNextExercises} type="submit" className="custom-btn">Next Exercise</button></p></div>);
	
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Reliance on Stretch Attributes Discussion</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_button}
{display_video}{display_intro}{display_next}</div>);
	} else if (this.state.missing_attributes) {
		var display_missing = [];
		if (this.state.missing_attributes_entries.length > 0) {
			 //<button onClick={this.onAddAssociation} type="submit" id={ma.cc_id} name="new_stretch" disabled={this.state[`${ma.cc_id}_added`]}>Add</button>

			this.state.missing_attributes_entries.forEach((ma) => {
				display_missing.push(<div><p><font color="blue">Core Constitution Entry:</font> {ma.cc_entry}</p>
				<p><font color="blue">Description:</font> {ma.cc_description}</p>
				<p><font color="blue">Add new Stretch Attribute:</font></p>
				<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" id={ma.cc_id} type="text" name="new_stretch" value={this.state[`${ma.cc_id}_answer_value`]} className="form-control"></textarea>
  				</div>
  				</form>
  				<p><font color="blue">Share your thoughts on this new addition:</font></p>
  				<form>
				<div className="form-group">
					<textarea onChange={this.onExerciseChange} rows="10" cols="80" id={ma.cc_id} type="text" name="answer_stretch" className="form-control"></textarea>
					</div>
					<button onClick={this.onAddAssociation} className="btn btn-primary my-3" type="submit" id={ma.cc_id} name="new_stretch" disabled={this.state[`${ma.cc_id}_done`]} className="custom-btn">Submit</button>
					</form></div>);
			});
			display_intro.push(<div><p>It looks like you weren't able to assign either a Core or Stretch Attribute to each one of your Core Constitution
			entries. Let's discuss this a bit together here. Your Core Constitution is what you hold yourself and others to. It represents your Authentic Self.
			The Attributes you possess are your way of expressing your Core Constitution. If you were unable to assign either a Core or Stretch Attribute to an entry,
			this could indicate that you don't have a way of expressing that belief or value. This can cause that inner tension and lead to Core Disharmonies and unhealthy
			Response Cycles. Below is a list of the entries you were unable to assign an Attribute. Take a look at the entry and description and think of an 
			Attribute that would help you in expressing that belief or value and enter it in the space provided. This will be added to your Stretch Attribute list and worked
			on in a later chapter as you continue to restructure your psychological structure and move towards Optimal Mental Fitness. In the additional space, discuss 
			how you might use that new Stretch Attribute to express that belief or value. Describe what your take is on that Attribute.</p><hr className="new" />{display_missing}</div>);
		} else {
			display_intro.push(<div><p>It looks like you were able to assign either a Core or Stretch Attribute to each of your Core Constitution entries. That's great! 
			Your Core Constitution is what you hold yourself and others to. It represents you Authentic Self.
			The Attributes you possess are your way of expressing your Core Constitution. You can imagine, if you were unable to assign an Attribute to one of the entries, that
			would mean you don't have the ability (don't possess an Attribute) to express the belief or value. This can lead to inner tension and Core Disharmonies, eventually leading
			to negative Response Cycles. This is something to keep in mind as you continue to develop your Core Constitution throughout the training and start adding Amendments.
			You will want to make sure you have the ability to express that ideal for yourself and others. We will continue to keep an eye on this as you restructure your psychological structure
			and move towards Optimal Mental Fitness</p><hr className="new" /></div>);
		}
		var display_next = [];
		display_next.push(<div><p><button onClick={this.onNextExercises} type="submit" className="custom-btn">Finish</button></p></div>);
	
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Missing Attributes</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_button}
{display_video}{display_intro}{display_next}</div>);
	}

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter37">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter37">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}

						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter37