import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import me from './images/me.jpg';
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { APIHost } from './GlobalVariables';

const ServerFailure = () => (
	<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class ManageSprint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			capacity: 0,
			objectives: [],
			themes: [],
			chapters: [],
			purchased_objectives: [],
			chapter_descriptions: [],
			chapter_points: [],
			sprint: [],
			historic_chapters: [],
			active_themes: [],
			not_active_themes: [],
			completed_themes: [],
			credits: 0,
			sprint_id: 0,
			server_failure: false,
			sprint_info: {},
			loading: true
		}
		this.Toggle = this.Toggle.bind(this);
		this.NewToggle = this.NewToggle.bind(this);
		this.DisplayThemes = this.DisplayThemes.bind(this);
		this.GetThemes = this.GetThemes.bind(this);
		this.PurchaseObjective = this.PurchaseObjective.bind(this);
		this.GetPurchases = this.GetPurchases.bind(this);
		this.AddToSprint = this.AddToSprint.bind(this);
		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.RemoveFromSprint = this.RemoveFromSprint.bind(this);
		this.GetCapacity = this.GetCapacity.bind(this);
		this.GetSprint = this.GetSprint.bind(this);
		this.SubmitSprint = this.SubmitSprint.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.SubmitSprintCallback = this.SubmitSprintCallback.bind(this);
		this.StopChecked = this.StopChecked.bind(this);
		this.GetUsersHistoricChapters = this.GetUsersHistoricChapters.bind(this);
		this.GetCompletedThemes = this.GetCompletedThemes.bind(this);
		this.DisplayIntro = this.DisplayIntro.bind(this);
		this.UpdateFirstVisit = this.UpdateFirstVisit.bind(this);
		this.GetDoneChapters = this.GetDoneChapters.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		var all_promises = [];
		var purchase_flag = false;
		if (this.props.location.state != null) {
			var historic_chapters_promise = this.GetUsersHistoricChapters().then(data => {
				this.setState({ historic_chapters: data.data.chapters })
				return data;
			}).catch((error) => {
				console.log("GetUsersHistoricChapters failed " + error);
				this.setState({ server_failure: true });
			});

			var active_themes_promise = this.GetActiveThemes().then(data => {
				this.setState({ active_themes: data.data.themes });
			}).catch((error) => {
				console.log("GetActiveThemes failed " + error);
				this.setState({ server_failure: true });
			});

			var completed_themes_promise = this.GetCompletedThemes().then(data => {
				if (data.data != "nothing completed") {
					this.setState({ completed_themes: data.data.themes });
				}
			}).catch((error) => {
				console.log("GetCompletedThemes failed " + error);
				this.setState({ server_failure: true });
			});

			var sprint_promise = this.GetSprint().then((data) => {
				this.setState({ sprint: data.data.sprint, sprint_id: data.data.sprint_info.sprint_id });

				/*if (data.data.sprint_info.no_chapters != true) {
					this.setState({sprint: data.data.sprint, sprint_id: data.data.sprint_info.sprint_id});
				} else {
					this.setState({sprint_id: data.data.sprint_info.sprint_id});
				}*/
				return data;
			}).catch((error) => {
				console.log("GetSprint failed " + error);
				this.setState({ server_failure: true });
			});

			var theme_promise = this.GetThemes().then(data => {
				let temp_themes = data.data.themes;
				temp_themes.forEach((element) => {
					element.plus = true;
					element.hide = true;
				});
				this.setState({ themes: temp_themes });
				return data;
			}).catch((error) => {
				console.log("GetThemes failed " + error);
				this.setState({ server_failure: true });
			});

			var objective_promise = this.GetObjectives().then(data => {
				let local_objectives = data.data.objectives;
				local_objectives.forEach((element) => {
					element.plus = true;
					element.hide = true;
					element.purchased = false;
					element.added = false;
				});
				this.setState({ objectives: local_objectives })
				return data;
			}).catch((error) => {
				console.log("GetObjectives failed " + error);
				this.setState({ server_failure: true });
			});

			var purchase_promise = this.GetPurchases().then(data => {
				if (data.data.message === "none") {
					console.log("didn't purchase anything");
				} else {
					purchase_flag = true;
					this.setState({ purchased_objectives: data.data.purchased_objectives });
				}
				return data;
			}).catch(error => {
				console.log("GetPurchases failed " + error);
				this.setState({ server_failure: true });
			});

			var chapters_promise = this.GetChapters().then(data => {
				this.setState({ chapters: data.data.chapters });
				return data;
			}).catch(error => {
				console.log("GetChapters failed " + error);
				this.setState({ server_failure: true });
			});

			var credits_promise = this.GetCredits().then(data => {
				this.setState({ credits: data.data.credits });
				return data;
			}).catch(error => {
				console.log("GetCredits failed " + error);
				this.setState({ server_failure: true });
			});

			var capacity_promise = this.GetCapacity().then(data => {
				this.setState({ capacity: data.data.capacity });
				return data;
			}).catch(error => {
				console.log("GetCapacity failed " + error);
				this.setState({ server_failure: true });
			});

			var done_chapters_promise = this.GetDoneChapters().then(data => {
				this.setState({ done_chapters: data.data.done_chapters });
				return data;
			}).catch(error => {
				console.log("GetDoneChapters failed " + error);
				this.setState({ server_failure: true });
			});

			all_promises.push(done_chapters_promise);
			all_promises.push(sprint_promise);
			all_promises.push(theme_promise);
			all_promises.push(objective_promise);
			all_promises.push(purchase_promise);
			all_promises.push(chapters_promise);
			all_promises.push(credits_promise);
			all_promises.push(capacity_promise);
			all_promises.push(historic_chapters_promise);
			all_promises.push(active_themes_promise);
			all_promises.push(completed_themes_promise);
			Promise.all(all_promises).then(() => {
				window.credits = this.state.credits;
				if (purchase_flag) {
					let local_objectives = this.state.objectives;
					var flag = false;
					local_objectives.forEach((element) => {
						flag = false;
						for (var j = 0; j < this.state.purchased_objectives.length; j++) {
							if (this.state.purchased_objectives[j].objective_id == element.id) {
								flag = true;
							}
						}
						if (flag) {
							element.purchased = true;
						} else {
							element.purchased = false;
						}
					});
					this.setState({ objectives: local_objectives });
				}

				let local_chapters = this.state.chapters;
				let local_objectives = this.state.objectives;
				var points = 0;
				if (this.state.sprint.length > 0) {
					let local_sprint = this.state.sprint;
					local_sprint.forEach((sp) => {
						this.state.chapters.find((element) => {
							if (element.id == sp.chapter_id) {
								sp.description = element.description;
								sp.objective_id = element.objective_id;
								sp.points = element.points;
								sp.chapter_id = element.id;
							}
						});

					});
					this.setState({ sprint: local_sprint });
				}
				local_objectives.forEach((element) => {
					points = 0;
					for (var j = 0; j < this.state.chapters.length; j++) {
						if (this.state.chapters[j].objective_id == element.id) {
							points = parseInt(points) + parseInt(this.state.chapters[j].points);
						}
					}
					element.points = points;
				});
				local_chapters.forEach((element) => {
					this.state.sprint.find((el) => {
						if (el.chapter_id == element.id) {
							element.in_sprint = true;
							element.in_current_sprint = true;
							local_objectives.find((obj) => {
								if (obj.id == element.objective_id) {
									obj.added = true;
								}
							});
						}
					});
				});
				var local_not_active = [];
				let local_active = this.state.active_themes;
				var cnt = 0;
				var flag = false;
				this.state.themes.forEach((element) => {
					flag = false;
					this.state.active_themes.find((theme) => {
						if (element.id == theme.id) {
							flag = true;
						}
					});
					if (!flag) {
						element.plus = true;
						element.hide = true;
						local_not_active.push(element);

					} else {
						local_active[cnt].description = element.description;
						local_active[cnt].plus = true;
						local_active[cnt].hide = true;
						cnt++;
					}
				});
				// A theme is either active, completed, or neither - the below takes the not active themes from above and
				// identifies if they are completed - so if it is in the completed themes list, don't include it as not active 
				let another_not_active = [];
				local_not_active.forEach((element) => {
					flag = false;
					this.state.completed_themes.find((theme) => {
						if (element.id == theme.theme_id) {
							flag = true;
						}
					});
					if (!flag) {
						another_not_active.push(element);
					}
				});
				this.setState({ active_themes: local_active, not_active_themes: another_not_active, chapters: local_chapters, objectives: local_objectives, loading: false })
			});
		}
	}

	componentWillUnmount() {
		if (this._asyncRequest) {
			this._asyncRequest.cancel();
		}
	}

	PurchaseObjective(event) {
		const id = event.currentTarget.getAttribute('objectiveId');
		const theme = event.currentTarget.getAttribute('theme');
		axios.post(APIHost + '/api/purchaseObjective', {
			id: id,
			withCredentials: true
		}).then(res => {
			let temp_objectives = this.state.objectives;
			temp_objectives.find((element) => {
				if (element.id == id) {
					element.purchased = true;
				}
			});
			this.setState({
				objectives: temp_objectives
			});

		}).catch(error => {

		});
	}

	async FirstVisits() {
		const res = await axios.get(APIHost + '/api/firstVisits', { withCredentials: true });
		return await res;
	}

	async GetDoneChapters() {
		const res = await axios.get(APIHost + '/api/getAllDoneChapters', { withCredentials: true });
		return await res;
	}

	async GetUsersHistoricChapters() {
		const res = await axios.get(APIHost + '/api/getAllHistoricChapters', { withCredentials: true });
		return await res;
	}

	async GetThemes() {
		const res = await axios.get(APIHost + '/api/getThemes', { withCredentials: true });
		return await res;
	}

	async GetObjectives() {
		const res = await axios.get(APIHost + '/api/getObjectives', { withCredentials: true });
		return await res;
	}

	async GetChapters() {
		const res = await axios.get(APIHost + '/api/getChapters', { withCredentials: true });
		return await res;
	}

	async GetSprint() {
		const res = await axios.post(APIHost + '/api/getSprint', { withCredentials: true });
		return await res;
	}

	async GetCredits() {
		const res = await axios.get(APIHost + '/api/getCredits', { withCredentials: true });
		return await res;
	}

	async GetPurchases() {
		const res = await axios.get(APIHost + '/api/purchasedObjectives', { withCredentials: true });
		return await res;
	}

	async GetCapacity() {
		const res = await axios.get(APIHost + '/api/getCapacity', { withCredentials: true });
		return await res;
	}

	async GetActiveThemes() {
		const res = await axios.get(APIHost + '/api/getActiveThemes', { withCredentials: true });
		return await res;
	}

	async GetCompletedThemes() {
		const res = await axios.get(APIHost + '/api/getCompletedThemes', { withCredentials: true });
		return await res;
	}

	StopChecked(event) {
		this.checked = false;
		event.preventDefault();
	}

	DisplayThemes(themes, which_theme) {
		let for_display = [];
		var i = 0;
		var j = 0;
		var k = 0;
		var objective_cnt = 1;
		var flag = false;
		var status = null;
		var has_been_in_sprint = false;

		for (i = 0; i < themes.length; i++) {
			let objectives = [];
			for (j = 0; j < this.state.objectives.length; j++) {
				let chapters = [];
				if (themes[i].id === this.state.objectives[j].theme_id) {
					for (k = 0; k < this.state.chapters.length; k++) {
						if (this.state.objectives[j].id === this.state.chapters[k].objective_id) {
							var chapter_name = "objective" + j + "chapter" + k;
							if (this.state.chapters[k].in_sprint) {
								this.state.sprint.find((element) => {
									if (element.chapter_id == this.state.chapters[k].id) {
										status = element.status;
									}
								});
								if (this.state.has_dependency && this.state.dep_chapter == this.state.chapters[k].id) {
									var dep_des_display = [];
									this.state.dependencies_description.forEach((desc) => {
										dep_des_display.push(<li>{desc}</li>);
									});
									chapters.push(<div><p><li><input className="mr-2" onClick={this.RemoveFromSprint} objectiveId={this.state.objectives[j].id} theme={i + 1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} checked />
										{this.state.chapters[k].description} ({this.state.chapters[k].type}) ({this.state.chapters[k].points} points)
			                         </li><Alert variant="danger" onClose={() => { this.setState({ has_dependency: false }); }} dismissible>
											<Alert.Heading>This chapter has dependencies you haven't completed! You can add it, but won't be able to start it until you've completed the dependencies.</Alert.Heading>
											<p>The list of chapters you need to complete before this chapter is:
									          <ul>{dep_des_display}</ul>
											</p>
										</Alert></p></div>);
								} else {
									if (status == "in progress" || status == "done") {
										chapters.push(<li><input className="mr-2" onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i + 1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} checked />
											{this.state.chapters[k].description} ({this.state.chapters[k].type}) ({this.state.chapters[k].points} points)
				                         </li>);
									} else {
										chapters.push(<li><input className="mr-2" onClick={this.RemoveFromSprint} objectiveId={this.state.objectives[j].id} theme={i + 1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} checked />
											{this.state.chapters[k].description} ({this.state.chapters[k].type}) ({this.state.chapters[k].points} points)
				                         </li>);
									}
								}
								flag = true; // This is not needed
							} else {
								this.state.historic_chapters.find((element) => {
									if (element.chapter_id == this.state.chapters[k].id) {
										has_been_in_sprint = true;
									}
								});
								if (has_been_in_sprint || which_theme == 'not_active') {
									chapters.push(<li><input className="mr-2" onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i + 1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} />
										{this.state.chapters[k].description} ({this.state.chapters[k].type}) ({this.state.chapters[k].points} points)
			                         </li>);
								} else if (!this.state.objectives[j].purchased) {
									chapters.push(<li><input className="mr-2" onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i + 1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} />
										{this.state.chapters[k].description} ({this.state.chapters[k].type}) ({this.state.chapters[k].points} points)
			                         </li>);
								} else {
									chapters.push(<li><input className="mr-2" onClick={this.AddToSprint} objectiveId={this.state.objectives[j].id} theme={i + 1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} />
										{this.state.chapters[k].description} ({this.state.chapters[k].type}) ({this.state.chapters[k].points} points)
			                         </li>);
								}
								has_been_in_sprint = false;
							}
						}
					}
					var name = "objective" + which_theme + (objective_cnt);
					let button_content = [];
					if (!this.state.objectives[j].purchased) {
						if (this.state.credits > 0) {
							button_content.push(<div align="center"><button className="btn btn-primary" objectiveId={this.state.objectives[j].id} id={[`purchase${j}`]} theme={i + 1} onClick={this.PurchaseObjective}>Unlock Objective</button></div>);
						} else {
							button_content.push(<Popup trigger={<button className="btn btn-primary">Unlock Objective</button>} position="right center">
								<div>Please <Link to="/getCredits">Purchase</Link> credits</div>
							</Popup>);
						}
					}
					objectives.push(<div className="panel-body">
						<h5 className="panel-title">
							<i role="button"  name={name}
								onClick={this.Toggle} id={this.state.objectives[j].id} className={this.state.objectives[j].plus ? 'fa fa-plus plus-minus m-0' : 'fa fa-minus plus-minus m-0'}></i>
							{this.state.objectives[j].description} (total points {this.state.objectives[j].points}){button_content}
						</h5>
						<div className={this.state.objectives[j].hide ? 'hide' : ''}>
							<div className="panel-objective">
								<ul>{chapters}</ul></div></div></div>);
					objective_cnt++;
				}
			}

			var obj_name = "theme" + which_theme + (i + 1);
			//var obj_name="theme"+(i+1);
			for_display.push(<div className="panel-group"><div className="panel-heading d-flex align-items-center">
				<i role="button" name={obj_name} id={themes[i].id} onClick={(e) => { this.NewToggle(e, themes, which_theme) }} className={themes[i].plus ? 'fa fa-plus plus-minus m-0' : 'fa fa-minus plus-minus m-0'}></i>
				<h4>{themes[i].description}
			</h4></div><div className={themes[i].hide ? 'hide' : ''}>{objectives}</div></div>);
		}
		return for_display;
	}

	NewToggle(event, themes, which_theme) {
		event.preventDefault();
		console.log("Name is " + event.target.name);
		const id = event.currentTarget.getAttribute('id');
		themes.find((element) => {
			if (element.id == id) {
				element.plus = !element.plus;
				element.hide = !element.hide;
			}
		});
		if (which_theme == 'active') {
			this.setState({ active_themes: themes });
		} else {
			this.setState({ not_active_themes: themes });
		}
	}

	Toggle(event) {
		event.preventDefault();

		const attribute = event.currentTarget.getAttribute('name');
		const id = event.currentTarget.getAttribute('id');
		const themereg = /theme/;
		const objectivereg = /objective/;
		let temp_themes = this.state.themes;
		let temp_objectives = this.state.objectives;
		if (themereg.test(attribute)) {
			temp_themes.find((element) => {
				if (element.id == id) {
					element.plus = !element.plus;
					element.hide = !element.hide;
				}
			});
			this.setState({ themes: temp_themes });
		} else if (objectivereg.test(attribute)) {
			temp_objectives.find((element) => {
				if (element.id == id) {
					element.hide = !element.hide;
					element.plus = !element.plus;
				}
			});
			this.setState({ objectives: temp_objectives });
		}
	}

	RemoveFromSprint(event) {
		const objective_id = event.currentTarget.getAttribute('objectiveId');
		const chapter_id = event.currentTarget.getAttribute('chapterId');
		const theme = event.currentTarget.getAttribute('theme');
		const name = event.currentTarget.getAttribute('name');
		let temp_chapters = [];
		var flag = false;
		if (name) {
			var cnt = 0;
			temp_chapters = this.state.chapters;
			temp_chapters.forEach((element) => {
				if (element.id == chapter_id) {
					element.in_sprint = false;
				}
			})
			this.setState({ chapters: temp_chapters });
			let temp_sprint = [];
			for (var i = 0; i < this.state.sprint.length; i++) {
				if (this.state.sprint[i].chapter_id == chapter_id && this.state.sprint[i].objective_id == objective_id) {
					continue;
				} else {
					temp_sprint.push({});
					temp_sprint[cnt].description = this.state.sprint[i].description;
					temp_sprint[cnt].points = this.state.sprint[i].points;
					temp_sprint[cnt].objective_id = this.state.sprint[i].objective_id;
					temp_sprint[cnt].sprint_id = this.state.sprint_id;
					temp_sprint[cnt].chapter_id = this.state.sprint[i].chapter_id;
					temp_sprint[cnt].status = this.state.sprint[i].status;
					temp_sprint[cnt].status = this.state.sprint[i].status;
					cnt++;
				}
			}
			//flag = false;
			let local_objectives = this.state.objectives;
			temp_sprint.find((element) => {
				if (element.objective_id == objective_id) {
					flag = true;
				}
			});
			if (!flag) {
				local_objectives.find((element) => {
					if (element.id == objective_id) {
						element.added = false;
					}
				});
			}
			this.setState({
				sprint: temp_sprint,
				objectives: local_objectives
			});

		} else {

			var cnt = 0;
			for (var i = 0; i < this.state.sprint.length; i++) {
				if (this.state.sprint[i].objective_id == objective_id) {
					continue;
				} else {
					temp_chapters.push({});
					temp_chapters[cnt].description = this.state.sprint[i].description;
					temp_chapters[cnt].points = this.state.sprint[i].points;
					temp_chapters[cnt].objective_id = objective_id;
					temp_chapters[cnt].sprint_id = this.state.sprint_id;
					temp_chapters[cnt].status = this.state.sprint[i].status;
					temp_chapters[cnt].chapter_id = this.state.sprint[i].chapter_id;
					temp_chapters[cnt].status = this.state.sprint[i].status;
					cnt++;
				}
			}
			let local_objectives = this.state.objectives;
			local_objectives.find((element) => {
				if (element.id == objective_id) {
					element.added = false;
				}
			});
			this.setState({ sprint: temp_chapters, objectives: local_objectives });
			/*this.setState({sprint_chapters: temp_chapters,
						["theme"+theme+"Objective"+objective_id+"Added"]: false})*/
			temp_chapters = this.state.chapters;
			for (var i = 0; i < temp_chapters.length; i++) {
				temp_chapters[i].description = this.state.chapters[i].description;
				temp_chapters[i].objective_id = this.state.chapters[i].objective_id;
				temp_chapters[i].id = this.state.chapters[i].id;
				temp_chapters[i].points = this.state.chapters[i].points;
				temp_chapters[i].status = this.state.chapters[i].status;
				if (temp_chapters[i].objective_id == objective_id) {
					temp_chapters[i].in_sprint = false;
				}
			}
			this.setState({ chapters: temp_chapters });
		}
		if (this.state.dep_chapter == chapter_id) {
			this.setState({ has_dependency: false, dep_chapter: null, dependencies_description: null });
		}
	}
	// I removed the if name else part of this - I have no idea why it was there and it doesn't  seem to ever be called
	AddToSprint(event) {
		const chapter_id = event.currentTarget.getAttribute('chapterId');
		const name = event.currentTarget.getAttribute('name');
		const objective_id = event.currentTarget.getAttribute('objectiveId');
		var dep_cnt = 0;
		var check_flag = true;
		var dep_description = [];
		var dep_flag = false;
		this.state.chapters.find((ch) => {
			if (ch.id == chapter_id) {
				if (ch.dependencies) {
					ch.dependencies.forEach((dep) => {
						this.state.chapters.find((chapter) => {
							flag = false;
							if (chapter.id === dep) {
								this.state.done_chapters.find((done_ch) => {
									if (done_ch.chapter_id === dep) {
										flag = true;
									}
								});
								if (!flag) {
									dep_description.push(chapter.description);
								}
							}
						});
						this.state.done_chapters.find((done) => {
							if (done.chapter_id == dep) {
								dep_cnt++;
							}
						});
					});
					if (dep_cnt != ch.dependencies.length) {
						//this.checked = false;
						//check_flag = false;
						event.preventDefault();
						this.setState({ has_dependency: true, dep_chapter: chapter_id, dependencies_description: dep_description });
					}
				}
			}
		});
		var flag = false;
		var cnt = this.state.sprint.length;
		var temp_sprint = this.state.sprint;
		let temp_chapters = this.state.chapters;
		if (check_flag) {
			temp_chapters.forEach((element) => {
				if (element.id == chapter_id) {
					element.in_sprint = true;
				}
			});
			this.setState({ chapters: temp_chapters });
			for (var i = 0; i < this.state.chapters.length; i++) {
				if (this.state.chapters[i].id == chapter_id) {
					temp_sprint.push({});
					temp_sprint[cnt].sprint_id = this.state.sprint_id;
					temp_sprint[cnt].description = this.state.chapters[i].description;
					temp_sprint[cnt].points = this.state.chapters[i].points;
					temp_sprint[cnt].objective_id = this.state.chapters[i].objective_id;
					temp_sprint[cnt].chapter_id = this.state.chapters[i].id;
					temp_sprint[cnt].status = "open";
				} else {
					continue;
				}
			}
			let local_objectives = this.state.objectives;
			local_objectives.find((element) => {
				if (objective_id == element.id) {
					element.added = true;
				}
			});

			this.setState({ sprint: temp_sprint, objectives: local_objectives });
		}
	}

	DisplaySprint() {
		var for_display = [];
		var row = [];
		var points = 0;
		var sprint_rows = [];
		var points = 0;
		for (var i = 0; i < this.state.sprint.length; i++) {
			row = [];
			var flag = false;
			if (this.state.sprint[i].status == "open") {
				row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
				points = points + this.state.sprint[i].points;
				flag = true;
			} else {
				row.push(<td></td>);
			}
			if (this.state.sprint[i].status == "in progress") {
				row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
				points = points + this.state.sprint[i].points;
				flag = true;
			} else {
				row.push(<td></td>);
			}
			if (this.state.sprint[i].status == "done") {
				row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
				points = points + this.state.sprint[i].points;
				flag = true;
			} else {
				row.push(<td></td>);
			}
			if (flag) {
				sprint_rows.push(<tr>{row}</tr>);
			}
		}
		for_display.push(<table className="table table-bordered"><thead><tr><th>To Do (points)</th><th>In Progress (points)</th><th>Done (points)</th>
		</tr></thead><tbody>{sprint_rows}</tbody></table>);
		if (points > this.state.capacity) {
			for_display.push(<font color="red"><h4>Total points: {points} <br />Capacity: {this.state.capacity}</h4><h5>Looks like you are stretching a bit. Good for you! You Got This!</h5></font>);
		} else {
			for_display.push(<h5>Total points: {points} <br />Capacity: {this.state.capacity}</h5>);
		}
		if (this.state.sprint.length > 0) {
			for_display.push(
				<div className="form-input light-rounded-buttons mt-30">
					<button className="main-btn light-rounded-two" onClick={this.onSubmit}>Update Sprint</button>
				</div>
			);
		}

		return for_display;
	}

	UpdateFirstVisit() {
		axios.post(APIHost + '/api/firstVisits', {
			update: 'done',
			withCredentials: true
		}).then(res => {

		}).catch(error => {

		});
	}

	DisplayIntro() {
		var for_display = [];
		for_display.push(<div><iframe src="https://player.vimeo.com/video/507310065" width="400" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
		<p>Click <Link history={this.props.history} to={{ pathname: '/History', state: { loggedIn: true } }}>here</Link> to see completed themes.</p>
		<p>A "C" next to the chapter name means it is part of the "Core" of Mental Fitness Training and is necessary to complete along your journey. An "S" stands for "supplemental" 
		meaning it is a chapter with helpful and crucial information but not mandatory to complete.</p></div>);
		return for_display;
	}

	SubmitSprintCallback(status) {
		if (status) {
			this.props.history.push("/Home");
		} else {
			console.log("couldn't update the sprint");
		}
	}

	SubmitSprint(SubmitSprintCallback) {
		axios.post(APIHost + '/api/updateSprint', {
			sprint: this.state.sprint,
			sprint_id: this.state.sprint_id,
			withCredentials: true
		}).then(res => {
			SubmitSprintCallback(true);
		}).catch(error => {

		});
	}

	onSubmit(event) {
		event.preventDefault();
		this.SubmitSprint(this.SubmitSprintCallback);
	}

	render() {
		if (this.props.location.state == null) {
			return (<Redirect to='/Home' />);
		} else {
			return (
				<div className="Content">
					<Header history={this.props.history} preview={true} loggedIn={true} />
					<div className="container_profile pt-3 pb-5">
						<div className="row">
							{this.state.server_failure && <ServerFailure />}
							<aside className="col-md-6">
								<section className="two-sided-box">
									<div className="two-sided-box-in">
										<div className="col-md-12">

											{!this.state.loading && this.DisplaySprint()}
										</div>
									</div>
								</section>
							</aside>

							<div className="col-md-6">
								<section className="theme two-sided-box">
									<div className="container two-sided-box-in">
										{!this.state.loading && this.DisplayIntro()}
										<div className="col-md-12 col-md-offset-1">

											<h4 className="my-3">
												Active Themes
            			</h4>

											{!this.state.loading && this.DisplayThemes(this.state.active_themes, 'active')}
											<hr />
											<h4 className="my-3">Future Themes</h4>
											{!this.state.loading && this.DisplayThemes(this.state.not_active_themes, 'not_active')}

										</div> {/*col-md-10*/}
									</div> {/*container*/}
								</section>
							</div>
						</div>
					</div>
					<Footer history={this.props.history} preview={true} loggedIn={true} />
				</div>
			);
		}
	}

}

export default ManageSprint;