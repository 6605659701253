import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
import {UpdateLastActivity} from '../utilities.js';
		
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter9 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 9,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		answers: [],
		answers_before: [],
		answers_after: [],
		display_video: false,
		loading: true,
		finished: false,
		dl_exercises: false,
		pl_exercises: false,
		dl_describe_exercises: false,
		pl_describe_exercises: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		finished_daily_life_describe: false,
		finished_past_life_describe: false,
		viruses: [],
		questions: [],
		added_daily: false,
		added_past: false,
		dis_button: false,
		chapter_video: "https://player.vimeo.com/video/447783952"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetDailyLife = this.GetDailyLife.bind(this);
this.GetPastLife = this.GetPastLife.bind(this);
this.onSubmitDL = this.onSubmitDL.bind(this);
this.SubmitDL = this.SubmitDL.bind(this);
this.SubmitPLCallback = this.SubmitPLCallback.bind(this);
this.onSubmitPL = this.onSubmitPL.bind(this);
this.SubmitDLCallback = this.SubmitDLCallback.bind(this);
this.onSubmitDLDescribe = this.onSubmitDLDescribe.bind(this);
this.SubmitDLDescribe = this.SubmitDLDescribe.bind(this);
this.onRemoveEntry = this.onRemoveEntry.bind(this);
this.RemoveEntry = this.RemoveEntry.bind(this);
this.RemoveDLCallback = this.RemoveDLCallback.bind(this);
this.RemovePLCallback = this.RemovePLCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.SubmitPLDescribeCallback = this.SubmitPLDescribeCallback.bind(this);
this.onSubmitPLDescribe = this.onSubmitPLDescribe.bind(this);
this.SubmitPLDescribeCallback = this.SubmitPLDescribeCallback.bind(this);
this.SubmitDLDescribeCallback = this.SubmitDLDescribeCallback.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	UpdateLastActivity();
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (this.state.chapter_stage == "video") {
			this.setState({display_video: true, 
			pl_exercises: false, dl_exercises: false, display_exercises: false, dl_describe_exercises: false, pl_describe_exercises: false, started: true});
		} else if (this.state.chapter_stage == "daily_life") {
			this.setState({display_video: false, 
			dl_exercises: true, pl_exercises: false, display_exercises: true, dl_describe_exercises: false, pl_describe_exercises: false, started: true});
		} else if (this.state.chapter_stage == "past_life")  {
			this.setState({display_video: false, 
			dl_exercises: false, pl_exercises: true, display_exercises: true, dl_describe_exercises: false, pl_describe_exercises: false, started: true});				
		} else if (this.state.chapter_stage == "daily_life_describe") {
			this.setState({display_video: false, 
			dl_exercises: false, pl_exercises: false, display_exercises: true, dl_describe_exercises: true, pl_describe_exercises: false, started: true});
		} else if (this.state.chapter_stage == "past_life_describe")  {
			this.setState({display_video: false, 
			dl_exercises: false, pl_exercises: false, display_exercises: true, dl_describe_exercises: false, pl_describe_exercises: true, started: true});				
		} else if (this.state.chapter_stage == "done") {
		this.setState({display_before_questions: false, display_exercises: false, display_video: false, 
			display_after_questions: false, display_exercises: false, dl_describe_exercises: false, pl_describe_exercises: false, finished: true, started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var daily_life_promise = this.GetDailyLife().then((daily_life) => {
		var cnt = 0;
		daily_life.data.daily_life.forEach((element) => {
			if (element.description != null) {
				cnt++;
				this.setState({[element.id + "_done"]: true});
			}
		});
		if (daily_life.data.daily_life.length > 0) {
			this.setState({added_daily: true});
		}
		if (cnt === daily_life.data.daily_life.length) {
			this.setState({daily_life: daily_life.data.daily_life,
				finished_daily_life_describe: true});
		} else {
			this.setState({daily_life: daily_life.data.daily_life});
		}
	}).catch((error) => {
		console.log("GetDailyLife failed " + error);
		this.setState({server_failure: true});
	});

	var past_life_promise = this.GetPastLife().then((past_life) => {
		var cnt = 0;
		past_life.data.past_life.forEach((element) => {
			if (element.description != null) {
				cnt++;
				this.setState({[element.id + "_done"]: true});
			}
		});
		if (past_life.data.past_life.length > 0) {
			this.setState({added_past: true});
		}
		if (cnt === past_life.data.past_life.length) {
			this.setState({past_life: past_life.data.past_life,
				finished_past_life_describe: true});
		} else {
			this.setState({past_life: past_life.data.past_life});
		}
	}).catch((error) => {
		console.log("GetPastLife failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(daily_life_promise);
	all_promises.push(past_life_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var temp_daily = this.state.daily_life;
		var temp_past =  this.state.past_life;

		temp_daily.forEach((td) => {
			if (td.description != null) {
				td.description_set = true;
			} else {
				td.description_set = false;
			}
		});
		temp_past.forEach((tp) => {
			if (tp.description != null) {
				tp.description_set = true;
			} else {
				tp.description_set = false;
			}
		});
		this.setState({loading: false, past_life: temp_past, daily_life: temp_daily});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetDailyLife() {
	const res = await axios.get(APIHost + '/api/dailyLife',
		{params: {withCredentials: true}});
	return await res;
}

async GetPastLife() {
	const res = await axios.get(APIHost + '/api/pastLife',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
event.persist();
	if (event.target.name == "dl_describe") {
		var temp_daily = this.state.daily_life;
		var cnt = 0;
		this.state.daily_life.find((element) => {
			if (element.id == event.target.id) {
				temp_daily[cnt].id = event.target.id;
				temp_daily[cnt].entry = this.state.daily_life[cnt].entry;
				temp_daily[cnt].description = event.target.value;
			}
			cnt++;
		});
		this.setState({daily_life: temp_daily});
		this.setState({[event.target.name]: event.target.value, [event.target.id+"_done"]: false, 
		[event.target.id+"_description"]: event.target.value});
	} else if (event.target.name == "pl_describe") {
		var temp_past = this.state.past_life;
		var cnt = 0;
		this.state.past_life.find((element) => {
			if (element.id == event.target.id) {
				temp_past[cnt].id = event.target.id;
				temp_past[cnt].entry = this.state.past_life[cnt].entry;
				temp_past[cnt].description = event.target.value;
			}
			cnt++;
		});
		this.setState({past_life: temp_past});
		this.setState({[event.target.name]: event.target.value, [event.target.id+"_done"]: false, 
		[event.target.id+"_description"]: event.target.value});
	} else {
		this.setState({[event.target.name]: event.target.value, current_answer: event.target.value});
	}
}

DisplayFinished(history) {
	var display = [];
	var display_current = [];
	var display_daily_life = [];
	var display_past_life = [];

	for (var i=0; i<this.state.daily_life.length; i++) {
		display_daily_life.push(<div className="display-linebreak"><p>{i+1}) {this.state.daily_life[i].entry}</p>
		<p>{this.state.daily_life[i].description}</p></div>);
	}

	for (var i=0; i<this.state.past_life.length; i++) {
		display_past_life.push(<div className="display-linebreak"><p>{i+1}) {this.state.past_life[i].entry}</p>
		<p>{this.state.past_life[i].description}</p></div>);
	}
	var his_text = '';
	if(history === "history") {
		his_text = "Here is how you described your daily and past life events";
	} else {
		his_text = "Congrats! You have finished the chapter!";
	}
		display.push(<div className="col-md-12"><h1>{his_text}</h1>
				 <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
		<div className='container'>
    	<div className='row'>  
		<div className='col-md-6'><h3>Your Daily Life Events:</h3>
		<p>{display_daily_life}</p>
		</div>
		<div className='col-md-6'><h3>Your Past Life Events:</h3>
		<p>{display_past_life}</p>
		</div></div></div><h3 className="my-2">Chapter Video:</h3><p><div className="embed-responsive embed-responsive-4by3 service-video">
    <iframe src={this.state.chapter_video} width="320" height="180" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div></p>
		<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);		
		return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, 
			dl_exercises: true, pl_exercises: false, dl_describe_exercises: false, pl_describe_exercises: false, display_exercises: true, started: true});
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			percent_complete: .2,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("daily_life", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
		<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
		<button onClick={this.onAddNote}>Submit</button> </div>
		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
		<div><ul>{this.state.display_notes}</ul></div>
		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitDLCallback(id) {
	var temp_dl = this.state.daily_life;
	temp_dl.push({});
	temp_dl[this.state.daily_life.length-1].entry = this.state.dl;
	temp_dl[this.state.daily_life.length-1].id = id;
	this.setState({daily_life: temp_dl, dl: "", added_daily: true});
}

SubmitDL(event, callback) {
	axios.post(APIHost + '/api/dailyLife', {
			withCredentials: true,
			entry: this.state.current_answer,
			type: "entry"
		}).then(res => {
			this.SubmitDLCallback(res.data.id);
		}).catch(error => {
			console.log("Failed to submit a daily_life entry " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitDL(event) {
	event.preventDefault();
	this.SubmitDL();
}

SubmitPLCallback(id) {
	var temp_pl = this.state.past_life;
	temp_pl.push({});
	temp_pl[this.state.past_life.length-1].entry = this.state.pl;
	temp_pl[this.state.past_life.length-1].id = id;
	this.setState({past_life: temp_pl, pl: "", added_past: true});
}

SubmitPL() {
	axios.post(APIHost + '/api/pastLife', {
			withCredentials: true,
			entry: this.state.current_answer,
			type: "entry"
		}).then(res => {
			this.SubmitPLCallback(res.data.id);
		}).catch(error => {
			console.log("Failed to submit a past life entry " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitPL(event) {
	event.preventDefault();
	this.SubmitPL();
}

SubmitDLDescribeCallback(id) {
	var temp_dl = this.state.daily_life;
	var cnt = 0;

	temp_dl.find((element) => {
		if (element.id == id) {
			element.description = this.state.dl_describe;
		}
	});
	temp_dl.forEach((element) => {
		if (element.description != null) {
			cnt++;
		}
	});
	if (cnt === this.state.daily_life.length) {
		this.setState({daily_life: temp_dl, dl_describe: "", finished_daily_life_describe: true});
	} else {
		this.setState({daily_life: temp_dl, dl_describe: ""});
	}
	this.setState({[id+"_done"]: true});
}

SubmitDLDescribe(id) {
	var update = false;
	var temp_daily = this.state.daily_life;
	temp_daily.find((daily) => {
		if (daily.id == id) {
			if (daily.description_set) {
				update = true;
			} else {
				daily.description_set = true;
			}
		}
	});
	this.setState({daily_life: temp_daily});
	axios.post(APIHost + '/api/dailyLife', {
			withCredentials: true,
			entry: this.state[`${id}_description`],
			type: "description",
			update: update,
			id: id
		}).then(res => {
			this.SubmitDLDescribeCallback(id);
		}).catch(error => {
			console.log("Failed to submit a daily life description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitDLDescribe(event) {
	event.preventDefault();
	//document.getElementById(event.target.id).disabled = true;
	this.SubmitDLDescribe(event.target.id);
}

SubmitPLDescribeCallback(id) {
	var temp_pl = this.state.past_life;
	var cnt = 0;

	temp_pl.find((element) => {
		if (element.id == id) {
			element.description = this.state.pl_describe;
		}
	});
	temp_pl.forEach((element) => {
		if (element.description != null) {
			cnt++;
		}
	});
	if (cnt === this.state.past_life.length) {
		this.setState({past_life: temp_pl, dl_describe: "", finished_past_life_describe: true});
	} else {
		this.setState({past_life: temp_pl, pl_describe: ""});
	}
	this.setState({[id+"_done"]: true});
}

SubmitPLDescribe(id) {
	var update = false;
	var temp_past = this.state.past_life;
	temp_past.find((past) => {
		if (past.id == id) {
			if (past.description_set) {
				update = true;
			} else {
				past.description_set = true;
			}
		}
	});
	this.setState({past_life: temp_past});
	axios.post(APIHost + '/api/pastLife', {
			withCredentials: true,
			entry: this.state[`${id}_description`],
			type: "description",
			id: id
		}).then(res => {
			this.SubmitPLDescribeCallback(id);
		}).catch(error => {
			console.log("Failed to submit a past life description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitPLDescribe(event) {
	event.preventDefault();
	this.SubmitPLDescribe(event.target.id);
}

RemovePLCallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.past_life.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			cnt++;
		}
	});
	if (temp.length == 0) {
		this.setState({added_past: false});
	}
	this.setState({past_life: temp});
}

RemoveDLCallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.daily_life.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			cnt++;
		}
	});
	if (temp.length == 0) {
		this.setState({added_daily: false});
	}
	this.setState({daily_life: temp});
}

RemoveEntry(id, api) {
	axios.delete(APIHost + '/api/' + api, {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		if (api === "dailyLife") {
			this.RemoveDLCallback(id);
		} else {
			this.RemovePLCallback(id);
		} 
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveEntry(event) {
	event.preventDefault();
	this.RemoveEntry(event.target.id, event.target.name);
}

NextExercisesCallback(stage) {
	window.scroll(0,0);
	var temp_daily = this.state.daily_life;

	if (stage === "daily_life") {
		this.setState({pl_exercises: true, dl_exercises: false, dl_describe_exercises: false, pl_describe_exercises: false});
	} else if (stage === "past_life") {
		this.setState({pl_exercises: false, dl_exercises: false, dl_describe_exercises: true, pl_describe_exercises: false});
	} else if (stage === "daily_life_describe") {
	for (var i=0; i<this.state.daily_life.length; i++) {
		//temp_daily[i].description = "";
		document.getElementById(this.state.daily_life[i].id).value = "";
	}
	//this.setState({daily_life: temp_daily});
		this.setState({pl_exercises: false, dl_exercises: false, dl_describe_exercises: false, pl_describe_exercises: true});
	} else {
		this.setState({pl_exercises: false, dl_exercises: false, dl_describe_exercises: false, pl_describe_exercises: false, finished: true});
	}
}

NextExercises(stage) {
	var next_stage = '';
	var percent_complete = null;
	if (stage === "daily_life") {
		next_stage = "past_life";
		percent_complete = .4;
	} else if (stage === "past_life") {
		percent_complete = .6;
		next_stage = "daily_life_describe";
	} else if (stage === "daily_life_describe") {
		next_stage = "past_life_describe";
		percent_complete = .8;
	} else { 
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback(stage);
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises(event.target.id);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
// display exercises for core attributes and stretch so they can add and remove from those respective lists
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	if(this.state.dl_exercises) {
		for (var i=0; i<this.state.daily_life.length; i++) {
			display_current.push(<li>{this.state.daily_life[i].entry} <button id={this.state.daily_life[i].id} className="btn btn-primary my-3" name="dailyLife" onClick={this.onRemoveEntry}>Remove</button></li>);
		}
		display_mc.push(<div><ul>{display_current}</ul></div>);

	
		display_intro.push(<div><p>List events from the past 6 months that stand out in your mind. They do not have to be major life 
		events. You are looking for whatever comes easily to mind and events that have some substance to them; ones you can expand on. 
		Write a one line synopsis for the event. You will write out the details in the next section. An example synopsis is: 
		“I took a weekend trip with my family last month” or “I had my performance review at work 2 weeks ago”. Your goal is to have 
		at least 5 for each of the following: intimate relationships, family, friendships, work, and other (for those that don’t fit 
		in any other category). The more entries you have, the more of a complete picture you will get for future chapters. </p><hr className="new" />
		<div className='container'>
    	<div className='row'>  
		<div className='col-md-6'>
		<p>Enter a life event in the space provided and then click Add. Once you are finished adding life events, click Finish to move to the next section</p>
		<form>
		<div className="form-group">
     	<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="dl" value={this.state.dl} className="form-control"></textarea>
  		</div>
  		<button onClick={this.onSubmitDL} className="btn btn-primary my-3" type="submit" id="AddDaily">Add</button>
  		</form>
  		<br />
  		<p>Click <button onClick={this.onNextExercises} type="submit" disabled={!this.state.added_daily} className="btn btn-primary my-3" id="daily_life">Finish</button> to move to the next section</p>		
  		</div>
  		<div className='col-md-6'><h4>Your Daily Life</h4> <br />{display_mc}</div></div></div></div>);
  			  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Daily Life</h1>{display_button}
{display_video}<p><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">Add a note</button>} position="right center">
		<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
		<button onClick={this.onAddNote}>Submit</button> </div>
		</Popup><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">See chapter notes</button>} position="right center">
		<div><ul>{this.state.display_notes}</ul></div>
		</Popup></p>{display_intro}</div>);
	} else if (this.state.pl_exercises) {
		for (var i=0; i<this.state.past_life.length; i++) {
			display_current.push(<li>{this.state.past_life[i].entry} <button id={this.state.past_life[i].id} name="pastLife" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
		}
		display_mc.push(<div><ul>{display_current}</ul></div>);
		display_intro.push(<div><p>List events from within the last 10 years. These again  don't have to be major events, but just events
		that stand-out in your mind. Your goal is to have at least 5 for each of the following: intimate relationships, family, friendships, 
		work, and other (for those that don’t fit in any other category). </p><hr className="new" />
		<p>Enter a past life event in the space provided and click Add. Once you have finished entering past life events, click Finish to move to
		the next section</p>
		<div className='container'>
    	<div className='row'>  
		<div className='col-md-6'>
		<form>
		<div className="form-group">
     	<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="pl" value={this.state.pl} className="form-control"></textarea>
  		</div>
  		<button onClick={this.onSubmitPL} className="btn btn-primary my-3" type="submit" id="AddPast">Add</button>
  		</form>
  		<br />
  	 	<p>Click <button onClick={this.onNextExercises} type="submit" disabled={!this.state.added_past} id="past_life" className="btn btn-primary my-3">Finish</button> to move to the next section</p>		
  		</div>
  		<div className='col-md-6'><h4>Your Past Life Events</h4> <br />{display_mc}</div></div></div>
  		</div>);
  			  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Your Past Life</h1>{display_button}
{display_video}<p><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">Add a note</button>} position="right center">
		<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
		<button onClick={this.onAddNote}>Submit</button> </div>
		</Popup><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">See chapter notes</button>} position="right center">
		<div><ul>{this.state.display_notes}</ul></div>
		</Popup></p>{display_intro}</div>);
	} else if (this.state.dl_describe_exercises) {
		for (var i=0; i<this.state.daily_life.length; i++) {
			display_mc.push(<div><p>{i+1}) {this.state.daily_life[i].entry}</p>
			<form>
			<div className="form-group">
 			<textarea onChange={this.onExerciseChange} value={this.state.daily_life[i].description} rows="10" cols="80" type="text" id={this.state.daily_life[i].id} name="dl_describe" className="form-control"></textarea>
				</div>
				<button onClick={this.onSubmitDLDescribe} type="submit" name={`${i+1}_submit_dl`} disabled={this.state[`${this.state.daily_life[i].id}_done`]} id={this.state.daily_life[i].id} className="custom-btn">Submit</button>
				</form></div>);
		}
	
		display_intro.push(<div><p>Below, you will provide details for the daily life events you entered previously. It is important here
	 		to provide as much detail about those events as  you can. The information here will empower your success in later chapters. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="daily_life_describe" className="btn btn-primary my-3" disabled={!this.state.finished_daily_life_describe}>Finish</button></p></div>);
		  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Describing Your Daily Life</h1>{display_button}
{display_video}<p><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">Add a note</button>} position="right center">
		<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
		<button onClick={this.onAddNote}>Submit</button> </div>
		</Popup><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">See chapter notes</button>} position="right center">
		<div><ul>{this.state.display_notes}</ul></div>
		</Popup></p>{display_intro}</div>);
	} else if(this.state.pl_describe_exercises) {
		for (var i=0; i<this.state.past_life.length; i++) {
			display_mc.push(<div><p>{i+1}) {this.state.past_life[i].entry}</p>
			<form>
			<div className="form-group">
 			<textarea onChange={this.onExerciseChange} value={this.state.past_life[i].description} rows="10" cols="80" type="text" id={this.state.past_life[i].id} name="pl_describe" className="form-control"></textarea>
				</div>
				<button onClick={this.onSubmitPLDescribe} type="submit" name={`${i+1}_submit_pl`} disabled={this.state[`${this.state.past_life[i].id}_done`]} id={this.state.past_life[i].id} className="custom-btn">Submit</button>
				</form></div>);
		}
	
		display_intro.push(<div><p>Below, you will provide details for the past life events you entered previously. It is important here
	 	to provide as much detail about those events as  you can. The information here will empower your success in later chapters. </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onNextExercises} type="submit" id="past_life_describe" className="btn btn-primary my-3" disabled={!this.state.finished_past_life_describe}>Finish</button></p></div>);
	  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Describing Your Past Life</h1>{display_button}
{display_video}<p><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">Add a note</button>} position="right center">
		<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
		<button onClick={this.onAddNote}>Submit</button> </div>
		</Popup><Popup trigger={<button className="simple_button" className="btn btn-primary my-3">See chapter notes</button>} position="right center">
		<div><ul>{this.state.display_notes}</ul></div>
		</Popup></p>{display_intro}</div>);
	}

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter9">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter9">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter9