import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter47 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 47,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		finished_exercises: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
    	signals: [],
    	catalysts: [],
		viruses_count: 0,
		viruses: [],
		display_exercises: false,
		chapter_video: "https://player.vimeo.com/video/451336629"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.onSubmitCatalysts = this.onSubmitCatalysts.bind(this);
this.SubmitCatalysts = this.SubmitCatalysts.bind(this);
this.SubmitCatalystsCallback = this.SubmitCatalystsCallback.bind(this);
this.onSubmitSignals = this.onSubmitSignals.bind(this);
this.SubmitSignals = this.SubmitSignals.bind(this);
this.SubmitSignalsCallback = this.SubmitSignalsCallback.bind(this);
this.GetCatalysts = this.GetCatalysts.bind(this);
this.GetSignals = this.GetSignals.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.onRemoveCatalyst = this.onRemoveCatalyst.bind(this);
this.RemoveCatalyst = this.RemoveCatalyst.bind(this);
this.RemoveCatalystCallback = this.RemoveCatalystCallback.bind(this);
this.onRemoveSignal = this.onRemoveSignal.bind(this);
this.RemoveSignal = this.RemoveSignal.bind(this);
this.RemoveSignalCallback = this.RemoveSignalCallback.bind(this);
this.Unique = this.Unique.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	this.setState({loading: true});
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (this.state.chapter_stage == "video") {
			this.setState({display_video: true, display_exercises: false, started: true});
		} else if (this.state.chapter_stage == "exercises") {
			this.setState({display_video: false, display_exercises: true, started: true});	
		} else if (this.state.chapter_stage == "done") {
			this.setState({display_video: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var signals_promise = this.GetSignals().then((signals) => {
		this.setState({signals: signals.data.signals});
	}).catch((error) => {
		console.log("GetSignals failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(signals_promise);
	all_promises.push(viruses_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var cnt=0;
		this.GetCatalysts().then((catalysts) => {
			var sorted = this.Unique(catalysts.data.catalysts);
			this.state.viruses.forEach((virus) => {
				sorted.find((c) => {
					if (virus.id == c.virus_id) {
						cnt++;
					}
				});
			});
			if (cnt == this.state.viruses.length) {
				this.setState({finished_catalysts: true});
			}
			this.setState({catalysts: catalysts.data.catalysts, loading: false});
		}).catch((error) => {
			console.log("GetCatalysts failed " + error);
			this.setState({server_failure: true});
		});
		
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetCatalysts() {
	const res = await axios.get(APIHost + '/api/catalysts',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetSignals() {
	const res = await axios.get(APIHost + '/api/signals',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
event.persist();
	this.setState({ [event.target.name]: event.target.value});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

Unique(ToSort) {
	var temp = [];
	var flag = false;
	ToSort.forEach((ts) => {
		flag = false;
		if (temp.length ==  0) {
			temp.push(ts);
		} else {
			temp.find((t) => {
				if (t.virus_id == ts.virus_id) {
					flag = true;
				}
			});
			if (!flag) {
				temp.push(ts);
			}

		}
	});
	return temp;
}

DisplayFinished(history) {
	var display = [];
	var display_current = [];
	var display_cc = [];
	var display_ca = [];
	var display_viruses = [];
	var display_viruses = [];
	var display_video = [];
	var display_button = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if(this.state.display_video_after) {
		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	for (var i=0; i<this.state.viruses.length; i++) {
		// look at the catalysts and signals with the virus id and create the display variables 
		var display_catalysts = [];
		var display_signals = [];
		this.state.catalysts.find((c) => {
			if (this.state.viruses[i].id == c.virus_id) {
				display_catalysts.push(<li>{c.catalyst} </li>);
			}
		});
		
		this.state.signals.find((s) => {
			if (this.state.viruses[i].id == s.virus_id) {
				display_signals.push(<li>{s.signal} </li>);
			}
		});
		
		display_viruses.push(<div><h5>Virus: {this.state.viruses[i].virus}</h5>
		<p>Description: {this.state.viruses[i].description}</p>
		<h5>This Virus's Catalysts</h5>
				<div><ul>{display_catalysts}</ul></div>
		<h5>This Virus's Signals</h5>
				<div><ul>{display_signals}</ul></div>
				<hr className="new" />
		</div>);
	}
	display.push(<div><h1>Congrats on finishing the chapter</h1><p>Below are all the Cognitive Catalysts and Signals along with your Viruses. Take some time to review
	the results here. This is a huge step forward in the process of creating your Action Plans in the coming chapters and
	eventually getting ahead of those negative Response Cycles that have been holding you back from your Optimal Life</p>{display_button}{display_video}
	<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
	<hr className="new" />{display_viruses}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>)
	
		return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, display_video: false, finished: true});
}

UpdateStage(stage, callback) {
	var percent_complete = null;
	if (stage == "exercises") {
		percent_complete = .25;
	}
	axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			percent_complete: percent_complete,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
event.preventDefault();
this.UpdateStage("exercises", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-51"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

onSubmitCatalysts(event) {
	event.preventDefault();
	this.SubmitCatalysts(event.target.id);
}

SubmitCatalysts(id) {
	axios.post(APIHost + '/api/catalysts', {
		withCredentials: true,
		catalyst: this.state[`${id}_catalyst`],
		virus_id: id
	}).then(res => {
		this.SubmitCatalystsCallback(id, res.data.id);
	}).catch(error => {
		console.log("Failed to submit catalyst description " + error);
		this.setState({submission_failure: true});
	});
}

SubmitCatalystsCallback(virus_id, catalyst_id) {
	var temp = this.state.catalysts;
	temp.push({});
	var cnt = 0;
	temp[this.state.catalysts.length-1].catalyst = this.state[`${virus_id}_catalyst`];
	temp[this.state.catalysts.length-1].virus_id = virus_id;
	temp[this.state.catalysts.length-1].id = catalyst_id;
	var sorted = this.Unique(this.state.catalysts);
	this.state.viruses.forEach((virus) => {
		sorted.find((c) => {
			if (virus.id == c.virus_id) {
				cnt++;
			}
		});
	});
	if (cnt == this.state.viruses.length) {
		this.setState({finished_catalysts: true});
	} else {
		this.setState({finished_catalysts: false});
	}
	this.setState({[virus_id+"_catalyst"]: "", catalysts: temp});
}

onSubmitSignals(event) {
	event.preventDefault();
	this.SubmitSignals(event.target.id);
}

SubmitSignals(id) {
	axios.post(APIHost + '/api/signals', {
		withCredentials: true,
		signal: this.state[`${id}_signal`],
		virus_id: id
	}).then(res => {
		this.SubmitSignalsCallback(id, res.data.id);
	}).catch(error => {
		console.log("Failed to submit signal description " + error);
		this.setState({submission_failure: true});
	});
}

SubmitSignalsCallback(virus_id, signal_id) {
	var temp = this.state.signals;
	temp.push({});
	temp[this.state.signals.length-1].signal = this.state[`${virus_id}_signal`];
	temp[this.state.signals.length-1].virus_id = virus_id;
	temp[this.state.signals.length-1].id = signal_id;
	this.setState({[virus_id+"_signal"]: "", signals: temp});
}

RemoveSignalCallback(id) {
	var cnt = 0;
	var temp = [];
	var ids = [];
	var original_ids = [];
	this.state.signals.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].virus_id = element.virus_id;
			temp[cnt].signal = element.signal;
			cnt++;
		}
	});
	this.setState({signals: temp});
}

RemoveSignal(id) {
	axios.delete(APIHost + '/api/signals', {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		this.RemoveSignalCallback(id);
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveSignal(event) {
	event.preventDefault();
	this.RemoveSignal(event.target.id);
}

RemoveCatalystCallback(id) {
	var cnt = 0;
	var temp = [];
	var ids = [];
	var original_ids = [];
	this.state.catalysts.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].virus_id = element.virus_id;
			temp[cnt].catalyst = element.catalyst;
			cnt++;
		}
	});
	var sorted = this.Unique(this.state.catalysts);
	this.state.viruses.forEach((virus) => {
		sorted.find((c) => {
			if (virus.id == c.virus_id) {
				cnt++;
			}
		});
	});
	if (cnt == this.state.viruses.length) {
		this.setState({finished_catalysts: true});
	} else {
		this.setState({finished_catalysts: false});
	}

	this.setState({catalysts: temp});
}

RemoveCatalyst(id) {
	axios.delete(APIHost + '/api/catalysts', {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		this.RemoveCatalystCallback(id);
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveCatalyst(event) {
	event.preventDefault();
	this.RemoveCatalyst(event.target.id);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_viruses = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	for (var i=0; i<this.state.viruses.length; i++) {
		// look at the catalysts and signals with the virus id and create the display variables 
		var display_catalysts = [];
		var display_signals = [];
		this.state.catalysts.find((c) => {
			if (this.state.viruses[i].id == c.virus_id) {
				display_catalysts.push(<li>{c.catalyst} <button className="btn btn-primary my-3" id={c.id} name="catalyst" onClick={this.onRemoveCatalyst}>Remove</button></li>);
			}
		});
		
		this.state.signals.find((s) => {
			if (this.state.viruses[i].id == s.virus_id) {
				display_signals.push(<li>{s.signal}<button className="btn btn-primary my-3"  id={s.id} name="signal" onClick={this.onRemoveSignal}>Remove</button></li>);
			}
		});
		
		display_viruses.push(<div><h5>Virus: {this.state.viruses[i].virus}</h5>
		<p>Description: {this.state.viruses[i].description}</p>
		<div className='container'>
		<div className='row'>  
		<div className='col-md-6'>
		<h5>Enter Cognitive Catalyst:</h5>
		<form>
			<textarea onChange={this.onExerciseChange} rows="5" cols="40" type="text" value={this.state[`${this.state.viruses[i].id}_catalyst`]} name={`${this.state.viruses[i].id}_catalyst`} className="form-control"></textarea>
			</form>
		<button onClick={this.onSubmitCatalysts} type="submit" className="btn btn-primary my-3" id={this.state.viruses[i].id} >Submit Catalyst</button>
		</div>
		<div className='col-md-6'>
		<h5>This Virus's Catalysts</h5>
				<div><ul>{display_catalysts}</ul></div>
		</div></div></div>
		<div className='container'>
		<div className='row'>  
		<div className='col-md-6'>
		<h5>Enter Cognitive Signals:</h5>
		<form>
			<textarea onChange={this.onExerciseChange} rows="5" cols="40" type="text" value={this.state[`${this.state.viruses[i].id}_signal`]} name={`${this.state.viruses[i].id}_signal`} className="form-control"></textarea>
			</form>
		<button onClick={this.onSubmitSignals} className="btn btn-primary my-3" type="submit" id={this.state.viruses[i].id} >Submit Signal</button>

		</div>
		<div className='col-md-6'>
		<h5>This Virus's Signals</h5>
				<div><ul>{display_signals}</ul></div>

		</div></div></div><hr /></div>);
	}

	display_intro.push(<div><p>Below, you will provide the Cognitive Catalysts and Signals as described in the chapter video. You add them by entering them
	in the provided text box and clicking "Submit Catalyst" or "Submit Signal". You can add as many of each that 
	is appropriate for each Virus. Each Virus has to have at least one Cognitive Catalyst associated with it before moving on.</p>
		<hr className="new" /> {display_viruses}
			<p><button onClick={this.GoToEnd} type="submit" id="viruses" className="custom-btn" disabled={!this.state.finished_catalysts}>Finish</button></p></div>);
	  if(this.state.display_video_after) {
		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	display.push(<div className="col-md-51"><h1>Identifying your Cognitive Catalysts and Signals</h1>{display_button}
	{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);

	return display;
}

GoToEnd() {
	this.UpdateStage("done", this.GoToEndCallback);
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter47">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter47">
			<Header history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
					{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter47