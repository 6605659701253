import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import me from './images/me.jpg';
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';
import {APIHost} from './GlobalVariables';

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class SprintHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sprint_id: this.props.match.params.ID,
			chapters: [],
			sprint: [],
			wentwell: null,
			challenges: null,
			learnings: null,
			loading: true,
			velocity: 0,
			server_failure: false,
			start_date: null
		}

		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.GetChapters = this.GetChapters.bind(this);
		this.GetHistoricSprint = this.GetHistoricSprint.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	this.setState({loading: true});
	if(this.props.location.state != null) {
	var historic_promise = this.GetHistoricSprint().then((sprint) => {
		this.setState({sprint: sprint.data.sprint, sprint_history: sprint.data.sprint_history, retrospective: sprint.data.retrospective,
		sprint_details: sprint.data.sprint_details});
		return sprint;
	}).catch(error => {
    	console.log("GetHistoricSprint failed " + error);
		this.setState({server_failure: true});
    });
	
    var chapters_promise = this.GetChapters().then((chapters) => {
    	this.setState({chapters: chapters.data.chapters});
		return chapters;
	}).catch(error => {
    	console.log("GetChapters failed " + error);
		this.setState({server_failure: true});
    });

	all_promises.push(historic_promise);
	all_promises.push(chapters_promise);
	
	Promise.all(all_promises).then( () => {
	  	let local_chapters = this.state.chapters;
		var points=0;
		let local_sprint_details = this.state.sprint_details;
   		local_sprint_details.forEach((sp) => {
		this.state.chapters.find((element) => {
			if (element.id == sp.chapter_id) {
				sp.description = element.description;
				sp.objective_id = element.objective_id;
				sp.points = element.points;
				sp.chapter_id = element.id;
			}
		});

		});
		var date = new Date(this.state.sprint[0].start_date);
		var start_date = (date.getMonth()+1) + "-" + date.getDate() + "-" + date.getFullYear();
		this.setState({start_date: start_date, chapters: local_chapters, sprint_details: local_sprint_details, loading: false})

	});
	}
}

componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }


async GetHistoricSprint() {
	const res = await axios.get(APIHost + '/api/getHistoricSprint', {params: {withCredentials: true,
	sprint_id: this.state.sprint_id}});
	return await res;
}

async GetChapters() {
	const res = await axios.get(APIHost + '/api/getChapters', {withCredentials: true});
	return await res; 
}

DisplaySprint() {
	var for_display = [];
	var open_chapters = [];
	var in_progress = [];
	var done_chapters = [];
	var sprint_rows = [];
	var row = [];
	var points = 0;
	this.state.sprint_details.forEach((element) => {
		if (element.status == "open") {
			open_chapters.push(element.description);
		} else if (element.status == "in progress") {
			in_progress.push(element.description);
		} else if (element.status == "done") {
			done_chapters.push(element.description);
		}	
		points = points+element.points;
	});
	for (var i=0; i<this.state.sprint_details.length; i++) {
	row = [];
	var flag = false;
		if (open_chapters[i]) {
		flag = true;
			row.push(<td>{open_chapters[i]} ({this.state.sprint_details[i].points})</td>);
		} else {
			row.push(<td></td>);
		}
		if (in_progress[i]) {
		flag = true;
			row.push(<td>{in_progress[i]} ({this.state.sprint_details[i].points})</td>);
		} else {
			row.push(<td></td>);
		}
		if (done_chapters[i]) {
		flag = true;
			row.push(<td>{done_chapters[i]} ({this.state.sprint_details[i].points})</td>);
		} else {
			row.push(<td></td>);
		}
			if(flag) {
			sprint_rows.push(<tr>{row}</tr>);
			}
		
	}
      for_display.push(<table className="table table-bordered"><thead><tr><th>To Do (points)</th><th>In Progress (points)</th><th>Done (points)</th>
      </tr></thead><tbody>{sprint_rows}</tbody></table>);

		for_display.push(<h5>Points Completed (Velocity): {this.state.sprint_history[0].velocity}</h5>);
		if(this.state.sprint_history[0].comparison < 0) {
			for_display.push(<p>Looks like this sprint was a bit of a challenge. You were not able to complete all the chapters you committed to. 
			Here's some helpful material</p>);
		} else if (this.state.sprint_history[0].comparison == 0) {
		
		} else {
		
		}

      return for_display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	return (
		<div className="SprintHistory">
			<Header history={this.props.history} LoggedIn={true} />
			<div className="container_profile">
			{this.state.server_failure && <ServerFailure />}
				<div className="row">
				<div className="col-md-6">
                	<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
                        	<h5 className="mb-3">Here are the sprint details for the sprint you started on:<br /> {!this.state.loading && this.state.start_date}</h5>                        
								{!this.state.loading && this.DisplaySprint()}
                			</div>                	
                		</div>           
           			</section>

           		</div>
           		<div className="col-md-6">
        			<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
 							<h5>Here's your sprint feedback you reported:</h5>
 							<br />
 							<h6 className="mb-2">What you felt went well</h6>
 							<p>{!this.state.loading && this.state.retrospective[0].wentwell}</p>
 							<h6 className="mb-2">What you felt were your challenges</h6>
 							<p>{!this.state.loading && this.state.retrospective[0].challenges}</p>
 							<h6 className="mb-2">What your key learnings were</h6>
 							<p>{!this.state.loading && this.state.retrospective[0].learnings}</p>
                			</div>                	
                		</div>           
          			 </section>
          		</div>

        		</div>
        	</div>
			<Footer history={this.props.history} loggedIn={true} />
		</div>
	);	
	}	
}
	
}

export default SprintHistory;