import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter42 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 42,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_wisdom: false,
		display_exercises: false,
		display_courage: false,
		display_hl: false,
		display_temperance: false,
		display_transcendence: false,
		display_justice: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		virtues: [],
		wisdom:  [],
		courage: [],
		justice: [],
		transcendence: [],
		temperance: [],
		humanity_love: [],
		wisdom_language:  [],
		courage_language: [],
		justice_language: [],
		transcendence_language: [],
		temperance_language: [],
		humanity_love_language: [],
		started: false,
		language: [],
		chapter_video: "https://player.vimeo.com/video/451334050"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.LanguageState = this.LanguageState.bind(this);
this.onSubmitVirtues = this.onSubmitVirtues.bind(this);
this.SubmitVirtues = this.SubmitVirtues.bind(this);
this.SubmitVirtuesCallback = this.SubmitVirtuesCallback.bind(this);
this.GetVirtues = this.GetVirtues.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, display_wisdom: false, display_justice: false, display_transcendence: false, 
				display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: false});
		} else if (stage.data.stage == "wisdom") {
			this.setState({display_video: false, display_wisdom: true, display_justice: false, display_transcendence: false, 
				display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: true});		
		} else if (stage.data.stage == "courage") {
			this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
				display_hl: false, display_temperance: false, display_courage: true, started: true, display_exercises: true});
		} else if (stage.data.stage == "humanity_love") {
			this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
				display_hl: true, display_temperance: false, display_courage: false, started: true, display_exercises: true});
		} else if (stage.data.stage == "justice") {
			this.setState({display_video: false, display_wisdom: false, display_justice: true, display_transcendence: false, 
				display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: true});
		} else if (stage.data.stage == "temperance") {
			this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
				display_hl: false, display_temperance: true, display_courage: false, started: true, display_exercises: true});
		} else if (stage.data.stage == "transcendence") {
			this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: true, 
				display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: true});
		} else if (stage.data.stage == "done") {
			this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
				display_hl: false, display_temperance: false, display_courage: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(language_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var wisdom_ids = [];
		var courage_ids = [];
		var justice_ids = [];
		var hl_ids = [];
		var transcendence_ids = [];
		var temperance_ids = [];

		this.GetVirtues().then((virtues) => {
			virtues.data.virtues.forEach((element) => {
				if (element.wisdom) {
					wisdom_ids.push(element.language_id);
				}
				if (element.justice) {
					justice_ids.push(element.language_id);
				}
				if (element.courage) {
					courage_ids.push(element.language_id);
				}
				if (element.humanity_love) {
					hl_ids.push(element.language_id);
				}
				if (element.transcendence) {
					transcendence_ids.push(element.language_id);
				}
				if (element.temperance) {
					temperance_ids.push(element.language_id);
				}
			});
			this.setState({loading: false, virtues: virtues.data.virtues, wisdom: wisdom_ids, courage: courage_ids,
				justice: justice_ids, temperance: temperance_ids, transcendence: transcendence_ids, humanity_love: hl_ids});
		}).catch((error) => {
			console.log("Failed to get virtues " + error);
			this.setState({server_failure: true});
		});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetVirtues() {
	const res = await axios.get(APIHost + '/api/virtues', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

StartChapterCallback() {
	this.setState({display_video: true, display_exercises: false, started: true, finished: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({ [event.target.name]: event.target.value});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayFinished(history) {
	var display_final = [];
	var display_wisdom = [];
	var display_justice = [];
	var display_courage = [];
	var display_hl = [];
	var display_temperance = [];
	var display_transcendence = [];
	var display = [];
	var display_v = [];
	var display_heading = [];
	if (this.state.wisdom.length == 0) {
		display.push(<div><h4>You didn't mark anything as Wisdom. You will have the opportunity to build on your virtues as the training
		progresses.</h4></div>);
	} else {
		for (var i=0; i<this.state.wisdom.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.wisdom[i]) {
					display_v.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_wisdom.push(
			<div><ul>{display_v}</ul></div>
		);
	}
	if (this.state.justice.length == 0) {
		display.push(<div><h4>You didn't mark anything as Justice. You will have the opportunity to build on your virtues as the training
		progresses.</h4></div>);
	} else {
		display_v = [];
		for (var i=0; i<this.state.justice.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.justice[i]) {
					display_v.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_justice.push(
			<div><ul>{display_v}</ul></div>
		);
	}
	if (this.state.courage.length == 0) {
		display.push(<div><h4>You didn't mark anything as Courage. You will have the opportunity to build on your virtues as the training
		progresses.</h4></div>);
	} else {
		display_v = [];
		for (var i=0; i<this.state.courage.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.courage[i]) {
					display_v.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_courage.push(
			<div><ul>{display_v}</ul></div>
		);
	}
	if (this.state.humanity_love.length == 0) {
		display.push(<div><h4>You didn't mark anything as Humanity and Love. You will have the opportunity to build on your virtues as the training
		progresses.</h4></div>);
	} else {
		display_v = [];
		for (var i=0; i<this.state.humanity_love.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.humanity_love[i]) {
					display_v.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_hl.push(
			<div><ul>{display_v}</ul></div>
		);
	}
	if (this.state.temperance.length == 0) {
		display.push(<div><h4>You didn't mark anything as Temperance. You will have the opportunity to build on your virtues as the training
		progresses.</h4></div>);
	} else {
		display_v = [];
		for (var i=0; i<this.state.temperance.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.temperance[i]) {
					display_v.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_temperance.push(
			<div><ul>{display_v}</ul></div>
		);
	}
	if (this.state.transcendence.length == 0) {
		display.push(<div><h4>You didn't mark anything as Transcendence. You will have the opportunity to build on your virtues as the training
		progresses.</h4></div>);
	} else {
		display_v = [];
		for (var i=0; i<this.state.transcendence.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.transcendence[i]) {
					display_v.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_transcendence.push(
			<div><ul>{display_v}</ul></div>
		);
	}

	var display_video = [];
	var display_button = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  	} else {
  		display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  	}
	display_final.push(<div className="col-md-12"><h1>Here are all the various language snippets you labeled with virtues.</h1>
	{display_button}{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
		<div className='container'>
    	<div className='row'>  
		<div className='col-md-6'>
		<h3>Wisdom:</h3><p>{display_wisdom}</p>
		<h3>Justice:</h3><p>{display_justice}</p>
		<h3>Transcendence:</h3>
		<p>{display_transcendence}</p>
		</div>
		<div className='col-md-6'>
		<h3>Courage:</h3>
		<p>{display_courage}</p>
		<h3>Humanity and Love:</h3>
		<p>{display_hl}</p>
		<h3>Temperance:</h3>
		<p>{display_temperance}</p>
		</div><p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div></div></div>);	
	return display_final;
}

async SetChapterStage(stage) {
var stage_promise = axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		});
	return await stage_promise;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_wisdom: true, display_justice: false, display_transcendence: false, 
			display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: true});	
}

GoToExercises(event) {
	event.preventDefault();
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: .2,
      stage: "wisdom",
      withCredentials: true
    }).then(res => {
		this.GoToExercisesCallback();
	  }).catch(error => {
		console.log("Error in setPercentComplete");
		this.setState({submission_failure: true});
    });
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitVirtuesCallback(next_stage) {
	window.scroll(0,0);
	console.log("Next stage in callback " + next_stage);
	if(next_stage === "courage") {
		this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
			display_hl: false, display_temperance: false, display_courage: true, started: true, display_exercises: true});
	} else if (next_stage === "justice") {
		this.setState({display_video: false, display_wisdom: false, display_justice: true, display_transcendence: false, 
			display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: true});
	} else if (next_stage === "humanity_love") {
		this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
			display_hl: true, display_temperance: false, display_courage: false, started: true, display_exercises: true});
	} else if (next_stage === "transcendence") {
		this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: true, 
			display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: true});
	} else if (next_stage ===  "temperance") {
		this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
			display_hl: false, display_temperance: true, display_courage: false, started: true, display_exercises: true});
	} else if (next_stage === "done") {
		this.setState({display_video: false, display_wisdom: false, display_justice: false, display_transcendence: false, 
			display_hl: false, display_temperance: false, display_courage: false, started: true, display_exercises: false,
			finished: true});
	}
}

SubmitVirtues(callback, stage) {
	var next_stage = '';
	var percent_complete = null;
	if (stage === "wisdom") {
		next_stage = "courage";
		percent_complete = .35;
	} else if (stage === "courage") {
		next_stage = "justice";
		percent_complete = .5
	} else if (stage === "justice") {
		next_stage = "humanity_love";
		percent_complete = .65
	} else if (stage === "humanity_love") {
		next_stage = "transcendence";
		percent_complete = .8;
	} else if (stage === "transcendence" ) {
		next_stage = "temperance";
		percent_complete = .95
	} else if (stage === "temperance") {
		next_stage = "done";
	}
	axios.post(APIHost + '/api/virtues', {
			withCredentials: true,
			entries: this.state[`${stage}`],
			virtue: stage,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID,
			stage: next_stage,
			percent_complete: percent_complete
	}).then(res => {
		callback(next_stage);
	}).catch((error) => {
		console.log("Failed to submit " + stage + " with error: " + error);
		this.setState({submission_failure: true});
	});
}

onSubmitVirtues(event) {
	event.preventDefault();
	this.SubmitVirtues(this.SubmitVirtuesCallback, event.target.name);
}

LanguageState(event) {
	var temp = this.state[`${event.target.name}`];
	var temp2 = [];
	var already_there = false;
	var objIndex = -1;
	objIndex = temp.findIndex((obj => obj == event.target.id));
	if (objIndex != -1) {
		already_there = true;
	}
	if (already_there) {
		for (var i = 0; i<temp.length; i++) {
			if (i != objIndex) {
				temp2.push(temp[i]);
			}
		}
		temp = temp2;
	} else {
		temp.push(event.target.id);
	}
	this.setState({wisdom: temp});
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_language = [];
	var display_ca_language = [];
	var display_virus_language = [];
	var virtue_list = [];
	var which_virtue = "";
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	if (this.state.display_wisdom) {
		which_virtue = "Wisdom";
		for (var j=0; j<this.state.language.length; j++) {
			display_language.push(<p><input onChange={this.LanguageState} key={`${this.state.language[j].id}_wisdom`} name="wisdom" id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
			}
	display_mc.push(
			<div>
			<h5>Your Language</h5>
  			<div>{display_language}</div></div>
		);
		virtue_list.push(<div><li>Curiosity or interest in the world</li><li>Critical thinking or open mindedness</li>
			<li>Ingenuity/originality/practical intelligence/street smarts</li>
			<li>Social intelligence/personal intelligence/emotional intelligence</li>
			<li>Perspective</li></div>);
	display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel falls under the virtue of Wisdom
		as discussed in the chapter video. As a reminder, here's how to think about Wisdom:<div><ul>{virtue_list}</ul></div> </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitVirtues} className="custom-btn" name="wisdom" type="submit">Finish</button></p></div>);
 	} else if (this.state.display_courage) {
 		which_virtue = "Courage";
 		for (var j=0; j<this.state.language.length; j++) {
			display_language.push(<p><input onChange={this.LanguageState} name="courage" key={`${this.state.language[j].id}_courage`} id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
			}
	display_mc.push(
			<div>
			<h5>Your Language</h5>
  			<div>{display_language}</div></div>
		);
 		virtue_list.push(<div><li>Valor/bravery</li><li>Perseverance/industry/diligence</li>
			<li>Integrity/genuineness/honesty</li>
			</div>);
	display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel falls under the virtue of Courage
		as discussed in the chapter video. As a reminder, here's how to think about Courage:<div><ul>{virtue_list}</ul></div> </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitVirtues} className="custom-btn" name="courage" type="submit">Finish</button></p></div>);
 	} else if (this.state.display_justice) {
 		which_virtue = "Justice";
 		for (var j=0; j<this.state.language.length; j++) {
			display_language.push(<p><input onChange={this.LanguageState} name="justice" key={`${this.state.language[j].id}_justice`} id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
			}
			display_mc.push(
			<div>
			<h5>Your Language</h5>
  			<div>{display_language}</div></div>
		);

 		virtue_list.push(<div><li>Valor/bravery</li><li>Citizenship/duty/teamwork/loyalty</li>
			<li>Fairness and equity</li><li>Leadership</li>
			</div>);
		display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel falls under the virtue of Courage
		as discussed in the chapter video. As a reminder, here's how to think about Justice:<div><ul>{virtue_list}</ul></div> </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitVirtues} className="custom-btn" name="justice" type="submit">Finish</button></p></div>);
 	} else if (this.state.display_hl) {
 		which_virtue = "Humanity and Love";
 		for (var j=0; j<this.state.language.length; j++) {
			display_language.push(<p><input onChange={this.LanguageState} name="humanity_love" key={`${this.state.language[j].id}_hl`} id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
			}
			display_mc.push(
			<div>
			<h5>Your Language</h5>
  			<div>{display_language}</div></div>
		);

 		virtue_list.push(<div><li>Kindness and generosity</li><li>Loving and allowing oneself to be loved</li>
			</div>);
		display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel falls under the virtue of Courage
		as discussed in the chapter video. As a reminder, here's how to think about Humanity and Love:<div><ul>{virtue_list}</ul></div> </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitVirtues} className="custom-btn" name="humanity_love" type="submit">Finish</button></p></div>);
 	} else if (this.state.display_temperance) {
 		which_virtue = "Temperance";
 		for (var j=0; j<this.state.language.length; j++) {
			display_language.push(<p><input onChange={this.LanguageState} name="temperance" key={`${this.state.language[j].id}_temperance`} id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
			}
			display_mc.push(
			<div>
			<h5>Your Language</h5>
  			<div>{display_language}</div></div>
		);

 		virtue_list.push(<div><li>Self-control</li><li>Prudence/discretion/caution</li><li>Humility and Modesty</li>
			</div>);
		display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel falls under the virtue of Courage
		as discussed in the chapter video. As a reminder, here's how to think about Temperance:<div><ul>{virtue_list}</ul></div> </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitVirtues} className="custom-btn" name="temperance" type="submit">Finish</button></p></div>);
 	} else {
 		which_virtue = "Transcendence";
 		for (var j=0; j<this.state.language.length; j++) {
			display_language.push(<p><input onChange={this.LanguageState} name="transcendence" key={`${this.state.language[j].id}_transcendence`} id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
			}
			display_mc.push(
			<div>
			<h5>Your Language</h5>
  			<div>{display_language}</div></div>
		);

 		virtue_list.push(<div><li>Appreciation of beauty and excellence</li><li>Gratitude</li><li>Hope/optimism/future-mindedness</li>
			<li>Spirituality/sense of purpose/faith/religiousness</li><li>Forgiveness and Mercy</li><li>Forgiveness and mercy</li>
			<li>Forgiveness and mercy</li><li>Playfulness and humor</li><li>Zest/passion/enthusiasm</li></div>);
		display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel falls under the virtue of Courage
		as discussed in the chapter video. As a reminder, here's how to think about Temperance:<div><ul>{virtue_list}</ul></div> </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitVirtues} className="custom-btn" name="transcendence" type="submit">Finish</button></p></div>);
 	}
	if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  	} else {
  		display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  	}
	display.push(<div className="col-md-12"><h1>Identifying Expressions of {which_virtue}</h1>{display_button}
	{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter42">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter42">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter42