import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import {APIHost} from './GlobalVariables';
import axios from 'axios';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import SessionCheckout from './SessionCheckout';
import WebinarProcrastinationCheckout from './WebinarProcrastinationCheckout';
import WebinarCheckout from './WebinarCheckout';
import PayPalWebinars from './PayPalWebinars';
import PayPalProcrastination from './PayPalProcrastination';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
const promise = loadStripe("pk_live_51HHMFBLb5KZXfDYguK930v3O6ydugI72ThwFDVzIaKdqIVVOsmUDEHJCBm9E6eJAeLGSjvTNmdXNYLXfD9Q9G8R0005gh9V8zN");


const WelcomeMessage = () => (
    <p>Thank you so much for registering. Please select from available workshops or webinars below. By registering, you have access to Mental Fitness Training. You can <Link to="/login">login here.</Link> Feel free to <Link to="/Contact">reach out</Link> if you have any questions.</p>
);

const NotRegistered = () => (
    <p>Please make sure you have an account or register before purchasing a webinar or workshop. If you don't have an account, you can register <Link to="/Register">here</Link>.</p>
);

class Workshops extends Component {
constructor(props) {
	super(props);
	this.state = ({
		mobile: false,
        welcome: false
	})

	this.UpdateImpressions = this.UpdateImpressions.bind(this);
	this.CheckMobile = this.CheckMobile.bind(this);
    this.DirectToPaypal = this.DirectToPaypal.bind(this);
    this.DirectToPaypal2 = this.DirectToPaypal2.bind(this);
}
componentDidMount() {
	window.scroll(0,0);
	window.addEventListener("resize", this.CheckMobile());
        if(this.props.location.state && !this.props.location.state.loggedIn) {
        this.setState({welcome: true});
    }
	this.UpdateImpressions().catch((error) => {
		console.log("Updating impressions failed");
	});
}

componentWillUnmount() {
    window.removeEventListener("resize", this.CheckMobile)
}

CheckMobile() {
	this.setState({mobile: window.innerWidth <= 760});
}

DirectToPaypal(event) {
    event.preventDefault();
    const path = "/PayPalWebinars";
    if (event.target.id == "1") {
        this.props.history.push({ pathname: path, state: { webinar_id: 1, charge: 1999, price: 19.99 } });
    } else {
        this.props.history.push({ pathname: path, state: { webinar_id: 3, charge: 2999, price: 29.99 } });
    }
  }

  DirectToPaypal2(event) {
    event.preventDefault();
    const path = "/PayPalProcrastination";
    this.props.history.push({ pathname: path, state: { foo: 'bar' } });
  }


async UpdateImpressions() {
	const res = await axios.post(APIHost + '/api/updateImpressions', 
		{withCredentials: true, page: 'mft_page'});
	return await res;
}

  render() {
    if (this.props.location.state && this.props.location.state.company == "spd") {
       return (
          <div className="Workshops">
            <Header history={this.props.history} />
             <section id="testimonial" className="testimonial-area">
                <div className="container">
                <h1>Welcome Strategic Partnership Division/UN Women!</h1>
                <h5>Look forward to working with you!</h5>
                                        <h4 className="title mb-2">Leveraging the subconscious to success – Workshop with Elliot Barnett</h4>

                  <div className="row justify-content-between">
                    <div className="col-xl-5 col-lg-6">
                      <div className="testimonial-left-content mt-45">
                        <p>12/17/21 at 9am PST</p>
                        <p className="text">We are driven mostly by habits and routines in our lives. We hope we will stumble over success through the habitual cycles we live by. In order to achieve the success you deserve and live an Optimal Life, you have to define what success looks like for you, discover the power within yourself to achieve that success, and take action towards its realization. In order to do that, you need to learn to leverage the power of the subconscious so it starts serving your success instead of driving you in the direction your past pointed you in.  
                         </p>
                          <ul className="list-group list-group-flush">
                                            <li className="list-group-item" style={{ borderTop: 'none' }}>
                                                <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Learn the science behind the subconscious</h6>
                                                </div>
                                               </li>
                                                 <li className="list-group-item" style={{ borderTop: 'none' }}>
                                                <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Learn how your psychological structure is formed and how it drives how you show up in your life
</h6>
                                                </div>
                                               </li>
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Learn what the introspection delusion is and how it impacts your ability to achieve success
 </h6>
                                                </div>
                                                </li>
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Define your personal success
</h6>
                                                </div>
                                                </li>
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Learn the power of language in your life
</h6>
                                                </div>
                                                </li>
                                                                
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Learn to leverage what your subconscious provides to you

</h6>
                                                </div>
                                                </li>
                                        </ul>
                                        <p>“You have what it takes to create the success you deserve and live your Optimal Life. Let's work together to make that happen."</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="author-info  pt-5">
                      <h5>Agenda</h5><br />
                      <table className="table table-bordered">
                      <tr><td>Welcome</td><td>5</td>
                      </tr>
                                            <tr><td>Introduction to the subconscious</td><td>30</td>
                      </tr>
                                            <tr><td>Defining personal success</td><td>25</td>
                      </tr>
                                            <tr><td>The power of language</td><td>25</td>
                      </tr>
                                            <tr><td>Break</td><td>5</td>
                      </tr>
                                            <tr><td>Leveraging the power within</td><td>25</td>
                      </tr>
                                                                  <tr><td>Take away and closing</td><td>5</td>
                      </tr>
                      </table>
                      </div>            
                    </div>
                  </div>
                </div>
              </section>        
            <Footer history={this.props.history} />
          </div>
        );
    } else {
        let props = {
            webinar_id: "3",
            charge: "2999",
            price: "29.99"
        }
        let props2 = {
            webinar_id: "2",
            charge: "1999",
            price: "19.99"
        }
        return (
          <div className="Workshops">
    		<Header history={this.props.history} />
             <section id="testimonial" className="testimonial-area">
                <div className="container">
                <h1>Grab your spot below!</h1>
                {this.state.welcome && <WelcomeMessage />}
                {!this.state.welcome && <NotRegistered />}
 
                 <div className="row justify-content-between">
                    <div className="col-xl-5 col-lg-6">
                      <div className="testimonial-left-content mt-45">
                        <h4 className="title mb-2">Live Better! Resolve your Inner Conflicts!</h4>
                        <p>2/26/22 at 10am PST</p>
                        <p className="text">You deserve to live the life you want, your Optimal Life! But there are inner conflicts that can hold you back and negatively impact your mental and physical health. In this powerful workshop you will learn about the concept of Cognitive Traps and how to resolve them. Research studies have shown that these inner disconnects cause tension, stress, and damage your mental and physical well-being. With powerful intellectual property offered in this workshop, you will take action towards resolving them here and now and learn the tools to continue to strengthen what's within so you can achieve Optimal Mental Fitness and living your Optimal Life! You are not going to want to miss this!
                         </p>
                          <ul className="list-group list-group-flush">
                                            <li className="list-group-item" style={{ borderTop: 'none' }}>
                                                <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Limited time offer: normally $59.99 now $29.99!</h6>
                                                </div>
                                               </li>
                                                 <li className="list-group-item" style={{ borderTop: 'none' }}>
                                                <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Format: online workshop with webinar</h6>
                                                </div>
                                               </li>
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Duration: 3 hours </h6>
                                                </div>
                                                </li>
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Limited seats available </h6>
                                                </div>
                                                </li>
                                            <li className="list-group-item">
                                            <div className="d-flex align-items-center">
                                                <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Refundable if you can't attend </h6>
                                                </div>
                                                </li>
                
                                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-center author-info align-items-center pt-5">
                        <div>
                          <Elements stripe={promise}>
                            <WebinarCheckout {...props} />
                          </Elements>

                          <div className="form-input light-rounded-buttons mt-30">
                            <div className="d-flex align-items-center justify-content-center">
                            <p>Prefer PayPal? Go here</p>
                            <button className="main-btn light-rounded-two ml-2" id="3" onClick={this.DirectToPaypal}>PayPal</button>
                            </div>
                           
                          </div>
                        </div>
                      </div>            
                    </div>
                  </div>
                </div>
              </section>		
    		<Footer history={this.props.history} />
          </div>
        );
      }
  }
}

export default Workshops;
