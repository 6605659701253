import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';

class TermsOfUse extends Component {

componentDidMount() {
window.scroll(0,0);
this.DisplayHeader = this.DisplayHeader.bind(this);
this.DisplayFooter = this.DisplayFooter.bind(this);
}

DisplayHeader() {
    var display = [];
    if (this.props.loggedIn || (this.props.location.state!=null && this.props.location.state.loggedIn)) {
        display.push(<Header history={this.props.history} loggedIn={true} />);
    } else {
        display.push(<Header />);
    }
    return display;
}

DisplayFooter() {
    var display = [];
    if (this.props.loggedIn || (this.props.location.state!=null && this.props.location.state.loggedIn)) {
        display.push(<Footer history={this.props.history} loggedIn={true} />);
    } else {
        display.push(<Footer />);
    }
    return display;
}

render() {
	return (
		<div className="TermsOfUse">
		{this.DisplayHeader()}
    <section className="two-sided-box">
        <div className="container">
            <div className="two-sided-box-in">
            <h1>My Core Insights Terms of Use</h1>
            <h4>Introduction</h4>
            <p>No Discrimination. My Core Insights will not discriminate against any consumer for exercising 
            their rights under the California Consumer Privacy Act.</p>
            <p>By accessing the My Core Insights website, you agree to be bound by these terms and conditions ("Terms") 
            and our Privacy Policy. If you do not accept all of these Terms, then you may not use The Site. In these Terms, 
            "we", "us", "our" and "My Core Insights" refers to the My Core Insights business, and "you" and "your" refers to you, 
            the user of The Site.</p>
            <p>THESE TERMS INCLUDE AN ARBITRATION CLAUSE AND A WAIVER OF YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE LAWSUIT.</p>
            <p>We may modify these Terms for any reason—at any time—by posting a new version on The Site. These changes do not affect rights and obligations
             that arose prior to such changes. Your continued use of The Site following the posting of modified Terms will be subject to the Terms in effect 
             at the time of your use. Please review these Terms periodically for changes. If you object to any provision of these Terms or any subsequent 
             modifications to these Terms or become dissatisfied with The Site in any way, your only recourse is to immediately terminate use of The Site.</p>
             <h4>Copyright</h4>
             <p>We own all of the text, images, software, trademarks, service marks, and other material contained on The Site except User Content. You will 
             not copy or transmit any of the material except if you are doing so for your personal, non-commercial use. All copyright, trademark and other 
             proprietary rights notices presented on The Site must appear on all copies you print. Your use of and access to The Site does not grant you 
             any license or right to use any of the marks included on The Site.</p>
           <p>You may not (i) transfer, copy or display Content, except as permitted in this Agreement; (ii) sell, rent, lease, distribute, or broadcast 
           any Content; (iii) remove any proprietary notices or labels on Content; (iv) attempt to disable, bypass, modify, defeat, or otherwise circumvent any 
           digital rights management or other protection system applied to Content or used as part of The Site; or (v) use The Site or Content for any commercial 
           or illegal purpose.</p>
           <h4>Participation disclaimer</h4>
           <p>The Content provided at The Site is meant for information and entertainment purposes only. Your participation in The Training or use of any of The 
           Content at The Site is understood by you to be for information and entertainment purposes and is not intended or represented to diagnose, cure, solve, 
           resolve, or otherwise do anything beyond entertain you and provide interesting information for you to decide what and how it applies to you. How it 
           applies to you, impacts you, causes any effect on you or your life, or otherwise causes you distress, pain, suffering, harm, injury, or any ill effects 
           is your responsibility and you commit to not hold The Site or its officers, shareholders,  employees, agents, directors, subsidiaries, affiliates, successors, 
           or licensors responsible. Your participation is voluntary and it is your responsibility to stop using The Site if you experience ill effects. 
           You also understand that The Site is not intended to replace or be in lieu of professional medical or diagnostic help you may need for any mental or physical issues you face.</p>
           <h4>Indemnification</h4>
           <p>You agree to indemnify and hold The Site harmless from and against all damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of or 
           related to: (1) your breach of the Agreements or any one of them; (2) any User Content you post or otherwise contribute; (3) any activity in which you engage on or through The Site; 
           and (4) your violation of any law or the rights of a third party.</p>
           <h4>Registration</h4>
           <p>You may access areas of The Site that require registration by becoming a registered member and creating an account with Us. You agree to be responsible for maintaining the 
           confidentiality of your passwords or other account identifiers which you choose and all activities that occur under your account.</p>
           <p>By registering on The Site, you agree that:
           <ol>
           <li>your account and password are personal to you and may not be used by anyone else to access The Site</li>
           <li> you will not do anything which would assist anyone who is not a registered user to gain access to any registration area of The Site</li>
           <li>you will not create registration accounts for the purpose of abusing the functionality of The Site, or other users; nor will you seek to pass yourself off as another user</li>
           <li>that you are of legal age to enter into a legal contract</li>
           </ol>
           </p>
           <p>You agree to notify us immediately if you become aware of any unauthorised use of your password or account identifiers by others.</p>
           <p>We reserve the right to accept or refuse access or to restrict use of The Site at our discretion. You may not transfer or assign your credits or any benefits attained by The  Site. 
           We may take actions we deem reasonably necessary to prevent fraud and abuse, including placing restrictions on the amount of content or other services that can be accessed from The Site at any one time.</p>
           <h4>Termination of Registration</h4>
           <p>If you no longer wish to have a registered account, you may terminate your account by sending an email to info@mycoreinsights.com. If you no longer accept these terms and conditions, or any future 
           modification to these terms and conditions, you must cease using The Site. Continued use of The Site indicates your continued acceptance of these terms and conditions.</p>
           <p>If, for any reason, we believe that you have not complied with these terms and conditions, we may, at our sole discretion, cancel your access to the registration areas of The Site immediately and without prior notice.</p>
           <p>We may terminate your registered account, at our sole discretion, by emailing you at the address you have registered stating that the agreement has terminated.</p>
           <h4>User Generated Content</h4>
           <p>You warrant that the content you submit to us is not obscene, threatening, harassing, libellous, deceptive, fraudulent, invasive of another’s privacy, offensive, defamatory of any person or illegal. You warrant that the content 
           you submit to us does not infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary or privacy right of any party or individual. You agree not to (i) post content which is deliberately intended 
           to upset or harm other users; (ii) use The Site to post or otherwise transmit content that victimises, harasses, degrades, or intimidates an individual or group of individuals on the basis of any impermissible classification, 
           including, without limitation, religion, gender, sexual orientation, race, colour, creed, ethnicity, national origin, citizenship, age, marital status, military status or disability; (iii) post or otherwise transmit any content 
           that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of The Site or any computer software or hardware or telecommunications equipment; (iv) upload 
           or otherwise transmit any content, or take any other actions with respect to your use of The Site, that would constitute, or would otherwise encourage, criminal conduct or give rise to civil liability; or (v) use The Site for 
           commercial purposes, including, without limitation, submitting any material to solicit funds or to promote, advertise or solicit the sale of any goods or services.</p>
           <p>You understand that the technical processing and transmission of The Site may involve (i) transmissions over various networks; and (ii) changes to content to conform and adapt to technical requirements of connecting networks 
           or devices. We assume no responsibility for the deletion or failure to store postings of content or other information submitted by you or other users to The Site.</p>
           <h4>Refunds and Guarantees</h4>
           <p>Credits are purchased from The Site and applied to content of the Site called Objectives, which includes documentation and video content. You can request a refund for credits you have purchased BEFORE they are used to access 
           the contents of an Objective. Once the credit is applied to an Objective, it is no longer refundable. A refund can be requested by emailing info@mycoreinsights.com.</p>
           <h4>Services Limitations</h4>
           <p>We will make reasonable efforts to keep the Site operational. However, certain technical difficulties, maintenance or testing, or updates required to reflect changes in relevant laws and regulatory requirements, may, from time 
           to time, result in temporary interruptions. We reserve the right, periodically and at any time, to modify or discontinue, temporarily or permanently, functions and features of The Site, with advance notice where possible, all without 
           liability to you, except where prohibited by law, for any interruption, modification, or discontinuation of The Site or any function or feature thereof. You understand, agree, and accept that We will make reasonable efforts, although 
           it has no obligation to maintain, support, upgrade, or update The Site, or to provide all or any specific content through The Site. We and/or the owners of any Content may, from time to time, remove any such Content without notice. 
           This section will be enforced to the extent permissible by applicable law.</p>
           <h4>Email opt-in</h4>
           <p>When using our The Site you will be opted-in to receive weekly email updates, tips and suggestions Wewe believe will help you. You may unsubscribe at any time by clicking on the "Unsubscribe or Modify my subscription" link at the bottom of any email sent.</p>
           <h4>No waiver</h4>
           <p>Our failure to insist upon or enforce any provision of these terms of service shall not be construed as a waiver of any provision or right of The Site.</p>
           <h4>Limitations and time of filing</h4>
           <p>YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE SITE IS TO STOP USING THE SITE. YOU AGREE THAT THE SITE HAS NO OBLIGATION OR LIABILITY ARISING FROM OR RELATED TO THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF MADE 
           AVAILABLE THROUGH OR IN CONNECTION WITH THE SITE, AND WHILE YOUR RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, YOUR SOLE AND EXCLUSIVE REMEDY, AS WITH RESPECT TO THE SITE, FOR ANY PROBLEMS OR 
           DISSATISFACTION WITH THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, STOP USING ANY SUCH THIRD PARTY APPLICATIONS.
			IN NO EVENT WILL THE SITE, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; 
			(2) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE OF OR INABILITY TO USE THE SPOTIFY SERVICE, DEVICES THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY, 
			WITHOUT REGARD TO WHETHER THE SITE HAS BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; OR (3) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SPOTIFY SERVICE, THIRD PARTY APPLICATIONS, OR THIRD PARTY 
			APPLICATION CONTENT MORE THAN THE AMOUNTS PAID BY YOU TO THE SITE DURING THE PRIOR TWELVE MONTHS IN QUESTION.</p>
			<p>Nothing in the Agreements removes or limits The Site’s liability for fraud, fraudulent misrepresentation, death or personal injury caused by its negligence, and, if required by applicable law, gross negligence.</p>
			<p>YOU AGREE THAT ANY CLAIM AGAINST THE SITE MUST BE COMMENCED (BY FILING A DEMAND FOR ARBITRATION UNDER SECTION (24.2.1) OR FILING AN INDIVIDUAL ACTION UNDER SECTION (24.2.2) WITHIN ONE (1) YEAR AFTER THE DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS 
				OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION, OR DEFAULT GIVING RISE TO THE CLAIM; AND THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD.
			THIS SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p>


           </div>
            </div>
            </section>
 
            {this.DisplayFooter()}
            </div>
		);
}
}

export default TermsOfUse;