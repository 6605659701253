import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import Popup from "reactjs-popup";
import { APIHost } from './GlobalVariables';
import SessionCheckout from './SessionCheckout';
import PayPalButton from './PayPalButton';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
const promise = loadStripe("pk_live_51HHMFBLb5KZXfDYguK930v3O6ydugI72ThwFDVzIaKdqIVVOsmUDEHJCBm9E6eJAeLGSjvTNmdXNYLXfD9Q9G8R0005gh9V8zN");

class GetSessionCredits extends Component {

  constructor(props) {
    super(props);
    this.state = ({
      loading: true,
      logged_in: true,
      debit_credit: false,
      paypal: false
    });
    this.CheckLogin = this.CheckLogin.bind(this);
    this.PaymentMethod = this.PaymentMethod.bind(this);
    this.DirectToPaypal = this.DirectToPaypal.bind(this);

  }

  DirectToPaypal(event) {
    event.preventDefault();
    const path = "/PayPalSession";
    this.props.history.push({ pathname: path, state: { foo: 'bar' } });
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.setState({ loading: true });
    this.CheckLogin().then((result) => {
      if (result) {
        this.setState({ logged_in: true, loading: false });
      } else {
        this.setState({ logged_in: false, loading: false });
      }
    });
  }

  async CheckLogin() {
    const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
    return await res.data.loggedIn;
  }

  PaymentMethod(event) {
    if (event.target.id == "debit_credit") {
      this.setState({ debit_credit: true, paypal_checked: false });
    } else if (event.target.id == "paypal") {
      this.setState({ paypal: true, debit_credit_checked: false });
    }
  }

  render() {
    if (this.state.loading) {
      return (<h1>Loading...</h1>);
    } else if (!this.state.logged_in) {
      return (<Redirect to='/Home' />);
    } else {
      return (
        <div className="GetSessionCredits">
          <Header history={this.props.history} />
          <section className="two-sided-box">
            <div className="container">
              <div className="row justify-content-center">

                <div className="col-md-6">
                  <div className="d-flex justify-content-center">
                    <div>
                      <Elements stripe={promise}>
                        <SessionCheckout />
                      </Elements>

                      <div className="form-input light-rounded-buttons mt-30">
                        <div className="d-flex align-items-center justify-content-center">
                        <p>Prefer PayPal? Go here</p>
                        <button className="main-btn light-rounded-two ml-2" onClick={this.DirectToPaypal}>PayPal</button>
                        </div>
                       
                      </div>

                      {/* <button className="custom-btn" onClick={this.DirectToPaypal}>PayPal</button> */}
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </section>
          <Footer />
        </div>
      );
    }
  }
}

export default GetSessionCredits;