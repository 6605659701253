import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import {APIHost} from './GlobalVariables';

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class SetCapacity extends Component {

constructor(props) {
	super(props);
	this.state = {
		capacity: 4,
		sprint_defined: false,
		sprint_started: false,
		profile_setup: false,
		capacity_one: 0,
		capacity_two: 0,
		capacity_three: 0,
		capacity_four: 0,
		account_type: this.props.location.state.account_type,
		sprint: [],
        server_failure: false,
		chapters: []
	}
	this.SubmitCapacity = this.SubmitCapacity.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	this.SubmitCapacityCallback = this.SubmitCapacityCallback.bind(this);
	this.CapacityOne = this.CapacityOne.bind(this);
	this.CapacityTwo = this.CapacityTwo.bind(this);
	this.CapacityThree = this.CapacityThree.bind(this);
	this.CapacityFour = this.CapacityFour.bind(this);
}

componentDidMount() {
window.scroll(0,0);

}

SubmitCapacityCallback(result) {
	if(result) {
		this.props.history.push("/Home");
		//this.context.router.history.push("/Home");
	} else {
		console.log("failed to set Capacity")
	}
}

SubmitCapacity(SubmitCapacityCallback, capacity_total) {
var sprint = [];
if (this.state.account_type === 'Personal Training') {
	sprint = [{chapter_id: 1}, {chapter_id: 2}, {chapter_id: 3}];
} else if(capacity_total == 4) {
    sprint = [{chapter_id: 1}];
} else  if (capacity_total == 5 || capacity_total == 6 ||  capacity_total == 7) {
    sprint = [{chapter_id: 1}, {chapter_id: 2}];
} else {
    sprint = [{chapter_id: 1}, {chapter_id: 2}, {chapter_id: 3}];
}
	axios.post(APIHost + '/api/setCapacity', {
      withCredentials: true,
      capacity: capacity_total,
      sprint: sprint,
      initial: true,
      id: 1,
      update: 'first sprint'
      }).then( () => {
        this.SubmitCapacityCallback(true);
      }).catch(error => {
        console.log("setCapacity failed");
        this.setState({server_failure: true});
    }); 
}

onSubmit(event) {
	event.preventDefault();
	var capacity_total = this.state.capacity + parseFloat(this.state.capacity_one) + parseFloat(this.state.capacity_two) +
	parseFloat(this.state.capacity_three) + parseFloat(this.state.capacity_four);
	capacity_total = parseInt(capacity_total);
	this.SubmitCapacity(this.SubmitCapacityCallback, capacity_total);
}

CapacityOne(event) {
	this.setState({capacity_one: parseFloat(event.target.value)});
}

CapacityTwo(event) {
	this.setState({capacity_two: parseFloat(event.target.value)});
}

CapacityThree(event) {
	this.setState({capacity_three: parseFloat(event.target.value)});
}

CapacityFour(event) {
	this.setState({capacity_four: parseFloat(event.target.value)});
}

render() {
	if (this.props.location.state == null) {
		return ( <Redirect to='/Home' />);
	} else {
	return (
		<div className="SetCapacity">
			<Header history={this.props.history} loggedIn={true} />
		    <div className="container_profile pb-5">
            	<div className="row justify-content-center">
                	<div className="col-md-10">
                        <section className="two-sided-box">
            				<div className="two-sided-box-in justify-content-center">                        		
            					<h4>Let's get started!</h4>
                				<div className="row">  
                				                        			<div className="col-md-4 justify-content-center px-3">
                                		<div className="embed-responsive embed-responsive-4by3 service-video">
                                    			<iframe src="https://player.vimeo.com/video/353230665" width="485" height="364" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                                		</div>                        
                    				</div>             
                        			<div className="col-md-6">
                        				    <form onSubmit={this.onSubmit}>
  												<div className="form-group">
    												<p>I can find 1 hour 2-3 times per week to dedicate to this training.</p>
    												<div onChange={this.CapacityOne} className="checkboxOptions">
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="one" value="0" />Not confident 									
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="one" value=".5" />Confident 
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="one" value="1" />Very confident 
    												</div>
    												</div>
    												
    												<p>I could use 1/2 of a lunch break 2-3 times per week to dedicate to the training.</p>
    												<div onChange={this.CapacityTwo} className="checkboxOptions">
    												<div className="floatBlock">			
    												<input type="radio" className="form-control" name="two" value="0" />Not confident     												
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="two" value=".5" />Confident 
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="two" value="1" />Very confident 
    												</div>
    												</div>
    												
    												<p>I could spend 1/2 hour before bed or when I first wake up 2-3 times per week.</p>
    												<div onChange={this.CapacityThree} className="checkboxOptions">
    												<div className="floatBlock">			
    												<input type="radio" className="form-control" name="third" value="0" />Not confident     												
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="third" value=".5" />Confident 
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="third" value="1" />Very confident 
    												</div>
    												</div>
    												
    												<p>I can spend 30 minutes less on TV or other screen time 2-3 times per week and dedicate that to the training.</p>
    												<div onChange={this.CapacityFour} className="checkboxOptions">
    												<div className="floatBlock">			
    												<input type="radio" className="form-control" name="fourth" value="0" />Not confident     												
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="fourth" value=".5" />Confident 
    												</div>
    												<div className="floatBlock">
    												<input type="radio" className="form-control" name="fourth" value="1" />Very confident 
    												</div>
    												</div>
  												</div>
												  <div className="form-input light-rounded-buttons mt-30">
												<button type="submit" className="main-btn light-rounded-two" onClick={this.onSubmit}>Capacity</button>
											</div>
										</form>
                        			</div>

                				</div>            						 	
                			</div>
           				</section>
                </div>                       
            </div>
          </div>		
		<Footer history={this.props.history} loggedIn={true} />
		</div>
	);
} 
}
}

export default SetCapacity;