import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/My-Core-Insights-Transparent.png';

class Footer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        if (!this.props.loggedIn || (this.props.location != null && this.props.location.state.loggedIn)) {
            return (
                <>
                    <div className="Footer">
                        <footer className="footer">
                            <div className="footer-top">
                                <div className="container">
                                    <div className="row py-3">
                                        <div className="col-md-4">
                                            <div className="f-top-left">
                                                <img src={logo} alt="My Core Insights" className="img-fluid" width="200" height="200" />
                                                <p className="copy">
                                                    © 2020 My Core Insights - All Rights Reserved
                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <div className="f-top-center">
                                                <nav className="f-soc-nav">
                                                    <a href="https://www.facebook.com/MyCoreInsights"><i className="fa fa-facebook ml-0"></i></a>
                                                    <a href="https://twitter.com/MyCoreInsights"><i className="fa fa-twitter"></i></a>
                                                    <a href="https://www.instagram.com/MyCoreInsights"><i className="fa fa-instagram"></i></a>
                                                    <div className="mt-3">
                                                        <Link to="/PrivacyPolicy" className="mr-3">Privacy Policy</Link>
                                                        <Link to="/TermsOfUse">Terms of Use</Link>
                                                    </div>
                                                </nav>

                                            </div>
                                            <br /><br />

                                        </div>
                                        <div className="col-md-4 justify-content-center">
                                            <div className="f-top-right d-flex justify-content-center">

                                                <ul className="f-right-list">
                                                    <h3>Navigation</h3>
                                                    <li><Link to={{ pathname: "/" }}>Home</Link></li>
                                                    <li><Link to={{ pathname: "/mft" }}>Mental FitnessTraining</Link></li>
                                                    <li><Link to={{ pathname: "/About" }}>About</Link></li>
                                                    <li><Link to={{ pathname: "/faq" }}>Help</Link></li>
                                                    <li><Link to={{ pathname: "/contact" }}>Contact</Link></li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </>
            );
        } else {
            return (
                <div className="Footer">
                    <footer className="footer">
                        <div className="footer-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="f-top-left d-flex align-items-center" style={{height:"100%"}}>
                                            <p className="copy">
                                                ©2020 My Core Insights - All Rights Reserved
                </p>
                                        </div></div>
                                    <div className="col-md-5">
                                        <div className="f-top-center">
                                        </div></div>
                                    <div className="col-md-3">
                                        <div className="f-top-right">
                                            <Link to="/PrivacyPolicy">Privacy Policy</Link >
                                            <Link to="/TermsOfUse" className="pull-right">Terms of Use</Link>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            );
        }
    }
}

export default Footer;