import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter8 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 8,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		answers: [],
		answers_before: [],
		answers_after: [],
		display_video: false,
		loading: true,
		finished: false,
		cc_exercises: false,
		ca_exercises: false,
		viruses_exercises: false,
		stretch_exercises: false,
		display_video_after: false,
		finished_exercises: false,
		finished_core_constitution: false,
		finished_core_attributes: false,
		finished_viruses: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		core_constitution_count: 0,
		core_attribute_count: 0,
		viruses_count: 0,
		viruses: [],
		questions: [],
		chapter_video: "https://player.vimeo.com/video/447782610"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.ExercisesDone = this.ExercisesDone.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.onSubmitCC = this.onSubmitCC.bind(this);
this.SubmitCC = this.SubmitCC.bind(this);
this.SubmitCCCallback = this.SubmitCCCallback.bind(this);
this.onSubmitCA = this.onSubmitCA.bind(this);
this.SubmitCA = this.SubmitCA.bind(this);
this.SubmitCACallback = this.SubmitCACallback.bind(this);
this.onSubmitViruses = this.onSubmitViruses.bind(this);
this.SubmitViruses = this.SubmitViruses.bind(this);
this.SubmitVirusesCallback = this.SubmitVirusesCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (this.state.chapter_stage == "video") {
			this.setState({display_video: true, 
			cc_exercises: false, ca_exercises: false, display_exercises: false, viruses_exercises: false, started: true});
		} else if (this.state.chapter_stage == "core_constitution") {
			this.setState({display_video: false, 
			cc_exercises: true, ca_exercises: false, display_exercises: true, viruses_exercises: false, started: true});
		} else if (this.state.chapter_stage == "core_attributes")  {
			this.setState({display_video: false, 
			cc_exercises: false, ca_exercises: true, viruses_exercises: false, display_exercises: true, started: true});		
		} else if (this.state.chapter_stage == "viruses")  {
			this.setState({display_video: false, 
			cc_exercises: false, ca_exercises: false, viruses_exercises: true, display_exercises: true, started: true});		
		} else if (this.state.chapter_stage == "done") {
		this.setState({display_before_questions: false, display_video: false, 
			display_after_questions: false, display_exercises: false, display_exercises: false, finished: true, started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		var cnt = 0;
		attributes.data.attributes.forEach((element) => {
			if (element.description != null) {
				cnt++;
				this.setState({[element.id+"_done"]: true});
			}
		});
		if (cnt === attributes.data.attributes.length) {
			this.setState({core_attributes: attributes.data.attributes, core_attribute_count: cnt,
				finished_core_attributes: true});
		} else {
			this.setState({core_attributes: attributes.data.attributes, core_attribute_count: cnt});
		}
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {
		var cnt = 0;
		entries.data.entries.forEach((element) => {
			if (element.description != null) {
				cnt++;
				this.setState({[element.id+"_done"]: true});
			}
		});
		if (cnt === entries.data.entries.length) {
			this.setState({core_constitution: entries.data.entries, core_constitution_count: cnt,
				finished_core_constitution: true});
		} else {
			this.setState({core_constitution: entries.data.entries, core_constitution_count: cnt});
		}	
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		var cnt = 0;
		viruses.data.viruses.forEach((element) => {
			if (element.description != null) {
				cnt++;
			}
		});
		if (cnt === viruses.data.viruses.length) {
			this.setState({viruses: viruses.data.viruses, viruses_count: cnt,
				finished_viruses: true});
		} else {
			this.setState({viruses: viruses.data.viruses, viruses_count: cnt});
		}
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}
	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);

	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(viruses_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		if(this.state.chapter_stage != 'exercises') {
			this.setState({finished_exercises: false});
		}
		var temp_cc = this.state.core_constitution;
		var temp_ca = this.state.core_attributes;
		var temp_virus = this.state.viruses;
		temp_cc.forEach((cc) => {
			if (cc.description != null) {
				cc.description_set = true;
			} else {
				cc.description_set = false;
			}
		});
		temp_ca.forEach((ca) => {
			if (ca.description != null) {
				ca.description_set = true;
			} else {
				ca.description_set = false;
			}
		});
		temp_virus.forEach((virus) => {
			if (virus.description != null) {
				virus.description_set = true;
			} else {
				virus.description_set = false;
			}
		});
		this.setState({loading: false, core_constitution: temp_cc, core_attributes: temp_ca, viruses: temp_virus});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
event.persist();
	if (event.target.name === "cc_describe") {
		var temp_cc= this.state.core_constitution;
		var cnt = 0;
		this.state.core_constitution.find((element) => {
			if (element.id == event.target.id) {
				temp_cc[cnt].id = event.target.id;
				temp_cc[cnt].entry = this.state.core_constitution[cnt].entry;
				temp_cc[cnt].description = event.target.value;
			}
			cnt++;
		});
		this.setState({core_constitution: temp_cc});
	} else	if (event.target.name === "ca_describe") {
		var temp_ca= this.state.core_attributes;
		var cnt = 0;
		this.state.core_attributes.find((element) => {
			if (element.id == event.target.id) {
				temp_ca[cnt].id = event.target.id;
				temp_ca[cnt].entry = this.state.core_attributes[cnt].attribute;
				temp_ca[cnt].description = event.target.value;
			}
			cnt++;
		});
		this.setState({core_attributes: temp_ca});
	} else if (event.target.name === "virus_describe") {
		var temp_virus= this.state.viruses;
		var cnt = 0;
		this.state.viruses.find((element) => {
			if (element.id == event.target.id) {
				temp_virus[cnt].id = event.target.id;
				temp_virus[cnt].entry = this.state.viruses[cnt].virus;
				temp_virus[cnt].description = event.target.value;
			}
			cnt++;
		});
		this.setState({viruses: temp_virus});
	}
	this.setState({[event.target.name]: event.target.value, [event.target.id+"_description"]: event.target.value, [event.target.id+"_done"]: false});
}

DisplayFinished(history) {
	var display = [];
	var display_current = [];
	var display_cc = [];
	var display_ca = [];
	var display_viruses = [];

	for (var i=0; i<this.state.core_attributes.length; i++) {
		display_ca.push(<div className="display_linebreak"><p>{i+1}) {this.state.core_attributes[i].attribute}</p>
		<p>{this.state.core_attributes[i].description}</p></div>);
	}

	for (var i=0; i<this.state.core_constitution.length; i++) {
		display_cc.push(<div className="display_linebreak"><p>{i+1}) {this.state.core_constitution[i].entry}</p>
		<p>{this.state.core_constitution[i].description}</p></div>);
	}

	for (var i=0; i<this.state.viruses.length; i++) {
		display_viruses.push(<div className="display_linebreak"><p>{i+1}) {this.state.viruses[i].virus}</p>
		<p>{this.state.viruses[i].description}</p></div>);
	}

	var his_text = '';
	if(history === "history") {
		his_text = "Here is how you described the major parts of your Mental Core";
	} else {
		his_text = "Congrats! You have finished the chapter!";
	}
		display.push(<div><div className="col-md-12"><h1>{his_text}</h1>
			<p><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
		<h3>Your Core Constitution:</h3>
		<p>{display_cc}</p>
		
		<h3>Your Core Attributes:</h3>
		<p>{display_ca}</p>
		<h3>Your Viruses:</h3>
		<p>{display_viruses}</p>
		<h3 className="my-2">Chapter Video:</h3><p><div className="embed-responsive embed-responsive-4by3 service-video">
    <iframe src={this.state.chapter_video} width="320" height="180" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div></p>
		</div><p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);		
		return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({cc_exercises: true, display_video: false, ca_exercises: false, display_exercises: true, virus_exercises: false});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, display_video: false, 
	display_before_questions: false, display_after_questions: true, finished_exercises: false});
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			percent_complete: .25,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
event.preventDefault();
this.UpdateStage("core_constitution", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitCCCallback(id) {
	var temp_cc = this.state.core_constitution;
	var cnt = 0;

	/*temp_cc.find((element) => {
		if (element.id == id) {
			element.description = this.state.cc;
		}
	});*/
	temp_cc.forEach((element) => {
		if (element.description != null) {
			cnt++;
		}
	});
	if (cnt === this.state.core_constitution.length) {
		this.setState({core_constitution: temp_cc, finished_core_constitution: true});
	} else {
		this.setState({core_constitution: temp_cc});
	}
	this.setState({[id+"_done"]: true});
}

SubmitCC(id) {
	var update = false;
	var temp_cc = this.state.core_constitution;
	temp_cc.find((cc) => {
		if (cc.id == id) {
			if (cc.description_set) {
				update = true;
			} else {
				cc.description_set = true;
			}
		}
	});
	this.setState({core_constitution: temp_cc});
	axios.post(APIHost + '/api/coreConstitution', {
			withCredentials: true,
			description: this.state[`${id}_description`],
			type: "description",
			update: update,
			id: id
		}).then(res => {
			this.SubmitCCCallback(id);
		}).catch(error => {
			console.log("Failed to submit a core constitution description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitCC(event) {
	event.preventDefault();
	//document.getElementById(event.target.id).disabled = true;
	this.SubmitCC(event.target.id);
}

SubmitCACallback(id) {
	var temp_ca = this.state.core_attributes;
	var cnt = 0;

	temp_ca.forEach((element) => {
		if (element.description != null) {
			cnt++;
		}
	});
	if (cnt === this.state.core_attributes.length) {
		this.setState({core_attributes: temp_ca, finished_core_attributes: true});
	} else {
		this.setState({core_attributes: temp_ca});
	}
	this.setState({[id+"_done"]: true});
}

SubmitCA(id) {
	var update = false;
	var temp_ca = this.state.core_attributes;
	temp_ca.find((ca) => {
		if (ca.id == id) {
			if (ca.description_set) {
				update = true;
			} else {
				ca.description_set = true;
			}
		}
	});
	this.setState({core_attributes: temp_ca});
	axios.post(APIHost + '/api/coreAttributes', {
			withCredentials: true,
			description: this.state[`${id}_description`],
			type: "description",
			update: update,
			id: id
		}).then(res => {
			this.SubmitCACallback(id);
		}).catch(error => {
			console.log("Failed to submit a core attributes description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitCA(event) {
	event.preventDefault();
	this.SubmitCA(event.target.id);
}

SubmitVirusesCallback(id) {
	var temp_virus = this.state.viruses;
	var cnt = 0;

	temp_virus.forEach((element) => {
		if (element.description != null) {
			cnt++;
		}
	});
	if (cnt === this.state.viruses.length) {
		this.setState({viruses: temp_virus, finished_viruses: true});
	} else {
		this.setState({viruses: temp_virus});
	}
	this.setState({[id+"_done"]: true});
}

SubmitViruses(id) {
	var update = false;
	var temp_virus = this.state.viruses;
	temp_virus.find((virus) => {
		if (virus.id == id) {
			if (virus.description_set) {
				update = true;
			} else {
				virus.description_set = true;
			}
		}
	});
	this.setState({viruses: temp_virus});
	axios.post(APIHost + '/api/viruses', {
			withCredentials: true,
			description: this.state[`${id}_description`],
			type: "description",
			update: update,
			id: id
		}).then(res => {
			this.SubmitVirusesCallback(id);
		}).catch(error => {
			console.log("Failed to submit virus description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitViruses(event) {
	event.preventDefault();
	//document.getElementById(event.target.id).disabled = true;
	this.SubmitViruses(event.target.id);
}

NextExercisesCallback(stage) {
	window.scroll(0,0);
	if (stage === "core_constitution") {
		// don't know why this is needed but the textarea entries weren't clearing.
		this.state.core_constitution.forEach((element) => {
			document.getElementById(element.id).value = "";
		});
		this.setState({cc_exercises: false, ca_exercises: true, viruses_exercises: false});
	} else if (stage === "core_attributes") {
		this.state.core_attributes.forEach((element) => {
			document.getElementById(element.id).value = "";
		});
		this.setState({cc_exercises: false, ca_exercises: false, viruses_exercises: true});
	} else {
		this.setState({cc_exercises: false, ca_exercises: false, viruses_exercises: false, finished: true});
	}
}

NextExercises(stage) {
	var next_stage = '';
	var percent_complete = null;
	if (stage === "core_constitution") {
		next_stage = "core_attributes";
		percent_complete = .5;
	} else if (stage === "core_attributes") {
		next_stage = "viruses";
		percent_complete = .75;
	} else { 
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback(stage);
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();

	this.NextExercises(event.target.id);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
// display exercises for core attributes and stretch so they can add and remove from those respective lists
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	if (this.state.cc_exercises) {
		for (var i=0; i<this.state.core_constitution.length; i++) {
			display_mc.push(<div><p>{i+1}) {this.state.core_constitution[i].entry}</p>
			<form>
			<div className="form-group">
 			<textarea onChange={this.onExerciseChange} value={this.state.core_constitution[i].description} rows="10" cols="80" type="text" id={this.state.core_constitution[i].id} name="cc_describe" className="form-control"></textarea>
				</div>
				<button onClick={this.onSubmitCC} type="submit" name={`${i+1}_submit_cc`} disabled={this.state[`${this.state.core_constitution[i].id}_done`]} id={this.state.core_constitution[i].id} className="custom-btn">Submit</button>
				</form></div>);
		}
	
		display_intro.push(<div><p>Below, you will provide details for your Core Constitution you entered previously. It is important here
	 		to provide as much detail about each entry that you can. The information here will empower your success in later chapters. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="core_constitution" className="btn btn-primary my-3" disabled={!this.state.finished_core_constitution}>Finish</button></p></div>);
		  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Describing Your Core Constitution</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.ca_exercises) {
		for (var i=0; i<this.state.core_attributes.length; i++) {
			display_mc.push(<div><p>{i+1}) {this.state.core_attributes[i].attribute}</p>
			<form>
			<div className="form-group">
 			<textarea onChange={this.onExerciseChange} value={this.state.core_attributes[i].description} rows="10" cols="80" type="text" id={this.state.core_attributes[i].id} name="ca_describe" className="form-control"></textarea>
				</div>
				<button onClick={this.onSubmitCA} type="submit" name={`${i+1}_submit_ca`} disabled={this.state[`${this.state.core_attributes[i].id}_done`]} id={this.state.core_attributes[i].id} className="custom-btn">Submit</button>
				</form></div>);
		}
	
		display_intro.push(<div><p>Below, you will provide details for your Core Attributes you entered previously. It is important here
	 		to provide as much detail about those events as  you can. The information here will empower your success in later chapters. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="core_attributes" className="btn btn-primary my-3" disabled={!this.state.finished_core_attributes}>Finish</button></p></div>);
		  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
		display.push(<div className="col-md-12"><h1>Describing Your Core Attributes</h1>{display_button}
		{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.viruses_exercises) {
		for (var i=0; i<this.state.viruses.length; i++) {
			display_mc.push(<div><p>{i+1}) {this.state.viruses[i].virus}</p>
			<form>
			<div className="form-group">
 			<textarea onChange={this.onExerciseChange} value={this.state.viruses[i].description} rows="10" cols="80" type="text" id={this.state.viruses[i].id} name="virus_describe" className="form-control"></textarea>
				</div>
				<button onClick={this.onSubmitViruses} type="submit" name={`${i+1}_submit_virus`} disabled={this.state[`${this.state.viruses[i].id}_done`]} id={this.state.viruses[i].id} className="custom-btn">Submit</button>
				</form></div>);
		}
	
		display_intro.push(<div><p>Below, you will provide details for your Core Attributes you entered previously. It is important here
	 		to provide as much detail about those events as  you can. The information here will empower your success in later chapters. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" id="viruses" className="btn btn-primary my-3" disabled={!this.state.finished_viruses}>Finish</button></p></div>);
		  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
		display.push(<div className="col-md-12"><h1>Describing Your Viruses</h1>{display_button}
		{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	}

	return display;
}

GoToEnd() {
	this.UpdateStage("questions_after", this.GoToEndCallback);
}

ExercisesDone() {
var display = [];
display.push(<button onClick={this.GoToEnd} type="submit" id="2" className="custom-btn">Submit</button>
);
return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter8">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter8">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
					{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.finished_exercises && this.ExercisesDone()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter8