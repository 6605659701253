import React, { Component } from 'react';
import axios from 'axios';
import { Link, Route, Redirect } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import idea from './images/idea.jpg';
import { APIHost } from './GlobalVariables';
axios.defaults.withCredentials = true;

const LoginError = () => (
	<p><font color="red">Your email or password were not correct.</font></p>
);

const NoAccount = () => (
	<p><font color="red">There doesn't appear to be an account with that email.
<Link to='/CreateAccount'>Create Account</Link></font></p>
);

const ExceededLoginFailures = () => (
	<p><font color="red">You have exceeded the number of login failures allowed. You will have to reset your password to login.</font></p>
);

const ServerFailure = () => (
	<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			failedLogin: false,
			loginSuccess: false,
			loading: true,
			server_failure: false,
			noAccount: false
		}
		this.Login = this.Login.bind(this);
		this.loginCallBack = this.loginCallBack.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.CheckLogin = this.CheckLogin.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.CheckLogin().then((loggedin) => {
			if (loggedin) {
				this.setState({ loginSuccess: true, loading: false });
			} else {
				this.setState({ loginSuccess: false, loading: false });
			}
		}).catch((error) => {
			console.log("CheckLogin failed");
			this.setState({ server_failure: true, loading: false });
		});
	}

	async CheckLogin() {
		const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
		return await res.data.loggedIn;
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	loginCallBack(response, error) {
		if (response) {
			this.setState({ failedLogin: false, loginSuccess: true });
			this.props.history.push({ pathname: '/', state: { from_login: true } });
			//<Redirect to={{pathname: '/profile', state: {authenticated: true}}} />
		} else {
			if (error === "wrong password") {
				console.log("Wrong password");
				this.setState({ failedLogin: true });
			} else if (error === "no account") {
				this.setState({ noAccount: true });
			} else if (error === "Exceeded login failures") {
				this.setState({ exceeded_failure: true });
			}
		}

	}

	Login() {
		var temp_email = this.state.email.toLowerCase();
		this.setState({email: temp_email});
		axios.post(APIHost + '/api/login', {
			email: temp_email,
			password: this.state.password,
			withCredentials: true
		}).then(res => {
			this.loginCallBack(true);
		}).catch(error => {
			this.loginCallBack(false, error.response.data.errorMessage);
		});
	}

	onSubmit(event) {
		event.preventDefault();
		this.Login();
	}

	// I should be able to pass from to the onSubmit with that callback way for onSumbit and then use
	// that in the LoginCallBack to redirect them to from
	render() {
		if (this.state.loading) {
			return (<h1>Loading...</h1>);
		} else if (this.state.loginSuccess) {
			return (<Redirect to='/Home' />);
		} else {
			return (
				<div className="Login">
					<Header />
					<section className="two-sided-box">
						<div className="container">
							{this.state.server_failure && <ServerFailure />}
							<div className="row justify-content-center">

								{/* <div className="col-md-5">
											<img src={idea} alt="Idea" className="img-fluid" />

										</div> */}
								<div className="col-md-7 pt-4">
									<h2 className="section-title">Welcome Back!</h2>
									<form onSubmit={this.onSubmit}>
									<div className="form-input mt-25">
											<label>Email</label>
											<div className="input-items default">
												<input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
												<i className="lni lni-envelope"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Password</label>
											<div className="input-items default">
												<input type="password" id="password" name="password" onChange={this.onChange} placeholder="Password" />
												<i className="lni lni-key"></i>
											</div>
										</div>
										<div className="form-input light-rounded-buttons mt-30">
											<button type="submit" className="main-btn light-rounded-two" >Login</button>
											<Link to={{ pathname: "/forgotPassword" }} className="ml-4">Forgot your password?</Link>
										</div>
										{/* <div className="form-group">
											<input type="email" className="form-control" name="email" placeholder="Email address"
												onChange={this.onChange} />
										</div>
										<div className="form-group">
											<input type="password" className="form-control" name="password" placeholder="password"
												onChange={this.onChange} />
										</div> */}

										{/* <button type="submit" className="custom-btn">Login</button><br /> */}
										
									</form>
									{this.state.failedLogin && <LoginError />}
									{this.state.noAccount && <NoAccount />}
									{this.state.exceeded_failure && <ExceededLoginFailures />}
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>
			);
		}
	}
}

export default Login;