import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter39 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 39,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		core_attributes: [],
		core_constitution: [],
		language: [],
		past_language: [],
		review: false,
		associations: false,
		finished: false,
		display_exercises: false,
		stretch_attributes: [],
		stretch_associations: [],
		virus_associations: [],
		ca_associations: [],
		cc_associations: [],
		missing_attributes_entries: [],
		stretch_reliance: false,
		descriptions_notdone: true,
		high_stretch: false,
		chapter_video: "https://player.vimeo.com/video/451333030"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GoToEnd = this.GoToEnd.bind(this);
this.onGoToEnd = this.onGoToEnd.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetStretchAttributes = this.GetStretchAttributes.bind(this);
this.GetExerciseAnswers = this.GetExerciseAnswers.bind(this);
this.GetDaily = this.GetDaily.bind(this);
this.GetPast = this.GetPast.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.onSubmitStretch = this.onSubmitStretch.bind(this);
this.SubmitStretch = this.SubmitStretch.bind(this);
this.SubmitStretchCallback = this.SubmitStretchCallback.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, attribute_associations: false, 
				stretch_reliance: false, missing_attributes: false, display_exercises: false, started: true});
		} else if (stage.data.stage == "exercises")  {
			this.setState({display_video: false, display_exercises: true, started: true});			
		} else if (stage.data.stage  == "done") {
			this.setState({display_video: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var stretch_promise = this.GetStretchAttributes().then((stretch) => {
		var flag = false;
		stretch.data.attributes.forEach((sa) => {
			if(!sa.description) {
				flag = true;
			}
		});
		if (flag) {
			this.setState({descriptions_notdone: true});
		} else {
			this.setState({descriptions_notdone: false});
		}
		this.setState({stretch_attributes: stretch.data.attributes});
	}).catch((error) => {
		console.log("GetStretchAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var daily_promise = this.GetDaily().then((daily) => {
		this.setState({daily_life: daily.data.daily_life});
	}).catch((error) => {
		console.log("GetDaily failed " + error);
		this.setState({server_failure: true});
	});

	var past_promise = this.GetPast().then((past) => {
		this.setState({past_life: past.data.past_life});
	}).catch((error) => {
		console.log("GetPast failed " + error);
		this.setState({server_failure: true});
	});

	var language_promise = this.GetLanguage().then((language) => {
		this.setState({language: language.data.language});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	var exercise_answers_promise = this.GetExerciseAnswers().then ((exercise_answers) => {
		this.setState({exercise_answers: exercise_answers.data.exercise_answers});
		this.state.exercise_answers.forEach((element) => {
			this.setState({[element.exercise_id + "_done"]: true});
		});
	}).catch((error) => {
		console.log("GetExerciseAnswers failed " + error);
		this.setState({server_failure: true});
	});

	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(stretch_promise);
	all_promises.push(exercise_answers_promise);
	all_promises.push(daily_promise);
	all_promises.push(past_promise);
	all_promises.push(language_promise);
	Promise.all(all_promises).then( () => {
		this.setState({loading: false});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetExerciseAnswers() {
	const res = await axios.get(APIHost + '/api/getExerciseAnswers',
		{params: {withCredentials: true, chapter_id: this.state.chapterID}});
	return await res;
}

async GetStretchAttributes() {
	const res = await axios.get(APIHost + '/api/stretchAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetDaily() {
	const res = await axios.get(APIHost + '/api/dailyLife',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetPast() {
	const res = await axios.get(APIHost + '/api/pastLife',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({["description"+event.target.id]: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	var display_sa = [];
	var display_daily = [];
	var display_language = [];

	this.state.stretch_attributes.forEach((sa) => {
		var daily_flag = false;
		var past_flag = false;
		var cc_flag = false;
		var display_daily = [];
		var virus_flag = false;
		var display_cc = [];
		var display_past = [];
		display_sa.push(<div><h4>Stretch Attribute: {sa.attribute}</h4></div>);
		this.state.language.find((lang) => {
			if (lang.association_id === sa.id && lang.association_type == "stretch attribute") {
				if (lang.type == "daily life") {
					daily_flag = true;
					this.state.daily_life.find((dl) => {
						if (dl.id === lang.original_id) {
							display_daily.push(<div><p><font color="blue">Entry:</font> {dl.entry}</p><p><font color="blue">Description:</font> {dl.description}</p></div>);
						}
					});
				} else if (lang.type == "past life") {
					past_flag = true;
					this.state.past_life.find((pl) => {
						if (pl.id === lang.original_id) {
							display_past.push(<div><p><font color="blue">Entry:</font> {pl.entry}</p><p><font color="blue">Description:</font> {pl.description}</p></div>);
						}
					});
				}
			}
		});
		if (daily_flag) {
			display_sa.push(<div><h5>You associated this Stretch Attribute to the following Daily Life events:</h5>{display_daily}</div>);
		}
		if (past_flag) {
			display_sa.push(<div><h5>You associated this Stretch Attribute to the following Past Life events:</h5>{display_past}</div>);
		}
		this.state.core_constitution.find((cc) => {
			if (cc.association_id === sa.id && cc.association_type == "stretch attribute") {
				display_cc.push(<p><font color="blue">Core Constitution entry:</font> {cc.entry}</p>);
				display_cc.push(<p><font color="blue">Core Constitution description:</font> {cc.description}</p>);
				cc_flag = true;
			}
		});
		if (cc_flag) {
			display_sa.push(<div><h5>You associated this Stretch Attribute with the following Core Constitution entries</h5>{display_cc}</div>);
		}
		if (sa.description) {
			display_sa.push(<div><p><font color="blue">Stretch Attribute's description:</font> {sa.description}</p></div>);
		} else {
			display_sa.push(<div><p><font color="blue">Please provide a description for this Stretch Attribute:</font>	
			<form>
		  	<div className="form-group">
				<textarea onChange={this.onExerciseChange} rows="10" cols="80" type="text" id={sa.id} name={[`description${sa.id}`]} className="form-control"></textarea>
			</div>
			<button onClick={this.onSubmitStretch} type="submit" id={sa.id} disabled={this.state[`${sa.id}_done`]} className="custom-btn">Submit</button>
			</form></p></div>);
		}
		display_sa.push(<hr />);

	});
	display_intro.push(<div><p>Below you'll find your completed Stretch Attribute information. Take your time to review the added descriptions and the associations. 
	You will continue to build the muscle of practiced and incorporating your Stretch Attributes fully into your Mental Core in the coming chapters, but
	this is your Journey to continue each and every day. Look for those opportunities to express these Stretch Attributes in healthy ways so they become
	part of your Authentic Self expression.</p><hr className="new" />{display_sa}</div>);

	

	if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	display.push(<div className="col-md-12"><h1>Congrats! You finished the Chapter!</h1>{display_button}
	{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}	<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);

	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, attribute_associations: true, 
		stretch_reliance: false, missing_attributes: false, display_exercises: true});
}

SetChapterStage(callback) {
	axios.post(APIHost + '/api/updateStage', {
			stage: "exercises",
			percent_complete: .5,
			withCredentials: true,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
	event.preventDefault();
	this.SetChapterStage(this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_exercises: false, started: true, finished: true});
}

GoToEnd() {
	var percent_complete = null;
	axios.post(APIHost + '/api/updateStage', {
		stage: "done",
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID,
		percent_complete: percent_complete
	}).then(res => {
    	this.GoToEndCallback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

onGoToEnd(event) {
	event.preventDefault();
	this.GoToEnd();
}

SubmitStretchCallback(id) {
	this.setState({[id+"_done"]: true});
	var temp_stretch = this.state.stretch_attributes;
	temp_stretch.find((stretch) => {
		if (id == stretch.id) {
			stretch.description = this.state[`description${id}`];
		}
	});
	var flag = false;
	this.state.stretch_attributes.forEach((sa) => {
		if(!sa.description && sa.id != id) {
			flag = true;
		}
	});
	if (flag) {
		this.setState({descriptions_notdone: true});
	} else {
		this.setState({descriptions_notdone: false});
	}
	this.setState({stretch_attributes: temp_stretch});
}

SubmitStretch(id) {
	axios.post(APIHost + '/api/stretchAttributes', {
			withCredentials: true,
			description: this.state[`description${id}`],
			type: "description",
			id: id
		}).then(res => {
			this.SubmitStretchCallback(id);
		}).catch(error => {
			console.log("Failed to submit a core attributes description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitStretch(event) {
	event.preventDefault();
	//document.getElementById(event.target.id).disabled = true;
	this.SubmitStretch(event.target.id);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	var display_sa = [];
	var display_daily = [];
	var display_language = [];

	this.state.stretch_attributes.forEach((sa) => {
		var daily_flag = false;
		var past_flag = false;
		var cc_flag = false;
		var display_daily = [];
		var virus_flag = false;
		var display_cc = [];
		var display_past = [];
		display_sa.push(<div><h4>Stretch Attribute: {sa.attribute}</h4></div>);
		this.state.language.find((lang) => {
			if (lang.association_id === sa.id && lang.association_type == "stretch attribute") {
				if (lang.type == "daily life") {
					daily_flag = true;
					this.state.daily_life.find((dl) => {
						if (dl.id === lang.original_id) {
							display_daily.push(<div><p><font color="blue">Entry:</font> {dl.entry}</p><p><font color="blue">Description:</font> {dl.description}</p></div>);
						}
					});
				} else if (lang.type == "past life") {
					past_flag = true;
					this.state.past_life.find((pl) => {
						if (pl.id === lang.original_id) {
							display_past.push(<div><p><font color="blue">Entry:</font> {pl.entry}</p><p><font color="blue">Description:</font> {pl.description}</p></div>);
						}
					});
				}
			}
		});
		if (daily_flag) {
			display_sa.push(<div><h5>You associated this Stretch Attribute to the following Daily Life events:</h5>{display_daily}</div>);
		}
		if (past_flag) {
			display_sa.push(<div><h5>You associated this Stretch Attribute to the following Past Life events:</h5>{display_past}</div>);
		}
		this.state.core_constitution.find((cc) => {
			if (cc.association_id === sa.id && cc.association_type == "stretch attribute") {
				display_cc.push(<p><font color="blue">Core Constitution entry:</font> {cc.entry}</p>);
				display_cc.push(<p><font color="blue">Core Constitution description:</font> {cc.description}</p>);
				cc_flag = true;
			}
		});
		if (cc_flag) {
			display_sa.push(<div><h5>You associated this Stretch Attribute with the following Core Constitution entries</h5>{display_cc}</div>);
		}
		if (sa.description) {
			display_sa.push(<div><p><font color="blue">Stretch Attribute's description:</font> {sa.description}</p></div>);
		} else {
			display_sa.push(<div><p><font color="blue">Please provide a description for this Stretch Attribute:</font>		
			<form>
		  	<div className="form-group">
				<textarea onChange={this.onExerciseChange} rows="10" cols="80" type="text" id={sa.id} name={[`description${sa.id}`]} className="form-control"></textarea>
			</div>
			<button onClick={this.onSubmitStretch} type="submit" id={sa.id} disabled={this.state[`${sa.id}_done`]} className="custom-btn">Submit</button>
			</form></p></div>);
		}
		display_sa.push(<hr />);

	});
	display_intro.push(<div><p>In this section, you will have a chance to review and practice your Stretch Attributes. These Attributes represent
	a huge opportunity to design your Mental Core the way you want to, driving your Optimal Life. They are the positive Attributes that you can express 
	at times, but don't come as easily or naturally as your Core Attributes do. In later Themes of the Training, you will continue to practice your Stretch
	Attributes with the idea that eventually they will become Core Attributes and fully part of your Mental Core. This is your first step in that Journey.
	Take your time to explore the below and practice each of your Stretch Attributes each and every day by being mindful of what they mean to you and the 
	role they play in how you show up in your life. <b>Please provide a description for each based on your understanding you've developed throughout these chapters.
	These descriptions will be used in later chapters when you strengthen your Mental Core and necessary before moving on.</b></p><hr className="new" />{display_sa}</div>);

	var display_next = [];
	display_next.push(<div><p><button onClick={this.onGoToEnd} type="submit" disabled={this.state.descriptions_notdone} className="custom-btn">Finish Chapter</button></p></div>);

	if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	display.push(<div className="col-md-12"><h1>Practicing Your Stretch Attributes</h1>{display_button}
	{display_video}{display_intro}{display_next}</div>);

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter39">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter39">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter39