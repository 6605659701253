import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import me from './images/me.jpg';
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';
import {APIHost} from './GlobalVariables';

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class Retrospective extends Component {
	constructor(props) {
		super(props);
		this.state = {
			capacity: 0,
			objectives: [],
			themes: [],
			chapters: [],
			purchased_objectives: [],
			chapter_descriptions: [],
			chapter_points: [],
			sprint: [],
			all_chapters: [],
			objective_progress: [],
			wentwell: null,
			challenges: null,
			learnings: null,
			credits: 0,
			loading: true,
			server_failure: false,
			sprint_info: {},
			sprint_id: 0,
			first_retro: this.props.location.state.first_retro,
			velocity: 0,
		}
		this.GetChapters = this.GetChapters.bind(this);
		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.GetCapacity = this.GetCapacity.bind(this);
		this.GetSprint = this.GetSprint.bind(this);
		this.SprintPlanning = this.SprintPlanning.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.SprintPlanningCallback = this.SprintPlanningCallback.bind(this);
		this.GetProgress = this.GetProgress.bind(this);
		this.GetVelocity = this.GetVelocity.bind(this);
		this.onChange = this.onChange.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	this.setState({loading: true});
	if(this.props.location.state != null) {	
	var progress_promise = this.GetProgress().then((progress) => {
		this.setState({objective_progress: progress.data.sorted_objectives});
		return progress;
	}).catch((error) => {
		console.log("GetProgress failed " + error);
		this.setState({server_failure: true});
	});
	
    var sprint_promise = this.GetSprint().then(sprint => {
		this.setState({sprint: sprint.data.sprint, sprint_info: sprint.data.sprint_info, sprint_id: sprint.data.sprint_info.sprint_id});
		return sprint;
	}).catch((error) => {
		console.log("GetSprint failed " + error);
		this.setState({server_failure: true});
	});

	var objective_promise = this.GetObjectives().then(objectives => {
   			let local_objectives = objectives.data.objectives;
   			local_objectives.forEach((element) => {
   			element.plus = true;
   			element.hide = true;
   			element.purchased = false;
   			element.added = false;
   			});
   			this.setState({objectives: local_objectives})
   			return objectives;
   	}).catch((error) => {
		console.log("GetObjectives failed " + error);
		this.setState({server_failure: true});
	});
    		
    var chapters_promise = this.GetChapters().then(chapters => {
    	this.setState({chapters: chapters.data.chapters});
		return chapters;
	}).catch((error) => {
		console.log("GetChapters failed " + error);
		this.setState({server_failure: true});
	});
	
	var capacity_promise = this.GetCapacity().then(capacity => {
    	this.setState({capacity: capacity.data.capacity});
    	return capacity;
    }).catch((error) => {
		console.log("GetCapacity failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(sprint_promise);
	all_promises.push(objective_promise);
	all_promises.push(chapters_promise);
	all_promises.push(capacity_promise);
	
	Promise.all(all_promises).then( () => {
	  	let local_chapters = this.state.chapters;
		let local_objectives = this.state.objectives;
		var points=0;
		var sprint_points = 0;
		let local_sprint = this.state.sprint;
   		local_sprint.forEach((sp) => {
		this.state.chapters.find((element) => {
			if (element.id == sp.chapter_id) {
				sp.description = element.description;
				sp.objective_id = element.objective_id;
				sp.points = element.points;
				sp.chapter_id = element.id;
				sprint_points = sprint_points + element.points;
			}
		});

		});
		this.setState({sprint: local_sprint, sprint_points: sprint_points});
  		local_objectives.forEach((element) => {
  		points=0;
  		for (var j=0; j<this.state.chapters.length; j++) {
  			if (this.state.chapters[j].objective_id == element.id) {
  				points = parseInt(points)+parseInt(this.state.chapters[j].points);
  			}
		}
		element.points = points;
  		});

    	this.GetVelocity(this.state.sprint_id).then((data) => {
    		if (sprint_points ==  data.data.velocity) {
    			this.setState({hit_target: true});
			} else if (sprint_points > data.data.velocity && sprint_points > this.state.capacity) {
				this.setState({missed_target_capacity: true});
			} else if (sprint_points > data.data.velocity) {
				this.setState({missed_target: true});
			}
		    this.setState({velocity: data.data.velocity, chapters: local_chapters, objectives: local_objectives, loading: false}) 
		}).catch((error) => {
			console.log("GetVelocity failed");
			this.setState({server_failure: true});
		});
  	});
  	}
}

componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
}

async GetVelocity(sprint_id) {
	const res = await axios.get(APIHost + '/api/getVelocity', {params: {withCredentials: true,
	sprint_id: sprint_id}});
	return await res;
}

async GetObjectives() {
	const res = await axios.get(APIHost + '/api/getObjectives', {withCredentials: true});
	return await res; 
}

async GetChapters() {
	const res = await axios.get(APIHost + '/api/getChapters', {withCredentials: true});
	return await res; 
}

async GetSprint() {
	const res = await axios.post(APIHost + '/api/getSprint', {withCredentials: true});
	return await res; 
}

async GetProgress() {
	const res = await axios.get(APIHost + '/api/getProgress', {withCredentials: true});
	return await res;
}

async GetCapacity() {
	const res = await axios.get(APIHost + '/api/getCapacity', {withCredentials: true});
	return await res;
}

onChange(event) {
	this.setState({ [event.target.name]: event.target.value });
}

	DisplaySprint() {
		var for_display = [];
		var row = [];
		var points = 0;
		var sprint_rows = [];
		var points = 0;
		for (var i = 0; i < this.state.sprint.length; i++) {
			row = [];
			var flag = false;
			if (this.state.sprint[i].status == "open") {
				row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
				points = points + this.state.sprint[i].points;
				flag = true;
			} else {
				row.push(<td></td>);
			}
			if (this.state.sprint[i].status == "in progress") {
				row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
				points = points + this.state.sprint[i].points;
				flag = true;
			} else {
				row.push(<td></td>);
			}
			if (this.state.sprint[i].status == "done") {
				row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
				points = points + this.state.sprint[i].points;
				flag = true;
			} else {
				row.push(<td></td>);
			}
			if (flag) {
				sprint_rows.push(<tr>{row}</tr>);
			}
		}
		for_display.push(<table className="table table-bordered"><thead><tr><th>To Do (points)</th><th>In Progress (points)</th><th>Done (points)</th>
		</tr></thead><tbody>{sprint_rows}</tbody></table>);
		for_display.push(<h4>Training Capacity: {this.state.capacity} <br />Points completed (Velocity): {this.state.velocity} <br />Points committed to: {this.state.sprint_points}</h4>);
		if (this.state.hit_target) {
			for_display.push(<h4>Looks like you hit your mark in this Sprint! You completed all the chapters you committed to...that's Great!!</h4>);

		} else if (this.state.missed_target) {
			for_display.push(<p>Looks like this Sprint was a bit of a challenge. You weren't able to complete all the chapters. That's OK!
			What you didn't finish will simply roll over to the next Sprint. You will have an opportunity to adjust your capacity in Sprint Planning, but
			don't worry...this is all about Progress Over Perfection. You are making great progress towards your Optimal Life! You Got This!</p>);
		} else if (this.state.missed_target_capacity) {
			for_display.push(<p>Looks like this Sprint was a bit of a challenge. You weren't able to complete all the chapters, but it looks like you were
			stretching a bit beyond your Capacity. That's a good thing! Scrum is all about seeing what works - Learn, Adapt, and Improve. 
			What you didn't finish will simply roll over to the next Sprint. You will have an opportunity to adjust your capacity in Sprint Planning.
			You are making great progress towards your Optimal Life! You Got This!</p>);
		}

		return for_display;
	}

SprintPlanningCallback(status) {
	if(status) {
		this.props.history.push({pathname: '/SprintPlanning', state: { foo: 'bar'}});
	} else {	
		console.log("couldn't update the sprint");
	}
}

SprintPlanning(SprintPlanningCallback) {
	if (this.state.first_retro) {
		axios.post(APIHost + '/api/firstVisits', {
			withCredentials: true,
			update: 'done'
		}).then(() => {
			axios.post(APIHost + '/api/closeSprint', {
		      sprint_id: this.state.sprint_id,
		      withCredentials: true,
		      wentwell: this.state.wentwell,
		      challenges: this.state.challenges,
		      learnings: this.state.learnings
		    }).then(res => {
		    	SprintPlanningCallback(true);
		    }).catch(error => {
		    	console.log("closeSprint failed");
		    	this.setState({server_failure: true});
		    });
		}).catch((error) => {
			console.log("Failed to update firstVisits");
		});
	} else {
		axios.post(APIHost + '/api/closeSprint', {
	      sprint_id: this.state.sprint_id,
	      withCredentials: true,
	      wentwell: this.state.wentwell,
	      challenges: this.state.challenges,
	      learnings: this.state.learnings
	    }).then(res => {
	    	SprintPlanningCallback(true);
	    }).catch(error => {
	    	console.log("closeSprint failed");
	    	this.setState({server_failure: true});
	    });
	}
}

onSubmit(event) {
event.preventDefault();
this.SprintPlanning(this.SprintPlanningCallback);
}

DisplayProgress() {
	var for_display = [];
	var percent = 0;
	this.state.objective_progress.forEach((element) => {
		this.state.objectives.find((obj) => {
			if(obj.id == element.objective_id) {
				for_display.push(<h4>{obj.description}</h4>);
			}
		});
		percent = (element.percent_complete*100).toFixed(0) + "%";
	    for_display.push(<div className="progress">
  <div className="progress-bar progress-bar-epic" role="progressbar" aria-valuenow="40"
  aria-valuemin="0" aria-valuemax="100" style={{width: percent}}>
    {percent}
  </div>
</div>);
	});
	return for_display;
}
	
render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	return (
		<div className="Retrospective">
			<Header history={this.props.history} preview={true} loggedIn={true} />
			<div className="container_profile">
			{this.state.server_failure && <ServerFailure />}
				<div className="row">
				<div className="col-md-6">
                	<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
                        	<h2>Congratulations on completing the sprint! Let's see how it went!</h2>                        
								{!this.state.loading && this.DisplaySprint()}
                			</div>                	
                		</div>           
           			</section>
           			                	<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
								{!this.state.loading && this.DisplayProgress()}
                			</div>                	
                		</div>           
           			</section>
           		</div>
           		<div className="col-md-6">
        			<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
                        	    <form onSubmit={this.onSubmit}>
                        	    <p>What do you feel went well this sprint?</p>
      							<div className="form-group">
    								<textarea name="wentwell" className="form-control" id="wentwell" 
										rows="7" onChange={this.onChange}></textarea>
  								</div>
  								<p>What were some challenges you faced this sprint?</p>
  								<div className="form-group">
    								<textarea name="challenges" className="form-control" id="challenges" 
										rows="7" onChange={this.onChange}></textarea>
  								</div>
  								<p>What are the key takeaways you had this sprint?</p>
  								<div className="form-group">
									<textarea name="learnings" className="form-control" id="learnings" 
										rows="7" onChange={this.onChange}></textarea>
  								</div>

							</form>  
							<p>Clicking below will submit your retrospective info. The current sprint will be closed and a new
							one will be opened with any chapters you didn't complete. You'll have an opportunity to update the
							sprint during Sprint Planning on the next page.</p>                      
							<button onClick={this.onSubmit} id="SprintPlanning" type="submit" className="custom-btn">Sprint Planning</button>
                			</div>                	
                		</div>           
          			 </section>
          		</div>
        		</div>
        	</div>
			<Footer history={this.props.history} preview={true} loggedIn={true} />
		</div>
	);		
	}
	}
}

export default Retrospective;