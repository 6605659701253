import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';

class Articles extends Component {
	render() {
/*if(true) {
 return (<div className="Articles"><Header history={this.props.history} />
    <h3>Coming Soon!</h3><Footer history={this.props.history} /></div>);
} else {*/
		return (
		<div className="Articles">
		<Header history={this.props.history} />
       <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">Message from Elliot</h4>
                    <p className="text">First and foremost, Welcome!! I am so thankful and excited that you're here at My Core Insights. The intent of this page is to introduce me to you and for us to get to know each other a bit better. If you're interested in my background 
                    and how all this came to be, please watch the featured video on this page. The rest of this page contains deatils about seminars and workshops I provide as well as special video and written blog content that gets updated often, so come back as much as you can and check it out! And, of course, I'm here to help so reach out to me any time!
                    I believe in you my friend! You got this!
                     </p>


                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src="https://player.vimeo.com/video/563817052" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>  

       <section id="testimonial" className="testimonial-area">
            <div className="container">
                     <h4>Do you want Elliot to deliver a keynote or run a workshop to empower your group or organization? Here are a few topics that are offered. Please <Link to={{ pathname: "/contact" }}>Contact</Link> us today to discuss how we can serve your needs.
                        </h4>
                                   <div className="row justify-content-between">

                     <div className="col-xl-5 col-lg-6">
                     <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>The power of language in mental health</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Training your subconscious</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Creating a life full of Purposeful Meaning</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Overcoming the Introspection Delusion</h6>
                                            </div>
                                            </li>
            
                                    </ul>
                                    </div>
                                    <div className="col-lg-6">
                   <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>The power of the internal narrative</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Defining and creating success</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Conflict empowerment </h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Creating Optimal relationships</h6>
                                            </div>
                                            </li>
            
                                    </ul>
                                    </div>
                                    </div></div></section>
        <div className="container">
            <div className="row">

                <div className="col-md-8">
                    <section className="blog-grid">
                        <div className="row">
        <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/476484684" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
An empowering lesson for relationships...what language should you express for relationship success?                                    </h5>

                                </div> 
                            </div>
                        <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/473954932" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
To live an Authentic Life is to show-up in your life by your design. To get there, start by getting the little things right!                                    
</h5>

                                </div> 
                            </div>
<div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/473964116" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Here is a key question you must ask yourself in each moment you face, no matter how small of a moment you think it is!
                                    </h5>

                                </div> 
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/462850861" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Why is it that we wait for a crisis to take action towards something greater for ourselves or avoid disaster?
                                    </h5>

                                </div> 
                            </div>

                            <div className="col-sm-6">
                                <div className="grid-box">
                                <div className="for_iframe">
<iframe src="https://player.vimeo.com/video/462850573" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        
</div>
                                   <h5>
                                    
                                    Be more passionate about what's to come than what was. Direct your moment!<br /><br />
                                    </h5>

                                </div> 
                            </div>

                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <figure>
<iframe src="https://player.vimeo.com/video/462850399" width="300" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>
                                    <h5>
                                    Seek healthy conflict within relationships to release the necessary tension and avoid resentment.
                                    </h5>

                                </div>
                            </div>

                            <div className="col-sm-6">
 
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2 id="futureself" name="futureself">
                                        <Link to="/FutureSelf">Honor your Future Self!</Link>
                                    </h2>
                                    <p>
                                        We live on the razor-thin line between our past and future...that is our moment. Every decision we make, every response we give to the world impacts the path we are on...
                                    </p>
                                    <Link to="/FutureSelf" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                                                        <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2 id="sorry" name="sorry">
                                        <Link to="/Sorry">The ineffective Sorry</Link>
                                    </h2>
                                    <p>
                                        She sat there, wondering if he was going to text or call to explain why he was so late - again. This was
                                        the third night...<br /><br />
                                    </p>
                                    <Link to="/Sorry" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2>
                                        <Link to="/LivingProactively">Living Proactively</Link>
                                    </h2>
                                    <p>
                                        We live very tactical and reactionary lives. We necessarily fail to see this. We make
										plans for vacations to ensure we have a happy family life...
                                    </p>
                                    <Link to="/LivingProactively" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2>
                                        <Link to="/Resentment">The Insidious Nature of Resentment</Link>
                                    </h2>
                                    <p>
                                        Resentment is the most insidious of emotions because it manifests itself in moments unrelated to the
										source...
                                    </p>
                                    <Link to="/Resentment" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div> 
                            </div>

						    <div className="col-sm-6">
                                <div className="grid-box">
                                    <h2>
                                        <Link to="/UnderReact">Underreact</Link>
                                    </h2>
                                    <p>
                                        He sits there, waiting more and more impatiently for your date to arrive. She is known to be late at
										times, but this is getting ridiculous. Finally, in she walks,
                                    </p>
                                    <Link to="/UnderReact" className="blog-read-more">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div> 
                            </div>



                        </div>

                    </section> 

                </div>

                <aside className="col-md-4">
                    <div className="sidebar-blog">
                        <div className="recent-post">

                            <h3>Recent Videos</h3><hr />
                            <div className="related-post">
                                <div className="media">
                                    <div className="media-left">
                                    <h5><Link to="LittleThingsRight">Get the Little Things Right!</Link></h5>
<iframe src="https://player.vimeo.com/video/473954932" width="150" height="100" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        

                                       
                                    </div>

                                </div>
                            </div> 

                        </div>
                        <div className="recent-post">

                            <h3>Recent Articles</h3><hr />

                            <div className="related-post">
                                <div className="media">
                                    <div className="media-body">
                                        <p className="post-text">
                                            <Link to="/FutureSelf">Honor your future self</Link><br />
                                             We live on the razor-thin line between our past and future...that is our moment. Every decision we make, every response we give to the world impacts the path we are on...
                                        </p>
                                    </div>
                                </div>
                            </div>



                        </div>



                    </div>
                </aside>

            </div>
        </div> 

<Footer history={this.props.history} />

</div>
		);
		
	//}	
		
	}
}

export default Articles;