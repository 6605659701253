import React, { Component } from 'react';
import './css/faq.css';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { Link } from 'react-router-dom';
import { APIHost } from './GlobalVariables';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const NameEmpty = () => (
    <p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
    <p><font color="red">Please make sure to provide a valid email address.</font></p>
);

const QuestionEmpty = () => (
    <p><font color="red">Please make sure to provide content in your question.</font></p>
);

const QuestionFailed = () => (
    <p><font color="red">Your question failed to send. Please try again.</font></p>
);

const QuestionSent = () => (
    <p><font color="blue">Thanks so much for your question. We will get back to you soon!</font></p>
);

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plusone: true,
            hideone: true,
            plustwo: true,
            hidetwo: true,
            plusthree: true,
            hidethree: true,
            plusfour: true,
            hidefour: true,
            plusfive: true,
            hidefive: true,
            plussix: true,
            hidesix: true,
            email: '',
            question: '',
            name: '',
            logged_in: false,
            video_link: '',
            nameEmpty: false,
            questionFailed: false,
            emailEmpty: false,
            loading: false,
            questionEmpty: false,
            questionSent: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.sendQuestionCallBack = this.sendQuestionCallBack.bind(this);
        this.SendQuestion = this.SendQuestion.bind(this);
        this.Toggle = this.Toggle.bind(this);
        this.CheckLogin = this.CheckLogin.bind(this);
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.setState({loading: true});
        this.CheckLogin().then((result) => {
            if (result.data.loggedIn) {
                this.setState({video_link: "https://player.vimeo.com/video/563833441", loading: false});
            } else {
                this.setState({video_link: "https://player.vimeo.com/video/563825103", loading: false})
            }
        });
    }

    async CheckLogin() {
        const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
        return await res;
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    sendQuestionCallBack(response, errorMessage) {
        if (response) {
            this.setState({
                questionFailed: false,
                nameEmpty: false,
                emailEmpty: false,
                questionEmpty: false,
                questionSent: true
            });
        } else {
            if (errorMessage === "name empty") {
                this.setState({ nameEmpty: true });
            } else if (errorMessage === "email empty") {
                this.setState({ emailEmpty: true });
            } else if (errorMessage === "question empty") {
                this.setState({ questionEmpty: true });
            } else {
                this.setState({ questionFailed: true });
            }
        }

    }

    SendQuestion(questionCallBack) {
        var passed = true;
        if (this.state.name === '') {
            passed = false;
            this.sendQuestionCallBack(false, "name empty");
        }
        if (this.state.email === '') {
            passed = false;
            this.sendQuestionCallBack(false, "email empty");
        }
        if (this.state.question === '') {
            passed = false;
            this.sendQuestionCallBack(false, "question empty");
        }
        if (passed) {
            axios.post(APIHost + '/api/submitQuestion', {
                email: this.state.email,
                question: this.state.question,
                name: this.state.name,
                withCredentials: true
            }).then(res => {
                this.sendQuestionCallBack(true);
            }).catch(error => {
                this.sendQuestionCallBack(false, error.response.data.errorMessage);
            });
        }
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({
            messageFailed: false,
            nameEmpty: false,
            emailEmpty: false,
            questionEmpty: false,
            questionFailed: false
        }); this.SendQuestion(this.sendQuestionCallBack);
    }

    Toggle(event) {
        const attribute = event.currentTarget.getAttribute('name');
        const plus = "plus" + attribute;
        const hide = "hide" + attribute;
        this.setState({
            ["plus" + attribute]: !eval("this.state.plus" + (attribute)),
            ["hide" + attribute]: !eval("this.state.hide" + (attribute))
        });
    }

    /* the old ask your question code 
    <div className="jumbotron jumbotron-fluid jumbo-background">
          <div className="container">
             <h1>Ask Your Question</h1>
             <div className="col-md-4 faq-form">
                          <form onSubmit={this.onSubmit}>
                               <div className="form-group">
                      <input type="name" className="form-control" id="name" name="name" 
                        placeholder="Name" onChange={this.onChange} />
                  </div>
                              <div className="form-group">
                                  <input name="email" className="form-control" id="email" type="email" placeholder="Email" onChange={this.onChange} />
                              </div>
  
                              <div className="input-group">
                              <input name="question" id="question" type="search" className="form-control" list="SubjectList" placeholder="How can we help?" onChange={this.onChange} />
                              <datalist id="SubjectList">
                                  <option value="How much is Mental Fitness Training?" />
                                  <option value="What does a credit get me?" />
                                  <option value="I don't have a lot of time. Can I still be successful with MFT?" />
                                  <option value="Why can't I access all the themes and objectives?" />
                                  <option value="Is the info I share private?" />
                                  <option value="Can I choose what I want to share with others?" />
                              </datalist>
                </div>
                {this.state.nameEmpty && <NameEmpty />}
                {this.state.emailEmpty && <EmailEmpty />}
                {this.state.questionFailed && <QuestionFailed />}
                {this.state.questionEmpty && <QuestionEmpty />}
                {this.state.questionSent && <QuestionSent />}
                              <button type="submit" className="custom-btn">Ask Question</button>
                      </form>
                      </div>
          </div>
        </div>*/
    text=[
        "There is not a set price for the entire Training. If you are doing the self-directed version, then each credit unlocks sections, or one Objective, of the training and are $12.99 each. There are currently 16 Objectives in the entire training. You can request one-on-one sessions with Elliot from your profile which are $125 for a 45 minute session. In the Personal Training version of the Training, you pay for your time with Elliot and meet with him every two weeks. The Training material is included in the price of the session which is $135.",
        "In the Self-directed approach, you are guided by the website itself. You don't have any direct engagement with Elliot unless you request a special training session which you can do from your profile. The Training is organized in a way that guides you along in a logical order. The powerful framework we've developed here at My Core Insights guides you, motivates you, and inspires you along your Journey. In the Personal Training version, you have regular one-on-one meetings with Elliot where you review what you've learned in the 2 weeks between training sessions and plan for the next two weeks of training. Elliot helps guide you and answers your questions, directly empowering your journey.",
        "This is a personal choice but one you can change. You can start with one or the other and switch between the two at any time from your Profile. In general, if you are someone that learns very independently then you can try the self-directed version. If you are someone that prefers more one-on-one help or a higher-touch approach, the Personal Training version might work better for you.",
        "In the self-directed version of the training, a credit allows you to gain access to the Chapters in an Objective. In the Personal Training version, a credit gets you a session with Elliot. The Training material is included in the price of the session.",
        "Absolutely! In fact, there is a Chapter in the Training with helpful hints and suggestions on exactly this topic. There is no minimum or maximum time commitments. You learn to be more and more effective in your life and with managing your time as you make progress in the training. The important thing is to take those first steps and get started so you can build momentum towards your Optimal Life.",
        "Mental Fitness Training is a self-directed, but guided training. The  Themes, Objectives, and Chapters have been organized in a logical order to maximize your effectiveness and success. Themes become available as you make progress. You will have plenty of choice as to what you include in each Sprint, but there is a recommended order reflected in what you have access to at any given time.",
        "Your information is protected by our Privacy Policy. We take your privacy very seriously. No one can access information you provide without your explicit permission to do so and none of your information is ever shared without your explicit consent.",
        "Yes! You have complete control over what information is shared on the public pages."
    ]
    render() {
        if (this.state.loading) {
            return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);
        } else {
        return (
            <div className="Faq">
                <Header history={this.props.history} />
          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">Help Center and Frequently Asked Questions</h4>
                    <p className="text">
                    Below are some videos that you might have missed that provide crucial information for you to be successful on your journey. A Mental Fitness Training overview video is also provided so check it out! The frequently asked questions and their answers are below the videos. And, of course, reach out with questions at any time using the <Link to="/Contact">Contact</Link> page.
                     </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src={this.state.video_link} width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>               
                </div>
                        <div className="row">
        <div className="col-sm-6">
                                <div className="grid-box">
                                                                    <h5>
In this video, the difference between the self-directed and the full personal training approach is explained.                                    </h5>
                                    <figure>
<iframe src="https://player.vimeo.com/video/562068275" width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>


                                </div> 
                            </div>
                        <div className="col-sm-6">
                                <div className="grid-box">
                                                                    <h5>
In this video, the concept of capacity is explained.  <br />                                  
</h5>
                                    <figure>
<iframe src="https://player.vimeo.com/video/353230665" width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>


                                </div> 
                            </div>
<div className="col-sm-6">
                                <div className="grid-box">
                                                                    <h5>
In this video, the basics of your profile setup and the training approach is described.                                    </h5>
                                    <figure>
<iframe src="https://player.vimeo.com/video/562068021" width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>


                                </div> 
                            </div>
                            <div className="col-sm-6">
                                <div className="grid-box">
                                                                    <h5>
This was a previous training chapter that explained the framework and approach of the training.                                    </h5>
                                    <figure>
<iframe src="https://player.vimeo.com/video/353459139" width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                                        <figcaption className="bookmark">
                                            <div className="fb-save" data-uri="#" data-size="large"></div>
                                        </figcaption>
                                    </figure>


                                </div> 
                            </div>




                        </div>

              </div>
            </div>
          </section>

                <section className="mt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 justify-content-center ">
                                <Collapse accordion>
                                    <Panel header="Q. How much is Mental Fitness Training?" key="1">
                                        <p>{this.text[0]}</p>
                                    </Panel>
                                    <Panel header="Q. What's the difference between the self-directed and the Personal Training versions of Mental Fitness Training?" key="2">
                                        <p>{this.text[1]}</p>
                                    </Panel>
                                    <Panel header="Q. I don't know if the self-directed or the Personal Training version of Mental Fitness Training is right for me. What should I do?" key="3">
                                        <p>{this.text[2]}</p>
                                    </Panel>
                                    <Panel header="Q. What does a credit get me?" key="4">
                                        <p>{this.text[3]}</p>
                                    </Panel>
                                    <Panel header="Q. I don't have a lot of time. Can I still be successful with Mental Fitness Training?" key="5">
                                        <p>{this.text[4]}</p>
                                    </Panel>
                                    <Panel header="Q. Why can't I access all the themes and objectives?" key="6">
                                        <p>{this.text[5]}</p>
                                    </Panel>
                                    <Panel header=" Q. Is the info I share private?" key="7">
                                        <p>{this.text[6]}</p>
                                    </Panel>
                                    <Panel header="Q. Can I control what I share with others?" key="8">
                                        <p>{this.text[7]}</p>
                                    </Panel>
                                </Collapse>
                                </div>
                        </div>
                    </div>
                </section>

                <Footer history={this.props.history} />
            </div>
        );
    }
    }
}

export default Faq;