import React, { Component } from 'react';

class Welcome extends Component {
	render() {
		return (
		<div className="Welcome">
		    <section className="perfect-moment">
        <div className="container">
            <div className="perfect-quote">
                <h4>
                    You can persevere in spite of fear.<br className="hidden-xs" />
                    You can do hard things.
                </h4>
            </div> 
        </div>
    </section>
</div>
		);
		
	}
}

export default Welcome;