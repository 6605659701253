import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';
import elliot from './images/elliot.jpg';
import community from './images/group_community.jpg';
import ricardo from './images/Ricardo1.jpg';

class About extends Component {

    componentDidMount() {
        window.scroll(0, 0);
    }
	render() {
/*if(true) {
 return (<div className="Articles"><Header history={this.props.history} />
    <h3>Coming Soon!</h3><Footer history={this.props.history} /></div>);
} else {*/
		return (
		<div className="About">
		<Header history={this.props.history} />
       <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">My Core Insights - building community!</h4>
                    <p className="text">First and foremost, Welcome!! We are so thankful and excited that you're here at My Core Insights. My Core Insights was founded on September 1st of 2020 with the mission of positively disrupting the mental health industry.
                    Elliot has dedicated his life to solving the human equation and finding ways to genuinely help people live their Optimal Life. The My Core Insights platform was his innovation built with the intent of finally make mental wellness affordable and accessible to 
                    anyone willing to take action. He didn't want any barriers to entry. He wanted this to be a welcoming place, a community for people working to improve their lives. We are well on our way towards realizing that vision. So, again, We welcome you here.
                    "You can live the life you deserve, your Optimal Life. I believe in you! You got this!" - Elliot Barnett
                     </p>


                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                                        <img src={community} alt="Community of people coming together"  className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </section>  
            <section className="perfect-community">
        <div className="container">
            <div className="perfect-quote-community">
                <h4>
                    The My Core Insights Team!
                </h4>
            </div> 
        </div>
    </section>
       <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">Elliot Barnett, Founder</h4>
                    <p className="text">Elliot Barnett is an inspirational speaker, a cognitive science researcher, an entrepreneur, an author, and a 
                    technology innovator. With over 20 years of technology leadership experience and over 20 years of cognitive science research and development, 
                    Elliot has been uniquely positioned to positively disrupt individual and corporate mental wellness approaches. His passion is to provide easily 
                    accessible, affordable, and effective solutions to empower everyone to live their Optimal Life. His research and development has culminated in 
                    innovative scientific and technology intellectual property empowering a new form of psychotherapy, Cognitive Response Therapy that laid the foundation
                    for Mental Fitness Training. With his tehcnology background, he was uniquely positioned to deliver Mental Fitness Training in a revolutionary technology platform here at My Core Inisghts. 
                        Elliot has run successful businesses in e-commerce, business and sales consulting, engineering consulting, 
                    and the point-of-sale industry.
                     </p>


                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                                        <img src={elliot} alt="Elliot Barnett"  className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </section>  
       <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">Ricardo Whyte, M.D.</h4>
                    <p className="text">Dr. Ricardo J. Whyte is an experienced medical director with a demonstrated history of almost 20 years in the healthcare industry. He is skilled in healthcare consulting, 
                    case management, prevention, addiction medicine, and healthcare information technology (HIT).  Additionally, Dr, Whyte is the proud Founder of the Master Mind Institute that has developed the 
                    Vital Living Membership that supports its members in establishing life balance and resilience fortification. 
                    Since 2017, Dr. Whyte has served as the Section Chief of Psychiatry at the Community Hospital of San Bernardino.  Prior to his current leadership role, he was the Medical Director of the 
                    Chemical Dependency Unit at the Behavioral Medicine Center of Loma Linda University Health and the Medical Director of Outpatient Psychiatry at the Behavioral Health Institute of Loma Linda University Health.  
                    Also, he was formerly on faculty at Loma Linda University as an Assistant Professor in its Department of Psychiatry.  

                     </p>


                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                                        <img src={ricardo} alt="Ricardo Whyte"  className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </section>  
 

<Footer history={this.props.history} />

</div>
		);
		
	//}	
		
	}
}

export default About;