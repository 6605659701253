import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import me from './images/me.jpg';
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import {APIHost} from './GlobalVariables';

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class SprintPlanning extends Component {
	constructor(props) {
		super(props);
		this.state = {
			capacity: 0,
			objectives: [],
			themes: [],
			chapters: [],
			purchased_objectives: [],
			chapter_descriptions: [],
			chapter_points: [],
			sprint: [],
			historic_chapters: [],
			historic_point_difference: [],
			active_themes: [],
			not_active_themes: [],
			completed_themes: [],
			credits: 0,
			sprint_id: 0,
			new_capacity: null,
      		no_chapters: false,
			server_failure: false,
			loading: true
		}
		this.Toggle = this.Toggle.bind(this);
		this.NewToggle = this.NewToggle.bind(this);
		this.DisplayThemes = this.DisplayThemes.bind(this);
		this.GetThemes = this.GetThemes.bind(this);
		this.PurchaseObjective = this.PurchaseObjective.bind(this);
		this.GetPurchases = this.GetPurchases.bind(this);
		this.AddToSprint = this.AddToSprint.bind(this);
		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.RemoveFromSprint = this.RemoveFromSprint.bind(this);
		this.GetCapacity = this.GetCapacity.bind(this);
		this.GetSprint = this.GetSprint.bind(this);
		this.SubmitSprint = this.SubmitSprint.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.SubmitSprintCallback = this.SubmitSprintCallback.bind(this);
		this.StopChecked = this.StopChecked.bind(this);
	this.GetUsersHistoricChapters = this.GetUsersHistoricChapters.bind(this);
		this.GetCompletedThemes = this.GetCompletedThemes.bind(this);
		this.DisplayIntro = this.DisplayIntro.bind(this);
		this.GetPointDifference = this.GetPointDifference.bind(this);
		this.DisplayRisks = this.DisplayRisks.bind(this);
		this.onSubmitNewCapacity = this.onSubmitNewCapacity.bind(this);
		this.SubmitNewCapacity = this.SubmitNewCapacity.bind(this);
		this.SubmitNewCapacityCallback = this.SubmitNewCapacityCallback.bind(this);
		this.onCapacityChange = this.onCapacityChange.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var purchase_flag = false;
	if (this.props.location.state != null) {
	var historic_chapters_promise = this.GetUsersHistoricChapters().then(data => {
		this.setState({historic_chapters: data.data.chapters})
		return data;
	}).catch((error) => {
		console.log("GetUsersHistoricChapters failed " + error);
		this.setState({server_failure: true});
	});
	
	var active_themes_promise = this.GetActiveThemes().then(data => {
		this.setState({active_themes: data.data.themes});
	}).catch((error) => {
		console.log("GetActiveThemes failed " + error);
		this.setState({server_failure: true});
	});

	var point_difference_promise = this.GetPointDifference().then((data) => {
		this.setState({historic_point_difference: data.data.sprint_history});
	}).catch((error) => {
		console.log("GetPointDifference failed " + error);
		this.setState({server_failure: true});
	});
	
	var completed_themes_promise = this.GetCompletedThemes().then(data => {
		if (data.data != "nothing completed") {
			this.setState({completed_themes: data.data.themes});
		} 
	}).catch((error) => {
		console.log("GetCompletedThemes failed " + error);
		this.setState({server_failure: true});
	});
	
    var sprint_promise = this.GetSprint().then((data) => {
    	if (data.data.sprint_info.no_chapters != true) {
    		this.setState({no_chapters: false, sprint: data.data.sprint, sprint_id: data.data.sprint_info.sprint_id});
    	} else {
    		this.setState({no_chapters: true, sprint_id: data.data.sprint_info.sprint_id});
    	}
		return data;
	}).catch((error) => {
		console.log("GetSprint failed " + error);
		this.setState({server_failure: true});
	});
	
	var theme_promise = this.GetThemes().then(data => {
		let temp_themes = data.data.themes;
		temp_themes.forEach((element) => {
			element.plus = true;
			element.hide = true;
		});
		this.setState({themes: temp_themes});		
		return data;
	}).catch((error) => {
		console.log("GetThemes failed " + error);
		this.setState({server_failure: true});
	});
	
	var objective_promise = this.GetObjectives().then(data => {
   			let local_objectives = data.data.objectives;
   			local_objectives.forEach((element) => {
   			element.plus = true;
   			element.hide = true;
   			element.purchased = false;
   			element.added = false;
   			});
   			this.setState({objectives: local_objectives})
   			return data;
   	}).catch((error) => {
		console.log("GetObjectives failed " + error);
		this.setState({server_failure: true});
	});
   	
   	var purchase_promise = this.GetPurchases().then(data => {
    	if (data.data.message === "none") {
    		console.log("didn't purchase anything");
    	} else {
    		purchase_flag = true;
    		this.setState({purchased_objectives: data.data.purchased_objectives});
    	}
    	return data;
    }).catch(error => {
    	console.log("GetPurchases failed " + error);
		this.setState({server_failure: true});
    });
    		
    var chapters_promise = this.GetChapters().then(data => {
    	this.setState({chapters: data.data.chapters});
		return data;
	}).catch(error => {
    	console.log("GetChapters failed " + error);
		this.setState({server_failure: true});
    });
      
	var credits_promise = this.GetCredits().then(data => {
		this.setState({credits: data.data.credits});
		return data;
	}).catch(error => {
    	console.log("GetCredits failed " + error);
		this.setState({server_failure: true});
    });
	
	var capacity_promise = this.GetCapacity().then(data => {
    	this.setState({capacity: data.data.capacity});
    	return data;
    }).catch(error => {
    	console.log("GetCapacity failed " + error);
		this.setState({server_failure: true});
    });

    var done_chapters_promise = this.GetDoneChapters().then(data => {
    	this.setState({done_chapters: data.data.done_chapters});
    	return data;
    }).catch(error => {
    	console.log("GetDoneChapters failed " + error);
    	this.setState({server_failure: true});
    });
	
	all_promises.push(done_chapters_promise);
	all_promises.push(sprint_promise);
	all_promises.push(theme_promise);
	all_promises.push(objective_promise);
	all_promises.push(purchase_promise);
	all_promises.push(chapters_promise);
	all_promises.push(credits_promise);
	all_promises.push(capacity_promise);
	all_promises.push(historic_chapters_promise);
	all_promises.push(active_themes_promise);
	all_promises.push(completed_themes_promise);
	
	Promise.all(all_promises).then( () => {
		if (purchase_flag) {
			let local_objectives = this.state.objectives;
			var flag = false;
			local_objectives.forEach((element) => {
				flag = false;
				for (var j=0; j<this.state.purchased_objectives.length; j++) {
						if (this.state.purchased_objectives[j].objective_id == element.id) {
							flag = true;
						}
					}
					if (flag) {
						element.purchased = true;
					} else {
						element.purchased = false;
					}
			});
			this.setState({objectives: local_objectives});
		}
	  	let local_chapters = this.state.chapters;
		let local_objectives = this.state.objectives;
		console.log("in Promise " + this.state.chapters.length);
		var points=0;
		if(this.state.sprint.length > 0) {
		let local_sprint = this.state.sprint;
   		local_sprint.forEach((sp) => {
		this.state.chapters.find((element) => {
			if (element.id == sp.chapter_id) {
				sp.description = element.description;
				sp.objective_id = element.objective_id;
				sp.points = element.points;
				sp.chapter_id = element.id;
			}
		});

		});
		this.setState({sprint: local_sprint});
		}
  		local_objectives.forEach((element) => {
  		points=0;
  		for (var j=0; j<this.state.chapters.length; j++) {
  			if (this.state.chapters[j].objective_id == element.id) {
  				points = parseInt(points)+parseInt(this.state.chapters[j].points);
  			}
		}
		element.points = points;
  		});
	    local_chapters.forEach((element) => {
    	this.state.sprint.find((el) => {
    		if (el.chapter_id == element.id) {
    			element.in_sprint = true;
    			element.in_current_sprint = true;
    			local_objectives.find((obj) => {
    				if (obj.id == element.objective_id) {
    					obj.added = true;
    				}
    			});
    			}	
    		});
    	});
    	var local_not_active = [];
    	let local_active = this.state.active_themes;
    	var cnt = 0;
    	var flag = false;
    	this.state.themes.forEach((element) => {
    		flag = false;
    		this.state.active_themes.find((theme) => {
    			if (element.id == theme.id) {
    				flag = true;
    			}
    		});
    		if(!flag) {
    			element.plus = true;
    			element.hide = true;
    			local_not_active.push(element);

    		} else { 
    			local_active[cnt].description = element.description;
    			local_active[cnt].plus = true;
    			local_active[cnt].hide = true;
    			cnt++;
    		}
    	});
    	let another_not_active = [];
		local_not_active.forEach((element) => {
			flag = false;
			this.state.completed_themes.find((theme) => {
				if (element.id == theme.theme_id) {
					flag = true;
				}
			});
			if(!flag) {
				another_not_active.push(element);
			}
		});
        this.setState({active_themes: local_active, not_active_themes: another_not_active, chapters: local_chapters, objectives: local_objectives, loading: false})
  	});
  	}
}

componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

PurchaseObjective(event) {
	const id = event.currentTarget.getAttribute('objectiveId');
	const theme = event.currentTarget.getAttribute('theme');
    axios.post(APIHost + '/api/purchaseObjective', {
	id: id,
	withCredentials: true
	}).then(res => {
	let temp_objectives = this.state.objectives;
	temp_objectives.find((element) => {
		if (element.id == id) {
			element.purchased = true;
		}
	});
    	this.setState({
   			objectives: temp_objectives});
    	
    	}).catch(error => {
    
    });
}

async GetDoneChapters() {
	const res = await axios.get(APIHost + '/api/getAllDoneChapters', {withCredentials: true});
	return await res;
}

async GetPointDifference() {
	const res = await axios.get(APIHost + '/api/getHistoricPointDifference', {withCredentials: true});
	return await res;
}

async GetUsersHistoricChapters() {
	const res = await axios.get(APIHost + '/api/getAllHistoricChapters', {withCredentials: true});
	return await res;
}

async GetThemes() {
	const res = await axios.get(APIHost + '/api/getThemes', {withCredentials: true});
	return await res; 
}

async GetObjectives() {
	const res = await axios.get(APIHost + '/api/getObjectives', {withCredentials: true});
	return await res; 
}

async GetChapters() {
	const res = await axios.get(APIHost + '/api/getChapters', {withCredentials: true});
	return await res; 
}

async GetSprint() {
	const res = await axios.post(APIHost + '/api/getSprint', {withCredentials: true});
	return await res; 
}

async GetCredits() {
	const res = await axios.get(APIHost + '/api/getCredits', {withCredentials: true});
	return await res;
}

async GetPurchases() {
	const res = await axios.get(APIHost + '/api/purchasedObjectives', {withCredentials: true});
	return await res;
}

async GetCapacity() {
	const res = await axios.get(APIHost + '/api/getCapacity', {withCredentials: true});
	return await res;
}

async GetActiveThemes() {
	const res = await axios.get(APIHost + '/api/getActiveThemes', {withCredentials: true});
	return await res;
}

async GetCompletedThemes() {
	const res = await axios.get(APIHost + '/api/getCompletedThemes', {withCredentials: true});
	return await res;
}

StopChecked(event) {
this.checked = false; // reset first
    event.preventDefault();
}

DisplayThemes(themes, which_theme) {
	let for_display = [];   
    var i=0;
    var j=0;
    var k=0;
    var objective_cnt=1;
    var flag = false;
    var status = null;
    var has_been_in_sprint = false;
    for (i=0; i<themes.length; i++ ) {
    	let objectives = [];
   		for (j=0; j<this.state.objectives.length; j++ ) {
	    	let chapters = [];
	   		if (themes[i].id === this.state.objectives[j].theme_id) {
		   		for (k=0; k<this.state.chapters.length; k++) {
			   	    if (this.state.objectives[j].id === this.state.chapters[k].objective_id) {        		
			        	var chapter_name = "objective"+j+"chapter"+k;
							if(this.state.chapters[k].in_sprint) {
								this.state.sprint.find((element) => {
									if(element.chapter_id == this.state.chapters[k].id) {
										status = element.status;
									}
								});
								if(this.state.has_dependency && this.state.dep_chapter == this.state.chapters[k].id) {
									var dep_des_display = [];
		                			this.state.dependencies_description.forEach((desc) => {
		                				dep_des_display.push(<li>{desc}</li>);
		                			});
			                    	chapters.push(<div><p><li><input onClick={this.RemoveFromSprint} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} checked />
			                         {this.state.chapters[k].description} ({this.state.chapters[k].points})
			                         </li><Alert variant="danger" onClose={() => {this.setState({has_dependency: false});}} dismissible>
									        <Alert.Heading>This chapter has dependencies you haven't completed! You can add it, but won't be able to start it until you've completed the dependencies.</Alert.Heading>
									        <p>The list of chapters you need to complete before this chapter is:
									          <ul>{dep_des_display}</ul>
									        </p>
									      </Alert></p></div>);
								} else {
									if(status == "in progress" || status == "done") {
				        		      chapters.push(<li><input onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} checked />
				                         {this.state.chapters[k].description} ({this.state.chapters[k].points})
				                         </li>);
				                    } else {
				                    	chapters.push(<li><input onClick={this.RemoveFromSprint} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} checked />
				                         {this.state.chapters[k].description} ({this.state.chapters[k].points})
				                         </li>);
				                    }
				                }
			                    flag = true; // This is not needed
			                } else {
			                	this.state.historic_chapters.find((element) => {
                	if(element.chapter_id == this.state.chapters[k].id){
                	has_been_in_sprint = true;
                	}

                	});
                	if (has_been_in_sprint || which_theme == 'not_active' ) {
                		chapters.push(<li><input onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} />
			                         {this.state.chapters[k].description} ({this.state.chapters[k].points})
                         </li>);
                    } else if(!this.state.objectives[j].purchased) {
                    	chapters.push(<li><input onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} />
			                         {this.state.chapters[k].description} ({this.state.chapters[k].points})
			                         </li>);
			                	} else {
			                		chapters.push(<li><input onClick={this.AddToSprint} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" chapterId={this.state.chapters[k].id} name={chapter_name} value={chapter_name} />
			                         {this.state.chapters[k].description} ({this.state.chapters[k].points})
			                         </li>);
			                	}
			                	has_been_in_sprint=false;
			                }                       
			        	}
		     		}    	
	    		var name="objective" + which_theme + (objective_cnt);
	    		let button_content = [];
				if (!this.state.objectives[j].purchased) {
			     	if (this.state.credits > 0) {
			        	button_content.push(<div align="center"><button className="btn btn-primary my-3" objectiveId={this.state.objectives[j].id} theme={i+1} onClick={this.PurchaseObjective}>Unlock Objective</button></div>);
			    	} else {
			    		button_content.push(<Popup trigger={<button className="btn btn-primary my-3">Unlock Objective</button>} position="right center">
			    	<div>Please <Link to="/getCredits">Purchase</Link> credits</div>
			  		</Popup>);
		    		}
				}
     			objectives.push(<div className="panel-body">
						<h5 className="panel-title">
                           <i role="button" name={name}
                        onClick={this.Toggle} id={this.state.objectives[j].id} className={this.state.objectives[j].plus ? 'fa fa-plus plus-minus' : 'fa fa-minus plus-minus'}></i>
                           {this.state.objectives[j].description} (total points {this.state.objectives[j].points}){button_content}
                        </h5>
                   <div className={this.state.objectives[j].hide ? 'hide' : ''}>
                    <div className="panel-objective">
                    <ul>{chapters}</ul></div></div></div>);
             objective_cnt++;       
        	}
        }

        var obj_name="theme"+which_theme+(i+1);
        for_display.push(<div className="panel-group"><div className="panel-heading"><h4>
        <i role="button" name={obj_name} id={themes[i].id} onClick={(e) => {this.NewToggle(e, themes, which_theme)}} className={themes[i].plus ? 'fa fa-plus plus-minus' : 'fa fa-minus plus-minus'}></i>
        {themes[i].description}
        </h4></div><div className={themes[i].hide ? 'hide' : ''}>{objectives}</div></div>);
    }
    return for_display;
}

DisplayIntro() {
	var for_display = [];
    /*if (this.state.completed_themes.length > 0) {
    	for_display.push(<div><p>Use the plus signs below to expand the Themes and Objectives. Whatever is
    	currently in your sprint will show as checked Chapters. If a Chapter is in progress or done, you can't unselect it. You can only
    	add chapters from objectives you have purchased. You have access to all objectives under "Active" Themes. Future Themes will become
    	available as you make progress. Your Sprint is only updated once you've clicked the Update Sprint button.  
    	Click <Link history={this.props.history} to={{ pathname: '/History', state: { loggedIn: true } }}>here</Link> to see completed themes.</p></div>)
    } else {
    	for_display.push(<div><p>Use the plus signs below to expand the Themes and Objectives. Whatever is
    	currently in your sprint will show as checked Chapters. If a Chapter is in progress or done, you can't unselect it. You can only
    	add chapters from objectives you have purchased. You have access to all objectives under "Active" Themes. Future Themes will become
    	available as you make progress. Your Sprint is only updated once you've clicked the Update Sprint button.</p></div>)
	}*/
	for_display.push(<iframe src="https://player.vimeo.com/video/507309466" width="400" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
);
	return for_display;
}

NewToggle(event, themes, which_theme) {
	event.preventDefault();
	const id = event.currentTarget.getAttribute('id');
	themes.find((element) => {
		if (element.id == id) {
			element.plus = !element.plus;
			element.hide = !element.hide;
		}
	});
	if (which_theme == 'active') {
		this.setState({active_themes: themes});
	} else {
		this.setState({not_active_themes: themes});
	}
}
	
Toggle(event) {
event.preventDefault();

		const attribute = event.currentTarget.getAttribute('name');
		const id = event.currentTarget.getAttribute('id');
		const themereg = /theme/;
		const objectivereg = /objective/;
		let temp_themes = this.state.themes;
		let temp_objectives = this.state.objectives;
		if(themereg.test(attribute)) {
		temp_themes.find((element) => {
			if (element.id == id) {
				element.plus = !element.plus;
				element.hide = !element.hide;
			}
		});
		this.setState({themes: temp_themes});
		} else if (objectivereg.test(attribute)) {
			temp_objectives.find((element) => {
				if (element.id == id) {
					element.hide = !element.hide;
					element.plus = !element.plus;
				}
			});
			this.setState({objectives: temp_objectives});
		}
}

RemoveFromSprint(event) {
	const objective_id = event.currentTarget.getAttribute('objectiveId');
	const chapter_id = event.currentTarget.getAttribute('chapterId');
	const theme = event.currentTarget.getAttribute('theme');
	const name = event.currentTarget.getAttribute('name');
	let temp_chapters = [];
	var flag = false;
	if (name) {
	var cnt=0;
		temp_chapters = this.state.chapters;
		temp_chapters.forEach((element) => {
			if (element.id == chapter_id) {
				element.in_sprint = false;
			}
		})
		this.setState({chapters: temp_chapters});
		let temp_sprint = [];
		for(var i=0; i<this.state.sprint.length; i++) {
			if (this.state.sprint[i].chapter_id == chapter_id && this.state.sprint[i].objective_id == objective_id) {
				continue;
			} else {
			temp_sprint.push({});
			temp_sprint[cnt].description = this.state.sprint[i].description;
			temp_sprint[cnt].points = this.state.sprint[i].points;
			temp_sprint[cnt].objective_id = this.state.sprint[i].objective_id;
			temp_sprint[cnt].sprint_id = this.state.sprint_id;
			temp_sprint[cnt].chapter_id = this.state.sprint[i].chapter_id;
			temp_sprint[cnt].status = this.state.sprint[i].status;
			temp_sprint[cnt].status = this.state.sprint[i].status;
			cnt++;
			}
		}
		//flag = false;
		let local_objectives = this.state.objectives;
		temp_sprint.find((element) => {
			if (element.objective_id == objective_id) {
				flag = true;
			}
		});
		if(!flag) {
		local_objectives.find((element) => {
		if (element.id == objective_id) {
			element.added = false;
			}
		});
		}
		this.setState({sprint: temp_sprint,
		objectives: local_objectives});

	} else {
	
	var cnt=0;
	for (var i = 0; i<this.state.sprint.length; i++) {
		if (this.state.sprint[i].objective_id == objective_id) {
			continue;
		} else {
			temp_chapters.push({});
			temp_chapters[cnt].description = this.state.sprint[i].description;
			temp_chapters[cnt].points = this.state.sprint[i].points;
			temp_chapters[cnt].objective_id = objective_id;
			temp_chapters[cnt].sprint_id = this.state.sprint_id;
			temp_chapters[cnt].status = this.state.sprint[i].status;
			temp_chapters[cnt].chapter_id = this.state.sprint[i].chapter_id;
			temp_chapters[cnt].status = this.state.sprint[i].status;
			cnt++;
		}
	}
	let local_objectives = this.state.objectives;
	local_objectives.find((element) => {
		if (element.id == objective_id) {
			element.added = false;
		}
	});
	this.setState({sprint: temp_chapters, objectives: local_objectives});
	/*this.setState({sprint_chapters: temp_chapters,
				["theme"+theme+"Objective"+objective_id+"Added"]: false})*/
	temp_chapters = this.state.chapters;
	for (var i=0; i<temp_chapters.length; i++) {
		   	temp_chapters[i].description = this.state.chapters[i].description;
  			temp_chapters[i].objective_id = this.state.chapters[i].objective_id;
  			temp_chapters[i].id = this.state.chapters[i].id;
  			temp_chapters[i].points = this.state.chapters[i].points;
  			temp_chapters[i].status = this.state.chapters[i].status;
  			if (temp_chapters[i].objective_id == objective_id) {
  				temp_chapters[i].in_sprint = false;
			}
	}
	this.setState({chapters: temp_chapters});
	}
	if (this.state.dep_chapter == chapter_id) {
		this.setState({has_dependency: false, dep_chapter: null, dependencies_description: null});
	}
}
// What I need to do is ensure if the sprint is empty at any time then don't set the temps to the sprint
// but otherwise do so 
AddToSprint(event) {
	const chapter_id = event.currentTarget.getAttribute('chapterId');
	const name = event.currentTarget.getAttribute('name');
	const objective_id = event.currentTarget.getAttribute('objectiveId');
	var dep_cnt = 0;
	var check_flag = true;
	var dep_description = [];
	var dep_flag = false;
	this.state.chapters.find((ch) => {
		if (ch.id == chapter_id) {
			if(ch.dependencies) {
				ch.dependencies.forEach((dep) => {
					this.state.chapters.find((chapter) => {
						flag = false;
						if(chapter.id === dep) {
							this.state.done_chapters.find((done_ch) => {
								if (done_ch.chapter_id === dep) {
									flag=true;
								}
							});
							if(!flag) {
								dep_description.push(chapter.description);
							}
						}
					});
					this.state.done_chapters.find((done) => {
						if(done.chapter_id == dep) {
							dep_cnt++;
						}
					});
				});
				if(dep_cnt != ch.dependencies.length) {
					//this.checked = false;
					//check_flag = false;
					event.preventDefault();

					this.setState({has_dependency: true, dep_chapter: chapter_id, dependencies_description: dep_description});
				}
			}
		}
	});
	var flag = false;
	var cnt=this.state.sprint.length;
		let temp_chapters = this.state.chapters;
	if (check_flag) {
		temp_chapters.forEach((element) => {
			if (element.id == chapter_id) {
				element.in_sprint = true;
			}
		});
		this.setState({chapters: temp_chapters});
		var temp_sprint = this.state.sprint;
		
		for(var i=0; i<this.state.chapters.length; i++) {
			if (this.state.chapters[i].id == chapter_id) {
			temp_sprint.push({});
			temp_sprint[cnt].sprint_id = this.state.sprint_id;
			temp_sprint[cnt].description = this.state.chapters[i].description;
			temp_sprint[cnt].points = this.state.chapters[i].points;
			temp_sprint[cnt].objective_id = this.state.chapters[i].objective_id;
			temp_sprint[cnt].chapter_id = this.state.chapters[i].id;
			temp_sprint[cnt].status = "open";
			} else {
				continue;
			}
		}
		let local_objectives = this.state.objectives;
		local_objectives.find((element) => {
			if(objective_id == element.id) {
				element.added = true;
			}
		});
		
		this.setState({sprint: temp_sprint, objectives: local_objectives});
	}
}

DisplaySprint() {
	var for_display = [];
	var sprint_rows = [];
	var row = [];
	var points = 0;
	for (var i=0; i<this.state.sprint.length; i++) {
		row = [];
		var flag = false;
		if (this.state.sprint[i].status == "open") {
			row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
			points = points + this.state.sprint[i].points;
			flag = true;
		} else {
			row.push(<td></td>);
		}
		if (this.state.sprint[i].status == "in progress") {
			row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
			points = points + this.state.sprint[i].points;
			flag = true;
		} else {
			row.push(<td></td>);
		}
		if (this.state.sprint[i].status == "done") {
			row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
			points = points + this.state.sprint[i].points;
			flag = true;
		} else {
			row.push(<td></td>);
		}
		if(flag) { 
			sprint_rows.push(<tr>{row}</tr>);
		}
	}
    for_display.push(<table className="table table-bordered"><thead><tr><th>To Do (points)</th><th>In Progress (points)</th><th>Done (points)</th>
      </tr></thead><tbody>{sprint_rows}</tbody></table>);
      if (points > this.state.capacity) {
		for_display.push(<font color="red"><h4>Total points: {points} <br />Capacity: {this.state.capacity}</h4><h5>Looks like you are stretching a bit. Good for you! You Got This!</h5></font>);
	} else {
		for_display.push(<h4>Total points: {points} <br />Capacity: {this.state.capacity}</h4>);
	}

	if(this.state.sprint.length>0) {
		for_display.push(<button onClick={this.onSubmit} type="submit" className="custom-btn">Update Sprint</button>);
	}
      
      return for_display;
}

DisplayRisks() {
	var for_display = [];
	var average = 0;
	for_display.push(<h5>Changing Your Capacity</h5>);
	if (this.state.historic_point_difference.length > 1 && this.state.historic_point_difference[0].capacity_difference > 0 &&
		this.state.historic_point_difference[1].capacity_difference > 0) {
		average = parseInt((parseInt(this.state.historic_point_difference[1].velocity) + parseInt(this.state.historic_point_difference[0].velocity) + parseInt(this.state.capacity))/3);
		for_display.push(<p>Looks like you exceeded your Capacity in the previous Sprint by {this.state.historic_point_difference[0].capacity_difference} points
		and in the Sprint before that one by {this.state.historic_point_difference[1].capacity_difference}. Great Job!
		Since you are trending towards consistently exceeding your Capacity, you might consider increasing your Capacity accordingly. An average might be a good idea. 
		In this case, that would be {average}</p>);
	} else if  (this.state.historic_point_difference[0].capacity_difference > 0) {
		for_display.push(<p>Looks like you exceeded your Capacity in the previous Sprint by {this.state.historic_point_difference[0].capacity_difference} points. Great Job!
		You might consider increasing your Capacity for the next Sprint. Given it was just this last Sprint, if you do increase it, increase it by just a couple
		of points</p>);
	} else if (this.state.historic_point_difference.length > 1 && this.state.historic_point_difference[0].capacity_difference < 0 &&
		this.state.historic_point_difference[1].capacity_difference < 0) {
		for_display.push(<p>It looks like the last two sprints your Velocity was below your Capacity, meaning, what you got done didn't match what you defined as your effort.
		No worries! That's what the Scrum Framework is all about, to Learn, Adapt, and Improve. You might consider lowering your capacity a bit for this coming Sprint 
		and see how it goes!</p>);
	} else if (this.state.historic_point_difference[0].capacity_difference < 0) {
		for_display.push(<p>It looks like this last sprint your Velocity was below your Capacity, meaning, what you got done didn't match what you defined as your effort.
		No worries! That's what the Scrum Framework is all about, to Learn, Adapt, and Improve. Given it was only this last Sprint, you can keep your Capacity as is 
		and see how it goes or you can lower your capacity a bit for this coming Sprint 
		and see how it goes!</p>);
	}
	for_display.push(<div><hr className="new" /><p>Can you identify any Risks in the next 2 weeks that could impact your ability to do the training? This could be vacations,
	visitors, something unusual at work, etc. Are there ways you could mitigate those risks? In other words, can you think of ways that you could still put the same effort
	towards the training as you have previously? After considering risks and mitigations, make any adjustments to your Capacity you feel is appropriate.</p>
	     			<p>Current Capacity {this.state.capacity}</p>
	     			<p>Enter new Capacity: <textarea onChange={this.onCapacityChange} value={this.state.new_capacity} rows="1" cols="10" type="text" id="new_capacity" name="new_capacity" 
	     			className="form-control"></textarea></p><button className="btn btn-primary my-3" onClick={this.onSubmitNewCapacity}>Update Capacity</button></div>)
	return for_display;
}

SubmitSprintCallback(status) {
	this.props.history.push({pathname: '/', state: { preview: true}});
}

SubmitSprint(SubmitSprintCallback) {
	this.state.sprint.forEach((element) => {
		console.log("chapter ids in sprint " + element.sprint_id);
	});
	axios.post(APIHost + '/api/updateSprint', {
      sprint: this.state.sprint,
      initial: false,
      sprint_id: this.state.sprint_id,
      withCredentials: true
    }).then(res => {
    	SubmitSprintCallback();
    }).catch(error => {
    	console.log("createSprint failed " + error);
		this.setState({server_failure: true});
    });
}

onSubmit(event) {
event.preventDefault();
this.SubmitSprint(this.SubmitSprintCallback);
}

onCapacityChange(event) {
	event.preventDefault();
	this.setState({new_capacity: event.target.value});
}

onSubmitNewCapacity(event) {
	event.preventDefault();
	this.SubmitNewCapacity();
}

SubmitNewCapacity() {
	axios.post(APIHost + '/api/updateCapacity', {
      capacity: this.state.new_capacity
    }).then(res => {
    	this.SubmitNewCapacityCallback();
    }).catch(error => {
    	console.log("createSprint failed " + error);
		this.setState({server_failure: true});
    });
}

SubmitNewCapacityCallback() {
	this.setState({capacity: this.state.new_capacity, new_capacity: ""});
}
	
render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
		return (
		<div className="SprintPlanning">
			<Header history={this.props.history} preview={true} loggedIn={true} />
			<div className="container_profile">
			<div className="row">
			{this.state.server_failure && <ServerFailure />}
               <aside className="col-md-6">
                    <section className="two-sided-box">
            <div className="two-sided-box-in">
                        <div className="col-md-12"> 
                        {!this.state.loading && this.DisplayRisks()}  
                        <h5>Your Upcoming Sprint</h5>                     
		{!this.state.loading && this.DisplaySprint()}
                		</div>                	
                </div>           
           </section>
                </aside>
                <div className="col-md-6">
		<section className="theme">
  			<div className="container">
  			{!this.state.loading && this.DisplayIntro()}

         		<div className="col-md-10 col-md-offset-1">
            			<h2>
                 			Active Themes
            			</h2>					

          		 {!this.state.loading && this.DisplayThemes(this.state.active_themes, 'active')}
				<hr />
				<h2>Future Themes</h2>
          		 {!this.state.loading && this.DisplayThemes(this.state.not_active_themes, 'not_active')}
					
           		</div> {/*col-md-10*/}         	
            </div> {/*container*/}
        </section>
        </div>
        </div>
        </div>
			<Footer history={this.props.history} preview={true} loggedIn={true} />
		</div>
		);		
	}
	}
}

export default SprintPlanning;