import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';

class PrivacyPolicy extends Component {

componentDidMount() {
window.scroll(0,0);
this.DisplayHeader = this.DisplayHeader.bind(this);
this.DisplayFooter = this.DisplayFooter.bind(this);
}

DisplayHeader() {
    var display = [];
    if (this.props.loggedIn || (this.props.location.state!=null && this.props.location.state.loggedIn)) {
        display.push(<Header history={this.props.history} loggedIn={true} />);
    } else {
        display.push(<Header />);
    }
    return display;
}

DisplayFooter() {
    var display = [];
    if (this.props.loggedIn || (this.props.location.state!=null && this.props.location.state.loggedIn)) {
        display.push(<Footer history={this.props.history} loggedIn={true} />);
    } else {
        display.push(<Footer />);
    }
    return display;
}

render() {
	return (
		<div className="PrivacyPolicy">
		{this.DisplayHeader()}
    <section className="two-sided-box">
        <div className="container">
            <div className="two-sided-box-in">
            <h1>My Core Insights Privacy Policy</h1>
            <h4>Introduction</h4>
            <p>Here at My Core Insights, we know your personal information and its protection is crucially important to you.
             We take this topic very seriously. The details of how we approach this topic and what we do about this topic is 
             articulated below. By using the My Core Insights website (herein referred to as “The Site”), you are agreeing to 
             these terms and practices. The Site provides a collection of questions, exercises, and videos provided in the form 
             of a training called Mental Fitness Training (referred to herein as “The Training”). The Training also includes 
             algorithms that take your provided information and produces that information in a new way to provide additional 
             information to you. The Site also provides other forms of information outside of The Training. This and The Training 
             is collectively referred to herein as The Services.</p>
             <h4>What Personal Information About Customers Does My Core Insights Collect?</h4>
             <p>All the information we collect at The Site is provided by you, the user of The Site with a few exceptions such as 
             but not limited to: your last login date, pages of The Site you have visited, support questions, feedback, and number 
             of visits. This information is used to improve our ability to provide content, answer questions, and help guide you in 
             using The Services.</p>
             <p>The nature of The Site as the platform that delivers The Training is to collect information from you to provide you the 
             ability to learn about yourself. This is information you provide by answering questions and engaging in exercises and is 
             therefore the sole purpose of providing the value The Training is meant to provide. Other information that is collected is your email and name.
             </p>
             <h4>What do you do with my information?</h4>
             <p>Your email is used to communicate with you for the purposes of providing important information or promotions for The Site and is used as your 
             login username, allowing you access to the membership areas of The Site. Your name is used for identification with The Site only.</p>
             <p>The information you provide in the form of feedback and questions are used to provide answers to you to help improve your experience at The Site.</p>
             <p>The information you provide in The Training is for your own usage only. The algorithms of The Training manipulate, collate, and connect your provided 
             information to other information you provide to provide additional information to you. All of this is for you and only your information.</p>
             <h4>Do you share my information?</h4>
             <p>Your information is never shared with 3rd parties of any kind. We will never share, sell, or otherwise distribute your information to anyone outside of 
             The Site. We may be required to disclose personal information that we handle in response to lawful requests by public authorities, including to meet national 
             security or law enforcement requirements.</p>
             <h4>What privacy do I have at The Site and what control do I have over my information?</h4>
             <p>You have control over what information is shared to other members and users of The Site and The Training. That control is provided in the Privacy Settings area 
             of your Profile at The Site. No other visitors or members of The Site can see any information you provide to The Site without you explicitly allowing it.</p>
             <p>You can remove some or all of your information you have provided to The Site at any time. You have the option of removing your account which fully deletes all 
             the personal information you have provided. You can do this at any time through your profile page of The Site.</p>
             <p>Access to the personal information you provide is obfuscated to employees of My Core Insights with the exception of your name and email which is necessary to 
             communicate to you appropriately. Your provided information is yours alone and used for your own growth while going through The Training.</p>
             <h4>What if I was referred to The Site by someone? What information do they have access to?</h4>
             <p>If you were reffered to The Site by an Ambassador of The Site and provided that Ambassador's Member ID upon creating your account, that Ambassador will receive your 
             name and email as confirmation that you have acreated an account at The Site. Additionally, that Ambassador will have the ability to see when you have logged into The Site 
             and how many credits you have purchased at The Site. This is the extent of the information they have access to. No additional personal information is available to them.
             </p>
             <h4>How secure is my information?</h4>
             <p>My Core Insights is committed to protecting your information. Our approach:
             <ul>
             <li>aligns with industry recognized frameworks</li>
             <li>includes security safeguards reasonably designed to protect the confidentiality, integrity, availability, and resilience of our Users' data</li>
             <li>is appropriate to the nature, size, and complexity of the My Core Insight’s business operations</li>
             <li>includes incident response and data breach notification processes</li>
             <li>complies with applicable information security-related laws and regulations in the geographic regions where My Core Insights does business.</li>
             </ul>
             </p>
             <p>In the event of a data breach that affects your personal Information, we will act promptly to mitigate the impact of a breach and notify any affected Users without undue delay.
                    Transmission of data related to The Site is encrypted using SSH, HTTPS (TLS). The Site resides within the Amazon Web Services (AWS) environment which provides high standards 
                    of security detailed on the AWS website under the <a href="https://aws.amazon.com/security">security area</a></p>
            <h4>Cookies</h4>
            <p>We use Cookies to setup your user session and allow you to gain access to the membership areas of The Site only. These Cookies are limited to session information only and don’t contain 
            any personal information about you. We recognize your browser and create a session ID to provide you with a membership experience for The Site.</p>
            <h4>EU-US and Swiss-US Privacy Shield</h4>
            <p>My Core Insights participates in the EU-US and Swiss-US Privacy Shield frameworks regarding the collection, use, and retention of personal information from European Union member countries, 
            the United Kingdom and Switzerland. We have certified with the Department of Commerce that we adhere to the Privacy Shield Principles. 
            To learn more about the Privacy Shield Principles, visit <a href="https://www.privacyshield.gov/welcome">here</a></p>
            <p>If you have any inquiries or complaints about our handling of your personal data under Privacy Shield, or about our privacy practices generally, please contact us at: info@mycoreinsights.com. 
            We will respond to your inquiry promptly. If we are unable to satisfactorily resolve any complaint relating to the Privacy Shield, or if we fail to acknowledge your complaint in a timely fashion, 
            you can submit your complaint to TRUSTe, which provides an independent third-party dispute resolution service based in the United States. TRUSTe has committed to respond to complaints and to provide 
            appropriate recourse at no cost to you. To learn more about TRUSTe's dispute resolution services or to refer a complaint to TRUSTe, visit <a href="https://feedback-form.truste.com/watchdog/request">here</a>. 
            If neither My Core Insights nor TRUSTe resolves your complaint, 
            you may pursue binding arbitration through the Privacy Shield Panel. To learn more about the Privacy Shield Panel, visit <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">here</a>.</p>
            <p>You can review our Privacy Shield registration <a href="https://www.privacyshield.gov/list">here</a>. My Core Insights is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). We may be required to disclose personal information 
            that we handle under the Privacy Shield in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
            <h4>California Consumer Privacy Act Disclosures</h4>
            <p>These additional disclosures are required by the California Consumer Privacy Act:
            <ol>
            <li><b>Categories of personal information collected.</b> The business of My Core Insights started after the inception of the California Consumer Privacy Act so no personal information was collected previously to this act.</li>
            <li><b>Categories of personal information disclosed for a business purpose.</b> The business of My Core Insights started after the inception of the California Consumer Privacy Act so no personal information was disclosed previously to this act.</li>
            <li><b>Right to Request Access to or Deletion of Personal Information.</b> You may have the right under the California Consumer Privacy Act to request information about the collection of your personal information by My Core Insights, or access to or deletion of 
            your personal information. If you wish to do any of these things, please visit your profile at The Site of contact us at info@mycoreinsights.com. Depending on your data choices, certain services may be limited or unavailable.</li>
            <li><b>No sale of personal information.</b> In the twelve months prior to the effective date of this Disclosure, My Core Insights has not sold any personal information of consumers, as those terms are defined under the California Consumer Privacy Act.</li>
            <li><b>No Discrimination.</b> My Core Insights will not discriminate against any consumer for exercising their rights under the California Consumer Privacy Act.</li>
            </ol>
            </p>
           </div>
            </div>
            </section>
 
            {this.DisplayFooter()}
            </div>
		);
}
}

export default PrivacyPolicy;