import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const NoCatalyst = () => (
<p><font color="red">Please pick a Catalyst by checking one of the check boxes</font></p>
);

class Chapter53 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 53,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		finished_virus: false,
		finished_exercises: false,
		start_failure: false,
		server_failure: false,
		question_failure: false,
		submission_failure: false,
    	chapter_stage: null,
    	antidote_language: [],
    	finished_action_plan_steps: [],
    	antidotes: [],
    	language_stage: false,
    	intro_stage: false,
    	checked_catalyst: 0,
		viruses: [],
		all_catalysts: [],
		foobar: [],
		no_catalyst: false,
		started_action_plan: false,
		all_action_plan_steps: [],
		display_exercises: false,
		chapter_video: "https://player.vimeo.com/video/451336314"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GoToEndCallback = this.GoToEndCallback.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GetCatalysts = this.GetCatalysts.bind(this);
this.GetSignals = this.GetSignals.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetStretchAttributes = this.GetStretchAttributes.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.GetVirtues = this.GetVirtues.bind(this);
this.GetCoreVirus = this.GetCoreVirus.bind(this);
this.GetAntidotes = this.GetAntidotes.bind(this);
this.GetActionPlans = this.GetActionPlans.bind(this);
this.onNextVirus = this.onNextVirus.bind(this);
this.NextVirus = this.NextVirus.bind(this);
this.NextVirusCallback = this.NextVirusCallback.bind(this);
this.GoToIntroCallback = this.GoToIntroCallback.bind(this);
this.GoToIntro = this.GoToIntro.bind(this);
this.LanguageCallback = this.LanguageCallback.bind(this);
this.GetAntidoteLanguage = this.GetAntidoteLanguage.bind(this);
this.handleMouseUp = this.handleMouseUp.bind(this);
this.onSubmitAntidoteLanguage = this.onSubmitAntidoteLanguage.bind(this);
this.AntidoteLanguage = this.AntidoteLanguage.bind(this);
this.AntidoteLanguageCallback = this.AntidoteLanguageCallback.bind(this);
this.RemoveAntidoteLanguage = this.RemoveAntidoteLanguage.bind(this);
this.RemoveAntidoteLanguage = this.RemoveAntidoteLanguage.bind(this);
this.onRemoveAntidoteLanguage = this.onRemoveAntidoteLanguage.bind(this);
this.CatalystState = this.CatalystState.bind(this);
this.onSubmitActionPlanStep = this.onSubmitActionPlanStep.bind(this);
this.ActionPlanStep = this.ActionPlanStep.bind(this);
this.ActionPlanStepCallback = this.ActionPlanStepCallback.bind(this);
this.onRemoveStep = this.onRemoveStep.bind(this);
this.RemoveStep = this.RemoveStep.bind(this);
this.RemoveStepCallback = this.RemoveStepCallback.bind(this);
this.StopChecked = this.StopChecked.bind(this);
this.onFinishActionPlan = this.onFinishActionPlan.bind(this);
this.FinishActionPlan = this.FinishActionPlan.bind(this);
this.FinishActionPlanCallback = this.FinishActionPlanCallback.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetNotes = this.GetNotes.bind(this);
this.GoBackToLanguage = this.GoBackToLanguage.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
		chapter_stage_promise = this.GetChapterStage().then ((stage) => {
			this.setState({chapter_stage: stage.data.stage});
			if (stage.data.stage == "video") {
				this.setState({display_video: true, display_exercises: false, started: true});
			} else if (stage.data.stage == "exercises") {
				this.setState({display_video: false, display_exercises: true, display_action_plans: true, language_stage: false, intro_stage: false, started: true});	
			} else if (this.state.chapter_stage == "intro" || this.state.chapter_stage == "intro_again") {
				this.setState({display_video: false, display_exercises: true, display_action_plans: false, language_stage: false, intro_stage: true, started: true});
			} else if (this.state.chapter_stage == "language" || this.state.chapter_stage == "language_again") {
				this.setState({display_video: false, display_exercises: true, display_action_plans: false, intro_stage: false, started: true, language_stage: true});
			} else if (this.state.chapter_stage == "done") {
				this.setState({display_video: false, display_exercises: false, display_action_plans: false, started: true, finished: true});
			}
		}).catch((error) => {
			console.log("GetChapterStage failed " + error);
			this.setState({server_failure: true});
		});
	}

	var signals_promise = this.GetSignals().then((signals) => {
		this.setState({signals: signals.data.signals});
	}).catch((error) => {
		console.log("GetSignals failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});

	var core_virus_promise = this.GetCoreVirus().then((core_virus) => {
		this.setState({core_virus: core_virus.data.core_virus});
	}).catch((error) => {
		console.log("GetCoreVirus failed " + error);
		this.setState({server_failure: true});

	});

	var antidotes_promise = this.GetAntidotes().then((antidotes) => {
		this.setState({antidotes: antidotes.data.antidotes});
	}).catch((error) => {
		console.log("GetAntidotes failed " + error);
		this.setState({server_failure: true});

	});

	var catalysts_promise = this.GetCatalysts().then((catalysts) => {
		this.setState({all_catalysts: catalysts.data.catalysts});
	}).catch((error) => {
		console.log("GetCatalysts failed " + error);
		this.setState({server_failure: true});
	});

	var stretch_promise = this.GetStretchAttributes().then((stretch) => {
		this.setState({stretch_attributes: stretch.data.attributes});
	}).catch((error) => {
		console.log("GetStretchAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	var action_plans_promise =  this.GetActionPlans().then((action_plans) => {
		this.setState({action_plans: action_plans.data.action_plans, action_plan_steps: action_plans.data.action_plan_steps,
			all_action_plan_steps: action_plans.data.action_plan_steps});
	}).catch((error) => {
		console.log("GetActionPlans failed " + error);
		this.setState({server_failure: true});
	});

	var antidote_language_promise = this.GetAntidoteLanguage().then((antidote_language) => {
		this.setState({antidote_language: antidote_language.data.antidote_language});
	}).catch((error) => {
		console.log("GetAntidoteLanguage failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(language_promise);
	all_promises.push(status_promise);
	all_promises.push(signals_promise);
	all_promises.push(viruses_promise);
	all_promises.push(catalysts_promise);
	all_promises.push(core_attributes_promise);
	all_promises.push(stretch_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_virus_promise);
	all_promises.push(antidotes_promise);
	all_promises.push(action_plans_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {	
	this.GetVirtues().then((virtues) => {
		var current_language = [];
		var cur_cnt = 0;
		var current_virus = 0;
		var temp = [];
		var  finished_aps = [];
		var cnt = 0;
		var finished_cnt = 0;
		var current_catalyst = 0;
		var current_action_plan_id = 0;
		var finished_action_plan_steps = [];
		var faps_cnt = 0;
		var current_catalysts = [];
		var catalyst_cnt = 0;
		for (var i = 0; i< this.state.viruses.length; i++) {
			if (!this.state.viruses[i].finished_action_plans) {
				var catalyst_cnt = 0;
				var virus_catalyst_cnt = 0;
				current_virus = this.state.viruses[i].id;
				this.state.all_catalysts.find((catalyst) => {
					if (catalyst.virus_id == current_virus) {
						current_catalysts.push({});
						current_catalysts[catalyst_cnt].id = catalyst.id;
						current_catalysts[catalyst_cnt].virus_id = catalyst.virus_id;
						current_catalysts[catalyst_cnt].catalyst = catalyst.catalyst;
						catalyst_cnt++;
					}
				});
				catalyst_cnt=0;
				current_catalysts.forEach((catalyst) => {
					this.state.action_plans.find((ap) =>  {
						if (ap.catalyst_id === catalyst.id && ap.finished) {
							catalyst_cnt++;
						}
					});
				});
				
				this.state.all_catalysts.find((catalyst) => {
					if (this.state.viruses[i].id == catalyst.virus_id) {
						virus_catalyst_cnt++;
					}
				});
				if (catalyst_cnt === virus_catalyst_cnt) {
					this.setState({finished_virus: true});
				}
				this.setState({current_virus: this.state.viruses[i].id});
				break;
			}
		}

		this.state.action_plans.forEach((ap) => {
			if (!ap.finished) {
				// Goes to current action plan
				current_catalyst = ap.catalyst_id;
				current_action_plan_id = ap.id;
				this.state.action_plan_steps.find((aps) => {
					if (aps.action_plan == ap.id) {
						temp.push({});
						temp[cnt].step = aps.step;
						temp[cnt].step_number = aps.step_number;
						cnt++;
					}
				});
			} else {
				// Otherwise add it to finished action plans
				current_catalysts.find((catalyst) => {
					if (ap.catalyst_id == catalyst.id) {
						finished_aps.push({});
						finished_aps[finished_cnt].id = ap.id;
						finished_aps[finished_cnt].catalyst_id = ap.catalyst_id;
						this.state.action_plan_steps.find((aps) => {
							if (aps.action_plan == ap.id) {
								console.log("finished_action_plan_steps on mount " + aps.step);
								finished_action_plan_steps.push({});
								finished_action_plan_steps[faps_cnt].step = aps.step;
								finished_action_plan_steps[faps_cnt].step_number = aps.step_number;
								finished_action_plan_steps[faps_cnt].action_plan = aps.action_plan;
								faps_cnt++;
							}
						});
						finished_cnt++;
					}
				});
			}
		});

		if (current_catalyst != 0) {
			this.setState({[current_catalyst+"_checked"]: true, checked_catalyst: current_catalyst});
		}
		this.setState({catalysts: current_catalysts, finished_action_plan_steps: finished_action_plan_steps, current_catalyst: current_catalyst, finished_action_plans: finished_aps, current_action_plan_id: current_action_plan_id,
		[current_catalyst+"_action_plan"]: temp});	
		var wisdom_ids = [];
		var courage_ids = [];
		var justice_ids = [];
		var hl_ids = [];
		var transcendence_ids = [];
		var temperance_ids = [];
		var wisdom_cnt = 0;
		var justice_cnt = 0;
		var courage_cnt = 0;
		var hl_cnt = 0;
		var transcendence_cnt = 0;
		var temperance_cnt = 0;
		var strongest_virtue = "wisdom";
		virtues.data.virtues.forEach((element) => {
			if (element.wisdom) {
				wisdom_ids.push(element.language_id);
				wisdom_cnt++;
			}
			if (element.justice) {
				justice_ids.push(element.language_id);
				justice_cnt++;
			}
			if (element.courage) {
				courage_ids.push(element.language_id);
				courage_cnt++;
			}
			if (element.humanity_love) {
				hl_ids.push(element.language_id);
				hl_cnt++;
			}
			if (element.transcendence) {
				transcendence_ids.push(element.language_id);
				transcendence_cnt++;
			}
			if (element.temperance) {
				temperance_ids.push(element.language_id);
				temperance_cnt++;
			}
		});
		if (justice_cnt>strongest_virtue) {
			strongest_virtue = "justice";
		} 
		if (courage_cnt > strongest_virtue) {
			strongest_virtue = "courage";
		} 
		if (hl_cnt > strongest_virtue) {
			strongest_virtue  = "hl";
		} 
		if (strongest_virtue > transcendence_cnt) {
			strongest_virtue = "transcendence";
		} 
		if (strongest_virtue > temperance_cnt) {
			strongest_virtue = "temperance";
		}

		this.setState({loading: false, wisdom_cnt: wisdom_cnt, justice_cnt: justice_cnt, courage_cnt: courage_cnt,
		hl_cnt: hl_cnt, transcendence_cnt: transcendence_cnt, temperance_cnt: temperance_cnt,
		 strongest_virtue: strongest_virtue, virtues: virtues.data.virtues, wisdom: wisdom_ids, courage: courage_ids,
			justice: justice_ids, temperance: temperance_ids, transcendence: transcendence_ids, humanity_love: hl_ids});
	}).catch((error) => {
		console.log("Failed to get virtues " + error);
		this.setState({server_failure: true});
	});	

	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetAntidoteLanguage() {
	const res = await axios.get(APIHost + '/api/antidoteLanguage',
		{params: {withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetActionPlans() {
	const res = await axios.get(APIHost + '/api/actionPlans',
		{params: {withCredentials: true}});
	return await res;
}

async GetAntidotes() {
	const res = await axios.get(APIHost + '/api/antidotes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCatalysts() {
	const res = await axios.get(APIHost + '/api/catalysts',
		{params: {withCredentials: true}});
	return await res;
}

async GetSignals() {
	const res = await axios.get(APIHost + '/api/signals',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetVirtues() {
	const res = await axios.get(APIHost + '/api/virtues', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreVirus() {
	const res = await axios.get(APIHost + '/api/coreVirusAssociation',
		{params: {withCredentials: true}});
	return await res;
}

async GetStretchAttributes() {
	const res = await axios.get(APIHost + '/api/stretchAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({[event.target.name]: event.target.value});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayFinished(history) {
	var display_finished_steps = [];
	var display_finished_catalyst = [];
	var display_finished_aps = [];
	var display_finished_aps2 = [];
	var display_steps = [];
	var display_catalyst = [];
	var display_viruses = [];
	var display = [];
	var display_video = [];
	var display_button = [];
	var display_intro = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if(this.state.display_video_after) {
		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
		} else {
		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
		}
	this.state.viruses.forEach((virus) => {
		display_catalyst = [];

		this.state.all_catalysts.find((catalyst) => {
			display_steps = [];
			if (catalyst.virus_id == virus.id) {
				this.state.action_plans.find((ap) => {
					if (ap.catalyst_id == catalyst.id) {
						this.state.all_action_plan_steps.find((aps) => {
							if (aps.action_plan == ap.id) {
								display_steps.push(<li>{aps.step}</li>);
							}
						});
					}
				});
				display_catalyst.push(<div><p>For Catalyst: {catalyst.catalyst}</p><ol>{display_steps}</ol></div>);
			}
		});
		display_viruses.push(<div><h3>Virus: {virus.virus}</h3>{display_catalyst}<hr /></div>);
	});

	if (history) {
		display_intro.push(<h1>Welcome back!</h1>);
	} else {
		display_intro.push(<h1>Congrats on finishing the chapter</h1>);
	}

	display.push(<div>{display_intro}<p>Below are all your Action Plans along with each Virus and Catalyst
	they are built for. Take your time here to review them.  Defining them is just your first step. Remember, the idea here is replace
	your negative Response Patterns with positive ones by following your Action Plans when facing your Cognitive Catalysts instead of 
	following the normal negative Response Cycles you have in the past. This takes time! Be patient with yourself. You might find that 
	you start down the negative path, but that's OK! You can initiate your Action Plan at any point to redirect the negative to positive.
	Remember self-compassion. Be compassionate with yourself. There are no failures here. You are learning more and more as you practice
	your Action Plans and every time you do, you are incrementally moving towards Optimal Mental Fitness and your Optimal Life.</p>{display_button}{display_video}
	<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
	<hr className="new" />{display_viruses}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>)
	
	return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false, intro_stage: false});
}

GoToIntroCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, display_video: false, intro_stage: true});
}

GoToEndCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: false, display_video: false, finished: true, loading: false});
}

UpdateStage(stage, callback, from_language) {
	var percent_complete = null;
	if (stage == "exercises") {
		percent_complete = .4;
	} else if (stage == "intro") {
		percent_complete = .1;
	} 
	axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			percent_complete: percent_complete,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback(from_language);
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("exercises", this.GoToExercisesCallback);
}

GoToIntro(event) {
	event.preventDefault();
	this.UpdateStage("intro", this.GoToIntroCallback);
}

LanguageCallback() {
	window.scroll(0,0);
	this.setState({loading: false, language_stage: true, video: false, intro_stage: false, display_exercises: true});
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-52"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToIntro} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

NextVirusCallback(from_language) {
	window.scroll(0,0);
	var current_language = [];
	var cur_cnt = 0;
	var finished_flag = true;
	var current_virus = 0;
	var current_catalysts = [];
	var catalyst_cnt = 0;

	for (var i=0; i<this.state.viruses.length; i++) {
		if (!this.state.viruses[i].finished_action_plans && this.state.viruses[i].id != this.state.current_virus) {
			current_virus = this.state.viruses[i].id;
			finished_flag = false;
			if (!from_language) {
				this.setState({current_virus: this.state.viruses[i].id})
			}
			break;	
		}	
	}

	// if they are coming back from language, we have to set the state variables so things show up for them
	if(!from_language) {
		this.state.all_catalysts.find((catalyst) => {
			if (catalyst.virus_id == current_virus) {
				current_catalysts.push({});
				current_catalysts[catalyst_cnt].id = catalyst.id;
				current_catalysts[catalyst_cnt].virus_id = catalyst.virus_id;
				current_catalysts[catalyst_cnt].catalyst = catalyst.catalyst;
				catalyst_cnt++;
			}
		});
	
		this.setState({catalysts: current_catalysts});
	}
	this.setState({finished_virus: false});
	this.setState({finished_action_plans: [], finished_action_plan_steps: []});
	if(finished_flag) {
		this.UpdateStage("done", this.GoToEndCallback);
	} else if (this.state.intro) {
		this.setState({intro_stage: false, language_stage: true, display_action_plans: false, display_exercises: true});
	} else if (this.state.language_stage) {
		this.setState({intro_stage: false, language_stage: false, display_action_plans: true, display_exercises: true});
	}
}

/*NextVirusCallback(from_language) {
	window.scroll(0,0);
	var current_language = [];
	var cur_cnt = 0;
	var finished_flag = true;
	var current_virus = 0;
	var current_catalysts = [];
	var catalyst_cnt = 0;


	this.setState({finished_action_plans: [], finished_action_plan_steps: []});
	this.state.viruses.forEach((virus) => {
		if (!virus.finished_action_plans && virus.id != this.state.current_virus) {
			finished_flag = false;
		}
	});
	for (var i=0; i<this.state.viruses.length; i++) {
		if (!this.state.viruses[i].finished_action_plans && this.state.viruses[i].id != this.state.current_virus) {
			current_virus = this.state.viruses[i].virus.id;
			if (!from_language) {
				this.setState({current_virus: this.state.viruses[i].id})
			}
			break;	
		}	
	}
	// if they are coming back from language, we have to set the state variables so things show up for them
	if(!from_language) {
		this.state.all_catalysts.find((catalyst) => {
			if (catalyst.virus_id == current_virus) {
				current_catalysts.push({});
				current_catalysts[catalyst_cnt].id = catalyst.id;
				current_catalysts[catalyst_cnt].virus_id = catalyst.virus_id;
				current_catalysts[catalyst_cnt].catalyst = catalyst.catalyst;
				catalyst_cnt++;
			}
		});
	
		this.setState({catalysts: current_catalysts});
	}
	this.setState({finished_virus: false});
	if(finished_flag) {
		this.UpdateStage("done", this.GoToEndCallback);
	} else if (this.state.intro) {
		this.setState({intro_stage: false, language_stage: true, display_action_plans: false, display_exercises: true});
	} else if (this.state.language_stage) {
		this.setState({intro_stage: false, language_stage: false, display_action_plans: true, display_exercises: true});
	}
}*/

NextVirus() {
	if (this.state.intro_stage) {
		this.UpdateStage("language", this.LanguageCallback);
	} else if (this.state.language_stage) {
		this.UpdateStage("exercises", this.NextVirusCallback, true);
	} else {
		axios.post(APIHost + '/api/viruses', {
			withCredentials: true,
			id: this.state.current_virus,
			type: "finished_action_plans"
		}).then(res => {
			this.NextVirusCallback();
		}).catch(error => {
			console.log("Failed to close out a virus description " + error);
			this.setState({submission_failure: true});
		});
	}
}

onNextVirus(event) {
	event.preventDefault();
	this.NextVirus();
}

handleMouseUp(event) {
	var addition = '';
	if (this.state[`${event.target.name}`]) {
		addition = this.state[`${event.target.name}`] + window.getSelection().toString();
	} else {
		addition = window.getSelection().toString();
	}
  this.setState({[`${event.target.name}`]: addition});
}

AntidoteLanguageCallback(antidote_id, language_id) {
	var temp = this.state.antidote_language;
	temp.push({});
	temp[this.state.antidote_language.length-1].antidote_id = antidote_id;
	temp[this.state.antidote_language.length-1].entry = this.state[`${antidote_id}_antidote`];
	temp[this.state.antidote_language.length-1].id = language_id;
	this.setState({antidote_language: temp, [antidote_id+"_antidote"]: ""});
}

AntidoteLanguage(antidote_id) {
	axios.post(APIHost + '/api/antidoteLanguage', {
		withCredentials: true,
		language: this.state[`${antidote_id}_antidote`],
		antidoteID: antidote_id
	}).then(res => {
		this.AntidoteLanguageCallback(antidote_id, res.data.id);
	}).catch(error => {
		console.log("Failed to close out a virus description " + error);
		this.setState({submission_failure: true});
	});
}

onSubmitAntidoteLanguage(event) {
	event.preventDefault();
	this.AntidoteLanguage(event.target.id);
}

RemoveAntidoteLanguageCallback(language_id) {
	var temp = []
	var cnt = 0;
	this.state.antidote_language.forEach((al) => {
		if (al.id != language_id) {
			temp.push({});
			temp[cnt].id = al.id;
			temp[cnt].antidote_id = al.antidote_id;
			temp[cnt].entry = al.entry;
		}
	});
	this.setState({antidote_language: temp});
}

RemoveAntidoteLanguage(language_id) {
	axios.delete(APIHost + '/api/antidoteLanguage', {
	 data: { language_id: language_id, withCredentials: true }
	}).then(res => {
		this.RemoveAntidoteLanguageCallback(language_id);
	}).catch(error => {
		console.log("Failed to remove antidote language " + error);
		this.setState({submission_failure: true});
	});
}

onRemoveAntidoteLanguage(event) {
	event.preventDefault();
	this.RemoveAntidoteLanguage(event.target.id);
}

UnfinalizeCallback(id) {
	var temp = [];
	var temp2 = [];
	var cnt2 = 0;
	var cnt = 0;
	var current_action_plan_id = 0;
	var finished_action_plan_steps = []; 
	var faps_cnt = 0;
	this.state.finished_action_plans.find((ap) => {
		if (ap.catalyst_id == id) {
			this.state.action_plan_steps.find((aps) => {
				if (aps.action_plan == ap.id) {
					temp.push({});
					temp[cnt].step = aps.step;
					temp[cnt].step_number = aps.step_number;
					temp[cnt].action_plan = aps.action_plan;
					current_action_plan_id = aps.action_plan;
					cnt++;
				}
			});
		} else {
			this.state.finished_action_plan_steps.find((faps) => {
				if (faps.action_plan == ap.id) {
					finished_action_plan_steps.push({});
					finished_action_plan_steps[faps_cnt].step = faps.step;
					finished_action_plan_steps[faps_cnt].step_number = faps.step_number;
					finished_action_plan_steps[faps_cnt].action_plan = faps.action_plan;
					faps_cnt++;
				}
			});			
			temp2.push({});
			temp2[cnt2].id = ap.id;
			temp2[cnt2].catalyst_id = ap.catalyst_id;
			cnt2++;		
		}
	});

	this.setState({finished_virus: false, finished_action_plan_steps: finished_action_plan_steps, [id+"_action_plan"]: temp, checked_catalyst: id, finished_action_plans: temp2, 
	[id+"_checked"]: !this.state[`${id}_checked`], current_catalyst: id, current_action_plan_id: current_action_plan_id});
	this.state.catalysts.forEach((c) => {
		if (c.id != id) {
			this.setState({[c.id+"_checked"]: false});
		}
	});
}

CatalystState(event) {
	var current_catalyst = event.target.id;
	var flag = false;
	var current_action_plan_id = 0;
	this.state.finished_action_plans.find((ap) => {
		if (ap.catalyst_id == current_catalyst) {
			flag = true;
			current_action_plan_id = ap.id;
		}
	});
	if (flag) {
		axios.post(APIHost + '/api/actionPlans', {
			withCredentials: true,
			unfinalize: true,
			action_plan_id: current_action_plan_id
		}).then(res => {
			this.UnfinalizeCallback(current_catalyst);
		}).catch(error => {
			console.log("Failed to unfinalize " + error);
			this.setState({submission_failure: true});
		});
	} else {
		console.log("in CatalystState and setting current_catalyst " + current_catalyst);
		this.setState({no_catalyst: false, checked_catalyst: current_catalyst, [current_catalyst+"_checked"]: !this.state[`${current_catalyst}_checked`]});
		this.state.catalysts.forEach((c) => {
			if (c.id != event.target.id) {
				this.setState({[c.id+"_checked"]: false});
			}
		});
	}
}

StopChecked(event) {
	this.checked = false;
    event.preventDefault();
}

ActionPlanStepCallback(action_plan_id, current_step_number, first_time) {
	var temp = [];
	var flag = false;
	var cnt = 0;
	var step_cnt = 0;
	var current_catalyst = 0;
	var temp_steps = [];
	if (first_time) {
		current_catalyst = this.state.checked_catalyst;
	} else {
		current_catalyst = this.state.current_catalyst;
	}
	if(first_time) {
		temp.push({});
		temp_steps.push({});
		temp[0].step = this.state[`${current_catalyst}_ap_step`];
		temp[0].step_number = 1;
		temp_steps[0].step = this.state[`${current_catalyst}_ap_step`];
		temp_steps[0].step_number = 1;
		temp_steps[0].action_plan = action_plan_id;
		flag = true;
	} else {
		this.state[`${current_catalyst}_action_plan`].forEach((ap) => {
			// Always replace the first entry that has empty_step in for its step
			if (ap.step == "empty_step") {
				if (!flag) {
					temp.push({});
					temp_steps.push({});
					temp[cnt].step = this.state[`${current_catalyst}_ap_step`];
					temp[cnt].step_number = ap.step_number;
					temp_steps[step_cnt].step = this.state[`${current_catalyst}_ap_step`];
					temp_steps[step_cnt].step_number = ap.step_number;
					temp_steps[step_cnt].action_plan = this.state.current_action_plan_id;
					flag = true;
				} 
				cnt++;
				step_cnt++;
			} else {
				// otherwise just copy the step over
				temp.push({});
				temp_steps.push({});
				temp[cnt].step = ap.step;
				temp[cnt].step_number = ap.step_number;
				temp_steps[step_cnt].step = ap.step;
				temp_steps[step_cnt].step_number = ap.step_number;
				temp_steps[step_cnt].action_plan = this.state.current_action_plan_id;
				cnt++;
				step_cnt++;
			}
		});
	}
	if (!flag) {
		// this means  it actually a new step
		temp.push({});
		temp_steps.push({});
		temp[cnt].step = this.state[`${current_catalyst}_ap_step`];
		temp[cnt].step_number = current_step_number;
		temp_steps[step_cnt].step = this.state[`${current_catalyst}_ap_step`];
		temp_steps[step_cnt].step_number = current_step_number;
		temp_steps[step_cnt].action_plan = this.state.current_action_plan_id;
		cnt++;
		step_cnt++;
	}
	if (first_time) {
		step_cnt = 1;
	}
	this.state.finished_action_plan_steps.forEach((faps) => {
		temp_steps.push({});
		temp_steps[step_cnt].step = faps.step;
		temp_steps[step_cnt].step_number = faps.step_number;
		temp_steps[step_cnt].action_plan = faps.action_plan;
		step_cnt++
	});

	if (action_plan_id != -1) {
		this.setState({current_action_plan_id: action_plan_id});
	}

	this.setState({[current_catalyst+"_ap_step"]: "", action_plan_steps: temp_steps, no_catalyst: false})
	if (first_time) {
		this.setState({started_action_plan: true, current_catalyst: this.state.checked_catalyst, [this.state.checked_catalyst+"_action_plan"]: temp})
	} else {
		this.setState({[this.state.current_catalyst+"_action_plan"]: temp});
	}
}

ActionPlanStep() {
	if (this.state.current_catalyst === 0 && this.state.checked_catalyst === 0) {
		this.setState({no_catalyst: true});
	} else if(this.state.started_action_plan || (this.state[`${this.state.current_catalyst}_action_plan`] && this.state[`${this.state.current_catalyst}_action_plan`].length>0)) {
		var current_step_number = 0;
		var flag = false;
		for (var i = 0; i < this.state[`${this.state.current_catalyst}_action_plan`].length; i++) {
			if(this.state[`${this.state.current_catalyst}_action_plan`][i].step == "empty_step") {
				current_step_number = this.state[`${this.state.current_catalyst}_action_plan`][i].step_number;
				flag = true;
				break;
			}
		}
		if (!flag) {
			current_step_number =  this.state[`${this.state.current_catalyst}_action_plan`].length + 1;
			axios.post(APIHost + '/api/actionPlans', {
				withCredentials: true,
				step: this.state[`${this.state.current_catalyst}_ap_step`],
				catalyst_id: this.state.current_catalyst,
				step_number: current_step_number,
				new_step: true,
				action_plan_id: this.state.current_action_plan_id
			}).then(res => {
				this.ActionPlanStepCallback(-1, current_step_number, false);
			}).catch(error => {
				console.log("Failed to enter a new step " + error);
				this.setState({submission_failure: true});
			});
		} else {
			axios.post(APIHost + '/api/actionPlans', {
				withCredentials: true,
				step: this.state[`${this.state.current_catalyst}_ap_step`],
				catalyst_id: this.state.current_catalyst,
				step_number: current_step_number,
				update: true,
				action_plan_id: this.state.current_action_plan_id
			}).then(res => {
				this.ActionPlanStepCallback(-1, current_step_number, false);
			}).catch(error => {
				console.log("Failed to update a step " + error);
				this.setState({submission_failure: true});
			});
		}
	} else {
		var temp = 0;
		var step = null;
		if(this.state[`${temp}_ap_step`]) {
			step = this.state[`${temp}_ap_step`];
			this.setState({[this.state.checked_catalyst+"_ap_step"]: step});
		} else {
			step = this.state[`${this.state.checked_catalyst}_ap_step`];
		}
		axios.post(APIHost + '/api/actionPlans', {
			withCredentials: true,
			step: step,
			catalyst_id: this.state.checked_catalyst,
			step_number: 1
		}).then(res => {
			this.ActionPlanStepCallback(res.data.id, 1, true);
		}).catch(error => {
			console.log("Failed to close out a virus description " + error);
			this.setState({submission_failure: true});
		});
	}
}

onSubmitActionPlanStep(event) {
	event.preventDefault();
	this.ActionPlanStep();
}

FinishActionPlanCallback() {
	var finished_aps = this.state.finished_action_plans;
	var catalyst_cnt = 0;
	var virus_catalyst_cnt = 0;
	var faps_cnt = 0;
	var flag = false;
	var local_action_plan_steps = [];
	var finished_action_plan_steps = this.state.finished_action_plan_steps;
	var empty_cnt = 0;
	var local_cnt = 0;
	if (this.state.finished_action_plan_steps.length > 0) {
		faps_cnt = this.state.finished_action_plan_steps.length;
	
	} 

	// this is just here for the finish - when they finish, the last virus they do didn't have the steps listed
	// this is here to ensure it does - tried it in the NextVirusCallback but the current_catalyst is set to 0 here
	var local_action_plan_steps = this.state.all_action_plan_steps;
	var local_action_plans = this.state.action_plans;
	local_action_plans.push({});
	local_action_plans[this.state.action_plans.length-1].id = this.state.current_action_plan_id;
	local_action_plans[this.state.action_plans.length-1].catalyst_id = this.state.current_catalyst;
	var step_cnt = local_action_plan_steps.length;
	for (var i=0; i<this.state[`${this.state.current_catalyst}_action_plan`].length; i++) {
	local_action_plan_steps.push({});
		local_action_plan_steps[step_cnt-1].step = this.state[`${this.state.current_catalyst}_action_plan`][i].step;
		local_action_plan_steps[step_cnt-1].step_number = this.state[`${this.state.current_catalyst}_action_plan`][i].step_number;
		local_action_plan_steps[step_cnt-1].action_plan = this.state.current_action_plan_id;	
		step_cnt++;
	}
	this.setState({all_action_plan_steps: local_action_plan_steps, action_plans: local_action_plans});

	finished_aps.push({});

	finished_aps[finished_aps.length-1].id = this.state.current_action_plan_id;
	finished_aps[finished_aps.length-1].catalyst_id = this.state.current_catalyst;
	empty_cnt = 0;
	for(var i = 0; i<this.state[`${this.state.current_catalyst}_action_plan`].length; i++) {
		if (this.state[`${this.state.current_catalyst}_action_plan`][i].step == "empty_step") {
			empty_cnt++;
		} else {
			finished_action_plan_steps.push({});
			if (empty_cnt != 0) {
				finished_action_plan_steps[faps_cnt].step = this.state[`${this.state.current_catalyst}_action_plan`][i].step;
				finished_action_plan_steps[faps_cnt].step_number = this.state[`${this.state.current_catalyst}_action_plan`][i].step_number - empty_cnt;
				finished_action_plan_steps[faps_cnt].action_plan = this.state.current_action_plan_id;			
			} else {
				finished_action_plan_steps[faps_cnt].step = this.state[`${this.state.current_catalyst}_action_plan`][i].step;
				finished_action_plan_steps[faps_cnt].step_number = this.state[`${this.state.current_catalyst}_action_plan`][i].step_number + empty_cnt;
				finished_action_plan_steps[faps_cnt].action_plan = this.state.current_action_plan_id;				
			}
			faps_cnt++;
		}
	}

	finished_aps.forEach((faps) => {
		this.state.catalysts.find((catalyst) =>  {
			if (faps.catalyst_id == catalyst.id) {
				catalyst_cnt++;
			}
		});
	});
	
	this.state.catalysts.find((catalyst) => {
		if (this.state.current_virus == catalyst.virus_id) {
			virus_catalyst_cnt++;
		}
	});
	if (catalyst_cnt === virus_catalyst_cnt) {
		this.setState({finished_virus: true});
	}
	this.state.catalysts.forEach((c) => {
		this.setState({[c.id+"_checked"]: false});
	});

	this.setState({started_action_plan: false, finished_action_plan_steps: finished_action_plan_steps, finished_action_plans: finished_aps, [this.state.current_catalyst+"_action_plan"]: [], current_catalyst: 0})
}

FinishActionPlan() {
	axios.post(APIHost + '/api/actionPlans', {
		withCredentials: true,
		finished: true,
		action_plan_id: this.state.current_action_plan_id
	}).then(res => {
		this.FinishActionPlanCallback();
	}).catch(error => {
		console.log("Failed to enter a new step " + error);
		this.setState({submission_failure: true});
	});
}

onFinishActionPlan(event) {
	event.preventDefault();
	this.FinishActionPlan();
}

RemoveStepCallback(step_number, deleted) {
	var temp = [];
	var cnt =0;
	var local_steps = [];
	var local_cnt = 0;

	// this logic is to exclude the step that matches in the same action plan
	this.state.action_plan_steps.forEach((step) => {
		if (step.step_number == step_number && step.action_plan != this.state.current_action_plan_id) {
			local_steps.push({});
			local_steps[local_cnt].step = step.step;
			local_steps[local_cnt].step_number = step.step_number;
			local_steps[local_cnt].action_plan = step.action_plan;
			local_cnt++;
		} else if (step.step_number != step_number) {
			local_steps.push({});
			local_steps[local_cnt].step = step.step;
			local_steps[local_cnt].step_number = step.step_number;
			local_steps[local_cnt].action_plan = step.action_plan;
			local_cnt++;
		}
	});

	this.state[`${this.state.current_catalyst}_action_plan`].forEach((ap) => {
		if (ap.step_number == step_number) {
			if (!deleted) {
				temp.push({});
				temp[cnt].step = "empty_step";
				temp[cnt].step_number = ap.step_number;
				cnt++;
			} 
		} else {
			temp.push({});
			temp[cnt].step = ap.step;
			temp[cnt].step_number = ap.step_number;
			cnt++;
		}
		
	});
	this.setState({action_plan_steps: local_steps, [this.state.current_catalyst+"_action_plan"]: temp});
}

RemoveStep(step_number) {
	if (step_number == this.state[`${this.state.current_catalyst}_action_plan`].length) {
		axios.delete(APIHost + '/api/actionPlans', {
	 		data: { step_number: step_number, action_plan_id: this.state.current_action_plan_id, withCredentials: true }
		}).then(res => {
			this.RemoveStepCallback(step_number, true);
		}).catch(error => {
			console.log("Failed to delete " + step_number + error);
			this.setState({submission_failure: true});
		});
	} else {
		axios.post(APIHost + '/api/actionPlans', {
			withCredentials: true,
			action_plan_id: this.state.current_action_plan_id,
			step_number: step_number,
			remove: true
		}).then(res => {
			this.RemoveStepCallback(step_number, false);
		}).catch(error => {
			console.log("Failed to Remove Step " + error);
			this.setState({submission_failure: true});
		});		
	}
}

onRemoveStep(event) {
	event.preventDefault();
	this.RemoveStep(event.target.id);
}

GoBackToLanguage(event) {
	event.preventDefault();
	this.UpdateStage("language", this.LanguageCallback);
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_viruses = [];
	var display_cc = [];
	var display_ca = [];
	var display_stretch = [];
	var display_questions = [];
	var display_questions_core = [];
	var display_mc = [];
	if (this.state.language_stage) {
		for (var i=0; i<this.state.antidotes.length; i++) {
			var display_antidote_language = [];
			for (var j=0; j<this.state.antidote_language.length; j++) {
				if (this.state.antidote_language[j].antidote_id == this.state.antidotes[i].id) {
					display_antidote_language.push(<li>{this.state.antidote_language[j].entry} <button id={this.state.antidote_language[j].id} name="antidote" className="btn btn-primary my-3" onClick={this.onRemoveAntidoteLanguage}>Remove</button></li>);
				}
			}
			// just need to duplicate the name below and pass it to the mouse up function
			var temp_id = this.state.antidotes[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Antidote:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.antidotes[i].antidote} name={`${this.state.antidotes[i].id}_antidote`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={`${i+1}_antidote`} name={`${this.state.antidotes[i].id}_antidote`} value={this.state[`${this.state.antidotes[i].id}_antidote`]} className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitAntidoteLanguage} type="submit" className="btn btn-primary my-3" name={`${i+1}_add_antidotee`} id={this.state.antidotes[i].id}>Add</button>
  				</form>
  				<div><ul>{display_antidote_language}</ul></div>
			</div></div></div>
			);
		}
		display_intro.push(<div><p>In the coming sections of this chapter, you will create your action plans based on the Antidotes you've discovered previously. You can do this
		using the full descriptions of the Antidotes you provided or you can extract the essential language, as you have before, from those Antidotes. Below, you have a chance
		to do just that. Each of your Antidotes are shown here. You can go through the process of extracting the essential language from as many of those Antidotes as you'd like. 
		You can do this as you have before by highlighting the language on the left or typing it directly into the box and then hitting "Add". If you start doing this for an Antidote, it
		is best if you complete that Antidote so you will have a full list of the associated language. You can also come back to this if you find working with the language is easier than the full descriptions.</p>
		<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
			<hr /> {display_mc} {display_viruses} {display_questions}
				<p><button onClick={this.onNextVirus} className="custom-btn" type="submit" id="viruses">Finish</button></p></div>);
			
		  if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
			} else {
			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
			}
		display.push(<div className="col-md-52"><h1>Your Antidote Language</h1>{display_button}
		{display_video}{display_intro}</div>);
	} else  if (this.state.display_action_plans) {
		if (this.state.display_video_after) {
			display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
	                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
	                 </div></div>); 
		}

		this.state.core_constitution.forEach((cc) => {
			display_cc.push(<p>Entry: {cc.entry}<Popup trigger={<button> See description</button>} position="right center">
		    				<div>{cc.description}</div>
		  					</Popup></p>);
		});
		this.state.core_attributes.forEach((ca) => {
			display_ca.push(<p>Attribute: {ca.attribute}<Popup trigger={<button> See description</button>} position="right center">
		    				<div>{ca.description}</div>
		  					</Popup></p>);
		});
		this.state.stretch_attributes.forEach((stretch) => {
			display_stretch.push(<p>Attribute: {stretch.attribute}<Popup trigger={<button> See description</button>} position="right center">
		    				<div>{stretch.description}</div>
		  					</Popup></p>);
		});
		var display_action_plan_steps = [];
		var display_action_plan = [];
		var display_finished_aps = [];
		var display_finished_steps = [];
		var display_finished_aps2 = [];
		var display_finished_catalyst = [];
		if (this.state[`${this.state.current_catalyst}_action_plan`]) {
			this.state[`${this.state.current_catalyst}_action_plan`].forEach((ap) => {
				if (ap.step == "empty_step") {
					display_action_plan_steps.push(<li>  </li>)
				} else {
					display_action_plan_steps.push(<li>{ap.step}  <button id={ap.step_number} name="step" className="btn btn-primary my-3" onClick={this.onRemoveStep}>Replace Step</button></li>);
				}
			});
		}
		this.state.finished_action_plans.forEach((aps) => {
			display_finished_steps = [];
			display_finished_catalyst = [];
			this.state.finished_action_plan_steps.find((steps) => {
				if (steps.action_plan == aps.id) {
					display_finished_steps.push(<li>{steps.step}</li>);
				}
			});
			this.state.catalysts.find((catalyst) => {
				if (catalyst.id == aps.catalyst_id) {
					display_finished_catalyst.push(<p>Catalyst for this Action Plan: {catalyst.catalyst}</p>);
				}
			});
			display_finished_aps.push(<div>{display_finished_catalyst}{display_finished_steps}<hr /></div>);
		});
		display_finished_aps2.push(<div><h5>Finished Action Plans</h5>{display_finished_aps}</div>);
		display_action_plan.push(<div><ol>{display_action_plan_steps}</ol></div>);

		this.state.viruses.find((virus) => {
			var display_core_virus = [];
			var display_cc_core = [];
			var display_ca_core = [];
	
			var has_core = false;
			if (virus.id == this.state.current_virus) {
				var display_catalysts = [];
				var display_signals = [];
				var display_antidotes = [];
				var display_antidotes2 = [];
				var display_antidotes_language = [];
				this.state.catalysts.find((c) => {
					if (virus.id == c.virus_id) {
						this.state.action_plans.find((ap) =>  {
							if (ap.catalyst_id == c.id) {

							}
						});
						if (this.state.current_catalyst != 0 || this.state[`${c.id}_checked`]) {
							display_catalysts.push(<div><p><input onClick={this.StopChecked} name="catalyst" checked={this.state[`${c.id}_checked`]} id={c.id} type="checkbox" />  {c.catalyst}</p></div>);
						} else {
							display_catalysts.push(<div><p><input onClick={this.CatalystState} name="catalyst" checked={this.state[`${c.id}_checked`]} id={c.id} type="checkbox" />  {c.catalyst}</p></div>);
						}
					}
				});
				
				this.state.signals.find((s) => {
					if (virus.id == s.virus_id) {
						display_signals.push(<li>{s.signal}</li>);
					}
				});

				this.state.antidotes.find((antidote) => {
					if (antidote.virus_id == virus.id) {
						var antidote_flag = false;
						display_antidotes_language = [];
						display_antidotes_language.push(<p>The language for this Antidote</p>);
						this.state.antidote_language.find((al) => {
							if (al.antidote_id == antidote.id) {
								antidote_flag = true;
								display_antidotes_language.push(<li>{al.entry}</li>);
							}
						});
						if (!antidote_flag) {
							display_antidotes.push(<div><p>{antidote.antidote}</p><hr className="new" /></div>);
						} else {
							display_antidotes2.push(<div><ul>{display_antidotes_language}</ul><Popup trigger={<button className="btn btn-primary my-3"> Full Antidote</button>} position="right center">
		    				<div>{antidote.antidote}</div>
		  					</Popup><hr className="new" /></div>);
						}
					}
				});

				display_viruses.push(<div><h5>Virus: {virus.virus}</h5>
				<p>Description: {virus.description}</p>
				<div className='container'>
				<div className='row'>  
				<div className='col-md-6'>
				<h5>This Virus's Catalysts</h5>
						<div>{display_catalysts}</div>
				<h5>This Virus's Signals</h5>
						<div><ul>{display_signals}</ul></div>
				<h5>Antidotes for this Virus</h5>
				{display_antidotes}
				{display_antidotes2}
				</div>

				<div className='col-md-6'>

						<h5>Enter Action Plan Step</h5>
						{this.state.no_catalyst && <NoCatalyst />}
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={`${this.state.checked_catalyst}_ap_step`} name={`${this.state.checked_catalyst}_ap_step`} value={this.state[`${this.state.checked_catalyst}_ap_step`]} className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitActionPlanStep} type="submit" className="btn btn-primary my-3" name={`${this.state.checked_catalyst}_action_plan`}>Add Step</button>
  				</form>
  				<div><br /><button onClick={this.onFinishActionPlan} type="submit" className="btn btn-primary my-3" name={`${this.state.checked_catalyst}_action_plan`}>Finish Action Plan</button><ul>{display_action_plan}</ul></div><hr />{display_finished_aps2}
				</div></div></div><hr className="new" /></div>);
			}
		});
	}
	if(this.state.intro_stage) {
		display.push(<div><h1>Intro to Building Your Action Plans</h1><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><p>In this chapter, you will build Action Plans one Virus at a time. Each page will show you a Virus, Cognitive Catalysts and Signals,
		and your Antidotes. Remember, each Virus has Cognitive Catalysts that bring that Virus forward causing negative Response Cycles to continue. Your Antidotes are what you have identified as starting points
		to battle back against those Catalysts. Your Action Plans are the formal steps you are going to call on when you face a Catalyst. You intercept the normal Response Pattern, which are the normal steps
		that are followed from the negative evoked by that Catalyst, and replace it with the steps you've defined in the Action Plan. As you continue to do this, you are truly re-patterning those negative 
		Response Patterns with positive ones, restructuring your Psychological Structure and moving towards Optimal Mental Fitness.  
		 Let's look at an example of how this will work. take a Virus such as angry and an associated attribute of empathetic. Let's suppose that part of the description under angry is:  
		"I was cut off while driving the other day. I screamed and swore, and pounded the dashboard of my car". A Trigger that would speak to this event would be:  "I get angry when 
		I'm disrespected while driving". </p>
		<p>Some of the key Signals from the Virus description of angry could be:
		<ul><li>Heavy breathing</li><li>Elevated pulse</li><li>Swearing</li><li>Yelling</li></ul></p>
		<p>Going back to the description under empathy we find the following with key phrases in bold:</p>
		<p>I try to <b>put myself in someone else's shoes.</b> I try to imagine <b>that I don't know what that person is going through</b> so <b>to treat them with kindness.</b> I try and <b>teach my son</b> that whenever 
		 can. When we were at a store last week, there was a rude person in line. I told my son that he is probably having a bad 
		 day and we should <b>show him empathy</b>.</p>
		 <p>From this, a possible Antidote could be (with key language highlighted)</p>
		 <p>When <b>someone does something disrespectful</b> to me while I'm driving, <b>I should stop and think</b> that <b>they might have something going on</b> in his or her life <b>that I'm not aware of.</b> If <b>my son</b> was in the car, 
		 <b>I would</b> take the opportunity to <b>teach him</b> that <b>we should respond with empathy</b> and <b>put ourselves in that person's shoes.</b></p>
		 <p>A specific Action Plan based on this description could be:</p>
		 <ol><li>Take a deep breath to get breathing under control</li>
		 <li>Think of a difficulty the person might be facing in his or her life</li>
		 <li>Imagine my son is there and explain that difficulty to him and explain how we should react in this situation</li>
		 <li>Turn up the radio and refocus my attention elsewhere</li></ol>
		 <p>Action Plans need to be short, easy, and concise. You are proactively preparing to respond to Catalysts by using the healthy parts of 
		 your Mental Core for guidance. Over time, these Action Plans will replace the negative Response Patterns that are normally retrieved by
		 these Catalysts, disrupting the normal negative Response Cycles that have driven the negative in your life. </p>
		 <p>Given this takes time and practice, you will likely find yourself following negative Response Cycles, responding to a situation as you have previously. If that happens, you can still execute on your Action Plan, but it might take more 
		 focus and effort in the moment. No matter how far down the negative Response Cycle you are, the associated Action Plan for a Catalyst can be executed on. If we take the example above, if the person starts to yell and swear at the person 
		 that cut him off, the feedback or continued Catalysts from the other driver will drive the normal Response Cycle forward. At any time while that normal negative Response Cycle is carried out, he can take that first step in his action plan and 
		 start down an alternative path of responses. </p>
		 <p>Go ahead and click "Get Started" when you are ready to get going on creating your Action Plans</p>
		 <h5>You Got This!</h5>
		<p><button className="custom-btn" onClick={this.onNextVirus} type="submit" id="viruses">Get Started</button></p></div>);
	} else if(!this.state.language_stage) {
		display_intro.push(<div><p>Below, you will find a specific Virus, along with the identified Catalysts and Antidotes you've worked so hard on
		in previous chapters. The idea here is to associate an Action Plan with a Catalyst, using your Antidotes as your guide. Click on a Catalyst on the left, and start to enter each step of your Action Plan on the right.
		When you have finished defining the steps of your Action Plan, hit "Finish Action Plan", and move onto the next Catalyst. If you want to replace a step, you can click on "Replace Step" and enter a new step. If you 
		want to edit an Action Plan you already clicked Finish for, then simply click on the Catalyst again and you can make updates. Once you have created
		Action Plans for each Catalyst, the Finish button at the bottom of the page will become active, and you can move onto the next Virus. You complete the chapter once all the Viruses have
		been completed. If you want to go back and work on extracting the language from the Antidotes, click <a href="#"onClick={this.GoBackToLanguage}>here</a>.</p>
		<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>
			<hr /> {display_mc} {display_viruses} {display_questions}
				<p><button onClick={this.onNextVirus} type="submit" id="viruses" className="custom-btn" disabled={!this.state.finished_virus}>Finish</button></p></div>);
			
		  if(this.state.display_video_after) {
			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
			} else {
			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
			}
		display.push(<div className="col-md-52"><h1>Building Your Action Plans</h1>{display_button}
		{display_video}{display_intro}</div>);
	}

	return display;
}

GoToEnd() {
	this.UpdateStage("done", this.GoToEndCallback);
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter53">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished(true)}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter53">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
					{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished(false)}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter53