import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';

class CommunityCorner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
  if(this.props.location.state == null) {
    return ( <Redirect to='/Home' /> );
  } else {
      return (
        <>
        <Header history={this.props.history} loggedIn={true} />
          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">My Core Insights Community Corner coming soon!</h4>
                    <p className="text">The My Core Insights Community Corner is where I'll share special content with you and where you as a Community Member will be able to share your wins, post updates, and share with the res of the Community. While we get this
                    experience ready for you, enjoy the video here that talks about the science behind Mental Fitness Training.
                     </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src="https://player.vimeo.com/video/563833189" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>               
                </div>
              </div>
            </div>
          </section>
          <Footer history={this.props.history} loggedIn={true} />
        </>
      );
    }
  }
}

export default CommunityCorner;