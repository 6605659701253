import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Header from './components/header';
import Footer from './components/footer';
import axios from 'axios';
import { APIHost } from './GlobalVariables';
import { Link, Redirect } from 'react-router-dom';

const CLIENT = {
  sandbox:
    "your_sandbox_key",
  production:
    "your_production_key"
};

const CLIENT_ID = "AXfaduH2bsRGm_-iOWDRUnvsNu8JY5FrfwhoHr4C624JGchOI6wshR5b-o0cRlSfkqHIZHnXaMY2FhiT"
//process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

const CreditUpdateFailure = () => (
  <h2><font color="red">We apologize. An error occurred while updating the number of credits for you. Support has been automatically contacted and will rectify as soon as possible.<br />
If you have any questions or concerns, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const ServerFailure = () => (
  <h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      quantity: 1,
      update_credit_failure: false,
      total: 0,
      discountCode: "",
      loading: false,
      account_type: '',
      server_failure: false,
      paid: false
    };

    this.UpdateQuantity = this.UpdateQuantity.bind(this);
    this.UpdateDiscountCode = this.UpdateDiscountCode.bind(this);

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    this.setState({loading: true});
    axios.get(APIHost + '/api/getAccountType', 
      {params: {withCredentials: true}
    }).then((type) => {
      const { isScriptLoaded, isScriptLoadSucceed } = this.props;
      if (type.data.account_type == 'Self-directed') {
        this.setState({total: 12.99});
      } else {
        this.setState({total: 135});
      }
      if (isScriptLoaded && isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
        this.setState({ account_type: type.data.account_type, loading: false, showButtons: true });
      }
    }).catch((error) => {
      console.log("getAccountType failed " + error);
      this.setState({server_failure: true});
    });

  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  createOrder = (data, actions) => {
    var reg_charge = 0;
    var discount_charge = 0;
    if (this.state.account_type == 'Self-directed') {
      reg_charge = 12.99;
      discount_charge = 12.99;
    } else {
      reg_charge = 135;
      discount_charge = 135;
    }
    if (this.state.discountCode == "MCI") {
      var amount = this.state.quantity * discount_charge;
    } else {
      var amount = this.state.quantity * reg_charge;
    }
    return actions.order.create({
      purchase_units: [
        {
          description: +"Objective",
          amount: {
            currency_code: "USD",
            value: amount,
          },
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      console.log("Payment Approved: ", paymentData);
      axios.post(APIHost + '/api/getCredits', {
        credits: this.state.quantity,
        withCredentials: true
      }).then(() => {
        this.setState({ showButtons: false, paid: true });
      }).catch((error) => {
        console.log("Error is " + error);
        this.setState({ update_credit_failure: true });
      });

    });
  };

  UpdateQuantity(event) {
    this.setState({ quantity: event.target.value.replace(/\D/, '') });
    /*if (event.target.value == 0) {
      if (this.state.discountCode == "MCI") {
        this.setState({total: 19.99});
      } else {
        this.setState({ total: 34.99 });
      }
    } else {
      if (this.state.discountCode == "MCI") {
        this.setState({total: event.target.value * 19.99})
      } else {
        this.setState({ total: event.target.value * this.state.total });
      }
    }*/
      var reg_charge = 0;
  var discount_charge = 0;
  if (this.state.account_type == 'Self-directed') {
    reg_charge = 12.99;
    discount_charge = 12.99;
  } else {
    reg_charge = 135;
    discount_charge = 135;
  }
  if (event.target.value == 0) {
      if (this.state.discountCode == "MCI") {
        this.setState({total: discount_charge});
      } else {
        this.setState({total: reg_charge})
      }
    } else {
      if(this.state.discountCode == "MCI") {
        this.setState({total: event.target.value*discount_charge});
      } else {
        this.setState({total: event.target.value*this.state.total});
      }
  }
  };

  UpdateDiscountCode(event) {
        var reg_charge = 0;
    var discount_charge = 0;
    if (this.state.account_type == 'Self-directed') {
      reg_charge = 12.99;
      discount_charge = 12.99;
    } else {
      reg_charge = 135;
      discount_charge = 135;
    }
      if (event.target.value == "MCI") {
        this.setState({total: this.state.quantity * discount_charge});
      } else {
        this.setState({ total: this.state.quantity * reg_charge });
      }
    this.setState({discountCode: event.target.value});
  }

  render() {
    const { showButtons, loading, paid } = this.state;
    if (this.state.loading) {
      return (<div className="Home"><Header /><h1>Loading...</h1></div>);
    } else {
      return (
        <div className="main">
          <Header history={this.props.history} />
          <section className="two-sided-box">
            <div className="container">
              <div className="row justify-content-center">

                <div className="col-md-6">
                  <div className="paypal-box">
                    <h5 className="mb-3">PayPal requires additional information not necessary for credit purchase like your shipping address, phone number, etc. This is a requirement from PayPal. None
                        of the information is used or stored by My Core Insights.</h5>
                        <p>Do you have a discount code? <input value={this.state.discountCode} onChange={this.UpdateDiscountCode} /></p>
                    <p>Enter number of credits you want to purchase <input value={this.state.quantity} onChange={this.UpdateQuantity} /></p>
                    <p>Total: {this.state.total}</p>
                    {showButtons && (
                      <div className="mt-3">
                        <PayPalButton
                          createOrder={(data, actions) => this.createOrder(data, actions)}
                          onApprove={(data, actions) => this.onApprove(data, actions)}
                        />
                      </div>
                    )}

                    {paid && (
                      <div className="main">
                        <h2>
                          You successfully purchased your credits!
            </h2>
                      </div>
                    )}
                    {this.state.update_credit_failure && <CreditUpdateFailure />}
                  </div>
                </div>
              </div>
            </div>

          </section>
          <Footer />

        </div>

      );
    }
  }
}


export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`)(PaypalButton);
