import React, { Component, useEffect } from 'react';
import axios from 'axios';
import {APIHost} from './GlobalVariables';

export const UpdateLastActivity = () => {
	console.log("Calling. last activity");
	axios.post(APIHost + '/api/updateLastActivity', {
      withCredentials: true
    }).catch(error => {
    	console.log("Error in updateLastActivity " + error)
		return false;
    });
}

