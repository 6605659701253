import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the information to our servers. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const EmptyVirus = () => (
<p><font color="red">Please make sure to enter a virus first.</font></p>
);

class Chapter32 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 32,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		average: 0,
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_exercises: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		victimization: [],
		generalizations: [],
		expectations: [],
		decision_latitude: [],
		self_limiting: [],
		judgmental: [],
		aggressive: [],
		regret: [],
		personalization: [],
		future_tailing: [],
		magnification: [],
		all_nothing: [],
		minimizing: [],
		mind_reading: [],
		comparative: [],
		new_viruses: [],
		empty_virus: false,
		chapter_video: "https://player.vimeo.com/video/449406323",
		language: []
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.onGoToExercises = this.onGoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.SubmitVirus = this.SubmitVirus.bind(this);
this.SubmitVirusCallback = this.SubmitVirusCallback.bind(this);
this.onSubmitVirus = this.onSubmitVirus.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.onRemoveEntry = this.onRemoveEntry.bind(this);
this.RemoveEntry = this.RemoveEntry.bind(this);
this.RemoveVirusCallback = this.RemoveVirusCallback.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, display_exercises: false, started: true});
		} else if (stage.data.stage != null && stage.data.stage != "video" && stage.data.stage != "done") {
			this.setState({display_video: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "done") {
			this.setState({display_video: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses})
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);

	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(language_promise);
	all_promises.push(viruses_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		var language_ids_gen = [];
		var language_ids_exp = [];
		var language_ids_dl = [];
		var language_ids_sl = [];
		var language_ids_j = [];
		var language_ids_a = [];
		var language_ids_r = [];
		var language_ids_p = [];
		var language_ids_ft = [];
		var language_ids_mag = [];
		var language_ids_an = [];
		var language_ids_min = [];
		var language_ids_mr = [];
		var language_ids_c = [];
		var language_ids_v = [];
		var min = 0;
		var max = 0;
		this.GetCognitiveTraps().then((cognitive_traps) => {
			cognitive_traps.data.cognitive_traps.forEach((element) => {
				if (element.victimization) {
					language_ids_v.push(element.language_id);
				} 
				if (element.generalizations) {
					language_ids_gen.push(element.language_id);
				} 
				if (element.expectations) {
					language_ids_exp.push(element.language_id);
				} 
				if (element.decision_latitude) {
					language_ids_dl.push(element.language_id);
				} 
				if (element.self_limiting) {
					language_ids_sl.push(element.language_id);
				} 
				if (element.judgmental) {
					language_ids_j.push(element.language_id);
				} 
				if (element.aggressive) {
					language_ids_a.push(element.language_id);
				} 
				if (element.regret) {
					language_ids_r.push(element.language_id);
				} 
				if (element.personalization) {
					language_ids_p.push(element.language_id);
				}
				if (element.future_tailing) {
					language_ids_ft.push(element.language_id);
				} 
				if (element.magnification) {
					language_ids_mag.push(element.language_id);
				} 
				if (element.all_nothing) {
					language_ids_an.push(element.language_id);
				} 
				if (element.minimizing) {
					language_ids_min.push(element.language_id);
				} 
				if (element.mind_reading) {
					language_ids_mr.push(element.language_id);
				} 
				if (element.comparative) {
					language_ids_c.push(element.language_id);
				} 
			});
			max = Math.max(language_ids_v.length, language_ids_gen.length, language_ids_exp.length, language_ids_dl.length, 
			language_ids_j.length, language_ids_a.length, language_ids_r.length, language_ids_p.length, language_ids_ft.length,
			language_ids_mag.length, language_ids_an.length, language_ids_min.length, language_ids_mr.length, 
			language_ids_c.length);
			min = Math.min(language_ids_v.length, language_ids_gen.length, language_ids_exp.length, language_ids_dl.length, 
			language_ids_j.length, language_ids_a.length, language_ids_r.length, language_ids_p.length, language_ids_ft.length,
			language_ids_mag.length, language_ids_an.length, language_ids_min.length, language_ids_mr.length, 
			language_ids_c.length);
			var average = parseInt((max+min)/2);
			var added_viruses = [];
			this.state.viruses.forEach((virus) => {
				if (virus.added) {
					added_viruses.push(virus);
				}
			});

			this.setState({loading: false, new_viruses: added_viruses, average: average, cognitive_traps: cognitive_traps.data.cognitive_traps, victimization: language_ids_v,
				generalizations: language_ids_gen, expectations: language_ids_exp, decision_latitude: language_ids_dl, self_limiting: language_ids_sl,
				judgmental: language_ids_j, aggressive: language_ids_a, regret: language_ids_r, personalization: language_ids_p, future_tailing: language_ids_ft,
				magnification: language_ids_mag,  all_nothing: language_ids_an, minimizing: language_ids_min, mind_reading: language_ids_mr, 
				comparative: language_ids_c});
		}).catch((error) => {
			console.log("Failed to get cognitive_traps " + error);
			this.setState({server_failure: true});
		});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetCognitiveTraps() {
	const res = await axios.get(APIHost + '/api/cognitiveTraps', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	this.setState({started: true, display_video: true, display_review: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

SubmitVirusCallback(id) {
	var temp_virus = this.state.viruses;
	var temp_new_viruses = this.state.new_viruses;
	temp_new_viruses.push({});
	temp_virus.push({});
	temp_virus[this.state.viruses.length-1].virus = this.state.virus;
	temp_virus[this.state.viruses.length-1].id = id;
	temp_new_viruses[this.state.new_viruses.length-1].virus = this.state.virus;
	temp_new_viruses[this.state.new_viruses.length-1].id = id;
	this.setState({viruses: temp_virus, new_viruses: temp_new_viruses, virus: "", empty_virus: false, current_answer: null});
}

SubmitVirus() {
	if (this.state.current_answer == null) {
		this.setState({empty_virus: true});
	} else {
		axios.post(APIHost + '/api/viruses', {
				withCredentials: true,
				virus: this.state.current_answer, 
				type: "added", 
				cognitive_trap: this.state.chapter_stage
			}).then(res => {
				this.SubmitVirusCallback(res.data.id);
			}).catch(error => {
				console.log("Failed to submit a virus");
				this.setState({submission_failure: true});
			});
		}
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

onSubmitVirus(event) {
	event.preventDefault();
	this.SubmitVirus();
}

RemoveVirusCallback(id) {
	var temp = [];
	var cnt = 0;
	this.state.viruses.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].virus = element.virus;
			cnt++;
		}
	});
	this.setState({viruses: temp});
}

RemoveEntry(id, api) {
	axios.delete(APIHost + '/api/' + api, {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		this.RemoveVirusCallback(id);
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveEntry(event) {
	event.preventDefault();
	this.RemoveEntry(event.target.id, event.target.name);
}

onExerciseChange(event) {
event.persist();
	this.setState({ [event.target.name]: event.target.value, current_answer: event.target.value});
}

DisplayFinished(history) {
	var display_viruses = [];
	var display = [];
	if(this.state.new_viruses.length === 0) {
		display.push(<h4>Doesn't appear you added any viruses through these exercises</h4>);
	} else {
		this.state.new_viruses.forEach((virus) => {
			display_viruses.push(<li>{virus.virus}</li>);
		});
		display.push(<div><p><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><h4>Here are the Viruses you added based on the Cognitive Traps</h4><ul>{display_viruses}</ul><p>We
		will get after these in the coming chapter and continue building towards your Optimal Life</p>	<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);
	}
	return display;
}

NextExercisesCallback() {
	window.scroll(0,0);
	if (this.state.chapter_stage === "victimization") {
		this.setState({chapter_stage: "generalizations"});
	} else if (this.state.chapter_stage === "generalizations") {
		this.setState({chapter_stage: "judgmental"});
	} else if (this.state.chapter_stage === "judgmental") {
		this.setState({chapter_stage: "expectations"});
	} else if (this.state.chapter_stage === "expectations") {
		this.setState({chapter_stage: "decision_latitude"});
	} else if (this.state.chapter_stage === "decision_latitude") {
		this.setState({chapter_stage: "self_limiting"});
	} else if (this.state.chapter_stage === "self_limiting") {
		this.setState({chapter_stage: "aggressive"});
	} else if (this.state.chapter_stage === "aggressive") {
		this.setState({chapter_stage: "regret"});
	} else if (this.state.chapter_stage === "regret") {
		this.setState({chapter_stage: "personalization"});
	} else if (this.state.chapter_stage === "personalization") {
		this.setState({chapter_stage: "future_tailing"});
	} else if (this.state.chapter_stage === "future_tailing") {
		this.setState({chapter_stage: "magnification"});
	} else if (this.state.chapter_stage === "magnification") {
		this.setState({chapter_stage: "all_nothing"});
	} else if (this.state.chapter_stage === "all_nothing") {
		this.setState({chapter_stage: "minimizing"});
	} else if (this.state.chapter_stage === "minimizing") {
		this.setState({chapter_stage: "mind_reading"});
	} else if (this.state.chapter_stage === "mind_reading") {
		this.setState({chapter_stage: "comparative"});
	} else if (this.state.chapter_stage === "comparative") {
		this.setState({chapter_stage: "done", finished: true, display_exercises: false});
	}
	this.setState({empty_virus: false});
}

NextExercises() {
	var next_stage = '';
	var percent_complete = null;
	if (this.state.chapter_stage === "victimization") {
		next_stage = "generalizations";
		percent_complete = .25;
	} else if (this.state.chapter_stage === "generalizations") {
		next_stage = "judgmental";
		percent_complete = .30;
	} else if (this.state.chapter_stage === "judgmental") {
		next_stage = "expectations";
		percent_complete = .35;
	} else if (this.state.chapter_stage === "expectations") {
		next_stage = "decision_latitude";
		percent_complete = .40;
	} else if (this.state.chapter_stage === "decision_latitude") {
		next_stage = "self_limiting";
		percent_complete = .45;
	} else if (this.state.chapter_stage === "self_limiting") {
		next_stage = "aggressive";
		percent_complete = .50;
	} else if (this.state.chapter_stage === "aggressive") {
		next_stage = "regret";
		percent_complete = .55;
	} else if (this.state.chapter_stage === "regret") {
		next_stage = "personalization";
		percent_complete = .60;
	} else if (this.state.chapter_stage === "personalization") {
		next_stage = "future_tailing";
		percent_complete = .65;
	} else if (this.state.chapter_stage === "future_tailing") {
		next_stage = "magnification";
		percent_complete = .70;
	} else if (this.state.chapter_stage === "magnification") {
		next_stage = "all_nothing";
		percent_complete = .75;
	} else if (this.state.chapter_stage === "all_nothing") {
		next_stage = "minimizing";
		percent_complete = .80;
	} else if (this.state.chapter_stage === "minimizing") {
		next_stage = "mind_reading";
		percent_complete = .85;
	} else if (this.state.chapter_stage === "mind_reading") {
		next_stage = "comparative";
		percent_complete = .90;
	} else if (this.state.chapter_stage === "comparative") {
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback();
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises();
}

DisplayExercises(history) {
	var display_final = [];
	var display_ct1 = [];
	var display_ct2 = [];
	var display_mc = [];
	var display_current = [];

	var display_heading = [];
	if (this.state.chapter_stage === "victimization") {
		for (var i=0; i<this.state.victimization.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.victimization[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.victimization.length == 0) {
			display_ct1.push(<div><h4>Victimization doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Victimization: <br  /> “I can play the martyr" where you might be referencing: "At times in my relationships, I make things about me in order to receive sympathy”. 
				<br /><hr className="new" />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.victimization.length < this.state.average) {
			display_ct1.push(<div><h4>Victimization doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Victimization: <br  /> “I can play the martyr" where you might be referencing: "At times in my relationships, I make things about me in order to receive sympathy”. 
				<br /><hr className="new" />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Victimization.</h4><ul>{display_ct2}</ul><hr className="new" /><p> An example Virus
				that can be associated with Victimization is: “I can play the martyr" where you might be referencing: "At times in my relationships, I make things about me in order to receive sympathy”.<br />
				 Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "generalizations") {
		for (var i=0; i<this.state.generalizations.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.generalizations[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.generalizations.length == 0) {
			display_ct1.push(<div><h4>Generalizations doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Generalizations: <br  /> “I use a lot of general terms when I argue". 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);			
		}
		if (this.state.generalizations.length < this.state.average) {
			display_ct1.push(<div><h4>Generalizations doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Generalizations: <br  /> “I use a lot of general terms when I argue". 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Generalizations.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Generalizations is: “I use a lot of general terms when I argue”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "judgmental") {
		for (var i=0; i<this.state.judgmental.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.judgmental[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.judgmental.length == 0) {
			display_ct1.push(<div><h4>Judgmental doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Judgmental: <br  /> “I can be quick to judge situations and people”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.judgmental.length < this.state.average) {
			display_ct1.push(<div><h4>Judgmental doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Judgmental: <br  /> “I can be quick to judge situations and people”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Judgmental.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Judgmental is: “I can be quick to judge situations and people”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "decision_latitude") {
		for (var i=0; i<this.state.decision_latitude.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.decision_latitude[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.decision_latitude.length == 0) {
			display_ct1.push(<div><h4>Decision Latitude doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Decision Latitude: <br  /> “I can react badly when I feel like I don’t have control over my work situation”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.decision_latitude.length < this.state.average) {
			display_ct1.push(<div><h4>Decision Latitude doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Decision Latitude: <br  /> “I can react badly when I feel like I don’t have control over my work situation”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {

			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Decision Latitude.</h4><ul>{display_ct2}</ul><hr className="new" /> <p>An example Virus
				that can be associated with Decision Latitude is: “I can react badly when I feel like I don’t have control over my work situation”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "expectations") {
		for (var i=0; i<this.state.expectations.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.expectations[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.expectations.length == 0) {
			display_ct1.push(<div><h4>Expectations doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Expectations: <br  /> “I tend to expect my significant other to act certain ways”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.expectations.length < this.state.average) {
			display_ct1.push(<div><h4>Expectations doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Expectations: <br  /> “I tend to expect my significant other to act certain ways”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {

			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Expectations.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Expectations is: “I tend to expect my significant other to act certain ways”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "self_limiting") {
		for (var i=0; i<this.state.self_limiting.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.self_limiting[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.self_limiting.length == 0) {
			display_ct1.push(<div><h4>Self Limiting doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Self Limiting: <br  /> “I tend to talk negatively about myself to others”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.self_limiting.length < this.state.average) {
			display_ct1.push(<div><h4>Self Limiting doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Self Limiting: <br  /> “I tend to talk negatively about myself to others”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {

			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Self Limiting.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Self Limiting is: “I tend to talk negatively about myself to others”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "aggressive") {
		for (var i=0; i<this.state.aggressive.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.aggressive[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.aggressive.length == 0) {
			display_ct1.push(<div><h4>Aggressive doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Aggressive: <br  /> “I can be overly aggressive in confrontational situations”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.aggressive.length < this.state.average) {
			display_ct1.push(<div><h4>Aggressive doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Aggressive: <br  /> “I can be overly aggressive in confrontational situations”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {

			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Aggressive.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Aggressive is: “I can be overly aggressive in confrontational situations”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "regret") {
		for (var i=0; i<this.state.regret.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.regret[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.regret.length == 0) {
			display_ct1.push(<div><h4>Regret doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Regret: <br  /> “I get stuck on things that frustrate me”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.regret.length < this.state.average) {
			display_ct1.push(<div><h4>Regret doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Regret: <br  /> “I get stuck on things that frustrate me”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {

			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Regret.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Regret is: “I get stuck on things that frustrate me”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "personalization") {
		for (var i=0; i<this.state.personalization.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.personalization[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.personalization.length == 0) {
			display_ct1.push(<div><h4>Personalization doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Personalization: <br  /> “I find myself assuming I’ve done something wrong if a friend seems upset”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.personalization.length < this.state.average) {
			display_ct1.push(<div><h4>Personalization doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Personalization: <br  /> “I find myself assuming I’ve done something wrong if a friend seems upset”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {

			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Personalization.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Personalization is: “I find myself assuming I’ve done something wrong if a friend seems upset”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "future_tailing") {
		for (var i=0; i<this.state.future_tailing.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.future_tailing[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.future_tailing.length == 0) {
			display_ct1.push(<div><h4>Future Tailing doesn't appear to be a prevalent Cognitive Trap for you. You didn't associate any language with this Cognitive Trap.</h4><p> Here's an example Virus that 
				can be associated with Future Tailing: <br  /> “I find myself worrying about things that haven’t happened yet" or "I tend to anticipate bad outcomes to situations”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.future_tailing.length < this.state.average) {
			display_ct1.push(<div><h4>Future Tailing doesn't appear to be a prevalent Cognitive Trap for you. Here is what you associated with this Cognitive Trap.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Future Tailing: <br  /> “I find myself worrying about things that haven’t happened yet" or "I tend to anticipate bad outcomes to situations”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Future Tailing.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Future Tailing is: “I find myself worrying about things that haven’t happened yet" or "I tend to anticipate bad outcomes to situations”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "magnification") {
		for (var i=0; i<this.state.magnification.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.magnification[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.magnification.length == 0) {
			display_ct1.push(<div><h4>Magnification doesn't appear to be a prevalent Cognitive Trap for you. You didn't associate any language with this Cognitive Trap.</h4><p> Here's an example Virus that 
				can be associated with Magnification: <br  /> “I seem to overreact to situations”.
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.magnification.length < this.state.average) {
			display_ct1.push(<div><h4>Magnification doesn't appear to be a prevalent Cognitive Trap for you. Here is what you associated with this Cognitive Trap.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Magnification: <br  /> “I seem to overreact to situations”.
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Magnification.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Future Tailing is: “I seem to overreact to situations”. <br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "all_nothing") {
		for (var i=0; i<this.state.all_nothing.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.all_nothing[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.all_nothing.length == 0) {
			display_ct1.push(<div><h4>All or Nothing doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with All or Nothing: <br  /> “If things don’t go how I expect, I tend to give up on whatever it is”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.all_nothing.length < this.state.average) {
			display_ct1.push(<div><h4>All or Nothing doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with All or Nothing: <br  /> “If things don’t go how I expect, I tend to give up on whatever it is”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of All or Nothing.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with All or Nothing is: “If things don’t go how I expect, I tend to give up on whatever it is”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "minimizing") {
		for (var i=0; i<this.state.minimizing.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.minimizing[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if(this.state.minimizing.length == 0) {
			display_ct1.push(<div><h4>Minimizing doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Minimizing: <br  /> “I tend to downplay situations out of fear”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.minimizing.length < this.state.average) {
			display_ct1.push(<div><h4>Minimizing doesn't appear to be a prevalent Cognitive Trap for you.</h4> <ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Minimizing: <br  /> “I tend to downplay situations out of fear”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Minimizing.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Minimizing is: “I tend to downplay situations out of fear”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "mind_reading") {
		for (var i=0; i<this.state.mind_reading.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.mind_reading[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.mind_reading.length == 0) {
			display_ct1.push(<div><h4>Mind Reading doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Mind Reading: <br  /> “I find myself imagining what my spouse is thinking and react based on what I’ve imagined”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.mind_reading.length < this.state.average) {
			display_ct1.push(<div><h4>Mind Reading doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Mind Reading: <br  /> “I find myself imagining what my spouse is thinking and react based on what I’ve imagined”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Mind Reading.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Mind Reading is: “I find myself imagining what my spouse is thinking and react based on what I’ve imagined”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} else if (this.state.chapter_stage === "comparative") {
		for (var i=0; i<this.state.comparative.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.comparative[i]) {
					display_ct2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		if (this.state.comparative.length == 0) {
			display_ct1.push(<div><h4>Comparative doesn't appear to be a prevalent Cognitive Trap for you.</h4><p> Here's an example Virus that 
				can be associated with Comparative: <br  /> “I define my value by comparing myself to other people”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else if (this.state.comparative.length < this.state.average) {
			display_ct1.push(<div><h4>Comparative doesn't appear to be a prevalent Cognitive Trap for you.</h4><ul>{display_ct2}</ul><hr className="new" /><p> Here's an example Virus that 
				can be associated with Comparative: <br  /> “I define my value by comparing myself to other people”. 
				<br />Reflect on this and enter a new Virus if one occurs to you.</p></div>);
		} else {
			display_ct1.push(
				<div><h4>Below is your list of language you associated with the Cognitive Trap of Comparative.</h4> <ul>{display_ct2}</ul><hr className="new" /><p>An example Virus
				that can be associated with Comparative is: “I define my value by comparing myself to other people”.<br /> Reflect on your list and this example and enter any new Viruses that
				come to mind for you. </p></div>
			);
		} 
	} 
	for (var i=0; i<this.state.viruses.length; i++) {
		display_current.push(<li>{this.state.viruses[i].virus} <button id={this.state.viruses[i].id} name="viruses" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
	}
	display_mc.push(<div><ul>{display_current}</ul></div>);

	display_final.push(<div>{display_ct1}
	<div className='container'>
    <div className='row'>  
	<div className='col-md-6'>
				<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" name="virus" value={this.state.virus} className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitVirus} className="btn btn-primary my-3" type="submit">Add</button>
  				</form>
  				{this.state.empty_virus && <EmptyVirus />}
  				<br />
  				<p>Click <button onClick={this.onNextExercises} className="btn btn-primary my-3" type="submit">Finish</button> to move to the next section</p>
  				
  				</div>
  				<div className='col-md-6'><h4>Your Viruses</h4> <br />{display_mc}</div></div></div></div>);
	return display_final;
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
		stage: stage,
		percent_complete: .15,
		withCredentials: true,
		sprint_id: this.state.sprintID,
		chapter_id: this.state.chapterID
	}).then(res => {
		callback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_exercises: true, started: true, chapter_stage: "victimization"});
}

onGoToExercises(event) {
	event.preventDefault();
	this.UpdateStage("victimization", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"> <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.onGoToExercises} className="custom-btn">Go To Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter32">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter32">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.display_exercises && 
						this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter32