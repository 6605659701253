import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter104 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 104,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_exercises: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		marked_language: [],
		display_regret: false,
		display_expectations: false,
		display_generalizations: false,
		expectations_cts: [],
		regret_cts: [],
		generalizations_cts: [],
		language_ids_r: [],
		language_ids_exp: [],
		language_ids_gen: [],
		chapter_video: "https://player.vimeo.com/video/447910719",
		language: []
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.LanguageState = this.LanguageState.bind(this);
this.SubmitCognitiveTrapCallback = this.SubmitCognitiveTrapCallback.bind(this);
this.SubmitCognitiveTrap = this.SubmitCognitiveTrap.bind(this);
this.onSubmitCognitiveTrap = this.onSubmitCognitiveTrap.bind(this);
this.GetCognitiveTraps = this.GetCognitiveTraps.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.GetNotes = this.GetNotes.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "exercises") {
			this.setState({display_exercises: true, started: true});
		} else if (stage.data.stage == "regret") {
			this.setState({display_exercises: true, started: true});
		} else if (stage.data.stage == "generalizations") {
			this.setState({display_exercises: true, started: true});
		} else if (stage.data.stage == "done") {
			this.setState({finished: true, started: true});
		} else {
			this.setState({finished: false, started: false});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var language_promise = this.GetLanguage().then((language) => {
		var language_ids = [];
		language.data.language.forEach((element) => {
			language_ids.push(element.id);
		});
		this.setState({language: language.data.language, language_ids: language_ids});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(language_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
			var language_ids_gen = [];
		var language_ids_exp = [];
		var language_ids_dl = [];
		var language_ids_sl = [];
		var language_ids_j = [];
		var language_ids_a = [];
		var language_ids_r = [];
		var language_ids_p = [];
		var language_ids_ft = [];
		var language_ids_mag = [];
		var language_ids_an = [];
		var language_ids_min = [];
		var language_ids_mr = [];
		var language_ids_c = [];
		var language_ids_v = [];
		if(this.state.language.length != 0) {
		this.GetCognitiveTraps().then((cognitive_traps) => {
			cognitive_traps.data.cognitive_traps.forEach((element) => {
				if (element.generalizations) {
					language_ids_gen.push(element.language_id);
				} 
				if (element.expectations) {
					language_ids_exp.push(element.language_id);
				} 
				if (element.regret) {
					language_ids_r.push(element.language_id);
				} 
			});
			this.setState({loading: false, cognitive_traps: cognitive_traps.data.cognitive_traps, 
				generalizations_cts: language_ids_gen, expectations_cts: language_ids_exp, regret_cts: language_ids_r});
		}).catch((error) => {
			console.log("Failed to get cognitive_traps " + error);
			this.setState({server_failure: true});
		});
	} else {
		this.setState({loading: false});
	}
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetCognitiveTraps() {
	const res = await axios.get(APIHost + '/api/cognitiveTrapsWorkshop', 
	{
		params: {withCredentials: true,
			language_ids: this.state.language_ids}
	});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/languageWorkshop',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
	event.preventDefault();
	this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({ [event.target.name]: event.target.value, current_answer: event.target.value});
}

DisplayFinished(history) {
	var display_final = [];
	var display_button = [];
	var display_header = [];
	var display_gen1 = [];
	var display_gen2 = [];
	var display_exp1 = [];
	var display_exp2 = [];
	var display_j1 = [];
	var display_j2 = [];
	var display_r1 = [];
	var display_r2 = [];

	display_button.push(<p>Head back to your <Link to="/home">Profile</Link> to start the next chapter</p>);

	var display_heading = [];

	if (this.state.generalizations_cts.length == 0) {
		display_gen1.push(<div><p>You didn't mark anything as Generalizations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.generalizations_cts.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.generalizations_cts[i]) {
					display_gen2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_gen1.push(
			<div><ul>{display_gen2}</ul></div>
		);
	} 
	if (this.state.expectations_cts.length == 0) {
		display_exp1.push(<div><p>You didn't mark anything as Expectations. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.expectations_cts.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.expectations_cts[i]) {
					display_exp2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_exp1.push(
			<div><ul>{display_exp2}</ul></div>
		);
	} 

	if (this.state.regret_cts.length == 0) {
		display_r1.push(<div><p>You didn't mark anything as Regret. That's great! It is still something to keep an eye out for in your daily life</p></div>);
	} else {
		for (var i=0; i<this.state.regret_cts.length; i++) {
			this.state.language.find((entry) => {
				if(entry.id == this.state.regret_cts[i]) {
					display_r2.push(<li>{entry.entry}</li>);
				}
			});	
		}
		display_r1.push(
			<div><ul>{display_r2}</ul></div>
		);
	} 

		display_header.push(<h1>Here are the Cognitive Traps you identified.</h1>);

		display_final.push(<div className="col-md-12">{display_header} <p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>


		<h3>Regret:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447927520" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup>
		<p>{display_r1}</p>

		<h3>Generalizations:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447900486" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup><p>{display_gen1}</p>


		<h3>Expectations:</h3><Popup trigger={<button className="btn btn-primary my-3"> Watch video</button>} position="right center">
    <div><div className="embed-responsive embed-responsive-4by3 service-video">
<iframe src="https://player.vimeo.com/video/447905847" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                 </div></div>
  </Popup><p>{display_exp1}</p>
  <hr />
		{display_button}
		</div>);	
	return display_final;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, display_exercises: true, started: true, finished: false});
}

SetChapterStage() {
	var next_stage = "";
	if (this.state.chapter_stage == "exercises") {
		next_stage = "regret";
	} else if (this.state.chapter_stage == "regret") {
		next_stage = "generalizations";
	} else {
		next_stage = "done";
	}
	if(this.state.chapter_stage == "generalizations") {
	axios.post(APIHost + '/api/updateStage', {
			stage: next_stage,
			withCredentials: true,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
	}).then(res => {
		this.SubmitCognitiveTrapCallback();
	}).catch(error => {
		console.log("failed to update the stage " + error);
		this.setState({submission_failure: true});
	});
	} else {
		this.SubmitCognitiveTrapCallback();
	}

}

GoToExercises(event) {
	event.preventDefault();
	this.SetChapterStage(this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

SubmitCognitiveTrapCallback() {
	window.scroll(0,0);
	if(this.state.chapter_stage == "exercises") {
		this.setState({display_expectations: false, chapter_stage: "regret", display_regret: true, display_generalizations: false, started: true, finished: false})
	} else if (this.state.chapter_stage == "regret") {
		this.setState({display_expectations: false, chapter_stage: "generalizations", display_regret: false, display_generalizations: true, started: true, finished: false})
	} else {
		this.setState({display_expectations: false, display_regret: false, chapter_stage: "done", display_exercises: false, display_generalizations: false, started: true, finished: true})		
	}
	for (var j=0; j<this.state.language.length; j++) {
		this.setState({[this.state.language[j].id+"_checked"]: false});
	}
}

SubmitCognitiveTrap(id) {
	var values = [];
	var type = "";
	var temp = [];
	if (this.state.chapter_stage == "exercises") {
		values = this.state.expectations_cts;
		type = "expectations";
		values.forEach((foo) => {
			console.log("foo " + foo);
		});
		/*temp = this.state.language_ids_exp;
		temp.push(id);
		this.setState({language_ids_exp: temp});*/
	} else if (this.state.chapter_stage == "regret") {
		values = this.state.regret_cts;
		type = "regret";
	} else {
		values = this.state.generalizations_cts;
		type = "generalizations";
	}
	axios.post(APIHost + '/api/cognitiveTrapsWorkshop', {
			withCredentials: true,
			entries: values,
			chapter_id: this.state.chapterID,
      		sprint_id: this.state.sprintID,
			type: type
		}).then(res => {
    		this.SetChapterStage();
		}).catch(error => {
			console.log("Failed to submit cognitive trap " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitCognitiveTrap(event) {
	event.preventDefault();
	this.SubmitCognitiveTrap(event.target.id);
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

LanguageState(event) {
var temp = [];
if (this.state.chapter_stage == "exercises") {
	temp = this.state.expectations_cts;
} else if (this.state.chapter_stage == "regret") {
	temp = this.state.regret_cts;
} else {
	temp = this.state.generalizations_cts;
}
console.log("ID is " + event.target.id);
var temp2 = [];
var already_there = false;
var objIndex = -1;
objIndex = temp.findIndex((obj => obj == event.target.id));
if (objIndex != -1) {
	already_there = true;
}
if (already_there) {
	for (var i = 0; i<temp.length; i++) {
		if (i != objIndex) {
			temp2.push(temp[i]);
		}
	}
	temp = temp2;
} else {
	temp.push(event.target.id);
}
	if (this.state.chapter_stage == "exercises") {
		this.setState({expectations_cts: temp});
	} else if (this.state.chapter_stage == "regret") {
		this.setState({regret_cts: temp});
	} else {
		this.setState({generalizations_cts: temp});
	}
	this.setState({[event.target.id+"_checked"]: !this.state[`${event.target.id}_checked`]});
}

DisplayExercises() {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_mc = [];
	var display_language = [];
	var display_header = []
	if (this.state.language.length == 0) {
		display.push(<h1>You have not yet completed the Life Events and Language chapter. It is required before proceeding with this chapter</h1>);
	} else {
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	display_language = [];
	for (var j=0; j<this.state.language.length; j++) {
		display_language.push(<p><input onChange={this.LanguageState} name={[`language${j+1}`]} checked={this.state[`${this.state.language[j].id}_checked`]} id={this.state.language[j].id} type="checkbox" />    {this.state.language[j].entry}</p>);
	}
	display_mc.push(
		<div>
		<h5>Your Language</h5>
  		<div>{display_language}</div></div>
	);
	this.state.marked_language.forEach((marked) => {
		console.log("marked_language " + marked);
	});
	if (this.state.chapter_stage == "exercises") {
	display_header.push(<h1>Identifying Expectations</h1>);
	display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel expresses the Cognitive Trap of Expectations where you might
	be expressing expectations of others. </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitCognitiveTrap} className="custom-btn" type="submit">Finish</button></p></div>);
	} else if (this.state.chapter_stage == "regret") {
		display_header.push(<h1>Identifying Regret</h1>);
	display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel expresses the Cognitive Trap of Regret where you might
	be expressing regret of past events. </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitCognitiveTrap} className="custom-btn" type="submit">Finish</button></p></div>);
	} else {
		display_header.push(<h1>Identifying Generalizations</h1>);
	display_intro.push(<div><p>Please use the checkboxes below to identify any of the language in the list that you feel expresses the Cognitive Trap of Generalizations where you might
	be using words such as "always" or "never". </p>
		<hr /> {display_mc}
 		<p><button onClick={this.onSubmitCognitiveTrap} className="custom-btn" type="submit">Finish</button></p></div>);
	}


	display.push(<div className="col-md-12">{display_header}{display_button}
	{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	}
	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter104">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter104">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{!this.state.loading && this.state.started && this.state.display_exercises && this.DisplayExercises()}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter104