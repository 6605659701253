import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import MftIntro from './components/mft-intro';
import MftInfo from './components/mft-info';
import {APIHost} from './GlobalVariables';
import axios from 'axios';
import solution from './images/the_solution2.png';
import impact from './images/the_impact3.png';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Card, Timeline } from 'antd'
import win from './images/big_win2.png';
import scale from './images/at_scale3.png';


const SolutionCode = () => (
    <div className="single-block pt-4">
        <p>
            
            
            <img src={solution} alt="solution" className="img-fluid" />
                                </p>

    </div>
);
/*const ImpactCode = () => (
    <div className="single-block">
        <h3>The Impact</h3>
        <p>
       <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Create massive employee engagement</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Empower peak performance</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Dramatically increase loyalty and commitment</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Maximize your business success  </h6>
                                            </div>
                                            </li>
            
                                    </ul>
                                </p>
                                <h4>Let's work together to make this happen</h4>
    </div>
);*/

const ImpactCode = () => (
    <div className="single-block">
        <img src={impact} alt="impact" className="img-fluid" />
    </div>
);

const ChallengeCode = () => (
    <div className="single-block">
        <h3>The Challenges</h3>
        <p>
        <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>66% of employees report being disengaged or actively disengaged</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>72% want employers to champion mental health programs</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Highly engaged organizations are 21% more profitable and have 59% less turnover</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>20% of employees reported high levels of concern for their productivity levels  </h6>
                                            </div>
                                            </li>
            
                                    </ul>
                                </p>
    </div>
);

/*const WinCode = () => (
    <div className="single-block">
        <h3>The Big Win</h3>
        <p>
            Highly engaged organizations win! They experience 21% higher profits and 59% less turnover! This can be your organization! What's the impact of that to your bottom line? Take an example organization of 150 employees.
            Reports tell us that 38% of them are looking for another job. If you were to achieve 59% retention of those employees through increasing engagement, you would save over $3.5 million assuming a $60,000 average salary. Let's get your organization there!
                                </p>
    </div>
);*/

const WinCode = () => (
    <div className="single-block">
        <h3>The numbers tell the story of what your organization can achieve with a powerful mental wellness program in place.</h3>
        <img src={win} alt="win" className="img-fluid" />
    </div>
);

const WorksCode = () => (
    <div className="single-block">
        <h3>We will find an approach that works for your organization!</h3>
        <p>
       <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>You purchase seats at My Core Insights allowing your employees access to Mental Fitness Training</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>You can pay for unlimited access to the program or pay-as-you-go</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>You have the option of having no workshops (employees go through the training on their own), some workshops, or a full workshop approach</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>The approach is customizable to fit the needs and budget of your organization</h6>
                                            </div>
                                            </li>
            
                                    </ul>                                </p>
    </div>
);

class ForBusiness extends Component {
constructor(props) {
	super(props);
	this.state = ({
		mobile: false,
        win: false,
        challenge: true,
        impacts: false,
        solution: false,
        works: false
	})
	this.CheckMobile = this.CheckMobile.bind(this);
	this.DirectToContact = this.DirectToContact.bind(this);
    this.toggleChallenge = this.toggleChallenge.bind(this);
    this.toggleWin = this.toggleWin.bind(this);
    this.toggleSolution = this.toggleSolution.bind(this);
    this.toggleImpact = this.toggleImpact.bind(this);
    this.toggleWorks = this.toggleWorks.bind(this);
}
componentDidMount() {
	window.scroll(0,0);
	window.addEventListener("resize", this.CheckMobile());
}

componentWillUnmount() {
    window.removeEventListener("resize", this.CheckMobile)
}

CheckMobile() {
	this.setState({mobile: window.innerWidth <= 760});
}

DirectToContact(event) {
    event.preventDefault();
    const path = "/Contact";
    this.props.history.push({ pathname: path });
}

   toggleChallenge() {
        const challenge = this.state.challenge;
        if (!challenge) {
            this.setState({
                win: false,
                challenge: true,
                solution: false,
                impact: false,
                works: false
            });
        }
    }

    toggleWin() {
        const win = this.state.win;
        if (!win) {
            this.setState({
                win: true,
                challenge: false,
                solution: false,
                impact: false,
                works: false
            });
        }
    }

    toggleSolution() {
        const solution = this.state.solution;
        if (!solution) {
            this.setState({
                win: false,
                challenge: false,
                solution: true,
                impact: false,
                works: false
            });
        }
    }

    toggleImpact() {
        const impact = this.state.impact;
        if (!impact) {
            this.setState({
                win: false,
                challenge: false,
                solution: false,
                impact: true,
                works: false
            });
        }

    }

    toggleWorks() {
        const works = this.state.works;
        if (!works) {
            this.setState({
                win: false,
                challenge: false,
                solution: false,
                impact: false,
                works: true
            });
        }

    }


  render() {

    return (
      <div className="ForBusiness">
		<Header />
          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <div className="text-center"><h4 className="title mb-2">Mental Fitness Training<br />for your business</h4></div>
                    <img src={scale} alt="At Scale" className="img-fluid" />
                    <h5>Imagine addressing the mental wellness challenges in your organization for a fraction of the cost of replacing a single employee!</h5>
                    <p className="text">
                    Empower your employees to achieve Optimal Wellbeing and you will empower your organization to Maximal Success. The challenge is, how do you make that 
                    happen in an affordable and practical way?  We have the answer for you. With the powerful program of <Link to="/mft">Mental Fitness Training</Link> combined with the revolutionary approach taken at My Core Insights, you will:
                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Create massive employee engagement </h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Dramatically increase loyalty and commitment</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Empower peak performance </h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>Positively impact your employees' lives </h6>
                                            </div>
                                            </li>
            
                                    </ul>
                     </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
<iframe src="https://player.vimeo.com/video/506243757" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>                  </div>
                  <div className="form-input light-rounded-buttons mt-5 text-center">
                    <button onClick={this.DirectToContact} className="main-btn light-rounded-two">Contact Us</button><p className="text"> to discuss a solution that will fit your organization</p>
										
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="testimonial" className="testimonial-area">
            <div className="container">
                        <div className="col-md-12">
                            <Card style={{ width: '100%' }}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4 py-3">
                                            <Timeline className="mt-4">
                                                <Timeline.Item> <button className="btn btn-primary" onClick={this.toggleChallenge}>The Challenges</button></Timeline.Item>
                                                <Timeline.Item> <button className="btn btn-primary" onClick={this.toggleWin}>The Big Win</button></Timeline.Item>
                                                <Timeline.Item><button className="btn btn-primary" onClick={this.toggleSolution}>The Solution</button></Timeline.Item>
                                                <Timeline.Item><button className="btn btn-primary" onClick={this.toggleImpact}>The Impact</button></Timeline.Item>
                                                <Timeline.Item><button className="btn btn-primary" onClick={this.toggleWorks}>How It Works</button></Timeline.Item>

                                            </Timeline>

                                        </div>
                                        <div className="col-md-8">
                                            {this.state.win && <WinCode />}
                                            {this.state.solution && <SolutionCode />}
                                            {this.state.challenge && <ChallengeCode />}
                                            {this.state.impact && <ImpactCode />}
                                            {this.state.works && <WorksCode />}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </section>	
                 <section id="testimonial" className="testimonial-area">
            <div className="container">
                     <h4>Do you want Elliot to deliver a keynote or run a workshop to empower your organization? Below are some of the currently offered workshops. Please <Link to={{ pathname: "/contact" }}>Contact</Link> us today to discuss how we can serve your needs.
                        
                        </h4>
                        <hr className="new" />
                                   <div className="row justify-content-between">

                     <div className="col-xl-5 col-lg-6">
                    <h4 className="title mb-2">How Scrum and the Science of Language can Transform your Life!</h4>
                        <p className="text">To maximize organizational effectiveness, it’s crucial to optimize the mental wellness of each individual in the organization. How? By combining powerful mental wellness programs with the Scrum framework to inspire and motivate these individuals to live the life they deserve - their Optimal Life! 
                        Come learn how the science of language can help answer the mental wellness challenge. Learn how it can transform negative cycles into powerful, positive narratives, enabling you to define, restructure, and strengthen what’s within.! 
                         </p>

                                    </div>
                                    <div className="col-lg-6">
                    <h4 className="title mb-2">Leverage your subconscious for success!</h4>
                        <p className="text">In this powerful workshop you will learn what drives you in your life and how to leverage it towards living your Optimal Life. You will learn how to define success for yourself and how your personal mission statement and personal brand impacts your ability to succeed. You will learn the impact of language on how you show up in your life and how to leverage language to empower you in your success. You will learn all the basics of the subconscious and how to train it to serve you instead of it driving you. As a bonus, Elliot will be discussing the basics of conflict and stress management! This is a hands-on-keyboard experience where you learn by listening AND doing. 
                         </p>

                                    </div>
                                    </div></div></section>	
              <section id="testimonial" className="testimonial-area">
            <div className="container">
 
                                   <div className="row justify-content-between">

                     <div className="col-xl-5 col-lg-6">
                    <h4 className="title mb-2">Optimize Actions Optimize Belief Optimize your Life!</h4>
                        <p className="text">In this powerful workshop you will learn strategies to maximize your effectiveness each and every moment you live. You will learn how your subconscious can serve your success instead of it holding you back from it. You will learn the science behind procrastination and how to break free of the paralyzing feelings procrastination gives to you. You will discover strategies that will continue to empower you and align you to live an Optimal Life. You get one shot at this life. Learn how to make it an Optimal Life! As a bonus, Elliot will be discussing success principles and how the technology approach of Agile and SCRUM can empower your success! 
                         </p>

                                    </div>
                                    <div className="col-lg-6">
                    <h4 className="title mb-2">Live Better! Resolve your Inner Conflicts!</h4>
                        <p className="text">You deserve to live the life you want, your Optimal Life! But there are inner conflicts that can hold you back and negatively impact your mental and physical health. In this powerful workshop you will learn about the concept of Cognitive Traps and how to resolve them. Research studies have shown that these inner disconnects cause tension, stress, and damage your mental and physical well-being. With powerful intellectual property offered in this workshop, you will take action towards resolving them here and now and learn the tools to continue to strengthen what's within so you can achieve Optimal Mental Fitness and living your Optimal Life!  
                         </p>

                                    </div>
                                    </div></div></section>  

          <Footer />
      </div>
    );
  }
}

export default ForBusiness;
