import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter46 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 46,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		display_video_after: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		core_attributes: [],
		core_constitution: [],
		language: [],
		review: false,
		associations: false,
		finished: false,
		display_exercises: false,
		virus_language_core_original: [],
		viruses: [],
		chapter_video: "https://player.vimeo.com/video/451335036"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.SetChapterStage = this.SetChapterStage.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.GetCoreAttributes = this.GetCoreAttributes.bind(this);
this.GetCoreConstitution = this.GetCoreConstitution.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.DisplayFinished = this.DisplayFinished.bind(this);
this.GetCoreJumpout = this.GetCoreJumpout.bind(this);
this.GetVirusJumpout = this.GetVirusJumpout.bind(this);
this.LanguageState = this.LanguageState.bind(this);
this.CoreVirusAssociationCallback = this.CoreVirusAssociationCallback.bind(this);
this.CoreVirusAssociation = this.CoreVirusAssociation.bind(this);
this.onCoreVirusAssociation = this.onCoreVirusAssociation.bind(this);
this.GetCoreVirus = this.GetCoreVirus.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
}

componentDidMount() {
	window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, review: false,
			core_virus_part1: false, core_virus_part2: false, display_exercises: false, started: true});
		} else if (stage.data.stage == "core_virus_part1") {
			this.setState({display_video: false, review: false,
			core_virus_part1: true, core_virus_part2: false, display_exercises: true, started: true});
		} else if (stage.data.stage == "core_virus_part2")  {
			this.setState({display_video: false, review: false,
			core_virus_part1: false, core_virus_part2: true, display_exercises: true, started: true});			
		} else if (stage.data.stage  == "done") {
			this.setState({display_video: false, review: false,
			core_virus_part1: false, core_virus_part2: false, display_exercises: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var core_virus_promise = this.GetCoreVirus().then((core_virus) => {
		this.setState({core_virus: core_virus.data.core_virus});
	}).catch((error) => {
		console.log("GetCoreVirus failed " + error);
		this.setState({server_failure: true});

	});

	var core_attributes_promise = this.GetCoreAttributes().then((attributes) => {
		this.setState({core_attributes: attributes.data.attributes});
	}).catch((error) => {
		console.log("GetCoreAttributes failed " + error);
		this.setState({server_failure: true});
	});

	var core_jumpout_promise = this.GetCoreJumpout().then((core_jumpout) => {
		this.setState({core_jumpout: core_jumpout.data.core_language})
	}).catch((error) => {
		console.log("GetCoreJumpout failed " + error);
		this.setState({server_failure: true});
	});

	var core_constitution_promise = this.GetCoreConstitution().then((entries) => {	
		this.setState({core_constitution: entries.data.entries});
	}).catch((error) => {
		console.log("GetCoreConstitution failed " + error);
		this.setState({server_failure: true});
	});

	var viruses_promise = this.GetViruses().then((viruses) => {
		this.setState({viruses: viruses.data.viruses});
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});

	var language_promise = this.GetLanguage().then((language) => {
		this.setState({language: language.data.language});
	}).catch((error) => {
		console.log("GetLanguage failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});

	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(core_constitution_promise);
	all_promises.push(viruses_promise);
	all_promises.push(core_jumpout_promise);
	all_promises.push(core_virus_promise);
	all_promises.push(language_promise);
	var more_promises = [];
	Promise.all(all_promises).then(() => {
		var  temp_jumpout = [];
		this.GetVirusJumpout().then((virus_jumpout) => {
			virus_jumpout.data.virus_language.forEach((vl) => {
				this.state.core_virus.find((cv) => {
					if(vl.id == cv.virus_language) {
						if (this.state.core_virus_part2) {
							if (cv.which_association !== 1) {
								this.setState({[vl.id+"_disabled"]: true});
							}
						} else {
							this.setState({[vl.id+"_disabled"]: true});
						}
					}
				});
			});
			
			this.setState({loading: false, virus_jumpout: virus_jumpout.data.virus_language})
		}).catch((error) => {
			console.log("GetVirusJumpout failed " + error);
			this.setState({server_failure: true});
		});
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetCoreAttributes() {
	const res = await axios.get(APIHost + '/api/coreAttributes',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreConstitution() {
	const res = await axios.get(APIHost + '/api/coreConstitution',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreVirus() {
	const res = await axios.get(APIHost + '/api/coreVirusAssociation',
		{params: {withCredentials: true}});
	return await res;
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true}});
	return await res;
}

async GetCoreJumpout() {
	const res = await axios.get(APIHost + '/api/coreLanguageJumpout',
		{params:  {withCredentials: true}});
	return await res;
}

async GetVirusJumpout() {
	const res = await axios.get(APIHost + '/api/virusLanguageJumpout',
		{params:  {withCredentials: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
	event.persist();
	this.setState({current_answer: event.target.value, [event.target.name]: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_intro = [];
	var display_mc = [];
	var display_button = [];
	var display_video = [];
	var display_core_virus = [];
	var display_core_language = [];
	var flag = false;
	var display_details = [];
	var current_virus = null;
	var done_ids = [];
	this.state.core_virus.forEach((cv) =>  {
		var index = done_ids.findIndex((obj => obj == cv.virus_language));
		if (index == -1) {
			done_ids.push(cv.virus_language);
			this.state.language.find((lang) => {
				if (cv.virus_language == lang.id) {
					this.state.viruses.find((virus) => {
						if (virus.id == lang.original_id) {
							current_virus = virus.virus;
						}
					});
					display_details.push(<p>You associated this Virus language: <b>{lang.entry}</b> which came from the Virus <b>{current_virus}</b> with the following Core language entries</p>);
				}
			});
			this.state.core_virus.find((cv2) => {
				if (cv2.virus_language == cv.virus_language) {
					this.state.language.find((lang) => {
						if(cv2.core_virus == lang.id) {
							display_details.push(<p>{lang.entry}</p>);
							if (lang.type == "core constitution") {
								this.state.core_constitution.find((cc) => {
									if (cc.id == lang.original_id) {
										display_details.push(<div><p>That language came from this Core Constitution entry: {cc.entry}</p><hr /></div>);
									} 
								});
							} else  if (lang.type == "core attribute") {
								this.state.core_attributes.find((ca) => {
									if (ca.id == lang.original_id) {
										display_details.push(<div><p>That language came from this Core Attribute: {ca.attribute}</p><hr /></div>);
									}
								});
							}
						}
					});
				}
			});
		}
	});
	
		display_mc.push(<div>{display_details}</div>);
		display_intro.push(<div><p>Below are the associations you made in this chapter. These are going to be crucial for the upcoming chapters as you 
		identify your Antidotes to your Viruses and ultimately build your Action Plans. Take some time to review them here.</p>
			<hr className="new" /> {display_details}
 			</div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
  		if (history == "not history"){
			display.push(<div className="col-md-12"><h1>Congrats! You finished the chapter!</h1>{display_button}
			{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);
		} else {
			display.push(<div className="col-md-12"><h1>Results of this chapter.</h1>{display_button}
				{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p></div>);
		}

return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_exercises: true, core_virus_part1: true, core_virus_part2: false, display_video: false});
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

SetChapterStage(callback) {
axios.post(APIHost + '/api/updateStage', {
			stage: "core_virus_part1",
			withCredentials: true,
			percent_complete: .4,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
event.preventDefault();
this.SetChapterStage(this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

LanguageState(event) {
	this.setState({[event.target.id + "_checked"]: !this.state[`${event.target.id}_checked`]});

	if (event.target.name == "core_language") {
		this.setState({["core_id_"+event.target.id]: event.target.id});
	} else {
		this.state.virus_jumpout.forEach((virus) => {
			if(virus.id != event.target.id) {
				this.setState({[virus.id+"_checked"]: false});
			}
		});
		this.setState({virus_association_id: event.target.id});
	}
}

CoreVirusAssociationCallback(id) {
	this.setState({[this.state.virus_association_id+"_disabled"]: true});
	this.state.core_jumpout.forEach((core) => {
		this.setState({[core.id+"_checked"]: false});
	});
	this.state.virus_jumpout.forEach((virus) => {
		this.setState({[virus.id+"_checked"]: false});
	});
}

CoreVirusAssociation(id) {
	var core_ids = [];
	var which_association = 0;
	if (this.state.core_virus_part1) {
		which_association = 1;
	} else if (this.state.core_virus_part2) {
		which_association =  2;
	}
	this.state.core_jumpout.forEach((core) => {
		if (this.state[`${core.id}_checked`]) {
			core_ids.push(core.id);
		}
	});
	axios.post(APIHost + '/api/coreVirusAssociation', {
		withCredentials: true,
		core_ids: core_ids,
		virus_id: this.state.virus_association_id, 
		which_association: which_association
	}).then(res => {
		this.CoreVirusAssociationCallback(id);
	}).catch( error => {

	});
}

onCoreVirusAssociation(event) {
	event.preventDefault();
	this.CoreVirusAssociation(event.target.id);
}

NextExercisesCallback(stage) {
	window.scroll(0,0);
	this.state.virus_jumpout.forEach((virus) => {
		this.setState({[virus.id+"_disabled"]: false});
	});
	if (this.state.core_virus_part1) {
		this.setState({core_virus_part2: true, core_virus_part1: false});
	} else {
		this.setState({core_virus_part2: false, core_virus_part1: false, finished: true});
	}
}

NextExercises(stage) {
	var next_stage = '';
	var percent_complete = 0;
	if (this.state.core_virus_part1) {
		next_stage = "core_virus_part2";
		percent_complete = .8;
	} else { 
		next_stage = "done";
		percent_complete = null;
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback();
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises();
}

DisplayExercises(stage) {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	var display_cc_language = [];
	var display_ca_language = [];
	var display_virus_language = [];
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}
	if (this.state.core_virus_part1) {
		var display_core = [];
		var display_virus = [];
		var display_virus_button = [];
		this.state.core_jumpout.forEach((core) => {
			if (core.association != null) {
				display_core.push(<p><input onChange={this.LanguageState} name="core_language" checked={this.state[`${core.id}_checked`]} id={core.id} type="checkbox" />    {core.association}</p>);
			}
		});

		this.state.virus_jumpout.forEach((virus) => {
			if (virus.association != null) {
				display_virus.push(<p><input onChange={this.LanguageState}  name="virus_language" checked={this.state[`${virus.id}_checked`]} id={virus.id} type="checkbox" /> {virus.association}
				<button onClick={this.onCoreVirusAssociation} className="btn btn-primary my-3" type="submit" disabled={this.state[`${virus.id}_disabled`]} id={`${virus.id}_submit`}>Add</button></p>);
			}
		});

			display_mc.push(
			<div className='container'>
    		<div className='row'> 
    		<div className='col-md-6'>
			<h5>Your Virus Associations</h5>
			{display_virus}
			</div> 
			<div className='col-md-6'>
			<h5>Your Core Associations</h5>
			{display_core}
			</div></div></div>
			);
		display_intro.push(<div><p>What you see below is your Virus Language Associations you built in the previous exercises on the left and your Core Language Associations
		on the right. Click the check box next to any of the Virus Language Associations that seem similar to any of the Core Language Associations and click "Add". For example, if you have an 
		Association of "thoughtful" on the left and "kind" on the right, you could say those are similar and you'd click the box next to "thoughtful" and "kind" and then click Add. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" className="custom-btn" id="core_attributes">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Core Word Associations Versus Your Virus Word Associations</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.core_virus_part2) {
		var display_core = [];
		var display_virus = [];
		var display_virus_button = [];
		this.state.core_jumpout.forEach((core) => {
			if (core.association != null) {
				display_core.push(<p><input onChange={this.LanguageState} name="core_language" checked={this.state[`${core.id}_checked`]} id={core.id} type="checkbox" />    {core.entry}</p>);
			}
		});

		this.state.virus_jumpout.forEach((virus) => {
			if (virus.association != null) {
				display_virus.push(<p><input onChange={this.LanguageState}  name="virus_language" checked={this.state[`${virus.id}_checked`]} id={virus.id} type="checkbox" /> {virus.entry}
				<button onClick={this.onCoreVirusAssociation} className="btn btn-primary my-3" type="submit" disabled={this.state[`${virus.id}_disabled`]} id={`${virus.id}_submit`}>Add</button></p>);
			}
		});

			display_mc.push(
			<div className='container'>
    		<div className='row'> 
    		<div className='col-md-6'>
			<h5>Your Virus Language</h5>
			{display_virus}
			</div> 
			<div className='col-md-6'>
			<h5>Your Core Language</h5>
			{display_core}
			</div></div></div>
			);
		display_intro.push(<div><p>What you see below is your Virus Language on the left and your Core Language 
		on your right. Click the check boxes next to any of the Core Language that seem similar to any of the Virus Language and click "Add".
		You are looking for language that has a similar feel or expresses similar ideas. 
		 </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" className="custom-btn" id="core_attributes">Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Core Word Associations Versus Your Virus Word Associations</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} 

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter46">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished("history")}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter46">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
						{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished("not history")}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter46