import React, { Component } from 'react';
import Home from './Home'
import { Link, Redirect } from 'react-router-dom';

const WrongCode = () => (
<p><font color="red">You entered the wrong code.</font></p>
);

class Preview extends Component {
constructor(props) {
	super(props);
	this.state = {wrong_code: false};
	this.onChange = this.onChange.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
}

onChange(event) {
	this.setState({ [event.target.name]: event.target.value });
}

onSubmit(event) {
	event.preventDefault();
	if (this.state.code == "MFTPreview") {
		this.props.history.push({pathname: '/', state: { preview: true}});
	} else {
		this.setState({wrong_code: true})
	}
}

render() {
			return(
<div className="Home">
<h2 className="section-title">Enter the code to access the site</h2>
    						<form onSubmit={this.onSubmit}>
      							<div className="form-group">
    								<input type="name" className="form-control" id="code" name="code" 
    									placeholder="Code" onChange={this.onChange} />
  								</div>
  								 <button name="PreviewSubmit" type="submit" className="custom-btn">Submit</button>
  								  <button name="Foobar" type="submit" className="custom-btn">Submit</button>
  								</form>
  								{this.state.wrong_code && <WrongCode />}
</div>
		);

}
}

export default Preview