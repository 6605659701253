import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class MftIntro extends Component {
  constructor(props) {
    super(props);
    this.DirectToAccount = this.DirectToAccount.bind(this);
    this.DirectToFaq = this.DirectToFaq.bind(this);
  }

  DirectToAccount(event) {
    event.preventDefault();
    const path = "/CreateAccount";
    this.props.history.push({ pathname: path });
  }

  DirectToFaq(event) {
    event.preventDefault();
    const path = "/FAQ";
    this.props.history.push({ pathname: path });
  }

  render() {
    
      return (
        <>
          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">Mental Fitness Training</h4>
                    <p>Define <i className="fa fa-arrow-right mx-0 p-1"></i> Discover <i className="fa fa-arrow-right mx-0 p-1"></i> Restructure <i className="fa fa-arrow-right mx-0 p-1"></i> Strengthen</p>
                    <p className="text">If you were to build a skyscraper, where would you spend your time? Right! On the foundation! If you have a faulty foundation, filling the resulting cracks in the walls isn't going to truly resolve the problem. Only rebuilding the foundation will do that. 
                    But this isn't how we typically approach mental health and wellness. The industry is symptom-focused, trying to help resolve the challenges you face without addressing the underlying challenge of being human. This is what we provide through Mental Fitness Training. The powerful scientific research
                    behind Mental Fitness Training addresses the foundational principles of the human experience and delivers that to you in an easily approachable and actionable way. You can finally make the changes you've wanted to make. This powerful self-directed, but guided program is truly unique in the industry. 
                    With over 20 years of research and development backing the scientifically based intellectual property, you will be empowered to achieve Optimal Mental Fitness and your Optimal Life! 

                     </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* <div className="testimonial-right-content mt-50">
                    <div className="quota">
                      <i className="lni lni-quotation"></i>
                    </div>
                    <div className="testimonial-content-wrapper testimonial-active">
                      <div className="single-testimonial">
                        <div className="testimonial-author d-sm-flex justify-content-between">
                          <div className="author-info d-flex align-items-center">
                            <iframe title="video" src="https://player.vimeo.com/video/447885591" width="450" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src="https://player.vimeo.com/video/447880321" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>
                  <div className="form-input light-rounded-buttons mt-5 text-center">
                    <button onClick={this.DirectToAccount} className="main-btn light-rounded-two">Create Account</button> 
                  </div>
                   <div className="form-input light-rounded-buttons mt-5 text-center">
                    <button onClick={this.DirectToFaq} className="main-btn light-rounded-two">&nbsp;&nbsp;&nbsp;&nbsp;Learn More&nbsp;&nbsp;&nbsp;&nbsp;</button> 
                  </div>                 
                </div>
              </div>
            </div>
          </section>

        </>
      );
  }
}

export default MftIntro;