import React, { Component } from 'react';
import './css/articles.css';
import Header from './components/header';
import Footer from './components/footer';
import { Link } from 'react-router-dom';

class Military extends Component {

componentDidMount() {
    window.scroll(0,0);
}

	render() {
		return (
		<div className="Military">
		<Header history={this.props.history} />

 
<section className="two-sided-box">

       <div className="container">
              <h1>We care about our veterans!</h1><hr />
            <div className="two-sided-box-in">
    <p>
    Here at My Core Insights, we care about our military veterans. It can be incredibly challenging to transition back to civilian life.  
    We are here to help! Our passion is to get wellness solutions into the hands of those that need them. Our veterans have paid a price to serve our country
    and shouldn't have to pay to take their journey to living their Optimal Life. 
</p>
<p>
Here's what to do:

<ul>
<li>Head over to the <Link to="/CreateAccount">Create Account </Link>page and create a new Account</li>
<li>You automatically get a free credit for joining, so you can get started right away</li>
<li>Use the <Link to="/Contact">Contact </Link>page to reach out to us and let us know that you are a veteran, providing sufficient information to verify that. (We might ask for additional information if needed)</li>
<li>We will add additional credits to your account once verified</li>

</ul>
</p>
    </div></div>
    </section>

<Footer history={this.props.history} />

</div>
		);
		
	}
}

export default Military;