import React, { Component, useEffect } from 'react';
import axios from 'axios';
import '../css/chapter.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, Redirect } from 'react-router-dom';
import { useRouter } from 'state';
import Popup from "reactjs-popup";
import {APIHost} from '../GlobalVariables';
  				
const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

const SubmissionFailure = () => (
<p><font color="red">We apologize. An error occurred while submitting the questions. Please try submitting again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const StartFailure = () => (
<p><font color="red">We apologize. An error occurred while starting the chapter. Please refresh and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></p>
);

class Chapter43 extends Component {
constructor(props) {
	super(props);
	this.state = {
		chapterID: 43,
		sprintID: this.props.match.params.ID,
		chapter_status: '',
		started: false,
		display_video: false,
		loading: true,
		finished: false,
		describe_viruses: false,
		extract_language: false,
		display_video_after: false,
		finished_exercises: false,
		finished_viruses: false,
		finished_virus_langauge: false,
		start_failure: false,
		server_failure: false,
		submission_failure: false,
    	chapter_stage: null,
		stretch_attributes: [],
		viruses_count: 0,
		viruses: [],
		chapter_video: "https://player.vimeo.com/video/451334644"
	}
this.StartChapter = this.StartChapter.bind(this);
this.onSubmitStart = this.onSubmitStart.bind(this);
this.StartChapterCallback = this.StartChapterCallback.bind(this);
this.GetChapterStage = this.GetChapterStage.bind(this);
this.GetChapterStatus = this.GetChapterStatus.bind(this);
this.DisplayStart = this.DisplayStart.bind(this);
this.DisplayVideo = this.DisplayVideo.bind(this);
this.DisplayExercises = this.DisplayExercises.bind(this);
this.GoToExercises = this.GoToExercises.bind(this);
this.GoToExercisesCallback = this.GoToExercisesCallback.bind(this);
this.UpdateStage = this.UpdateStage.bind(this);
this.onExerciseChange = this.onExerciseChange.bind(this);
this.DisplayVideoAfter = this.DisplayVideoAfter.bind(this);
this.DisplayVideoAfterCallback = this.DisplayVideoAfterCallback.bind(this);
this.onSubmitViruses = this.onSubmitViruses.bind(this);
this.SubmitViruses = this.SubmitViruses.bind(this);
this.SubmitVirusesCallback = this.SubmitVirusesCallback.bind(this);
this.NextExercises = this.NextExercises.bind(this);
this.onNextExercises = this.onNextExercises.bind(this);
this.NextExercisesCallback = this.NextExercisesCallback.bind(this);
this.GetViruses = this.GetViruses.bind(this);
this.GetLanguage = this.GetLanguage.bind(this);
this.handleMouseUp = this.handleMouseUp.bind(this);
this.SubmitVirusesCallback = this.SubmitVirusesCallback.bind(this);
this.SubmitViruses = this.SubmitViruses.bind(this);
this.onSubmitViruses = this.onSubmitViruses.bind(this);
this.onRemoveEntry = this.onRemoveEntry.bind(this);
this.RemoveEntry = this.RemoveEntry.bind(this);
this.RemoveVirusCallback = this.RemoveVirusCallback.bind(this);
this.onAddNote = this.onAddNote.bind(this);
this.AddNote = this.AddNote.bind(this);
this.AddNoteCallback = this.AddNoteCallback.bind(this);
this.NoteChange = this.NoteChange.bind(this);
this.SubmitLanguageCallback = this.SubmitLanguageCallback.bind(this);
this.onSubmitLanguage = this.onSubmitLanguage.bind(this);
this.SubmitLanguage = this.SubmitLanguage.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var chapter_stage = null;
	var chapter_stage_promise = null;
	if (this.state.sprintID != "none") {
	chapter_stage_promise = this.GetChapterStage().then ((stage) => {
		this.setState({chapter_stage: stage.data.stage});
		if (stage.data.stage == "video") {
			this.setState({display_video: true, describe_viruses: false, display_exercises: false, extract_language: false, started: true});
		} else if (stage.data.stage == "describe_viruses") {
			this.setState({display_video: false, describe_viruses: true, display_exercises: true, extract_language: false, started: true});
		} else if (stage.data.stage == "extract_language")  {
			this.setState({display_video: false, describe_viruses: false, display_exercises: true, extract_language: true, started: true});		
		} else if (stage.data.stage == "done") {
			this.setState({display_video: false, describe_viruses: false, display_exercises: false, extract_language: false, started: true, finished: true});
		}
	}).catch((error) => {
		console.log("GetChapterStage failed " + error);
		this.setState({server_failure: true});
	});
	}

	var viruses_promise = this.GetViruses().then((viruses) => {
		var cnt = 0;
		viruses.data.viruses.forEach((element) => {
			if (element.description != null) {
				cnt++;
			}
		});
		if (cnt === viruses.data.viruses.length) {
			this.setState({viruses: viruses.data.viruses, viruses_count: cnt,
				finished_viruses: true});
		} else {
			this.setState({viruses: viruses.data.viruses, viruses_count: cnt});
		}
	}).catch((error) => {
		console.log("GetViruses failed " + error);
		this.setState({server_failure: true});
	});
	
	var status_promise = null;
	if (this.state.sprintID != "none") {
	status_promise = this.GetChapterStatus().then( (status) => {
		this.setState({chapter_status: status.data.status});
		if (status.data.status == "in progress") {
			this.setState({started: true});
		}
	}).catch((error) => {
		console.log("GetChapterStatus failed " + error);
		this.setState({server_failure: true});
	});
	}

	var note_promise = this.GetNotes().then((notes) => {
		var display = [];
		notes.data.notes.forEach((note) => {
			display.push(<li>{note.note}</li>);
		});
		this.setState({notes: notes.data.notes, display_notes: display});
	}).catch((error) => {
		console.log("GetNotes failed " + error);
		this.setState({server_failure: true});
	});
	
	all_promises.push(note_promise);
	all_promises.push(chapter_stage_promise);
	all_promises.push(status_promise);
	all_promises.push(viruses_promise);
	var more_promises = [];
	Promise.all(all_promises).then( () => {
		this.GetLanguage().then((language) => {
			var temp_virus = [];
			language.data.language.forEach((element) => {
				if (element.type === "virus") {
					this.state.viruses.find((virus) => {
						if (virus.id == element.original_id) {
							temp_virus.push(element);
						}
					});
				} 
			});
			this.setState({virus_language: temp_virus});
			var ids = [];
			var original_ids = [];

			temp_virus.forEach((element) => {
				original_ids.push(element.original_id);
			});
			ids = [... new Set(original_ids)];
			if (ids.length === this.state.viruses.length) {
				this.setState({finished_virus_language: true});
			}
			if(this.state.chapter_stage != 'exercises') {
				this.setState({finished_exercises: false});
			}
			
			this.setState({loading: false});
		}).catch((error) => {
			console.log("GetLanguage failed " + error);
			this.setState({server_failure: true});
		});
		
	}).catch((error) => {
		console.log("Failed to resolve all_promises " + error);
		this.setState({server_failure: true});
	});
}

async GetViruses() {
	const res = await axios.get(APIHost + '/api/viruses',
		{params: {withCredentials: true, added: true}});
	return await res;
}

async GetChapterStage() {
	const res = await axios.get(APIHost + '/api/getChapterStage',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

async GetChapterStatus() {
	const res = await axios.get(APIHost + '/api/getChapterStatus',
	{params: {sprint_id: this.state.sprintID,
	chapter_id: this.state.chapterID, 
	withCredentials: true}});
	return await res;
}

StartChapterCallback() {
	window.scroll(0,0);
	this.setState({started: true});
	this.setState({display_video: true, display_exercises: false});
}

async GetNotes() {
	const res = await axios.get(APIHost + '/api/getChapterNotes', 
	{params: {withCredentials: true,
	chapter_id: this.state.chapterID}});
    return await res;
}

async GetLanguage() {
	const res = await axios.get(APIHost + '/api/language',
		{params: {withCredentials: true}});
	return await res;
}

StartChapter(StartChapterCallback) {
	axios.post(APIHost + '/api/startChapter', {
      chapterID: this.state.chapterID,
      sprintID: this.state.sprintID,
      stage: 'video',
      withCredentials: true
    }).then(res => {
    	StartChapterCallback();
    }).catch(error => {
		console.log("Error in startchapter");
    });
}

onSubmitStart(event) {
event.preventDefault();
this.StartChapter(this.StartChapterCallback);
}

onExerciseChange(event) {
event.persist();
	this.setState({ [event.target.name]: event.target.value, current_answer: event.target.value});
}

handleMouseUp(event) {
	var addition = '';
	if (this.state[`${event.target.name}`]) {
		addition = this.state[`${event.target.name}`] + window.getSelection().toString();
	} else {
		addition = window.getSelection().toString();
	}
  this.setState({[`${event.target.name}`]: addition});
}

RemoveVirusCallback(id) {
	var temp = [];
	var cnt = 0;
	var temp_viruses = this.state.viruses;
	var ids = [];
	var original_ids = [];
	this.state.virus_language.forEach((element) => {
		if(element.id != id) {
			temp.push({});
			temp[cnt].id = element.id;
			temp[cnt].entry = element.entry;
			temp[cnt].original_id = element.original_id;
			temp[cnt].type = element.type;
			cnt++;
		}
	});
	temp_viruses.forEach((element) => {
		original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
				console.log("Lengths at first are " + ids.length + " and " + this.state.viruses.length);

	if (ids.length < this.state.viruses.length) {
		this.setState({virus_language: temp, finished_virus_language: false});
	} else {
		this.setState({virus_language: temp});
	}
}

RemoveEntry(id, api) {
	axios.delete(APIHost + '/api/language', {
	 data: { id: id, withCredentials: true }
	}).then(res => {
		this.RemoveVirusCallback(id);
	}).catch(error => {
		console.log("Failed to delete " + id + error);
		this.setState({submission_failure: true});
	});
}

onRemoveEntry(event) {
	event.preventDefault();
	this.RemoveEntry(event.target.id, event.target.name);
}

onAddNote(event) {
	event.preventDefault();
	this.AddNote();
}

AddNote() {
	axios.post(APIHost + '/api/addChapterNote', {
		note: this.state.note,
		chapter_id: this.state.chapterID,
		withCredentials: true
	}).then(res => {
		this.AddNoteCallback();
	}).catch(error => {
		console.log("AddNote failed");
		this.setState({question_failure: true});
	});
}

AddNoteCallback() {
	var display = this.state.display_notes;
	display.push(<li>{this.state.note}</li>);
	this.setState({note: "", display_notes: display});
}

NoteChange(event) {
	event.preventDefault();
	this.setState({note: event.target.value});
}

DisplayFinished(history) {
	var display = [];
	var display_viruses = [];
	var display_virus_language = [];
	for (var i=0; i<this.state.viruses.length; i++) {
		display_virus_language = [];
		for (var j=0; j<this.state.virus_language.length; j++) {
			if (this.state.virus_language[j].original_id == this.state.viruses[i].id) {
				display_virus_language.push(<li>{this.state.virus_language[j].entry} <button id={this.state.virus_language[j].id} name="viruses" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
			}
		}
		var temp_id = this.state.viruses[i].id;
		display_viruses.push(
			<div><div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.viruses[i].virus}</p>
			<h5>Description:</h5>
			<p>
     		{this.state.viruses[i].description}
  			</p>
  			
			</div>
			<div className='col-md-6'>
			<h5>The Language</h5>
			
  			<div><ul>{display_virus_language}</ul></div>
			</div>
					</div></div><hr /></div>
		);
	}

	var his_text = '';
	if(history) {
		his_text = "Below is all the information for the Viruses you discovered along your Journey.";
	} else {
		his_text = "Congrats! You have finished the chapter!";
	}
		display.push(<div className="col-md-12"><h1>{his_text}</h1>
		<h3>Your Viruses:</h3>
		<p>{display_viruses}</p>
		<p>Head back to   
	your <Link to="/home">Profile</Link> to start the next chapter</p>
		</div>);		
		return display;
}

GoToExercisesCallback() {
	window.scroll(0,0);
	this.setState({display_video: false, describe_viruses: true, extract_language: false, display_exercises: true});
}

UpdateStage(stage, callback) {
	axios.post(APIHost + '/api/updateStage', {
			stage: stage,
			withCredentials: true,
			percent_complete: .4,
			sprint_id: this.state.sprintID,
			chapter_id: this.state.chapterID
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("failed to update the stage " + error);
			this.setState({submission_failure: true});
		});
}

GoToExercises(event) {
event.preventDefault();
this.UpdateStage("describe_viruses", this.GoToExercisesCallback);
}

DisplayStart() {
	var display = [];
	display.push(<aside className="col-md-4">               
	<button onClick={this.onSubmitStart} type="submit" className="custom-btn">Start Chapter</button><br />
			</aside>
	);
	return display;
}

DisplayVideo() {
	var display = [];
	display.push(<div className="col-md-12"><p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div><button onClick={this.GoToExercises} className="custom-btn">Exercises</button></div>); 
	return display;
}

DisplayVideoAfter(event) {
	event.preventDefault();
	this.DisplayVideoAfterCallback();
}

DisplayVideoAfterCallback() {
	this.setState({display_video_after: !this.state.display_video_after})
}

SubmitVirusesCallback(id) {
	var temp_viruses = this.state.viruses;
	var cnt = this.state.viruses_count;
	cnt++;
	temp_viruses.find((element) => {
		if (element.id == id) {
			element.description = this.state.virus;
		}
	});
	if (cnt === this.state.viruses.length) {
		this.setState({viruses: temp_viruses, virus: "", viruses_count: cnt, finished_viruses: true});
	} else {
		this.setState({viruses: temp_viruses, virus: "", viruses_count: cnt});
	}
}

SubmitViruses(callback, id) {
	axios.post(APIHost + '/api/viruses', {
			withCredentials: true,
			description: this.state.current_answer,
			type: "description",
			id: id
		}).then(res => {
			callback(id);
		}).catch(error => {
			console.log("Failed to submit virus description " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitViruses(event) {
	event.preventDefault();
	document.getElementById(event.target.id).disabled = true;
	this.SubmitViruses(this.SubmitVirusesCallback, event.target.id);
}

NextExercisesCallback(stage) {
	window.scroll(0,0);
	if (stage === "describe_viruses") {
		this.setState({describe_viruses: false, extract_language: true});
	} else {
		this.setState({describe_viruses: false, extract_language: false, finished: true});
	}
}

NextExercises(stage) {
	var next_stage = '';
	var percent_complete = null;
	if (stage === "describe_viruses") {
		next_stage = "extract_language";
		percent_complete = .8;
	} else { 
		next_stage = "done";
	}
	axios.post(APIHost + '/api/updateStage', {
      chapter_id: this.state.chapterID,
      sprint_id: this.state.sprintID,
      percent_complete: percent_complete,
      stage: next_stage,
      withCredentials: true
    }).then(res => {
    	this.NextExercisesCallback(stage);
    }).catch(error => {
		console.log("Error in updateStage " + error);
		this.setState({server_failure: true});
    });
}

onNextExercises(event) {
	event.preventDefault();
	this.NextExercises(event.target.id);
}

SubmitLanguageCallback(original_id, id) {
	var temp = this.state.virus_language;
	var ids = [];
	var original_ids = [];
	temp.push({});
	temp[temp.length-1].id = id;
	temp[temp.length-1].entry = this.state[`${original_id}_virus`];
	temp[temp.length-1].original_id = original_id;
	temp[temp.length-1].type = "virus";

	temp.forEach((element) => {
	original_ids.push(element.original_id);
	});
	ids = [... new Set(original_ids)];
	console.log("lengths are " + ids.length + " and " + this.state.viruses.length);
	if (ids.length === this.state.viruses.length) {
		this.setState({virus_language: temp, [original_id+"_virus"]: "", finished_virus_language: true});
	} else {
		this.setState({virus_language: temp, [original_id+"_virus"]: ""});
	}
}

SubmitLanguage(original_id) {
	axios.post(APIHost + '/api/language', {
			withCredentials: true,
			language: this.state[`${original_id}_virus`],
			type: "virus",
			id: original_id
		}).then(res => {
			this.SubmitLanguageCallback(original_id, res.data.id);
		}).catch(error => {
			console.log("Failed to submit language for virus " + error);
			this.setState({submission_failure: true});
		});
}

onSubmitLanguage(event) {
	event.preventDefault();
	this.SubmitLanguage(event.target.id);
}

DisplayExercises(stage) {
	var display = [];
	var display_intro = [];
	var display_video = [];
	var display_button = [];
	var display_current = [];
	var display_mc = [];
	var display_stretch = [];
	var display_current_stretch = [];
	console.log("stage in display is " + stage);
// display exercises for core attributes and stretch so they can add and remove from those respective lists
	if (this.state.display_video_after) {
		display_video.push(<div><div className="embed-responsive embed-responsive-4by3 service-video">
                 <iframe src={this.state.chapter_video} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                 </div></div>); 
	}

	if (this.state.describe_viruses) {
		for (var i=0; i<this.state.viruses.length; i++) {
			if (this.state.viruses[i].description == null) {
				display_mc.push(<div><p>{i+1}) {this.state.viruses[i].virus}</p>
				<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} id={`${i+1}_virus`} rows="10" cols="80" type="text" name="virus" className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitViruses} name={`${i+1}_submit_virus`} type="submit" id={this.state.viruses[i].id} className="custom-btn">Submit</button>
  				</form></div>);
			} else {
				display_mc.push(<div><p>{i+1}) {this.state.viruses[i].virus}</p>
				<p>{this.state.viruses[i].description}</p></div>);
			}
		}
	
		display_intro.push(<div><p>Below, you will provide details for the Viruses you've discovered along your Journey so far.  It is crucial to have this 
		information for the coming chapters as you define your Catalysts, Antidotes, and ultimately, your Action Plans, providing all that you need
		to battle all of your Viruses successfully and live the life of your choosing! </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" className="custom-btn" id="describe_viruses" disabled={!this.state.finished_viruses}>Finish</button></p></div>);
		  		if(this.state.display_video_after) {
  		display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  		display_button.push(<div><p>If you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
		display.push(<div className="col-md-12"><h1>Describing Your Viruses</h1>{display_button}
		{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	} else if (this.state.extract_language) {
		var display_virus_language = [];
				for (var i=0; i<this.state.viruses.length; i++) {
			display_virus_language = [];
			for (var j=0; j<this.state.virus_language.length; j++) {
				if (this.state.virus_language[j].original_id == this.state.viruses[i].id) {
					display_virus_language.push(<li>{this.state.virus_language[j].entry} <button id={this.state.virus_language[j].id} name="viruses" className="btn btn-primary my-3" onClick={this.onRemoveEntry}>Remove</button></li>);
				}
			}
			var temp_id = this.state.viruses[i].id;
			display_mc.push(
			<div className='container'>
    		<div className='row'>  
			<div className='col-md-6'>
			<h5>Entry:</h5><p>{this.state.viruses[i].virus}</p>
			<h5>Description:</h5>
			<form>
						<div onMouseUp={this.handleMouseUp} className="form-group">
     					<textarea rows="10" cols="80" type="text" value={this.state.viruses[i].description} name={`${this.state.viruses[i].id}_virus`} className="form-control"></textarea>
  						</div>
  						</form>
			
			</div>
			<div className='col-md-6'>
			<h5>Your Language</h5>
			<form>
				<div className="form-group">
     			<textarea onChange={this.onExerciseChange} rows="1" cols="1" type="text" id={`${i+1}_virus`} name={`${this.state.viruses[i].id}_virus`} value={this.state[`${this.state.viruses[i].id}_virus`]} className="form-control"></textarea>
  				</div>
  				<button onClick={this.onSubmitLanguage} name={`${i+1}_add_virus`} className="btn btn-primary my-3" type="submit" id={this.state.viruses[i].id}>Add</button>
  				</form>
  				<div><ul>{display_virus_language}</ul></div>
			</div></div></div>
			);
		}
	
		display_intro.push(<div><p>Below, you will extract the essential language of your Viruses as you have done previously in the "Extracting and Identifying our Language" chapter.
		Enter each language snippet in the box provided and hit Add to add it to your list. If you highlight text, it will automatically appear in the box provided. 
		You can also just type it in. Do this for each entry below. Once you have completed each entry, click Finish to go to the next section. </p>
			<hr /> {display_mc}
 			<p><button onClick={this.onNextExercises} type="submit" className="custom-btn" id="viruses" disabled={!this.state.finished_virus_language}>Finish</button></p></div>);
		if(this.state.display_video_after) {
  			display_button.push(<div><p><button onClick={this.DisplayVideoAfter} type="submit">Hide Video</button></p></div>);
  		} else {
  			display_button.push(<div><p>if you want to see the chapter video, you can do so <button className="btn btn-primary my-3" onClick={this.DisplayVideoAfter} type="submit">here</button></p></div>);
  		}
display.push(<div className="col-md-12"><h1>Capture the language of your Viruses</h1>{display_button}
{display_video}<p><Popup trigger={<button className="btn btn-primary my-3">Add a note</button>} position="right center">
	    	<div><textarea onChange={this.NoteChange} value={this.state.note} rows="10" cols="20" type="text" name="note" className="form-control"></textarea>
	    	<button onClick={this.onAddNote}>Submit</button> </div>
	  		</Popup><Popup trigger={<button className="btn btn-primary my-3">See chapter notes</button>} position="right center">
	    	<div><ul>{this.state.display_notes}</ul></div>
	  		</Popup></p>{display_intro}</div>);
	}

	return display;
}

render() {
	if(this.props.location.state == null) {
 		return ( <Redirect to='/Home' /> );
	} else {
	if(this.state.sprintID == "none") {
		return (
		<div className="Chapter43">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
            			{!this.state.loading && this.DisplayFinished(true)}
            		</div>
            	</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	} else {
		return (<div className="Chapter43">
			<Header history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
	    	<section className="two-sided-box body">
        		<div className="container">
        			{this.state.server_failure && <ServerFailure />}
            		<div className="two-sided-box-in">
                		{!this.state.loading && !this.state.started && this.DisplayStart()}	
                		{this.state.start_failure && <StartFailure />}	
						{!this.state.loading && this.state.started && this.state.display_video && 
						this.DisplayVideo()}
					{!this.state.loading && this.state.started && this.state.display_exercises &&
							this.DisplayExercises()}
						{!this.state.loading && this.state.started && this.state.finished && 
						this.DisplayFinished(false)}
						{this.state.submission_failure && <SubmissionFailure />}
					</div>
				</div>
			</section>	
			<Footer history={this.props.history} preview={this.props.location.state.preview} loggedIn={this.props.location.state.loggedIn} />
			
			</div>
		);
	}
	}		
}

}

export default Chapter43