import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import idea from './images/idea.jpg';
import { APIHost } from './GlobalVariables';

axios.defaults.withCredentials = true;

const MessageFailed = () => (
	<p><font color="red">We apologize but something went wrong. Please save your survey answers and refresh the page to try again.</font></p>
);

const MessageSent = () => (
	<p><font color="blue">Thanks so much for your input! We really appreciate you taking the time!</font></p>
);

class Survey extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			name: '',
			lessons: '',
			comments: '',
			different: '',
			messageSent: false,
			messageFailed: false
		}
		this.onChange = this.onChange.bind(this);
		this.QuestionState = this.QuestionState.bind(this);
		this.SubmitSurvey = this.SubmitSurvey.bind(this);
		this.SubmitSurveyCallback = this.SubmitSurveyCallback.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	QuestionState(event) {
	this.setState({question1_result: parseFloat(event.target.value)});
	}

	SubmitSurveyCallback(response) {
		if (response) {
			this.setState({
				messageSent: true
			});
		} else {
			this.setState({messageFailed: true});
		}

	}

	SubmitSurvey(event) {
		event.preventDefault();
		axios.post(APIHost + '/api/submitSurvey', {
			overall: this.state.question1_result,
			lessons: this.state.lessons,
			different: this.state.different,
			comments: this.state.comments,
			email: this.state.email,
			name: this.state.name,
			withCredentials: true
		}).then(res => {
			this.SubmitSurveyCallback(true);
		}).catch(error => {
			console.log("error is " + error);
			this.SubmitSurveyCallback(false);
		});
		
	}

	render() {
		return (
			<div className="Survey">
				<Header history={this.props.history} />
				<div className="container pt-65">
					<div className="row">
						{/*  <div className="col-md-5">
                            <img src={idea} alt="Idea" className="img-fluid" />
                        </div> */}
						<div className="col-md-12">
							<h3 className="contact-title pb-10">Your feedback is crucial!</h3>
							<p>Your feedback empowers us to empower others! Please share your experience with us. All below fields are optional.</p><br /><hr />
								<div className="col-md-12">
										<div className="form-input mt-25">
						<p>How would you rate the overall workshop?</p>
							 <div onChange={this.QuestionState} className="checkboxOptions">

    	<div className="floatBlock">			
    	<input type="radio" className="form-control" name="question1" value="1" />Poor     												
    	</div>
    	    	<div className="floatBlock">
    	<input type="radio" className="form-control" name="question1" value="2" />Fair 
		</div>
		    	<div className="floatBlock">
    	<input type="radio" className="form-control" name="question1" value="3" />Good 
		</div>
    	<div className="floatBlock">
    	<input type="radio" className="form-control" name="question1" value="4" />Very Good 
		</div>
		<div className="floatBlock">
		<input type="radio" className="form-control" name="question1" value="5" />Excellent 
		</div>	
		</div>
		</div></div>
		<div className="col-md-12">
			<div className="form-input mt-25">
					<p>What were the major lessons or takeaways you got from this workshop?</p>
				<div className="input-items default">
					<textarea name="lessons" id="lessons" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>
				<div className="col-md-12">
			<div className="form-input mt-25">
					<p>If you were to take this workshop again, what would you like to see different?</p>
				<div className="input-items default">
					<textarea name="different" id="different" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>
				<div className="col-md-12">
			<div className="form-input mt-25">
					<p>Do you have any other suggestions or comments?</p>
				<div className="input-items default">
					<textarea name="comments" id="comments" onChange={this.onChange}></textarea>	
				</div>
			</div>
		</div>

								<div className="row">
									<div className="col-md-6">
										<div className="form-input mt-25">
											<label>Name (optional but appreciated)</label>
											<div className="input-items default">
												<input id="name" name="name" type="text" onChange={this.onChange} placeholder="Name" />
												<i className="lni lni-user"></i>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-input mt-25">
											<label>Email (optional but appreciated)</label>
											<div className="input-items default">
												<input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
												<i className="lni lni-envelope"></i>
											</div>
										</div>
									</div>

									<p className="form-message"></p>
									<div className="col-md-12">
										<div className="form-input light-rounded-buttons mt-30">
											<button onClick={this.SubmitSurvey} className="main-btn light-rounded-two" disabled={this.state.messageSent}>Submit</button>
										</div>
									</div>
								</div>


							{this.state.messageSent && <MessageSent />}
							{this.state.messageFailed && <MessageFailed />}

						</div>
					</div>
				</div>
				<Footer history={this.props.history} />
			</div>
		);

	}
}

export default Survey;