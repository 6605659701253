import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import idea from './images/idea.jpg';
import { Link, Redirect } from 'react-router-dom';
import {APIHost} from './GlobalVariables';

axios.defaults.withCredentials = true;

const LoginError = () => (
	<p><font color="red">Your email or password were not correct.</font></p>
);
const CreateAccountError = () => (
	<p><font color="red">An account with that email exists. If you are already a member and would like to attend the webinar, please use the <Link to="/contact">contact page</Link> to let us know</font></p>
);

const CreateAccountFailed = () => (
	<p><font color="red">We aplogize. An error occurred while creating your account. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>
);

const PasswordMatch = () => (
	<p><font color="red">Your passwords didn't match. Please try again.</font></p>
);

const PhoneWrong = () => (
	<p><font color="red">Please enter a valid number and don't include -'s.</font></p>
);

const NameEmpty = () => (
	<p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
	<p><font color="red">Please make sure to provide a valid email account.</font></p>
);

const TermsError = () => (
	<p><font color="red">Please acknowledge the Terms of Use.</font></p>
);

const PasswordEmpty = () => (
	<p><font color="red">Please make sure to provide a strong password for your account.</font></p>
);

const StrongPassword = () => (
	<p><font color="red">Your password must be at least 6 characters, contain a mix of upper and lower case characters, and contain
at least one number</font></p>
);

const ServerFailure = () => (
	<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			referred_ambassador_id: this.props.match.params.ID,
			name: '',
			createFailed: false,
			become_ambassador: false,
			accountCreated: true,
			passwordMatch: true,
			nameEmpty: false,
			emailEmpty: false,
			passwordEmpty: false,
			logged_in: true,
			strongPassword: true,
			server_failure: false,
			terms_of_use: false,
			phoneWrong: false,
			success: false,
			spd: false,
			company: '',
			loading: true
		}
		this.CreateAccount = this.CreateAccount.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.CheckLogin = this.CheckLogin.bind(this);
		this.TermsOfUse = this.TermsOfUse.bind(this);
		this.Ambassador = this.Ambassador.bind(this);
		this.DisplaySuccess = this.DisplaySuccess.bind(this);
		this.CreateAccountCallback = this.CreateAccountCallback.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.setState({ loading: true });
		this.CheckLogin().then((result) => {
			if (result) {
				this.setState({ logged_in: true, loading: false });
			} else {
				this.setState({ logged_in: false, loading: false });
			}
		}).catch((error) => {
			this.setState({ server_failure: true });
		});
	}

	async CheckLogin() {
		const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
		return await res.data.loggedIn;
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	CreateAccountCallback() {
		if(this.state.company == "SPD") {
			//this.setState({spd: true});
			this.props.history.push({ pathname: '/Workshops', state: { company: 'spd' } });
		} else if (this.state.company == "mindful") {
			this.props.history.push({ pathname: '/', state: { foo: 'bar' } });
		} else {
			this.props.history.push({ pathname: '/Workshops', state: { foo: 'bar' } });
		}
	}

	CreateAccount() {
		var passed = true;
		var local_ambassador_id = null;
		if (this.state.referred_ambassador_id) {
			local_ambassador_id = this.state.referred_ambassador_id;
		} else if (this.state.ambassador_id) {
			local_ambassador_id = this.state.ambassador_id;
		}
		//let re = /[A-Z]\W/g;
		//var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/;
		var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		var phoneRegExp = /^[0-9]*$/;
		var emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!regExp.test(this.state.password)) {
			passed = false;
			this.setState({ strongPassword: false });
		}
		if (!phoneRegExp.test(this.state.phonenumber) || this.state.phonenumber.length < 10) {
			passed = false;
			this.setState({phoneWrong: true});
		}
		if (this.state.password !== this.state.confirmPassword) {
			passed = false;
			this.setState({ passwordMatch: false });
		}
		if (this.state.name === '') {
			passed = false;
			this.setState({ nameEmpty: true });
		}
		if (this.state.email === '' || !emailRegExp.test(this.state.email)) {			passed = false;
			this.setState({ emailEmpty: true });
		}
		if (this.state.password === '') {
			passed = false;
			this.setState({ passwordEmpty: true });
		}
		if (passed) {
			var temp_email = this.state.email.toLowerCase();
			this.setState({email: temp_email});
			var apiCall = "";
			if (this.state.company == "mindful") {
				apiCall = "/api/register";
			} else {
				apiCall = "/api/addUser";
			}
			axios.post(APIHost + apiCall, {
				email: temp_email,
				password: this.state.password,
				name: this.state.name,
				register: true,
				phonenumber: this.state.phonenumber,
				company: this.state.company,
				withCredentials: true
			}).then(res => {
				this.CreateAccountCallback();
				//this.setState({success: true});
			}).catch(error => {
				console.log("error is " + error);
				if (error.response.data.errorMessage === "Account exists with that email") {
					this.setState({ accountCreated: false });
				} else {
					console.log('Account creation failed');
					this.setState({ createFailed: true });
				}
			});
		}
	}

	onSubmit(event) {
		event.preventDefault();
		if (!this.state.terms_of_use) {
			this.setState({termsError: true});
		} else {
		this.setState({
			nameEmpty: false,
			passwordMatch: true,
			emailEmpty: false,
			passwordEmpty: false,
			strongPassword: true,
			termsError: false,
			phoneWrong: false
		});
		this.CreateAccount();
		}
	}

	TermsOfUse(event) {
		this.setState({ terms_of_use: !this.state.terms_of_use });
	}

	Ambassador(event) {
		this.setState({ become_ambassador: !this.state.become_ambassador });
	}

	DisplaySuccess() {
		var display = [];
		display.push(<p>Thank you so much for registering. You will receive additional details via email or text. By registering, you have access to Mental Fitness Training. You can <Link to="/login">login here.</Link> Feel free to <Link to="/Contact">reach out</Link> if you have any questions.</p>);
		return display;
	}


	render() {
		if (this.state.loading) {
			return (<h1>Loading...</h1>);
		} else if (this.state.logged_in) {
			return (<Redirect to='/Home' />);
		} else {
			return (
					<div className="Register">
						<Header history={this.props.history} loggedIn={false} />
						<section className="two-sided-box">
							<div className="container pt-30">
								{this.state.server_failure && <ServerFailure />}
								<div className="row">
									<div className="col-md-5 align-items-center">
										<div className="d-flex align-items-center" style={{height:'100%'}}>
											<div>
												<img src={idea} alt="Idea" className="img-fluid" />
											</div>
										</div>

									</div>
									<div className="col-md-7">
										<h2 className="section-title">Register for upcoming webinars</h2>
										<h5>Already registered? Head right to the <Link to="/Workshops">Webinar/Workshop page</Link>.</h5><p>You will receive an email or text with upcoming dates and details</p>
										<form>
										<div className="form-input mt-25">
											<label>Name</label>
											<div className="input-items default">
												<input type="name" id="name" name="name" onChange={this.onChange} placeholder="Name" />
												<i className="lni lni-user"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Email</label>
											<div className="input-items default">
												<input type="email" id="email" name="email" onChange={this.onChange} placeholder="Email" />
												<i className="lni lni-envelope"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Phone number - only used for opt-in reminders of events and never shared</label>
											<div className="input-items default">
												<input type="phonenumber" id="phonenumber" name="phonenumber" onChange={this.onChange} placeholder="Phone number" />
												<i className="lni lni-phone"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Password</label>
											<div className="input-items default">
												<input type="password" id="password" name="password" onChange={this.onChange} placeholder="Password" />
												<i className="lni lni-key"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Confirm Password</label>
											<div className="input-items default">
												<input type="password" id="confirmpassword" name="confirmPassword" onChange={this.onChange} placeholder="confirm password" />
												<i className="lni lni-key"></i>
											</div>
										</div>
										<div className="form-input mt-25">
											<label>Do you have a company code? (Leave blank if you don't)</label>
											<div className="input-items default">
												<input type="company" id="name" name="company" onChange={this.onChange} placeholder="Company" />
												
											</div>
										</div>
											<p className="pull-right my-4"><input onChange={this.TermsOfUse} type="checkbox" /> Please acknowledge you have
	  								read the My Core Insights <Link to="/TermsOfUse">Terms and Conditions of Use</Link> and <Link to="/PrivacyPolicy">Privacy Policy</Link>.
	  								By clicking Register, you agree and are bound by these terms. </p>
									  <div className="form-input light-rounded-buttons mt-30">
											<button type="submit" onClick={this.onSubmit} disabled={this.state.success} className="main-btn light-rounded-two" >Register</button>
										</div>

											{!this.state.accountCreated && <CreateAccountError />}
											{this.state.createFailed && <CreateAccountFailed />}
											{!this.state.passwordMatch && <PasswordMatch />}
											{this.state.nameEmpty && <NameEmpty />}
											{this.state.emailEmpty && <EmailEmpty />}
											{this.state.passwordEmpty && <PasswordEmpty />}
											{!this.state.strongPassword && <StrongPassword />}
											{this.state.phoneWrong && <PhoneWrong />}
											{this.state.termsError && <TermsError />}
											{this.state.success && this.DisplaySuccess()}
										</form>

									</div>
								</div>
							</div>
						</section>
						<Footer history={this.props.history} loggedIn={false} />
					</div>
				);
			

		}
	}
}

export default Register;