import React, { Component } from 'react';
import axios from 'axios';
import { APIHost } from './GlobalVariables';
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';

class Admin extends Component {

	constructor(props) {
		super(props);
		this.state = {
			user_details: [],
			all_sprints: [],
			individual_user: [],
			display_sprint: false,
			display_objectives: false,
			objectives_table: [],
			loggedIn: false,
			loading: true
		}
		this.GetAllUserDetails = this.GetAllUserDetails.bind(this);
		this.DisplayUserTable = this.DisplayUserTable.bind(this);
		this.GetAllSprints = this.GetAllSprints.bind(this);
		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.DisplaySprintCallback = this.DisplaySprintCallback.bind(this);
		this.GetPurchasedObjectives = this.GetPurchasedObjectives.bind(this);
		this.DisplayObjectives = this.DisplayObjectives.bind(this);
		this.DisplayObjectivesCallback = this.DisplayObjectivesCallback.bind(this);
		this.CheckLogin = this.CheckLogin.bind(this);
		this.GetUserDetails = this.GetUserDetails.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmitEmail = this.onSubmitEmail.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.setState({loading: true})
		var promises = [];
		this.CheckLogin().then((result) => {
			if (result.data.loggedIn) {
				var get_users_promise = this.GetAllUserDetails().then((user_details) => {
					this.setState({user_details: user_details.data.user_details});
				}).catch((error) => {
					console.log("GetAllUserDetails failed " + error);
				});

				var get_sprints_promise = this.GetAllSprints().then((all_sprints) => {
					this.setState({all_sprints: all_sprints.data.all_sprints})
				}).catch((error) => {
					console.log("GetAllSprints failed " + error);
				});

				var purchases_promise = this.GetPurchasedObjectives().then((purchases) => {
					this.setState({purchased_objectives: purchases.data.objective_purchases});
				}).catch((error) => {
					console.log("GetPurchasedObjectives failed");
				});

				var user_promise = this.GetUserDetails().then(dets => {
					this.setState({ individual_user: dets.data.user_details });
				}).catch((error) => {
					console.log("GetUserDetails failed " + error);
				});

				promises.push(get_users_promise);
				promises.push(get_sprints_promise);
				promises.push(purchases_promise);
				promises.push(user_promise);
				Promise.all(promises).then(() => {
					this.setState({loggedIn: true, loading: false});
				});
			} else {
				this.setState({loading: false, loggedIn: false});
			}
		}).catch((error) => {
			console.log("CheckLogin failed");
		});

	}

  async GetAllUserDetails() {
    const res = await axios.get(APIHost + '/api/getAllUserDetails', { withCredentials: true });
    return await res;
  }

  async GetAllSprints() {
  	const res = await axios.get(APIHost + '/api/getAllSprints', { withCredentials: true });
    return await res;
  }

  async GetPurchasedObjectives() {
  	const res = await axios.get(APIHost + '/api/getAllObjectivePurchases', { withCredentials: true });
    return await res;
  }

async CheckLogin() {
	const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
	return await res;
}

	async GetUserDetails() {
		const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
		return await res;
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

  DisplaySprintCallback(local_sprint_id) {
  	  	this.setState({display_sprint: !this.state.display_sprint});
  	var sprint_details = [];
  	var rows = [];
  	var display_table = [];
  	var display_bullets = [];
  	var display_list = [];
 	if (this.state.display_sprint) {
	  	this.state.all_sprints.find((as) => {
	  		if (as.sprint_id == local_sprint_id) {
	  			display_bullets.push(<ul><li>ID: {as.sprint_id}</li><li>Created: {as.created}</li><li>Start Date: {as.start_date}</li><li>End Date: {as.end_date}</li><li>Status: {as.status}</li><li>Scheduled Retro: {as.scheduled_retro}</li></ul>);
	  			as.sprint_details.forEach((sd) => {
	  			rows.push(<tr><td>{sd.chapter_id}</td><td>{sd.status}</td><td>{sd.stage}</td><td>{sd.percent_complete}</td></tr>)
	  			});
	  			display_table.push(<table className="table table-bordered"><thead><tr><th>Chapter ID</th><th>Status</th><th>Stage</th><th>Percent Complete</th>
				</tr></thead><tbody>{rows}</tbody></table>);
	  		}
	  	});
	  	sprint_details.push(<div>{display_bullets}{display_table}</div>);
	  } 
	  this.setState({sprint_details: sprint_details});
  }

  DisplaySprint(event) {
  	event.preventDefault();
  	this.DisplaySprintCallback(event.target.id);
  }

  DisplayObjectivesCallback(userid) {
  	this.setState({display_objectives: !this.state.display_objectives});
  	var rows = [];
  	var display = [];

  	if (this.state.display_objectives) {
  		this.state.purchased_objectives.find((po) => {
  			if (po.userid == userid) {
  				rows.push(<tr><td>{po.objective_id}</td><td>{po.date_purchased}</td></tr>);

  			}
  		});
  		  				display.push(<table className="table table-bordered"><thead><tr><th>Objective ID</th><th>Date Purchased</th>
				</tr></thead><tbody>{rows}</tbody></table>)
  	}

  	this.setState({objectives_table: display});
  }

  DisplayObjectives(event) {
  	event.preventDefault();
  	this.DisplayObjectivesCallback(event.target.id);
  }

  onSubmitEmail(event) {
  	event.preventDefault();
  	var ex = false;
  	if (this.state.existing == "yes") {
  		ex = true;
  	}
  	axios.post(APIHost + '/api/setupWorkshop', {
				email: this.state.email,
				webinar_id: parseInt(this.state.workshopID),
				existing: ex,
				withCredentials: true
			}).then(res => {
				console.log("Success");
			}).catch(error => {
				console.log("error is " + error.response.data.errorMessage);
			});
  }

 DisplayUserTable() {
  	var display = [];
  	var rows  = [];
  	var user_row = [];
  	this.state.user_details.forEach((user) => {
  		
  		rows.push(<tr><td><button onClick={this.DisplayObjectives} id={user.userID}>{user.userID}</button></td><td>{user.member_id}</td><td>{user.name}</td><td>{user.email}</td><td>{user.phonenumber}</td><td>{user.company}</td><td>{user.last_activity}</td>
  			<td>{user.capacity}</td><td><button onClick={this.DisplaySprint} id={user.sprint_id}>{user.sprint_id}</button></td></tr>);
  	});
  	display.push(<table className="table table-bordered"><thead><tr><th>User ID</th><th>Member ID</th><th>Name</th><th>Email</th><th>Phone number</th><th>Company</th><th>Last Activity</th>
  		<th>Capacity</th><th>Sprint ID</th>
		</tr></thead><tbody>{rows}</tbody></table>);
	return display;
  }

	render() {
		if (this.state.loading) {
			return (<div className="Admin"><h3 className="p-3">Loading...</h3></div>);
		} else if(!this.state.loggedIn || this.state.individual_user.member_id != 1000) {
			console.log("stuff is  " + this.state.loggedIn + " and " + this.state.user_details.member_id)
 			return ( <Redirect to='/Home' /> );
		} else {
			return (
				<div className="Admin">
				<div>
						<input id="email" name="email" type="text" onChange={this.onChange} placeholder="Email" />
						<input id="workshopID" name="workshopID" type="text" onChange={this.onChange} placeholder="Workshop ID" />
						<input id="existing" name="existing" type="text" onChange={this.onChange} placeholder="Existing" />
						<button type="submit" onClick={this.onSubmitEmail} className="custom-btn">Submit</button>
				</div>
				{!this.state.loading && this.DisplayUserTable()}
				{this.state.sprint_details}
				{this.state.objectives_table}
				</div>
			);
		}
	}
}

export default Admin;