import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import {APIHost} from './GlobalVariables';
import './css/profile.css';

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class History extends Component {
	constructor(props) {
	super(props);
	this.state = ({loading: false,
		done_chapters: [],
		completed_themes: [],
		completed_objectives: []

	});
	this.GetProgress = this.GetProgress.bind(this);
	this.DisplaySprintHistory = this.DisplaySprintHistory.bind(this);
	this.GetSprintHistory = this.GetSprintHistory.bind(this);
	this.GetHistoricChapters = this.GetHistoricChapters.bind(this);
	this.DisplayAllChapters = this.DisplayAllChapters.bind(this);
	this.DisplaySprintHistory = this.DisplaySprintHistory.bind(this);
	this.DirectToSprint = this.DirectToSprint.bind(this);
	this.DirectToChapter = this.DirectToChapter.bind(this);
	this.GetCompletedObjectives = this.GetCompletedObjectives.bind(this);
	this.GetCompletedThemes = this.GetCompletedThemes.bind(this);
	this.GetCompletedObjectives = this.GetCompletedObjectives.bind(this);
	this.DisplayThemes = this.DisplayThemes.bind(this);
	this.DisplayObjectives = this.DisplayObjectives.bind(this);
	}

componentDidMount() {
	window.scroll(0,0);
	this.setState({loading: true});
	var promises = [];
	var done_chapters_promise = this.GetHistoricChapters().then((chapters) => {
		this.setState({done_chapters: chapters.data.chapters});
		return chapters;
	}).catch((error) => {
		console.log("GetHistoricChapters failed " + error);
		this.setState({server_failure: true});
	});

	var sprint_history_promise = this.GetSprintHistory().then(sprint_info => {
		if (sprint_info.data.sprint_info !== "no sprint history") {
			this.setState({sprint_history: sprint_info.data.sprint_info});
		}
		return sprint_info;
	}).catch((error) => {
		console.log("GetSprintHistory failed " + error);
		this.setState({server_failure: true});
	});

	var completed_themes_promise = this.GetCompletedThemes().then(data => {
		if (data.data != "nothing completed") {
			this.setState({completed_themes: data.data.themes});
		} 
	}).catch((error) => {
		console.log("GetCompletedThemes failed " + error);
		this.setState({server_failure: true});
	});

	var completed_objectives_promise = this.GetCompletedObjectives().then(data => {
		if (data.data != "nothing completed") {
			this.setState({completed_objectives: data.data.objectives});
		} 
	}).catch((error) => {
		console.log("GetCompletedObjectives failed " + error);
		this.setState({server_failure: true});
	});

	var theme_promise = this.GetThemes().then(data => {
		this.setState({themes: data.data.themes});		
		return data;
	}).catch((error) => {
		console.log("GetThemes failed " + error);
		this.setState({server_failure: true});
	});
	
	var objective_promise = this.GetObjectives().then(data => {
   			this.setState({objectives: data.data.objectives})
   			return data;
   	}).catch((error) => {
		console.log("GetObjectives failed " + error);
		this.setState({server_failure: true});
	});

	var progress_promise = this.GetProgress().then(prog => {
		this.setState({objective_progress: prog.data.sorted_objectives});
		return prog;
	}).catch((error) => {
		console.log("GetProgress failed " + error);
		this.setState({server_failure: true});
	});

	var chapters_promise = this.GetChapters().then(chps => {
		this.setState({chapters: chps.data.chapters})
		return chps;
	}).catch((error) => {
		console.log("GetChapters failed " + error);
		this.setState({server_failure: true});
	});

	promises.push(progress_promise);
	promises.push(objective_promise);
	promises.push(chapters_promise);
	promises.push(sprint_history_promise);
	promises.push(done_chapters_promise);
	promises.push(theme_promise);
	promises.push(objective_promise)
	promises.push(completed_objectives_promise);
	promises.push(completed_themes_promise);

	Promise.all(promises).then( () => {
		let local_chapters = this.state.done_chapters;
		let local_objectives = this.state.completed_objectives;
		let local_themes = this.state.completed_themes;
		local_objectives.forEach((lobj) => {
			this.state.objectives.find((obj) => {
				if (obj.id == lobj.objective_id) {
					lobj.description = obj.description;
				}
			});
		});

		local_themes.forEach((ltheme) => {
			this.state.themes.find((theme) => {
				if (ltheme.theme_id == theme.id) {
					ltheme.description = theme.description;
				}
			});
		});

		local_chapters.forEach((sp) => {
			this.state.chapters.find((element) => {
				if (element.id === sp.chapter_id) {
					sp.description = element.description;
				}
			});
		});		
		this.setState({loading: false, done_chapters: local_chapters, completed_themes: local_themes, completed_objectives: local_objectives});
	}).catch((error) => {
		console.log("All promises failed " + error);
		this.setState({server_failure: true});
	});
}

async GetHistoricChapters() {
	const res = await axios.get(APIHost + '/api/getAllHistoricChapters', {withCredentials: true});
	return await res;
}

async GetSprintHistory() {
	const res = await axios.get(APIHost + '/api/getSprintHistoryData', {withCredentials: true});
	return await res;
}

async GetCompletedThemes() {
	const res = await axios.get(APIHost + '/api/getCompletedThemes', {withCredentials: true});
	return await res;
}

async GetCompletedObjectives() {
	const res = await axios.get(APIHost + '/api/getCompletedObjectives', {withCredentials: true});
	return await res;
}

async GetChapters() {
	const res = await axios.get(APIHost + '/api/getChapters', {withCredentials: true});
	return await res; 
}

async GetProgress() {
	const res = await axios.get(APIHost + '/api/getProgress', {withCredentials: true});
	return await res;
}

async GetObjectives() {
	const res = await axios.get(APIHost + '/api/getAllObjectives', {withCredentials: true});
	return await res;
}

async GetThemes() {
	const res = await axios.get(APIHost + '/api/getThemes', {withCredentials: true});
	return await res; 
}

DisplayThemes() {
	var display = [];
	var display_themes = [];
	if(this.state.completed_themes.length == 0) {
		display.push(<h5>You haven't completed any Themes yet, but once you do, they will be displayed here</h5>);
	} else {
		this.state.completed_themes.forEach((theme) => {
			display_themes.push(<li>{theme.description}</li>);
		});
		display.push(<div><h5 className="mb-2">Your completed Themes</h5><ul>{display_themes}</ul></div>);
	}
	return display;
}

DisplayObjectives() {
	var display = [];
	var display_objectives = [];
	if(this.state.completed_objectives.length == 0) {
		display.push(<h5>You haven't completed any Objectives yet, but once you do, they will be displayed here</h5>);
	} else {
		this.state.completed_objectives.forEach((obj) => {
			display_objectives.push(<li>{obj.description}</li>);
		});
		display.push(<div><h5 className="mb-2">Your completed Objectives</h5><ul>{display_objectives}</ul></div>);
	}
	return display;
}

DirectToChapter(event) {
	event.preventDefault();
	var chapter_id = event.currentTarget.getAttribute('chapter_id');
	var sprint_id = event.currentTarget.getAttribute('sprint_id');
	var path = "";
	// the none option is in case they are looking at a historic chapter
	if (sprint_id == "none") {
		path = "/chapter" + chapter_id + "/" + sprint_id;
	} else {
		path = "/chapter" + chapter_id + "/" + this.state.sprint_info.sprint_id;
	}
	this.props.history.push({pathname: path, state: { loggedIn: true }});
}

DisplaySprintHistory() {
	var display = [];
	var items = [];
	if(this.state.sprint_history == null) {
		display.push(<h5 className="mb-2">This section will have a list of your completed sprints</h5>);
	} else {
	display.push(<h5 className="mb-2">View your previous sprints</h5>);
	for (var i=0; i<this.state.sprint_history.length; i++) {
	items.push(<li><a href="" sprint_id={this.state.sprint_history[i].sprint_id} onClick={this.DirectToSprint}>{this.state.sprint_history[i].start_date}</a></li>);
	}
	display.push(<ul>{items}</ul>);
	}
	return display;
}

DisplayAllChapters() {
	var display = [];
	var items = [];
	if(this.state.done_chapters.length === 0) {
		display.push(<h5>This section will contain a list of chapters that you have completed in previous Sprints</h5>);
	} else {
	display.push(<h5 className="mb-2">View your previous chapters</h5>);
	for (var i=0; i<this.state.done_chapters.length; i++) {
	items.push(<li><a href="" chapter_id={this.state.done_chapters[i].chapter_id} sprint_id="none" onClick={this.DirectToChapter}>{this.state.done_chapters[i].description}</a></li>);
	}
	display.push(<div className="chapter_history"><ul>{items}</ul></div>);
	}
	return display;
}

DirectToSprint(event) {
	event.preventDefault();
	var sprint_id = event.currentTarget.getAttribute('sprint_id')
	const path = "/SprintHistory" + "/" + sprint_id;
	this.props.history.push({pathname: path, state: { foo: 'bar' }});
}

render() {
		/*if(this.props.location.state == null) {
 			return ( <Redirect to='/Home' /> );
		} else {
			return(
						<div className="History">
				<Header history={this.props.history} loggedIn={true} />
				<div className="container_profile">
				<div className="row">

			    				<div className="col-md-6">
        				<section className="two-sided-box body">
        					<div className="two-sided-box-in">

        					</div></section></div>
        								    				<div className="col-md-6">
        				<section className="two-sided-box body">
        					<div className="two-sided-box-in">

        					</div></section></div>
        					        								    				<div className="col-md-6">
        				<section className="two-sided-box body">
        					<div className="two-sided-box-in">

            					{!this.state.loading && this.DisplaySprintHistory()}
        					</div></section></div>
        					        								    				<div className="col-md-6">
        				<section className="two-sided-box body">
        					<div className="two-sided-box-in">

        					{!this.state.loading && this.DisplayAllChapters()}
        					</div></section></div>
        					</div>
        					</div>
        		<Footer history={this.props.history} loggedIn={true} />
        					</div>

			)*/
		return (<div className="History">
			<Header history={this.props.history} LoggedIn={true} />
			<div className="container_profile">
			{this.state.server_failure && <ServerFailure />}
				<div className="row">
				<div className="col-md-6">
                	<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
								<h5>Key stats</h5>
                			</div>                	
                		</div>           
           			</section>

           		</div>
           		<div className="col-md-6">
        			<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
        					{!this.state.loading && this.DisplayThemes()}

                			</div>                	
                		</div>           
          			 </section>
          		</div>
          						<div className="col-md-6">
                	<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
								{!this.state.loading && this.DisplaySprintHistory()}
                			</div>                	
                		</div>           
           			</section>

           		</div>

           		<div className="col-md-6">
        			<section className="two-sided-box">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
        					{!this.state.loading && this.DisplayObjectives()}

                			</div>                	
                		</div>           
          			 </section>
          		</div>
          						<div className="col-md-6">
         
           		</div>
           		  <div className="col-md-6">
                	<section className="two-sided-box body">
            			<div className="two-sided-box-in">
                        	<div className="col-md-12">
								{!this.state.loading && this.DisplayAllChapters()}
                			</div>                	
                		</div>           
           			</section>

           		</div>
        		</div>
        	</div>
			<Footer history={this.props.history} loggedIn={true} />
		</div>);
	}
}

export default History;