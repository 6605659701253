import React, { Component } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import './css/profile.css';
import MyCarousel from './components/carousel';
import Popup from "reactjs-popup";
import Welcome from './components/welcome';
import Moment from './components/moment';
import { APIHost } from './GlobalVariables';
import { Alert } from 'react-bootstrap';
//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faTrophy } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css';
import { UpdateLastActivity } from './utilities.js';
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

//const APIHost = "http://ec2-34-219-125-137.us-west-2.compute.amazonaws.com:8000";

const NameEmpty = () => (
	<p><font color="red">Please make sure to provide your name.</font></p>
);

const EmailEmpty = () => (
	<p><font color="red">Please make sure to provide a valid email address.</font></p>
);

const MessageEmpty = () => (
	<p><font color="red">Please make sure to provide some message content.</font></p>
);

const MessageFailed = () => (
	<p><font color="red">Your message failed to send. Please try again.</font></p>
);

const MessageSent = () => (
	<p><font color="black">Thanks so much for your message! We will get back to you soon.</font></p>
);

const ServerFailure = () => (
	<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			credits: 0,
			sprint_defined: false,
			sprint_status: '',
			sprint: [],
			chapters: [],
			sprint_history: [],
			done_chapters: [],
			new_sprint: false,
			name: "",
			loading: true,
			objective_progress: [],
			capacity: 0,
			logged_in: false,
			server_failure: false,
			start_sprint_failed: false,
			sprint_info: {},
			mission: [],
			brand: [],
			mission_set: false,
			brand_set: false,
			mission: false,
			update_mission: false,
			update_brand: false,
			authorized: false,
			encouraging_messages: [],
			new_theme: null,
			new_objective: null,
			new_badge: false,
			encouraging_videos: [],
			mobile: false,
			first_retro: false,
			display_retro_video: false,
			display_email_error: false,
			display_update_email: false,
			pick_account_type: false,
			ambassador_status: false
		}

		this.GetSprint = this.GetSprint.bind(this);
		this.UpdateImpressions = this.UpdateImpressions.bind(this);
		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.StartSprint = this.StartSprint.bind(this);
		this.DisplaySprintStatus = this.DisplaySprintStatus.bind(this);
		this.GetUserDetails = this.GetUserDetails.bind(this);
		this.DirectToChapter = this.DirectToChapter.bind(this);
		this.ProfileSetup = this.ProfileSetup.bind(this);
		this.GetProgress = this.GetProgress.bind(this);
		this.DisplayProgress = this.DisplayProgress.bind(this);
		this.DisplaySprintHistory = this.DisplaySprintHistory.bind(this);
		this.GetSprintHistory = this.GetSprintHistory.bind(this);
		this.DirectToSprint = this.DirectToSprint.bind(this);
		this.GetHistoricChapters = this.GetHistoricChapters.bind(this);
		this.DisplayAllChapters = this.DisplayAllChapters.bind(this);
		this.CheckLogin = this.CheckLogin.bind(this);
		this.onSubmitCreateSprint = this.onSubmitCreateSprint.bind(this);
		this.CreateFirstSprint = this.CreateFirstSprint.bind(this);
		this.CreateFirstSprintCallback = this.CreateFirstSprintCallback.bind(this);
		this.DisplayCapacity = this.DisplayCapacity.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitMessage = this.onSubmitMessage.bind(this);
		this.SendMessage = this.SendMessage.bind(this);
		this.SendMessageCallBack = this.SendMessageCallBack.bind(this);
		this.DisplayMission = this.DisplayMission.bind(this);
		this.GetMission = this.GetMission.bind(this);
		this.GetBrand = this.GetBrand.bind(this);
		this.EditMission = this.EditMission.bind(this);
		this.UpdateMission = this.UpdateMission.bind(this);
		this.EditBrand = this.EditBrand.bind(this);
		this.UpdateBrand = this.UpdateBrand.bind(this);
		this.onUpdateMission = this.onUpdateMission.bind(this);
		this.UpdateMissionCallback = this.UpdateMissionCallback.bind(this);
		this.onUpdateBrand = this.onUpdateBrand.bind(this);
		this.UpdateBrandCallback = this.UpdateBrandCallback.bind(this);
		this.onUpdateChange = this.onUpdateChange.bind(this);
		this.DirectToViewTraining = this.DirectToViewTraining.bind(this);
		this.DirectToManageSprint = this.DirectToManageSprint.bind(this);
		this.DirectToRetrospective = this.DirectToRetrospective.bind(this);
		this.DisplayMessage = this.DisplayMessage.bind(this);
		this.GetEncouragingMessages = this.GetEncouragingMessages.bind(this);
		this.GetEncouragingVideos = this.GetEncouragingVideos.bind(this);
		this.DismissVideo = this.DismissVideo.bind(this);
		this.DismissVideoCallback = this.DismissVideoCallback.bind(this);
		this.GetBadges = this.GetBadges.bind(this);
		this.GetAllDoneChapters = this.GetAllDoneChapters.bind(this);
		this.CheckMobile = this.CheckMobile.bind(this);
		this.GetMemberID = this.GetMemberID.bind(this);
		this.GetAmbassadorStatus = this.GetAmbassadorStatus.bind(this);
		this.RetroVideoDisplay = this.RetroVideoDisplay.bind(this);
		this.DisplayEmailError = this.DisplayEmailError.bind(this);
		this.DisplayUpdateEmail = this.DisplayUpdateEmail.bind(this);
		this.UpdateEmail = this.UpdateEmail.bind(this);
		this.ConfirmEmail = this.ConfirmEmail.bind(this);
		this.SendConfirmEmailCallback = this.SendConfirmEmailCallback.bind(this)
		this.SendConfirmEmail = this.SendConfirmEmail.bind(this);
		this.SubmitAccountType = this.SubmitAccountType.bind(this);
		this.AccountTypeChange = this.AccountTypeChange.bind(this);
		this.SubmitAccountTypeCallback = this.SubmitAccountTypeCallback.bind(this);
		this.SubmitAccountType = this.SubmitAccountType.bind(this);
		this.onSubmitAccountType = this.onSubmitAccountType.bind(this);
		this.UpdateAccountType = this.UpdateAccountType.bind(this);
		this.UpdateAccountTypeCallback = this.UpdateAccountTypeCallback.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		
		var promises = [];
		window.addEventListener("resize", this.CheckMobile());
		this.UpdateImpressions().catch((error) => {
			console.log("Updating impressions failed");
		});
		// If they are logged in then we proceed with all the rest of the gets
		// if not - then we set result to false and immediately set the loading to false so the redirect will happen	
		this.CheckLogin().then((result) => {
			if (result.data.loggedIn) {
				UpdateLastActivity();
				console.log('nextstrp')
				var done_chapters_promise = this.GetHistoricChapters().then((chapters) => {

					this.setState({ done_chapters: chapters.data.chapters });
					return chapters;
				}).catch((error) => {
					console.log("GetHistoricChapters failed " + error);
					this.setState({ server_failure: true });
				});

				var capacity_promise = this.GetCapacity().then(data => {
					this.setState({ capacity: data.data.capacity });
					return data;
				}).catch((error) => {
					console.log("GetCapacity failed " + error);
					this.setState({ server_failure: true });
				});

				var first_visits_promise = this.FirstVisits().then(first_visit => {
					if (first_visit.data.first_visit === "pick account type") {
						this.setState({pick_account_type: true});
					} else if (first_visit.data.first_visit === "first sprint") {
						this.setState({ first_sprint: true });
					} else if (first_visit.data.first_visit === "first visit") {
						this.setState({ first_visit: true });
					} else if (first_visit.data.first_visit === "first retro") {
						this.setState({first_retro: true});
					}
					// I don't know what this was for but I have a state variable called mission already
					/*else if (first_visit.data === "mission") {
						this.setState({mission: true});
					}*/
					return first_visit;
				}).catch((error) => {
					console.log("FirstVisits failed " + error);
					this.setState({ server_failure: true });
				});

				var sprint_history_promise = this.GetSprintHistory().then(sprint_info => {
					if (sprint_info.data.sprint_info !== "no sprint history") {
						this.setState({ sprint_history: sprint_info.data.sprint_info });
					}
					return sprint_info;
				}).catch((error) => {
					console.log("GetSprintHistory failed " + error);
					this.setState({ server_failure: true });
				});

				var progress_promise = this.GetProgress().then(prog => {
					this.setState({ objective_progress: prog.data.sorted_objectives });
					return prog;
				}).catch((error) => {
					console.log("GetProgress failed " + error);
					this.setState({ server_failure: true });
				});


				var mission_promise = this.GetMission().then((mission) => {
					if (mission.data.mission !== null) {
						this.setState({ mission: mission.data.mission, mission_set: true });
					}
				}).catch((error) => {
					console.log("Failed to get Mission");
					this.setState({ server_failure: true });
				});

				var brand_promise = this.GetBrand().then((brand) => {
					if (brand.data.brand !== null) {
						this.setState({ brand: brand.data.brand, brand_set: true });
					}
				}).catch((error) => {
					console.log("Failed to get Brand");
					this.setState({ server_failure: true });
				});

				var member_id_promise = this.GetMemberID().then((member_id) => {
					this.setState({ member_id: member_id.data.member_id });
				}).catch((error) => {
					console.log("Failed to get member_id");
					this.setState({ server_failure: true });
				});

				var all_done_promise = this.GetAllDoneChapters().then((done_chapters) => {
					this.setState({ all_done_chapters: done_chapters.data.done_chapters });
				}).catch((error) => {
					console.log("Failed to get Brand");
					this.setState({ server_failure: true });
				});

				var badges_promise = this.GetBadges().then((badges) => {
					badges.data.badges.forEach((badge) => {
						if (badge.new && badge.status === "complete") {
							if (badge.type === "theme") {
								var new_theme = { id: badge.id, type: "theme", badge_id: badge.badge_id }
								this.setState({ new_theme: new_theme })
							} else if (badge.type === "objective") {
								var new_objective = { id: badge.id, type: "objective", badge_id: badge.badge_id }
								this.setState({ new_objective: new_objective })
							}
							this.setState({ new_badge: true });
						}
					});
					this.setState({ badges: badges.data.badges })
				}).catch((error) => {
					console.log("Failed to get badges");
					this.setState({ server_failure: true });
				});

				var credits_promise = this.GetCredits().then(creds => {
					this.setState({ credits: creds.data.credits });
					return creds;
				}).catch((error) => {
					console.log("GetCredits failed " + error);
					this.setState({ server_failure: true });
				});

				var objectives_promise = this.GetObjectives().then(objs => {
					this.setState({ objectives: objs.data.objectives });
					return objs;
				}).catch((error) => {
					console.log("GetObjectives failed " + error);
					this.setState({ server_failure: true });
				});

				var user_promise = this.GetUserDetails().then(dets => {
					this.setState({ user_details: dets.data.user_details });
					return dets;
				}).catch((error) => {
					console.log("GetUserDetails failed " + error);
					this.setState({ server_failure: true });
				});

				var chapters_promise = this.GetChapters().then(chps => {
					this.setState({ chapters: chps.data.chapters })
					return chps;
				}).catch((error) => {
					console.log("GetChapters failed " + error);
					this.setState({ server_failure: true });
				});

				var sprint_promise = this.GetSprint().then(sprint => {
					if (sprint.data === "no sprint") {
						this.setState({ sprint_defined: false });
					} else {
						this.setState({ sprint: sprint.data.sprint, sprint_defined: true, sprint_info: sprint.data.sprint_info });
						if (sprint.data.sprint_info.sprint_status === "created") {
							this.setState({ sprint_status: 'created' });
						} else if (sprint.data.sprint_info.sprint_status === "started") {
							this.setState({ sprint_status: 'started' });
						} else if (sprint.data.sprint_info.sprint_status === "completed") {
							this.setState({ sprint_status: 'completed' });
						}
						/*let local_sprint = this.state.sprint;
						local_sprint.forEach((sp) => {
							this.state.chapters.find((element) => {
								if (element.id == sp.chapter_id) {
									sp.description = element.description;
								}
							});
						});
					this.setState({sprint: local_sprint});*/
					}
					return sprint;
				}).catch((error) => {
					console.log("GetSprint failed " + error);
					this.setState({ server_failure: true });
				});

				var encouraging_videos_promise = this.GetEncouragingVideos().then((videos) => {
					this.setState({ encouraging_videos: videos.data.videos })
				}).catch((error) => {
					console.log("GetEncouragingVideos failed " + error);
					this.setState({ server_failure: true });
				});

				var encouraging_message_promise = this.GetEncouragingMessages().then((messages) => {
					this.setState({ encouraging_messages: messages.data.messages })
				}).catch((error) => {
					console.log("GetEncouragingMessages failed " + error);
					this.setState({ server_failure: true });
				});

				var ambassador_promise = this.GetAmbassadorStatus().then((ambassador_status) => {
					this.setState({ ambassador_status: ambassador_status.data.ambassador_status })
				}).catch((error) => {
					console.log("GetAmbassadorStatus failed " + error);
					this.setState({ server_failure: true });
				});

				promises.push(badges_promise);
				promises.push(encouraging_message_promise);
				promises.push(encouraging_videos_promise);
				promises.push(progress_promise);
				promises.push(credits_promise);
				promises.push(objectives_promise);
				promises.push(user_promise);
				promises.push(chapters_promise);
				promises.push(sprint_promise);
				promises.push(sprint_history_promise);
				promises.push(done_chapters_promise);
				promises.push(first_visits_promise);
				promises.push(mission_promise);
				promises.push(brand_promise);
				promises.push(all_done_promise);
				promises.push(member_id_promise);
				promises.push(ambassador_promise);
				Promise.all(promises).then(() => {
					let local_chapters = this.state.done_chapters;
					var dep_flag = false;
					var flag = false;

					var dep_cnt = 0;
					if (this.state.user_details && this.state.user_details.member_id==1024) {
						this.setState({display_email_error: true});
					}
					if (this.state.user_details && this.state.user_details.confirmed_email) {
						this.setState({display_update_email: false});
					} else {
						this.setState({display_update_email: true});					
					}
					local_chapters.forEach((sp) => {
						this.state.chapters.find((element) => {
							if (element.id === sp.chapter_id) {
								sp.description = element.description;
							}
						});
					});

					let local_sprint = this.state.sprint;
					local_sprint.forEach((sp) => {
						this.state.chapters.find((element) => {
							if (element.id === sp.chapter_id) {
								sp.description = element.description;
								sp.objective_id = element.objective_id;
								sp.points = element.points;
								sp.chapter_id = element.id;
							}
						});
					});
					local_sprint.forEach((sp) => {
						this.state.chapters.find((ch) => {
							dep_cnt = 0;
							if (ch.id === sp.chapter_id) {
								if (ch.dependencies) {
									dep_flag = true;
									var dep_description = [];
									ch.dependencies.forEach((dep) => {
										this.state.chapters.find((chapter) => {
											flag = false;
											if (chapter.id === dep) {
												this.state.all_done_chapters.find((done_ch) => {
													if (done_ch.chapter_id === dep) {
														flag = true;
													}
												});
												if (!flag) {
													dep_description.push(chapter.description);
												}
											}
										});
										this.state.all_done_chapters.find((done) => {
											if (done.chapter_id === dep) {
												dep_cnt++;
											}
										});
									});
									if (dep_cnt !== ch.dependencies.length) {
										sp.has_dependency = true;
										sp.dep_description = dep_description;
									}
								} else {
									sp.has_dependency = false;
								}
							}
						});
					});

					this.setState({ sprint: local_sprint, done_chapters: local_chapters, loading: false, logged_in: result.data.loggedIn });
				});

			} else {
				this.setState({ logged_in: false, loading: false });
			}
		}).catch((error) => {
			console.log("GetLogin failed " + error);
			this.setState({ server_failure: true });
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.CheckMobile)
	}

	async GetEncouragingVideos() {
		const res = await axios.get(APIHost + '/api/getEncouragingVideos',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetAmbassadorStatus() {
		const res = await axios.get(APIHost + '/api/ambassadorStatus',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetEncouragingMessages() {
		const res = await axios.get(APIHost + '/api/getEncouragingMessages',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetMission() {
		const res = await axios.get(APIHost + '/api/getMission',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetBrand() {
		const res = await axios.get(APIHost + '/api/getBrand',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetMemberID() {
		const res = await axios.get(APIHost + '/api/getMemberID',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetBadges() {
		const res = await axios.get(APIHost + '/api/badges',
			{ params: { withCredentials: true } });
		return await res;
	}

	async GetCapacity() {
		const res = await axios.get(APIHost + '/api/getCapacity', { withCredentials: true });
		return await res;
	}

	async UpdateImpressions() {
		const res = await axios.post(APIHost + '/api/updateImpressions',
			{ withCredentials: true, page: 'home' });
		return await res;
	}

	async FirstVisits() {
		const res = await axios.get(APIHost + '/api/firstVisits', { withCredentials: true });
		return await res;
	}

	async CheckLogin() {
		const res = await axios.get(APIHost + '/api/login', { withCredentials: true });
		return await res;
	}

	async GetAllDoneChapters() {
		const res = await axios.get(APIHost + '/api/getAllDoneChapters', { withCredentials: true });
		return await res;
	}

	async GetHistoricChapters() {
		const res = await axios.get(APIHost + '/api/getAllHistoricChapters', { withCredentials: true });
		return await res;
	}

	async GetSprintHistory() {
		const res = await axios.get(APIHost + '/api/getSprintHistoryData', { withCredentials: true });
		return await res;
	}

	async GetCredits() {
		const res = await axios.get(APIHost + '/api/getCredits', { withCredentials: true });
		return await res;
	}

	async GetSprint() {
		const res = await axios.post(APIHost + '/api/getSprint', { withCredentials: true });
		return await res;
	}

	async GetChapters() {
		const res = await axios.get(APIHost + '/api/getChapters', { withCredentials: true });
		return await res;
	}

	async GetUserDetails() {
		const res = await axios.get(APIHost + '/api/getUserDetails', { withCredentials: true });
		return await res;
	}

	async GetProgress() {
		const res = await axios.get(APIHost + '/api/getProgress', { withCredentials: true });
		return await res;
	}

	async GetObjectives() {
		const res = await axios.get(APIHost + '/api/getAllObjectives', { withCredentials: true });
		return await res;
	}

	CheckMobile() {
		this.setState({ mobile: window.innerWidth <= 760 });
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	StartSprint() {
		if (this.state.first_sprint) {
			axios.post(APIHost + '/api/startSprintTransaction', {
				withCredentials: true,
				sprint_id: this.state.sprint_info.sprint_id,
				update: 'first retro'
			}).then(res => {
				var temp_sprint_info = this.state.sprint_info;
				temp_sprint_info.days_left = 14;
				this.setState({ sprint_status: 'started', sprint_info: temp_sprint_info });

			}).catch((error) => {
				console.log("StartSprint failed " + error);
				this.setState({ start_sprint_failed: true });
			})
		} else {
			axios.post(APIHost + '/api/startSprint', {
				withCredentials: true,
				sprint_id: this.state.sprint_info.sprint_id,
			}).then(res => {
				var temp_sprint_info = this.state.sprint_info;
				temp_sprint_info.days_left = 14;
				this.setState({ sprint_status: 'started', sprint_info: temp_sprint_info });
			}).catch((error) => {
				console.log("StartSprint failed " + error);
				this.setState({ start_sprint_failed: true });
			});
		}
	}

	DirectToChapter(event) {
		event.preventDefault();
		var chapter_id = event.currentTarget.getAttribute('chapter_id');
		var sprint_id = event.currentTarget.getAttribute('sprint_id');
		var path = "";
		// the none option is in case they are looking at a historic chapter
		if (sprint_id == "none") {
			path = "/chapter" + chapter_id + "/" + sprint_id;
		} else {
			path = "/chapter" + chapter_id + "/" + this.state.sprint_info.sprint_id;
		}
		this.props.history.push({ pathname: path, state: { loggedIn: true } });
	}

	DirectToSprint(event) {
		event.preventDefault();
		var sprint_id = event.currentTarget.getAttribute('sprint_id')
		const path = "/SprintHistory" + "/" + sprint_id;
		this.props.history.push({ pathname: path, state: { foo: 'bar' } });
	}

	DirectToManageSprint(event) {
		event.preventDefault();
		const path = "/ManageSprint";
		this.props.history.push({ pathname: path, state: { foo: 'bar' } });
	}

	DirectToViewTraining(event) {
		event.preventDefault();
		const path = "/ViewTraining";
		this.props.history.push({ pathname: path, state: { foo: 'bar' } });
	}

	DirectToRetrospective(event) {
		event.preventDefault();
		const path = "/Retrospective";
		this.props.history.push({ pathname: path, state: { foo: 'bar', first_retro: this.state.first_retro } });
	}

	DisplayProgress() {
		var for_display = [];
		var percent = 0;
		var flag = false;
		/*for_display.push(<div><h4>Badges</h4><table className="table table-bordered"><thead><tr><th>Objectives</th><th>Themes</th><th>Perfect Sprint</th></tr></thead><tbody><tr><td>
			<FontAwesomeIcon icon={faTrophy} size="1x" /></td><td><i className="fa fa-trophy" aria-hidden="true"></i></td><td><i className="fa fa-trophy" aria-hidden="true"></i></td></tr></tbody></table></div>)*/
		if (this.state.objective_progress.length === 0) {
			flag = true;
			for_display.push(<h6>This is where your progress in the training will be displayed</h6>);
		} else {
			this.state.objective_progress.forEach((element) => {
				percent = (element.percent_complete * 100).toFixed(0) + "%";
				if (percent !== "100%") {
					flag = true;
					this.state.objectives.find((obj) => {
						if (obj.id === element.objective_id) {
							for_display.push(<h5>{obj.description}</h5>);
						}
					});

					for_display.push(<div className="progress mt-3">
						<div className="progress-bar progress-bar-epic" role="progressbar" aria-valuenow="40"
							aria-valuemin="0" aria-valuemax="100" style={{ width: percent }}>
							{percent}</div></div>);
				}
			});

		}
		if (!flag) {
			for_display.push(<h5>You don't have any Objectives in progress right now.</h5>);
		}
		return for_display;
	}

	RetroVideoDisplay(event) {
		event.preventDefault();
		this.setState({display_retro_video: !this.state.display_retro_video});
	}

	DisplaySprint() {
		var for_display = [];
		var sprint_rows = [];
		var row = [];
		var cnt = 0;
		if (this.state.first_visit) {
			return null;
		} else if (!this.state.sprint_defined) {
			//this should actually never happen - when they first join, a credit and sprint is automatically created
			// when they setup their profile
			for_display.push(<h1>You do not have a sprint defined</h1>);
			return for_display;
		} else {
			for (var i = 0; i < this.state.sprint.length; i++) {
				row = [];
				var flag = false;
				if (this.state.sprint[i].status == "open") {
					if (this.state.sprint_status == "started") {
						if (this.state.sprint[i].has_dependency) {
							var dep_des_display = [];
							this.state.sprint[i].dep_description.forEach((desc) => {
								dep_des_display.push(<li>{desc}</li>);
							});
							row.push(<td><Popup trigger={<a href="javascript:void(0)" chapter_id={this.state.sprint[i].chapter_id}>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a>} position="right center">
								<div><p>This chapter has dependencies you haven't completed! You can start the chapter once those are completed.
								The list of chapters you need to complete before this chapter is:
									          <ul>{dep_des_display}</ul>
								</p> </div>
							</Popup></td>);
						} else {
							row.push(<td><a href="/" chapter_id={this.state.sprint[i].chapter_id} onClick={this.DirectToChapter}>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
						}
					} else {
						row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
					}
					flag = true;
				} else {
					row.push(<td></td>);
				}
				if (this.state.sprint[i].status === "in progress") {
					if (this.state.sprint_status === "started") {
						row.push(<td><a href="/" chapter_id={this.state.sprint[i].chapter_id} onClick={this.DirectToChapter}>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
					} else {
						row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
					}
					flag = true;
				} else {
					row.push(<td></td>);
				}
				if (this.state.sprint[i].status === "done") {
					if (this.state.sprint_status === "started") {
						row.push(<td><a href="/" chapter_id={this.state.sprint[i].chapter_id} onClick={this.DirectToChapter}>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
					} else {
						row.push(<td><a>{this.state.sprint[i].description} ({this.state.sprint[i].points})</a></td>);
					}
					flag = true;
				} else {
					row.push(<td></td>);
				}
				if (flag) {
					sprint_rows.push(<tr>{row}</tr>);
				}
			}
			if (this.state.first_sprint) {
				var capacity_warning = [];
				if (this.state.sprint.length < 3) {
					capacity_warning.push(<p>You have additional chapters in the first objective that aren't included
					in this sprint. If you are able to finish the below chapters before the sprint is over, you can add
					those additional chapters in by visiting the Manage Sprint page</p>);
				}
				for_display.push(<div className="col-md-12"><div className="embed-responsive embed-responsive-16by9 service-video">
					<iframe title="item" className="embed-responsive-item" src="https://player.vimeo.com/video/562068021" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
				</div><h4>Additional getting-started information including video overviews can be found on the <Link to="/FAQ">FAQ</Link> page</h4><br />{capacity_warning}</div>);
			}
			if (this.state.sprint.length === 0) {
				for_display.push(<h5>It appears you haven't added any chapters to your sprint yet. Please visit the Manage Sprint area once you have started the sprint to add chapters</h5>);
			} else {
				for_display.push(<table className="table table-bordered"><thead><tr><th>To Do (ch. points)</th><th>In Progress (ch. points)</th><th>Done (ch. points)</th>
				</tr></thead><tbody>{sprint_rows}</tbody></table>);
			}
		}
		return for_display;
	}

	EditMission(event) {
		this.setState({ update_mission: true });
	}

	UpdateMissionCallback() {
		this.setState({ update_mission: false });
	}

	UpdateMission(callback) {
		axios.post(APIHost + '/api/addMission', {
			withCredentials: true,
			mission: this.state.current_answer
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("Failed to submit mission");
			this.setState({ submission_failure: true });
		});
	}

	onUpdateMission(event) {
		event.preventDefault();
		this.UpdateMission(this.UpdateMissionCallback);
	}

	UpdateBrandCallback() {
		this.setState({ update_brand: false });
	}

	UpdateBrand(callback) {
		axios.post(APIHost + '/api/addBrand', {
			withCredentials: true,
			brand: this.state.current_answer
		}).then(res => {
			callback();
		}).catch(error => {
			console.log("Failed to submit brand");
			this.setState({ submission_failure: true });
		});
	}

	onUpdateBrand(event) {
		event.preventDefault();
		this.UpdateBrand(this.UpdateBrandCallback);
	}

	EditBrand(event) {
		this.setState({ update_brand: true });
	}

	onUpdateChange(event) {
		event.persist();
		this.setState({ [event.target.name]: event.target.value, current_answer: event.target.value });
	}

	DisplayMission() {
		var display_mission = [];
		var display_brand = [];
		var display = [];
		if (this.state.update_mission) {
			display_mission.push(
				<div className="d-flex align-items-center my-3">
					<h5>Your Mission Statement: <textarea onChange={this.onUpdateChange} rows="1" cols="20" type="text" name="mission" value={this.state.mission} className="form-control"></textarea></h5><button className="btn btn-outline-info ml-2" onClick={this.onUpdateMission}>upate</button>
				</div>
			)
		} else {
			display_mission.push(
				<div className="d-flex align-items-center my-3">
					<h5>Your Mission Statement: {this.state.mission}</h5> <button className="btn btn-outline-info ml-2" type="submit" onClick={this.EditMission}>edit</button>
				</div>
			);
		}
		if (this.state.update_brand) {
			display_mission.push(
				<div className="d-flex align-items-center my-3">
					<h5>Your Personal Brand: <textarea onChange={this.onUpdateChange} rows="1" cols="20" type="text" name="brand" value={this.state.brand} className="form-control"></textarea></h5><button className="btn btn-outline-info ml-2" onClick={this.onUpdateBrand}>upate</button>
				</div>
			)
		} else {
			display_brand.push(
				<div className="d-flex align-items-center my-3">

					<h5>Your Personal Brand: {this.state.brand} </h5><button type="submit" className="btn btn-outline-info ml-2" onClick={this.EditBrand}>edit</button>
				</div>
			);
		}
		display.push(<div>{display_mission}{display_brand}</div>);
		return display;
	}

	DisplaySprintStatus() {
		var display = [];
		var open_chapters = [];
		var in_progress = [];
		var done_chapters = [];
		if (this.state.first_visit) {
			return null;
		} else if (this.state.sprint_defined) {
			var points = 0;
			this.state.sprint.forEach((element) => {
				if (element.status === "open") {
					open_chapters.push(element.description);
					points = points + element.points;
				} else if (element.status === "in progress") {
					in_progress.push(element.description);
					points = points + element.points;
				} else if (element.status === "done") {
					done_chapters.push(element.description);
					//I didn't include this before -  not sure why. It is still part of the sprint
					points = points + element.points;
				}
			});
			if (this.state.sprint_status === 'completed') {
				if (this.state.user_details.account_type === 'Self-directed') {
					if (this.state.first_retro) {
						if (!this.state.display_retro_video) {
							display.push(
								<div className="d-flex align-items-center">
									<p>You've completed a sprint! Let's do a </p><div className="form-input light-rounded-buttons ml-2">
										<button className="main-btn light-rounded-two" onClick={this.DirectToRetrospective}>Retrospective</button>
									</div>
									<i className="fa fa-long-arrow-left"></i><p>What's this? Checkout this <button onClick={this.RetroVideoDisplay}>video</button></p>
								</div>
							);
						} else {
							display.push(
								<div className="d-flex align-items-center">
									<p>You've completed a sprint! Let's do a </p><div className="form-input light-rounded-buttons ml-2">
										<button className="main-btn light-rounded-two" onClick={this.DirectToRetrospective}>Retrospective</button>
									</div>
									<i className="fa fa-long-arrow-left"></i><p><button onClick={this.RetroVideoDisplay}>Hide Video</button></p>
									<p><iframe src="https://player.vimeo.com/video/507310541" width="400" height="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></p>

								</div>
							);						
						}
					} else {
						display.push(
							<div className="d-flex align-items-center">
								<p>You've completed a sprint! Let's do a </p><div className="form-input light-rounded-buttons ml-2">
									<button className="main-btn light-rounded-two" onClick={this.DirectToRetrospective}>Retrospective</button>
								</div>
							</div>
						);
					}
				} else if (!this.state.sprint_info.scheduled_retro) {
					display.push(<p>Schedule your Retrospective and Sprint Planning session <Link to={{ pathname: '/ScheduleRetro', state: { loggedIn: true } }}>here</Link> when you're ready!</p>)
				} else {
					display.push(<p>You have a confirmed Retrospective and Sprint Planning session scheduled. If you have any questions, please use the <Link to="/Contact">Contact</Link> page to reach out</p>);
				}
				//display.push(<Link className="custom-btn" id="Retrospective" to={{ pathname: "/Retrospective", state: { loggedIn: true}} }>Restrospective</Link>);
			} else if (this.state.sprint_status === 'created') {
				display.push(<p className="my-2">Sprint's Total Points: <h6 className="d-inline-block">{points}</h6></p>);
				display.push(<p>Training Capacity: <h6 className="d-inline-block">{this.state.capacity}</h6></p>);
				if (this.state.capacity < points && this.state.user_details.account_type === 'Self-directed') {
					display.push(<p>Your current sprint has more points than you have capacity. This might be OK. Stretching
					towards a greater capacity is a good thing. If you can complete the chapters, you might be able to increase
					your capacity for next time. If you realize you might not finish all of them, you can remove chapters under
				the Manage Sprint area.</p>)
				}
				display.push(
					<div className="form-input light-rounded-buttons mt-30">
						<button className="main-btn light-rounded-two" onClick={this.StartSprint}>Start Sprint</button>
					</div>
				);
				if (this.state.start_sprint_failed) {
					display.push(<p><font color="red">The sprint failed to start. Please refresh and try starting it again. If this continues to happen, please reach out to support <Link to={{ pathname: '/Contact', state: { loggedIn: true } }}>here</Link> and let us know.</font></p>);
				}
				// not sure why this was hear
				//if(this.state.new_sprint) {
				//display.push(<Link className="custom-btn" to={{ pathname: '/ManageSprint', state: { preview: true, loggedIn: true } }}>Manage Sprint</Link>);
				//}
			} else {
				if (this.state.sprint_info.days_left === .5) {
					display.push(<p>You have less than a day left in this sprint.</p>);
				} else {
					display.push(<p>Days remaining in sprint: <h6 className="d-inline-block">{this.state.sprint_info.days_left}</h6></p>);
				}
				display.push(<p className="my-2">Sprint's Total Points: <h6 className="d-inline-block">{points}</h6></p>);
				display.push(<p>Training Capacity: <h6 className="d-inline-block">{this.state.capacity}</h6></p>);
				if (this.state.capacity < points && this.state.user_details.account_type === 'Self-directed') {
					display.push(<p>Your current sprint has more points than you have capacity. This might be OK. Stretching
					towards a greater capacity is a good thing. If you can complete the chapters, you might be able to increase
					your capacity for next time. If you realize you might not finish all of them, you can remove chapters under
				the Manage Sprint area.</p>)
				}
				//display.push(<Link className="custom-btn" name="ManageSprintLink" to={{ pathname: '/ManageSprint', state: { loggedIn: true } }}>Manage Sprint</Link>);
				if (this.state.user_details.account_type === 'Self-directed') {
					display.push(
						<div className="form-input light-rounded-buttons mt-30">
							Add/Remove Chapters   <button className="main-btn light-rounded-two" onClick={this.DirectToManageSprint}>Manage Sprint</button>
						</div>
					);
				} else {
					var display_temp = [];
					if (!this.state.sprint_info.scheduled_retro) {
						display_temp.push(<p>Finished your chapters or getting close to your Sprint's end date? Schedule your Retrospective and Sprint Planning session <Link to={{ pathname: "/ScheduleRetro", state: { loggedIn: true } }}>here</Link>!</p>);
					} else {
						display_temp.push(<p>You have a confirmed Retrospective and Sprint Planning session scheduled. If you have any questions, please use the <Link to="/Contact">Contact</Link> page to reach out</p>);
					}
					display.push(<div><br />{display_temp}<p>View the Training content <a href="" onClick={this.DirectToViewTraining}>here</a></p></div>);
				}

			}
		} else {
			//display.push(<Link className="custom-btn" to={{ pathname: '/SprintPlanning', state: { sprint: 'first'} }}>Create Sprint</Link>);
		}
		return display;
	}

	DisplayMessage() {
		var display = [];
		var message = null;
		var message_type = null;
		var message_id = null;
		var message_flag = false;
		if (this.state.new_badge) {
			if (this.state.new_theme) {
				axios.post(APIHost + '/api/updateBadges', {
					theme: true,
					withCredentials: true
				}).catch(error => {
					console.log("updateBadges failed");
					this.setState({ server_failure: true });
				});
				display.push(<h4>You have completed a Theme! This is a HUGE milestone...you should be incredibly proud of yourself! I know I am!
		    Let's keep that momentum going!</h4>);
			} else if (this.state.new_objective) {
				axios.post(APIHost + '/api/updateBadges', {
					objective: true,
					withCredentials: true
				}).catch(error => {
					console.log("updateBadges failed");
					this.setState({ server_failure: true });
				});
				display.push(<h4>You have completed an Objective! This is a big step forward in your journey. You should be incredibly proud of yourself! I know I am!
		    Let's keep that momentum going!</h4>);
			}
		} else {
			if (!this.state.first_visit && !this.state.first_sprint) {
				if (this.props.location.state && this.props.location.state.from_login) {
					axios.post(APIHost + '/api/updateEncouragingMessageID', {
						next_message: this.state.user_details.current_message + 1,
						update_id: true,
						withCredentials: true
					}).catch(error => {
						console.log("updateEncouragingMessageID failed");
						this.setState({ server_failure: true });
					});
					message_id = this.state.user_details.current_message + 1;
				} else {
					message_id = this.state.user_details.current_message;
				}
				this.state.encouraging_messages.find((mes) => {
					if (mes.id === message_id) {
						message = mes.message;
						message_type = mes.type;
						message_flag = true;
					}
				});
				if (!message_flag) {
					axios.post(APIHost + '/api/updateEncouragingMessageID', {
						next_message: 1,
						update_id: true,
						withCredentials: true
					}).catch(error => {
						console.log("updateEncouragingMessageID failed");
						this.setState({ server_failure: true });
					});
					message = this.state.encouraging_messages[0].message;
					message_type = this.state.encouraging_messages[0].type;
				}
				if (!this.state.user_details.dismissed_message) {
					if (message_type === "text") {
						display.push(<h4 className="mb-3">"{message}"<br />    - Elliot</h4>);
					} else {
						display.push(<div><button onClick={this.DismissVideo} className="btn btn-danger">dismiss video</button><div className="embed-responsive embed-responsive-1by1 service-video">
							<iframe title="message" src={message} width="160" height="90" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
						</div></div>);
					}
				}
			}
		}
		return display;
	}

	DismissVideoCallback() {
		var temp = this.state.user_details;
		temp[0].dismissed_message = true;
		this.setState({ user_details: temp });
	}

	DismissVideo(event) {
		event.preventDefault();
		axios.post(APIHost + '/api/updateEncouragingMessageID', {
			next_message: this.state.user_details.current_message + 1,
			dismiss: true,
			withCredentials: true
		}).then(() => {
			this.DismissVideoCallback();
		}).catch(error => {
			console.log("updateEncouragingMessageID failed");
			this.setState({ server_failure: true });
		});
	}

	DisplaySprintHistory() {
		var display = [];
		var items = [];
		display.push(<h6 className="mb-2 sep-heading">View your previous sprints</h6>);
		if (this.state.sprint_history == null) {
		} else {
			if (this.state.sprint_history.length > 3 && this.state.sprint_history.length != 4) {
				
				for (var i = this.state.sprint_history.length - 4; i < this.state.sprint_history.length; i++) {
					items.push(<li><a href="/" sprint_id={this.state.sprint_history[i].sprint_id} onClick={this.DirectToSprint}>{this.state.sprint_history[i].start_date}</a></li>);
				}
				display.push(<div className="two-sided-box-in"><ul>{items}<p>Head to the <Link history={this.props.history} to={{ pathname: '/History', state: { loggedIn: true } }}>History page</Link> to
			see all your past Sprints</p> </ul></div>);
			} else {
				/* display.push(<h6 className="mb-3">View your previous sprints</h6>); */
				for (var i = 0; i < this.state.sprint_history.length; i++) {
					items.push(<li><a href="/" sprint_id={this.state.sprint_history[i].sprint_id} onClick={this.DirectToSprint}>{this.state.sprint_history[i].start_date}</a></li>);
				}
				display.push(<div className="two-sided-box-in"><ul className="bg-less-blue">{items}</ul></div>);
			}
		}
		return display;
	}

	DisplayAllChapters() {
		var display = [];
		var items = [];
		if (this.state.done_chapters.length === 0) {
			display.push(<h6 className="mb-2 sep-heading">This section will contain a list of chapters that you have completed in previous Sprints</h6>);
		} else {
			display.push(<h6 className="mb-2 sep-heading">View your previous chapters</h6>);
			if (this.state.done_chapters.length > 3 && this.state.done_chapters.length != 4) {
				for (var i = this.state.done_chapters.length - 4; i < this.state.done_chapters.length; i++) {
					items.push(<li><a href="/" chapter_id={this.state.done_chapters[i].chapter_id} sprint_id="none" onClick={this.DirectToChapter}>{this.state.done_chapters[i].description}</a></li>);
				}
				
				display.push(<div className="chapter_history two-sided-box-in"><ul>{items}</ul><p>Head to the <Link history={this.props.history} to={{ pathname: '/History', state: { loggedIn: true } }}>History page</Link> to
			see all your past Chapters</p> </div>);
			} else {
				for (var i = 0; i < this.state.done_chapters.length; i++) {
					items.push(<li><a href="/" chapter_id={this.state.done_chapters[i].chapter_id} sprint_id="none" onClick={this.DirectToChapter}>{this.state.done_chapters[i].description}</a></li>);
				}
				display.push(<div className="chapter_history two-sided-box-in"><ul className="bg-less-blue">{items}</ul></div>);
			}

		}
		return display;
	}

	CreateFirstSprintCallback() {
		this.setState({ loading: true });
	}

	CreateFirstSprint(CreateFirstSprintCallback) {
		var sprint = [{ chapter_id: 1 }, { chapter_id: 2 }];
		axios.post(APIHost + '/api/createSprint', {
			sprint: sprint,
			withCredentials: true
		}).then(res => {
			CreateFirstSprintCallback();
		}).catch(error => {
			console.log("CreateFirstSprint failed");
			this.setState({ server_failure: true });
		});
	}

	onSubmitCreateSprint(event) {
		event.preventDefault();
		this.CreateFirstSprint(this.CreateFirstSprintCallback);
	}

	SendConfirmEmailCallback() {
		this.setState({display_update_email: false});
	}

	SendConfirmEmail() {
		axios.post(APIHost + '/api/confirmEmail', {
			withCredentials: true
		}).then(res => {
			this.SendConfirmEmailCallback();
		}).catch(error => {
			console.log("ConfirmEmail failed " + error);
			this.setState({ server_failure: true });
		});
	}

	ConfirmEmail(event) {
		event.preventDefault();
		this.SendConfirmEmail();
	}

	SendUpdateEmail() {
		axios.post(APIHost + '/api/updateEmail', {
			withCredentials: true,
			email: this.state.email
		}).then(res => {
			axios.post(APIHost + '/api/confirmEmail', {
				withCredentials: true
			}).then(res => {
				this.SendConfirmEmailCallback();
			}).catch(error => {
				console.log("ConfirmEmail failed " + error);
				this.setState({ server_failure: true });
			});
		}).catch(error => {
			console.log("ConfirmEmail failed " + error);
			this.setState({ server_failure: true });
		});	
	}

	UpdateEmail(event) {
		event.preventDefault();
		this.SendUpdateEmail();
	}

	DisplayUpdateEmail() {
		var display = [];
		display.push(<div><p>Please confirm your email: {this.state.user_details.email}. <button className="btn btn-primary my-3" onClick={this.ConfirmEmail}>It's Correct!</button></p>
    								<p>Or update it here:</p>
    								<input type="email" id="email" name="email" 
    									placeholder="Email address" onChange={this.onChange} />
  								<button className="btn btn-primary my-3" onClick={this.UpdateEmail}>Update Email</button>
						<hr /></div>);
		return display;
	}

	ProfileSetup() {
		var display = [];
		if (!this.state.first_visit) {
			return null;
		} else {
			//className="embed-responsive embed-responsive-4by3 service-video"
			display.push(<div className="container">
				<h3 className="mb-2">Welcome to your My Core Insights profile page!</h3>
				<div className="row">
					<div className="col-md-6">
					{this.state.display_update_email && this.DisplayUpdateEmail()}

						<h5 className="mb-2">Let's get things setup for you...</h5>
					
		Capacity is a number that represents the effort you think you'll have to put towards the training. Determining your Capacity is an easy but important first step in getting started. Watch the video on this page or head straight over to the <Link history={this.props.history} to={{ pathname: '/SetCapacity', state: { loggedIn: true, account_type: this.state.user_details.account_type } }}>Set Capacity</Link> page to figure it out.
         </div>
					<div className="col-md-6">
						<div className="embed-responsive embed-responsive-16by9 service-video">
							<iframe title="vimeo" src="https://player.vimeo.com/video/562067875" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
						</div>
					</div>
				</div>
			</div>);
		}
		return display;
	}

	onChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	onSubmit(event) {
		event.preventDefault();
		if (this.state.code === "MyCoreInsights") {
			this.setState({ authorized: true })
		}
	}

	DisplayCapacity() {
		var display = []
		if (!this.state.capacity === 0) {
			display.push(<p>Training Capacity: <h6 className="d-inline-block">{this.state.capacity}</h6></p>);
		}
		return display;
	}

	SendMessageCallBack(response, errorMessage) {
		if (response) {
			this.setState({
				messageFailed: false,
				nameEmpty: false,
				emailEmpty: false,
				messageEmpty: false,
				messageSent: true
			});
		} else {
			if (errorMessage === "name empty") {
				this.setState({ nameEmpty: true });
			} else if (errorMessage === "email empty") {
				this.setState({ emailEmpty: true });
			} else if (errorMessage === "message empty") {
				this.setState({ messageEmpty: true });
			} else {
				this.setState({ messageFailed: true });
			}
		}

	}

	SendMessage(SendMessageCallBack) {
		var passed = true;
		if (this.state.name === '') {
			passed = false;
			this.SendMessageCallBack(false, "name empty");
		}
		if (this.state.email === '') {
			passed = false;
			this.SendMessageCallBack(false, "email empty");
		}
		if (this.state.message === '') {
			passed = false;
			this.SendMessageCallBack(false, "message empty");
		}
		if (passed) {
			//axios.post('https://ubuntu@ec2-18-188-187-150.us-east-2.compute.amazonaws.com:8000/api/sendMessage', {
			axios.post(APIHost + '/api/sendMessage', {
				email: this.state.email,
				message: this.state.message,
				name: this.state.name,
				withCredentials: true
			}).then(res => {
				this.SendMessageCallBack(true);
			}).catch(error => {
				console.log("error is " + error.response.data.errorMessage);
				this.SendMessageCallBack(false, error.response.data.errorMessage);
			});
		}
	}

	onSubmitMessage(event) {
		event.preventDefault();
		this.setState({
			messageFailed: false,
			nameEmpty: false,
			emailEmpty: false,
			messageEmpty: false,
			messageFailed: false
		});
		this.SendMessage(this.SendMessageCallBack);
	}

	DisplayEmailError() {
		var display = [];
		display.push(<h4><font color="red">It appears there might be an issue with your email address: {this.state.user_details.email}.
											Is there a possibe mispelling or error? This will prevent you from being able to re-login. Please 
											use the contact page to let support know your corrected email address and we will update it immediately! Thank you!</font></h4>);
		return display;
	}

SubmitAccountTypeCallback() {
	var local_details = this.state.user_details;
	local_details.account_type = this.state.account_type;
	this.setState({first_visit: true, pick_account_type: false, user_details: local_details});
}

SubmitAccountType() {
	axios.post(APIHost + '/api/setAccountType', {
		type: this.state.account_type,
		withCredentials: true
	}).then(res => {
		this.SubmitAccountTypeCallback();
	}).catch(error => {
		console.log("error is " + error.response.data.errorMessage);
		this.setState({server_failure: true});
	});
}

onSubmitAccountType(event) {
	event.preventDefault();
	this.SubmitAccountType();
}

AccountTypeChange(event) {
	this.setState({account_type: event.target.value});
}

UpdateAccountTypeCallback(type) {
	var user_temp = this.state.user_details;
	if (type == 'Self-directed') {
		user_temp.account_type = 'Self-directed';
	} else {
		user_temp.account_type = 'Personal Training';
	}
	this.setState({user_details: user_temp});
}

UpdateAccountType(event) {
	event.preventDefault();
	var value = event.target.value;
	axios.post(APIHost + '/api/updateAccountType', {
		type: value,
		withCredentials: true
	}).then(res => {
		this.UpdateAccountTypeCallback(value);
	}).catch(error => {
		console.log("error is " + error);
		this.setState({server_failure: true});
	});
}
	render() {
		if (this.state.loading) {
			return (<div className="Home"><Header /><h3 className="p-3">Loading...</h3></div>);
		} else if (this.state.pick_account_type) {
   return (
          <section id="testimonial" className="testimonial-area">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="testimonial-left-content mt-45">
                    <h4 className="title mb-2">You are steps away...</h4>
                    <p className="text">...from starting your journey through Mental Fitness Training. First, please pick an account type
                    that fits how you want to approach the Training. With the Self-directed version you:</p>
                                                   <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>are guided by the website through the Training</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>direct and drive your own Journey</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>can request one-on-one sssions with Elliot as needed </h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>purchase credits as you go for $19.99 unlocking sections of the training</h6>
                                            </div>
                                            </li>
            
                                    </ul>
                      <p className="text">With the Personal Training version you:</p>
                                                                         <ul className="list-group list-group-flush">
                                        <li className="list-group-item" style={{ borderTop: 'none' }}>
                                            <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>are guided by Elliot throughout your Journey</h6>
                                            </div>
                                           </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>purchase sessions with Elliot (normally $135 temporarily $99)</h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>don't buy the training directly. It is included in your sessions </h6>
                                            </div>
                                            </li>
                                        <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <CheckCircleTwoTone style={{fontSize:'16px'}} twoToneColor="#52c41a" className="mr-2" /><h6>can request additional sessions with Elliot at any time </h6>
                                            </div>
                                            </li>
            
                                    </ul>
                    <p className="text">Please make your selection. You can change it at any time</p>
             <div className="checkboxOptions" onChange={this.AccountTypeChange}>    		 
    	<div className="floatBlock">			
    	<input type="radio" className="form-control" name="account_type" value="Self-directed" />Self-directed     												
    	</div>
    	<div className="floatBlock">
    	<input type="radio" className="form-control" name="account_type" value="Personal Training" />Personal Training 
		</div>
		</div>
                  <div className="form-input light-rounded-buttons mt-5 text-center">
                    <button onClick={this.onSubmitAccountType} className="main-btn light-rounded-two">Submit</button>                
                </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="author-info d-flex align-items-center justify-content-center pt-5">
                    <iframe title="video" src="https://player.vimeo.com/video/562068275" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  </div>

              </div>
              </div>
            </div>
          </section>
      );
		} else if (this.state.logged_in || this.props.loggedIn || (this.props.location.state != null && this.props.location.state.loggedIn)) {
			var display = [];
			var display_account = [];
			if (this.state.ambassador_status) {
				display.push(<div><Link to={{ pathname: '/AmbassadorCentral', state: { member_id: this.state.member_id } }}>Ambassador Central</Link><br /></div>);
			}
			if (this.state.user_details.account_type == 'Self-directed') {
				display_account.push(<button className="btn btn-primary my-3" value='Personal Training'onClick={this.UpdateAccountType}>Change to Personal Training</button>)
			} else {
				display_account.push(<button className="btn btn-primary my-3" value='Self-directed' onClick={this.UpdateAccountType}>Change to Self-directed</button>);
			}
			return (
				<div className="Home">
					<Header history={this.props.history} loggedIn={true} />
					<div className="container_profile py-3">
						{this.state.server_failure && <ServerFailure />}
						<div className="row">
							<aside className="col-md-4">
								<section className="two-sided-box">
									<div className="two-sided-box-in">
										<div className="">

											<div className="mb-2"><h4>Welcome,<br /> {this.state.user_details && this.state.user_details.name}!</h4></div>

											<h6>Credits available: {this.state.credits}<br />
										Your Member ID is: {this.state.member_id}<br />
												<div className="mt-2">
												Account Type: {this.state.user_details.account_type}
												{display_account}<br />
													{display}
													<Link to="/getCredits">Get credits</Link><br />
													<Link to={{ pathname: "/ScheduleTraining", state: { loggedIn: true } }}>Schedule a training session</Link><br />
													<Link to={{ pathname: "/Journal", state: { loggedIn: true } }}>Your Journal</Link>
												</div>
											</h6>

										</div>
									</div>
									<div className="two-sided-box">
										<div className="two-sided-box-in">
											{!this.state.loading && this.DisplayProgress()}
										</div>
									</div>
								</section>
								<section className="two-sided-box">
									<div className="">

										{!this.state.loading && this.DisplaySprintHistory()}
									</div>
								</section>
								<section className="two-sided-box">
									<div className="">

										{!this.state.loading && this.DisplayAllChapters()}
									</div>
								</section>
							</aside>
							<div className="col-md-8">
								<section className="two-sided-box">
									<div className="two-sided-box-in">
										<div className="col-md-12">
											{!this.state.loading && this.ProfileSetup()}
											{!this.state.loading && this.state.display_email_error && this.DisplayEmailError()}
											<p>Register for upcoming workshops and webinars <Link history={this.props.history} to={{ pathname: '/Workshops', state: { loggedIn: true } }}>here</Link></p>
											{!this.state.loading && this.state.mission_set && this.state.brand_set && this.DisplayMission()}
											{!this.state.loading && this.DisplayMessage()}
											{!this.state.loading && this.DisplaySprint()}
											{!this.state.loading && this.DisplaySprintStatus()}
										</div>
									</div>
								</section>

							</div>
						</div>
					</div>
					<Footer history={this.props.history} loggedIn={true} />
				</div>
			);

		} else {
			return (<div className="Home">
				<Header history={this.props.history} loggedIn={false} />

				<Welcome history={this.props.history} loggedIn={false} mobile={this.state.mobile} />
				<Moment />
				<Footer loggedIn={false} />
			</div>);
		}
	}
}

export default Home;