import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import me from './images/me.jpg';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import {APIHost} from './GlobalVariables';

const ServerFailure = () => (
<h2><font color="red">We apologize. An error occurred while retrieving information from our server. Please refresh the page and try again.<br />
If the problem persists, please reach out to support <Link to={{ pathname: '/Contact', state: { preview: true, loggedIn: true } }}>here</Link> and let us know.</font></h2>
);

class CreateSprint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			capacity: 0,
			objectives: [],
			themes: [],
			stories: [],
			purchased_objectives: [],
			story_descriptions: [],
			story_points: [],
			sprint_stories: [],
			active_themes: [],
			not_active_themes: [],
			completed_themes: [],
			credits: 0,
			server_failure: false,
			loading: true
		}
		this.Toggle = this.Toggle.bind(this);
		this.NewToggle = this.NewToggle.bind(this);
		this.DisplayThemes = this.DisplayThemes.bind(this);
		this.GetThemes = this.GetThemes.bind(this);
		this.PurchaseObjective = this.PurchaseObjective.bind(this);
		this.GetPurchases = this.GetPurchases.bind(this);
		this.AddToSprint = this.AddToSprint.bind(this);
		this.DisplaySprint = this.DisplaySprint.bind(this);
		this.RemoveFromSprint = this.RemoveFromSprint.bind(this);
		this.GetCapacity = this.GetCapacity.bind(this);
		this.SubmitSprint = this.SubmitSprint.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.SubmitSprintCallback = this.SubmitSprintCallback.bind(this);
		this.GetCredits = this.GetCredits.bind(this);
		this.StopChecked = this.StopChecked.bind(this);
		this.GetCompletedThemes = this.GetCompletedThemes.bind(this);
}

componentDidMount() {
window.scroll(0,0);
	var all_promises = [];
	var active_themes_promise = this.GetActiveThemes().then(data => {
		this.setState({active_themes: data});
	}).catch((error) => {
		console.log("GetActiveThemes failed");
		this.setState({server_failure: true});
	});
	
	var completed_themes_promise = this.GetCompletedThemes().then(data => {
		if (data != "nothing completed") {
			this.setState({completed_themes: data});
			data.forEach((element) => {
				console.log("completes is " + element.id);
			});
		}
	}).catch((error) => {
		console.log("GetCompletedThemes failed");
		this.setState({server_failure: true});
	});

	var theme_promise = this.GetThemes().then(data => {
		let temp_themes = data;
		temp_themes.forEach((element) => {
			element.plus = true;
			element.hide = true;
		});
		this.setState({themes: temp_themes})
		return data;
	}).catch((error) => {
		console.log("GetThemes failed");
		this.setState({server_failure: true});
	});
	
	var objective_promise = this.GetObjectives().then(data => {
   			let local_objectives = data;
   			local_objectives.forEach((element) => {
   			element.plus = true;
   			element.hide = true;
   			element.purchased = false;
   			element.added = false;
   			});
   			this.setState({objectives: local_objectives})
   			return data;
   	}).catch((error) => {
		console.log("GetObjectives failed");
		this.setState({server_failure: true});
	});
   	
   	var purchase_promise = this.GetPurchases().then(data => {
    	if (data === "none") {
    		console.log("didn't purchase anything");
    	} else {
    		let local_objectives = this.state.objectives;
    		var flag = false;
    		this.setState({purchased_objectives: data});
    		local_objectives.forEach((element) => {
    			flag = false;
    			for (var j=0; j<this.state.purchased_objectives.length; j++) {
   					if (this.state.purchased_objectives[j].objective_id == element.id) {
   						flag = true;
   					}
   				}
   				if (flag) {
   					element.purchased = true;
   				} else {
   					element.purchased = false;
   				}
    		});
    		this.setState({objectives: local_objectives});
    	}
    	return data;
    }).catch((error) => {
		console.log("GetPurchases failed");
		this.setState({server_failure: true});
	});
    		
    var stories_promise = this.GetStories().then(data => {
    	this.setState({stories: data});
		return data;
	}).catch((error) => {
		console.log("GetStories failed");
		this.setState({server_failure: true});
	});
      
	var credits_promise = this.GetCredits().then(data => {
		this.setState({credits: data});
		return data;
	}).catch((error) => {
		console.log("GetCredits failed");
		this.setState({server_failure: true});
	});
	
	var capacity_promise = this.GetCapacity().then(data => {
    	this.setState({capacity: data.capacity});
    	return data;
    }).catch((error) => {
		console.log("GetCapacity failed");
		this.setState({server_failure: true});
	});
	
	all_promises.push(theme_promise);
	all_promises.push(objective_promise);
	all_promises.push(purchase_promise);
	all_promises.push(stories_promise);
	all_promises.push(credits_promise);
	all_promises.push(capacity_promise);
	all_promises.push(active_themes_promise);
	all_promises.push(completed_themes_promise);
	
	Promise.all(all_promises).then( () => {
	  	let local_stories = this.state.stories;
		let local_objectives = this.state.objectives;
		console.log("in Promise " + this.state.stories.length);
		var points=0;
  		local_objectives.forEach((element) => {
  		points=0;
  		for (var j=0; j<this.state.stories.length; j++) {
  			if (this.state.stories[j].objective_id == element.id) {
  				points = parseInt(points)+parseInt(this.state.stories[j].points);
  				  			console.log("element in the Promise " + points);

  			}
		}
		element.points = points;
  		});
    	var local_not_active = [];
    	let local_active = this.state.active_themes;
    	var cnt = 0;
    	var flag = false;
    	this.state.themes.forEach((element) => {
    		flag = false;
    		this.state.active_themes.find((theme) => {
    			if (element.id == theme.id) {
    				console.log("setting flag to true " + element.description);
    				flag = true;
    			}
    		});
    		if(!flag) {
    			element.plus = true;
    			element.hide = true;
    			local_not_active.push(element);

    		} else { 
    			console.log("found a local active " + element.description);
    			local_active[cnt].description = element.description;
    			local_active[cnt].plus = true;
    			local_active[cnt].hide = true;
    			cnt++;
    		}
    	});
    	let another_not_active = [];
		local_not_active.forEach((element) => {
			flag = false;
			this.state.completed_themes.find((theme) => {
				if (element.id == theme.id) {
					flag = true;
				}
			});
			if(!flag) {
				another_not_active.push(element);
			}
		});
        this.setState({active_themes: local_active, not_active_themes: another_not_active, stories: local_stories, objectives: local_objectives, loading: false})
  	});
}

PurchaseObjective(event) {
	const id = event.currentTarget.getAttribute('objectiveId');
	const theme = event.currentTarget.getAttribute('theme');
    axios.post(APIHost + '/api/purchaseObjective', {
	id: id,
	withCredentials: true
	}).then(res => {
	let temp_objectives = this.state.objectives;
	temp_objectives.find((element) => {
		if (element.id == id) {
			element.purchased = true;
		}
	});
    	this.setState({
   			objectives: temp_objectives});
    	
    	}).catch(error => {
    
    });
}

async GetThemes() {
	const res = await axios.get(APIHost + '/api/getThemes', {withCredentials: true});
	return await res.data.themes; 
}

async GetObjectives() {
	const res = await axios.get(APIHost + '/api/getObjectives', {withCredentials: true});
	return await res.data.objectives; 
}

async GetStories() {
	const res = await axios.get(APIHost + '/api/getStories', {withCredentials: true});
	return await res.data.stories; 
}

async GetPurchases() {
	const res = await axios.get(APIHost + '/api/purchasedObjectives', {withCredentials: true});
	if (res.data.message === "none") {
		return "none";
	} else {
		return await res.data.purchased_objectives;
	}
	
}

async GetCredits() {
	const res = await axios.get(APIHost + '/api/getCredits', {withCredentials: true});
	return await res.data.credits;
}

async GetCapacity() {
	const res = await axios.get(APIHost + '/api/getCapacity', {withCredentials: true});
	return await res.data.capacity;
}

async GetActiveThemes() {
	const res = await axios.get(APIHost + '/api/getActiveThemes', {withCredentials: true});
	return await res.data.themes;
}

async GetCompletedThemes() {
	const res = await axios.get(APIHost + '/api/getCompletedThemes', {withCredentials: true});
	if (res.data == "nothing completed") {
		return await res.data;
	} else {
		return await res.data.themes;
	}
}

StopChecked(event) {
	this.checked = false; // reset first
    event.preventDefault();
}

DisplayThemes(themes, which_theme) {
	let for_display = [];   
    var i=0;
    var j=0;
    var k=0;
    var objective_cnt=1;
    for (i=0; i<themes.length; i++ ) {
    let objectives = [];
    for (j=0; j<this.state.objectives.length; j++ ) {
    let stories = [];
   	if (themes[i].id === this.state.objectives[j].theme_id) {
   	for (k=0; k<this.state.stories.length; k++) {
   	    if (this.state.objectives[j].id === this.state.stories[k].objective_id) {        		
        	var story_name = "objective"+j+"story"+k;
				if(this.state.stories[k].in_sprint) {
        		      stories.push(<li><input onClick={this.RemoveFromSprint} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" storyId={this.state.stories[k].id} name={story_name} value={story_name} checked />
                         {this.state.stories[k].description} 
                         </li>);
                } else {
                if(!this.state.objectives[j].purchased) {
                    	stories.push(<li><input onClick={this.StopChecked} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" storyId={this.state.stories[k].id} name={story_name} value={story_name} />
                         {this.state.stories[k].description} 
                         </li>);
                } else {
                stories.push(<li><input onClick={this.AddToSprint} objectiveId={this.state.objectives[j].id} theme={i+1} type="checkbox" storyId={this.state.stories[k].id} name={story_name} value={story_name} />
                         {this.state.stories[k].description} 
                         </li>);
                }
                }                       
        }
     }
        	
     var name="objective" + (objective_cnt);
     let button_content = [];
    if (this.state.objectives[j].added) {
     	button_content.push(<div align="center"><button objectiveId={this.state.objectives[j].id} theme={i+1} onClick={this.RemoveFromSprint}>Remove from sprint</button></div>);
     } else if (!this.state.objectives[j].purchased) {
     	if (this.state.credits > 0) {
        	button_content.push(<div align="center"><button objectiveId={this.state.objectives[j].id} theme={i+1} onClick={this.PurchaseObjective}>Purchase Objective</button></div>);
    	} else {
    		button_content.push(<Popup trigger={<button>Purchase Objective</button>} position="right center">
    <div>Please <Link to="/getCredits">Purchase</Link> credits</div>
  </Popup>);
    	}
     } else if (this.state.objectives[j].purchased) {
        button_content.push(<div align="center"><button objectiveId={this.state.objectives[j].id} onClick={this.AddToSprint}>Add to Sprint</button></div>);
     }

     objectives.push(<div className="panel-body">
						<h5 className="panel-title">
                           <i role="button" name={name}
                        onClick={this.Toggle} id={this.state.objectives[j].id} className={this.state.objectives[j].plus ? 'fa fa-plus plus-minus' : 'fa fa-minus plus-minus'}></i>
                           {this.state.objectives[j].description} (total points {this.state.objectives[j].points}){button_content}
                        </h5>
                   <div className={this.state.objectives[j].hide ? 'hide' : ''}>
                    <div className="panel-objective">
                    <ul>{stories}</ul></div></div></div>);
             objective_cnt++;       
        	}
        }

        var obj_name="theme"+(i+1);
        for_display.push(<div className="panel-group"><div className="panel-heading"><h4>
        <i role="button" name={obj_name} id={themes[i].id} onClick={(e) => {this.NewToggle(e, themes, which_theme)}} className={themes[i].plus ? 'fa fa-plus plus-minus' : 'fa fa-minus plus-minus'}></i>
        {themes[i].description}
        </h4></div><div className={themes[i].hide ? 'hide' : ''}>{objectives}</div></div>);
    }
    return for_display;
}

NewToggle(event, themes, which_theme) {
	event.preventDefault();
	const id = event.currentTarget.getAttribute('id');
	themes.find((element) => {
		if (element.id == id) {
			element.plus = !element.plus;
			element.hide = !element.hide;
		}
	});
	if (which_theme == 'active') {
		this.setState({active_themes: themes});
	} else {
		this.setState({not_active_themes: themes});
	}
}
	
Toggle(event) {
event.preventDefault();

		const attribute = event.currentTarget.getAttribute('name');
		const id = event.currentTarget.getAttribute('id');
		const themereg = /theme/;
		const objectivereg = /objective/;
		let temp_themes = this.state.themes;
		let temp_objectives = this.state.objectives;
		if(themereg.test(attribute)) {
		temp_themes.find((element) => {
			if (element.id == id) {
				element.plus = !element.plus;
				element.hide = !element.hide;
			}
		});
		this.setState({themes: temp_themes});
		} else if (objectivereg.test(attribute)) {
			temp_objectives.find((element) => {
				if (element.id == id) {
					element.hide = !element.hide;
					element.plus = !element.plus;
				}
			});
			this.setState({objectives: temp_objectives});
		}
}

RemoveFromSprint(event) {
	const objective_id = event.currentTarget.getAttribute('objectiveId');
	const story_id = event.currentTarget.getAttribute('storyId');
	const theme = event.currentTarget.getAttribute('theme');
	const name = event.currentTarget.getAttribute('name');
	let temp_stories = [];
	var flag = false;
	if (name) {
	var cnt=0;
		temp_stories = this.state.stories;
		temp_stories.forEach((element) => {
			if (element.id == story_id) {
				element.in_sprint = false;
			}
		})
		this.setState({stories: temp_stories});
		let temp_sprint_stories = [];
		for(var i=0; i<this.state.sprint_stories.length; i++) {
			if (this.state.sprint_stories[i].id == story_id && this.state.sprint_stories[i].objective_id == objective_id) {
				continue;
			} else {
			//flag = true;
			temp_sprint_stories.push({});
			temp_sprint_stories[cnt].description = this.state.sprint_stories[i].description;
			temp_sprint_stories[cnt].points = this.state.sprint_stories[i].points;
			temp_sprint_stories[cnt].objective_id = this.state.sprint_stories[i].objective_id;
			temp_sprint_stories[cnt].id = this.state.sprint_stories[i].id;
			cnt++;
			}
		}
		//flag = false;
		let local_objectives = this.state.objectives;
		temp_sprint_stories.find((element) => {
			if (element.objective_id == objective_id) {
				flag = true;
			}
		});

		if(!flag) {
		local_objectives.find((element) => {
		if (element.id == objective_id) {
			element.added = false;
			}
		});
		}

		this.setState({sprint_stories: temp_sprint_stories,
		objectives: local_objectives});
	} else {
	
	var cnt=0;
	for (var i = 0; i<this.state.sprint_stories.length; i++) {
		if (this.state.sprint_stories[i].objective_id == objective_id) {
			continue;
		} else {
			temp_stories.push({});
			temp_stories[cnt].description = this.state.sprint_stories[i].description;
			temp_stories[cnt].points = this.state.sprint_stories[i].points;
			temp_stories[cnt].objective_id = this.state.sprint_stories[i].objective_id;
			temp_stories[cnt].id = this.state.sprint_stories[i].id;
			cnt++;
		}
	}
	let local_objectives = this.state.objectives;
	local_objectives.find((element) => {
		if (element.id == objective_id) {
			element.added = false;
		}
	});
	this.setState({sprint_stories: temp_stories, objectives: local_objectives});
	/*this.setState({sprint_stories: temp_stories,
				["theme"+theme+"Objective"+objective_id+"Added"]: false})*/
	temp_stories = this.state.stories;
	for (var i=0; i<temp_stories.length; i++) {
		   	temp_stories[i].description = this.state.stories[i].description;
  			temp_stories[i].objective_id = this.state.stories[i].objective_id;
  			temp_stories[i].id = this.state.stories[i].id;
  			temp_stories[i].points = this.state.stories[i].points;
  			if (temp_stories[i].objective_id == objective_id) {
  				temp_stories[i].in_sprint = false;
			}
	}
	this.setState({stories: temp_stories});
	}
}

AddToSprint(event) {
	const story_id = event.currentTarget.getAttribute('storyId');
	const name = event.currentTarget.getAttribute('name');
	const objective_id = event.currentTarget.getAttribute('objectiveId');

	if (name) {
	var flag = false;
	var cnt=this.state.sprint_stories.length;
		let temp_stories = this.state.stories;
		temp_stories.forEach((element) => {
			if (element.id == story_id) {
				element.in_sprint = true;
			}
		})
		this.setState({stories: temp_stories});
		let temp_sprint_stories = this.state.sprint_stories;
		for(var i=0; i<this.state.stories.length; i++) {
			if (this.state.stories[i].id == story_id) {
			temp_sprint_stories.push({});
			temp_sprint_stories[cnt].description = this.state.stories[i].description;
			temp_sprint_stories[cnt].points = this.state.stories[i].points;
			temp_sprint_stories[cnt].objective_id = this.state.stories[i].objective_id;
			temp_sprint_stories[cnt].id = this.state.stories[i].id;
			cnt++;				
			} else {
				continue;
			}
		}
		let local_objectives = this.state.objectives;
		local_objectives.find((element) => {
			if(objective_id == element.id) {
				element.added = true;
			}
		});
		
		this.setState({sprint_stories: temp_sprint_stories, objectives: local_objectives});
	} else {
   	let stories_for_sprint = this.state.sprint_stories;
   	var cnt=0;
   	if (this.state.sprint_stories.length>0) {
   		cnt=this.state.sprint_stories.length;
   	}
   	let local_stories = this.state.stories;
   	for(var i=0; i<this.state.stories.length; i++) {
   		  	local_stories[i].description = this.state.stories[i].description;
  			local_stories[i].objective_id = this.state.stories[i].objective_id;
  			local_stories[i].id = this.state.stories[i].id;
  			local_stories[i].points = this.state.stories[i].points;
   		if (this.state.stories[i].objective_id == objective_id) {
   			local_stories[i].in_sprint = true;
   		   	stories_for_sprint.push({});
   			stories_for_sprint[cnt].description = this.state.stories[i].description;
   			stories_for_sprint[cnt].points = this.state.stories[i].points;
   			stories_for_sprint[cnt].objective_id = this.state.stories[i].objective_id;
   			stories_for_sprint[cnt].id = this.state.stories[i].id;
   			cnt++;
   		}  	
   	}   	

   	this.setState({sprint_stories: stories_for_sprint, stories: local_stories});
		let local_objectives = this.state.objectives;
		local_objectives.find((element) => {
			if (element.id == objective_id) {
				element.added = true;
			}
		})
		this.setState({objectives: local_objectives});
   	} 
}

DisplaySprint() {
	let sprint = [];
	let rows = [];
	var points = 0;
	for (var i=0; i<this.state.sprint_stories.length; i++) {
		rows.push(<tr><td>{this.state.sprint_stories[i].description}</td><td align="center">{this.state.sprint_stories[i].points}</td></tr>);
		points = points+this.state.sprint_stories[i].points;
	}
	if(rows.length>0) {
		sprint.push(<table className="table table-bordered"><thead><tr><th>Story</th><th>Points</th></tr></thead><tbody>{rows}<tr><td>Total</td><td align="center">{points}</td></tr></tbody></table>);
	} else {
		sprint.push(<table className="table table-bordered"><thead><tr><th>Story</th><th>Points</th></tr></thead><tbody>{rows}</tbody></table>);

	}
	if (points > this.state.capacity) {
		sprint.push(<font color="red"><h4>Capacity: {this.state.capacity}</h4><h5>You have exceeded your capacity</h5></font>);
	} else {
		sprint.push(<h4>Capacity: {this.state.capacity}</h4>);
	}
	if(this.state.sprint_stories.length>0) {
		sprint.push(<button onClick={this.onSubmit} type="submit" className="custom-btn">Create Sprint</button>);
	}
	return sprint;

}

SubmitSprintCallback(status) {
	if(status) {
		this.props.history.push("/profile");
	} else {	
		console.log("couldn't create the sprint");
	}
}

SubmitSprint(SubmitSprintCallback) {
	axios.post(APIHost + '/api/createSprint', {
      stories: this.state.sprint_stories,
      withCredentials: true
    }).then(res => {
    	SubmitSprintCallback(true);
    }).catch((error) => {
		console.log("SubmitSprint failed");
		this.setState({server_failure: true});
	});
}

onSubmit(event) {
event.preventDefault();
this.SubmitSprint(this.SubmitSprintCallback);
}
	
render() {
		return (
		<div className="Content">
			<Header />
			<div className="container_profile">
			<div className="row">
                <aside className="col-md-6">
                    <section className="two-sided-box">
            <div className="two-sided-box-in">
                        <div className="col-md-12">                        
		{!this.state.loading && this.DisplaySprint()}
                		</div>                	
                </div>           
           </section>
                </aside>
                <div className="col-md-6">
		<section className="theme">
  			<div className="container">
         			<div className="col-md-10 col-md-offset-1">
            			<h2>
                 			Active Themes
            			</h2>					

          		 {!this.state.loading && this.DisplayThemes(this.state.active_themes, 'active')}
<hr />
<h2>Future Themes</h2>
          		 {!this.state.loading && this.DisplayThemes(this.state.not_active_themes, 'not active')}

           		</div> {/*col-md-10*/}         	
            </div> {/*container*/}
        </section>
        </div>
        </div>
        </div>
			<Footer />
		</div>
		);		
	}
}

export default CreateSprint;